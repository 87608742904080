@if (inputColumnType() === 'image') {
  <ft-magic-image
    [imageSrc]="elementValue()"
    [uploadType]="'image'"
    [disabled]="false"
    (changedImageSrc)="handleChangedImage($event)"
  ></ft-magic-image>

  <!-- class="image-cell" -->
  [src]="elementValue()" [attr.data-element]="column().elementId" [ngStyle]="column().imageStyle" [alt]="column().name +
  ' image'"
} @else {
  <mat-form-field
    class="edit-cell-component w-100"
    [ngClass]="isHovered ? 'tertiary-border' : ''"
    color="primary"
    [matTooltip]="tooltipValue()"
    [matTooltipDisabled]="!column().hasTooltip || !tooltipValue()"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
  >
    <!--           [ngClass]="[column().mainClass, column().cellClass, { 'has-selections': selectedValues().length }]"
 -->

    @if (column().selectOptions?.length) {
      @if (column().selectMultiple) {
        <mat-select
          multiple
          class="edit-cell"
          [class.has-selections]="!!selectedValues().length"
          [ngClass]="[column().mainClass, column().cellClass]"
          [ngStyle]="editStyle()"
          [class.more-than-2-values]="selectedValues().length > 2"
          [(ngModel)]="selectedValues"
          [placeholder]="placeHolder()"
          [attr.data-element]="elementId()"
          [attr.required]="column().selectNone ? null : ''"
          (selectionChange)="onSelectInput(column(), $event)"
          (blur)="finishEditing(element)"
          (keydown.enter)="finishEditing(element); $event.preventDefault()"
        >
          <mat-select-trigger
            class="edit-select-trigger"
            [ngStyle]="column().cellStyle"
            [class.two-values]="selectedValues().length == 2"
            [class.more-than-2-values]="selectedValues().length > 2"
          >
            {{ selectedValues().length ? selectedValues().join(column().arraySeparator) : '' }}
          </mat-select-trigger>

          @for (opt of column().selectOptions ?? []; track optIndex; let optIndex = $index) {
            <mat-option [value]="opt">
              {{ opt }}
            </mat-option>
          }
        </mat-select>

        <!-- Clear Button -->
        @if (selectedValues().length) {
          <button (click)="clearSelections(column(), $event)" class="clear-button">
            <fa-icon [icon]="faClose"></fa-icon>
          </button>
        }
      } @else {
        <!--           [ngClass]="[column().mainClass, column().cellClass]"
 -->

        <mat-select
          class="edit-cell"
          [ngClass]="[column().mainClass, column().cellClass]"
          [(ngModel)]="selectedValues"
          [placeholder]="placeHolder()"
          [attr.data-element]="elementId()"
          (selectionChange)="onSelectInput(column(), $event)"
          (blur)="finishEditing(element)"
          (keydown.enter)="finishEditing(element); $event.preventDefault()"
        >
          @if (column().selectNone) {
            <mat-option>None</mat-option>
          }
          @for (opt of column().selectOptions ?? []; track optIndex; let optIndex = $index) {
            <mat-option [value]="opt">
              {{ opt }}
            </mat-option>
          }
        </mat-select>
      }
    } @else {
      @if (column().hasFunction) {
        <div [ngClass]="[column().mainClass, column().cellClass]" [attr.data-element]="elementId()">
          <ft-conditional-async [displayableValue]="displayableValue()" [column]="column()"></ft-conditional-async>
        </div>
      } @else if (inputColumnType() === 'input') {
        <input
          matInput
          class="edit-cell"
          [ngClass]="[column().mainClass, column().cellClass]"
          [placeholder]="placeHolder()"
          [value]="displayableValue()"
          [attr.data-element]="elementId()"
          (input)="onTextInput(column(), $event)"
          (blur)="finishEditing(element)"
          (keydown.enter)="finishEditing(element); $event.preventDefault()"
        />
      } @else if (inputColumnType() === 'date') {
        <div class="date-cell" [attr.data-element]="elementId()">
          @if (column().format === 'date-ago') {
            {{ cellStringValue() | dateAgo }}
          } @else if (column().format === 'date-iso') {
            {{ cellStringValue() | date: 'yyyy-MM-dd' }}
          } @else {
            {{ cellStringValue() | date: 'dd-MMM-yy' }}
          }
        </div>
      } @else if (inputColumnType() === 'partialDate') {
        <div [ngClass]="[column().mainClass, column().cellClass]" [attr.data-element]="column().elementId">
          <ft-conditional-async [displayableValue]="displayableValue()" [column]="column()"></ft-conditional-async>
        </div>
      } @else {
        <div
          [ngClass]="[column().mainClass, column().cellClass]"
          [attr.data-element]="column().elementId"
          [attr.data-col-type]="columnType() || 'NONE'"
          [attr.data-missing-format]="columnType()"
        >
          {{ displayableValue() }}
        </div>
      }
    }
  </mat-form-field>
}
