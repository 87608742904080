import { Inject, Injectable, WritableSignal, inject, signal } from '@angular/core';
import { HOME_URL } from '@ft/lib/core-lib';
import { LocalStorageService } from '@furnas-technology/angular-library/services-storage';
import { FT_LogError } from '@furnas-technology/common-library/functions';

const LAST_ROUTE = 'lastRoute';

@Injectable({
	providedIn: 'root',
})
export class LastRouteService {
	private localStorage = inject(LocalStorageService);

	// lastRoute to use if going back
	readonly lastRoute: WritableSignal<string> = signal(this.homeUrl);

	constructor(@Inject(HOME_URL) private homeUrl: string) {
		// set lastroute to storage - cannot be login
		try {
			const loginPattern = /login/i;
			const lastRoute = this.localStorage.getLocalStorage(LAST_ROUTE).trim();
			if (lastRoute && !loginPattern.test(lastRoute)) {
				this.lastRoute.set(lastRoute.trim());
			}
		} catch (err: unknown) {
			FT_LogError(err, this.constructor.name, `lastRoute`);
		}
	}
	// save the route if it is not login/logout
	saveLastRoute(routeUrl: string): void {
		console.debug(`${this.constructor.name} - saveLastRoute=`, routeUrl);
		// skip login route, skip no route
		if (!routeUrl || /(login|welcome)/i.test(routeUrl)) {
			return;
		}

		// logout to home
		if (/logout/i.test(routeUrl)) {
			this.lastRoute.set(this.homeUrl);
			this.localStorage.setLocalStorage(LAST_ROUTE, String(this.homeUrl));
			return;
		}

		// set route
		if (routeUrl !== this.lastRoute()) {
			this.lastRoute.set(routeUrl);
			this.localStorage.setLocalStorage(LAST_ROUTE, String(routeUrl));
		}
	} // saveLastRoute
}
