import {
	ChangeDetectionStrategy,
	Component,
	type OnChanges,
	type OnInit,
	type SimpleChanges,
	effect,
	inject,
	input,
	output,
	viewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { AsyncPipe, NgClass } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BehaviorSubject, Subject } from 'rxjs';

import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { type NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';

import { LayoutInfoService } from '@ft/lib/screen-lib';

type SearchEvent = {
	term: string;
	items: string[];
};

const FILTER_DEBOUNCE_TIME = 300;
@Component({
	selector: 'ft-table-select-cell',
	templateUrl: './table-select-cell.component.html',
	styleUrls: ['./table-select-cell.component.scss'],
	imports: [
		AsyncPipe,
		FontAwesomeModule,
		FormsModule,
		MatButtonModule,
		MatCheckboxModule,
		MatDividerModule,
		MatFormFieldModule,
		NgClass,
		NgSelectModule,
		ReactiveFormsModule,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSelectCellComponent implements OnInit, OnChanges {
	protected layout = inject(LayoutInfoService);

	pluralText = input<string>('');
	selectOptions = input.required<string[] | number[] | unknown[]>();
	selectedOptions = input<string[] | number[]>([]);
	fieldPath = input<string>('');

	changedSelection = output<string[] | number[]>();

	selectElem = viewChild<NgSelectComponent>('selectElem');

	fieldInput$ = new Subject<string>();
	fieldInput = toSignal(this.fieldInput$);
	availableOption$ = new BehaviorSubject<(string | number | unknown)[]>([]);

	constructor() {
		effect(() => {
			this.availableOption$.next(this.selectOptions());
		});
	}

	ngOnInit() {}

	ngOnChanges(changes: SimpleChanges): void {}

	onSubmit() {
		// this.closeClicked.emit(true);
	}

	onSearch(evt: SearchEvent, fieldname: string) {}

	onClear() {}

	onOpen(fieldname: string): void {}

	onInputChange(fieldname: string) {
		this.changedSelection.emit(this.selectedOptions());
	}

	trackByFnString(item: string) {
		return item;
	}
}
