// metric-card.component.ts
import { Component, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

type SelectionItem = {
	selectionValue: string;
	count: number;
};

export type SelectedMetricItem = {
	cardTitle: string;
	selectionValue: string;
};

@Component({
	selector: 'ft-metric-card',
	templateUrl: './metric-card.component.html',
	styleUrls: ['./metric-card.component.scss'],

	standalone: true,
	imports: [FontAwesomeModule, MatCardModule, MatButtonModule, MatMenuModule, MatIconModule],
})
export class MetricCardComponent {
	cardLabel = input<string>('');
	cardTitle = input<string>('');
	cardValue = input<number>(0);
	selectionItems = input<SelectionItem[]>([]);
	isLoading = input<boolean>(false);

	selectedMetricItem = output<SelectedMetricItem>();

	faChevronDown = faChevronDown;
	faChevronUp = faChevronDown;

	onSelectionClick(selectedItem: SelectionItem): void {
		this.selectedMetricItem.emit({
			cardTitle: this.cardTitle(),
			selectionValue: selectedItem.selectionValue,
		});
	}
}
