<div class="image-carousel-component">
  <div class="carousel-container">
    <div class="thumbnails">
      @for (image of images(); let imageIndex = $index; track imageIndex) {
      <figure>
        <img
          (click)="onThumbnailClick(image, imageIndex)"
          [src]="image.url"
          [alt]="image.title ?? 'Image {{imageIndex + 1}}'"
        />
      </figure>
      }
    </div>
  </div>

  @if (isOpen() !== NoImageOpen && selectedImage()?.url) {
  <div class="popup mat-app-background-50">
    <img
      #textToAnimate
      [src]="selectedImage()?.url"
      [alt]="selectedImage()?.title ?? 'Selected Image'"
      (click)="imageClick(isOpen())"
      alt="Carousel Image"
    />
  </div>
  }
</div>
