import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, computed, inject } from '@angular/core';
import { CookieOptions, CookieService } from 'ngx-cookie-service';

export const POST_LOGIN_URL = 'postLoginUrl';

type CookieValue = string;
const MAX_LENGTH = 4096;

const userAgent = navigator.userAgent;

type CookieDays = number | Date | '30s' | '1m' | '2m' | '3m' | '4m' | '5m' | '1h' | undefined;

@Injectable({
	providedIn: 'root',
})
export class CookieStorageService {
	ngxCookieService = inject(CookieService);
	platformId = inject(PLATFORM_ID);

	private domain = '';
	isLocal = computed<boolean>(() => {
		return /localhost/i.test(this.domain);
	});

	cookieDomain = computed<string>(() => {
		return this.isLocal() ? `.app.localhost` : `.${this.domain}`;
	});

	isChromeAgent = computed<boolean>(() => {
		return userAgent.toLowerCase().indexOf('chrome') > -1;
	});

	isSafariAgent = computed<boolean>(() => {
		return !this.isChromeAgent() && userAgent.toLowerCase().indexOf('safari') > -1;
	});

	documentIsAccessible = computed<boolean>(() => {
		return isPlatformBrowser(this.platformId);
	});

	//@Inject(PLATFORM_ID) private platformId: typeof PLATFORM_ID ,
	constructor(@Inject(DOCUMENT) private document: Document) {
		this.domain = window.location.hostname;
	}

	/**
	 * get value for key from storage
	 */
	public get(key: string): CookieValue {
		try {
			if (this.ngxCookieService.check(key)) {
				const cookieValue = this.ngxCookieService.get(key);
				return cookieValue;
			} else {
				console.debug(`${this.constructor.name} - attempt to get cookie that does not exist: ${key}`);
				return '';
			}
		} catch (err: unknown) {
			console.error(`❗️${this.constructor.name} - get error:`, err);
			return '';
		}
	}

	/**
	 * set value with options expiration
	 */
	public set(key: string, value: CookieValue, days?: CookieDays) {
		// validate value
		try {
			if (!key) {
				console.warn(`${this.constructor.name} - attempt to set cookie without a key. value=${value}`);
				return;
			}

			if (value.length > MAX_LENGTH) {
				console.warn(
					`${this.constructor.name} - attempt to set cookie ${key} to value in excess of maximum length of ${MAX_LENGTH}. value=${value}`,
				);
			}

			const options = this.cookieOptions(days);

			// set cookie value
			this.ngxCookieService.set(key, value, options);

			// validate cookie

			const storedCookieValue = this.get(key) ?? '';
			if (value !== storedCookieValue) {
				const lengthError = value.length > MAX_LENGTH ? ` LENGTH > ${MAX_LENGTH} ` : '';

				console.error(
					`❗️${this.constructor.name} - key=${key} - storedCookieValue.length=${storedCookieValue.length},  value.length=${value.length}${lengthError}, userAgent=${userAgent}, options=`,
					options,
				);

				console.error(
					` ${this.constructor.name} - key=${key} - storedCookieValue=${storedCookieValue}, value=${value} `,
				);
			}
		} catch (err: unknown) {
			console.error(`❗️${this.constructor.name} - set cookie error:`, err);
		}
	}

	/**
	 * delete keys
	 */
	public delete(key: string) {
		try {
			if (!key) {
				console.warn(`${this.constructor.name} - attempt to delete cookie without a key`);
				return;
			}

			if (this.ngxCookieService.check(key)) {
				this.ngxCookieService.delete(key);
			} else {
				console.warn(`${this.constructor.name} - could not delete - no cookie with key=${key} found`);
			}
		} catch (err: unknown) {
			console.error(`❗️${this.constructor.name} - get error:`, err);
		}
	}

	/**
	 * Set cookie options
	 */

	private cookieOptions(days?: CookieDays): CookieOptions {
		const pathOption = { path: '/' };

		const localOptions = this.isLocal()
			? {}
			: {
					secure: true,
					sameSite: 'Strict',
					domain: `.${this.domain}`,
				};

		let dayOptions = {};
		if (days) {
			if (typeof days === 'number') {
				dayOptions = { expires: days };
			} else if (typeof days === 'string') {
				const OneSecond = 1000;
				const OneMinute = 60 * OneSecond;
				if (days === '30s') {
					dayOptions = { expires: new Date(Date.now() + OneSecond * 30) };
				} else if (days === '1m') {
					dayOptions = { expires: new Date(Date.now() + OneMinute) };
				} else if (days === '2m') {
					dayOptions = { expires: new Date(Date.now() + OneMinute * 2) };
				} else if (days === '3m') {
					dayOptions = { expires: new Date(Date.now() + OneMinute * 3) };
				} else if (days === '4m') {
					dayOptions = { expires: new Date(Date.now() + OneMinute * 4) };
				} else if (days === '5m') {
					dayOptions = { expires: new Date(Date.now() + OneMinute * 5) };
				} else if (days === '1h') {
					dayOptions = { expires: new Date(Date.now() + OneMinute * 60) };
				}
			} else if (days instanceof Date) {
				dayOptions = { expires: days };
			}
		}

		const allOptions = { ...pathOption, ...localOptions, ...dayOptions } as CookieOptions;
		return allOptions;

		// const options: CookieOptions = {
		//   path: '/',
		// };

		// if (!this.isLocal()) {
		//   options.secure = true;
		//   options.sameSite = 'Strict';
		//   options.domain = `.${this.domain}`;
		// }

		// expires?: number | Date;
		// path?: string;
		// domain?: string;
		// secure?: boolean;
		// sameSite?: SameSite;
		// partitioned?: boolean;

		//   if (this.isLocal()) {
		//     return {
		//       path: '/',
		//     };
		//   } else {
		//     return {
		//       path: '/',
		//       secure: true,
		//       sameSite: 'Strict',
		//       domain: `.${this.domain}`,
		//     };
		//   }
	}
}
