<div
  class="display-cell-component"
  color="primary"
  [matTooltip]="tooltipValue()"
  [matTooltipDisabled]="!column().hasTooltip || !tooltipValue()"
  (mouseenter)="isHovered = true"
  (mouseleave)="isHovered = false"
>
  @if (columnType() === 'actions') {
    <div
      class="actions-cell  actions-{{ column().align }}"
      [attr.data-element]="elementId()"
      [attr.data-align]="column().align"
    >
      @for (action of column().tableButtons; track action; let i = $index) {
        <div
          color="primary"
          (click)="onActionClick($event, action, column(), element())"
          matTooltip="{{ action.buttonLabel ?? action.buttonName }}"
        >
          <div class="table-button">
            <fa-icon color="accent" [icon]="action.buttonIcon"></fa-icon>
          </div>
        </div>
      }
    </div>
  }
</div>
