import { Directive, ElementRef, Renderer2, effect, inject, input, signal } from '@angular/core';
import { LayoutInfoService, UiMode } from '@ft/lib/screen-lib';

@Directive({
	selector: '[addAlternatingBackground]',
	standalone: true,
})
export class AddAlternatingBackgroundDirective {
	private el = inject(ElementRef);
	private renderer = inject(Renderer2);
	private layout = inject(LayoutInfoService);

	evenOrOdd = input<'even' | 'odd' | 'none'>('none');

	evenBackground = input<string>('var(--mat-sys-tertiary-container)');
	evenColor = input<string>('var(--mat-sys-on-tertiary-container)');

	oddBackground = input<string>('var(--mat-sys-primary-container)');
	oddColor = input<string>('var(--mat-sys-on-primary-container)');

	noneBackground = input<string>('var(--mat-sys-surface)');
	noneColor = input<string>('var(--mat-sys-on-surface)');

	backgroundOpacity = input<number | undefined>(undefined);

	targetProperty = signal<string>('backgroundColor');

	defaultBackgroundColor = 'var(--mat-sys-surface)';
	defaultColor = 'var(--mat-sys-on-surface)';

	constructor() {
		effect(() => {
			const mode = this.layout.uiMode();
			const defaultBackgroundColor = this.defaultBackgroundColor;
			const defaultColor = this.defaultColor;

			let backgroundColor = defaultBackgroundColor;
			let color = defaultColor;
			if (this.evenOrOdd() === 'even') {
				backgroundColor = this.evenBackground() || defaultBackgroundColor;
				color = this.evenColor() || defaultColor;
			} else if (this.evenOrOdd() === 'odd') {
				backgroundColor = this.oddBackground() || defaultBackgroundColor;
				color = this.oddColor() || defaultColor;
			} else if (this.evenOrOdd() === 'none') {
				backgroundColor = this.noneBackground() || defaultBackgroundColor;
				color = this.noneColor() || defaultColor;
			}

			this.setBackground(backgroundColor, color, this.backgroundOpacity(), this.el.nativeElement, mode);
		});
	}

	private setBackground(
		background: string,
		color: string,
		opacity: number | undefined,
		element: HTMLElement,
		_mode: UiMode,
	) {
		if (!element) return;
		if (!!color) this.renderer.setStyle(element, 'color', color);
		if (!!background) this.renderer.setStyle(element, 'backgroundColor', background);

		if (opacity !== undefined) {
			setTimeout(() => {
				const computedBackground: string = getComputedStyle(element).backgroundColor;
				const colorWithOpacity = this.addOpacityToColor(computedBackground, opacity);
				this.renderer.setStyle(element, 'backgroundColor', colorWithOpacity);
			});
		}
	}

	private addOpacityToColor(color: string, opacity: number): string {
		const scrubbedColor = color.trim();

		// Detect format and convert to rgba
		if (scrubbedColor.startsWith('#')) {
			// Convert hex to rgba
			return this.hexToRgba(color, opacity);
		} else if (scrubbedColor.startsWith('rgb(')) {
			// Convert rgb to rgba
			return scrubbedColor.replace('rgb(', 'rgba(').replace(')', `, ${opacity})`);
		} else if (scrubbedColor.startsWith('rgba(')) {
			// Already rgba, just modify the alpha value
			return this.updateRgbaOpacity(color, opacity);
		} else if (scrubbedColor.startsWith('hsl(')) {
			// Convert hsl to hsla
			return scrubbedColor.replace('hsl(', 'hsla(').replace(')', `, ${opacity})`);
		} else if (scrubbedColor.startsWith('hsla(')) {
			// Already hsla, modify the alpha
			return this.updateHslaOpacity(color, opacity);
		}

		// Return original if can't determine format
		return color;
	}

	private hexToRgba(hex: string, opacity: number): string {
		// Remove # if present
		let scrubbedHex = hex.replace('#', '');

		// Convert 3-digit hex to 6-digit
		if (scrubbedHex.length === 3) {
			scrubbedHex = scrubbedHex
				.split('')
				.map((c) => c + c)
				.join('');
		}

		// Parse hex values
		const r = Number.parseInt(scrubbedHex.substring(0, 2), 16);
		const g = Number.parseInt(scrubbedHex.substring(2, 4), 16);
		const b = Number.parseInt(scrubbedHex.substring(4, 6), 16);

		return `rgba(${r}, ${g}, ${b}, ${opacity})`;
	}

	private updateRgbaOpacity(rgba: string, opacity: number): string {
		return rgba.replace(/rgba\(([^,]+),([^,]+),([^,]+),[^)]+\)/, `rgba($1,$2,$3,${opacity})`);
	}

	private updateHslaOpacity(hsla: string, opacity: number): string {
		return hsla.replace(/hsla\(([^,]+),([^,]+),([^,]+),[^)]+\)/, `hsla($1,$2,$3,${opacity})`);
	}

	// Helper method to convert camelCase to kebab-case for CSS properties
	private convertCamelToKebabCase(camelCase: string): string {
		return camelCase.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
	}
}
