<div class="popup-overlay hide-scrollbars" (click)="closePopup()" [ngStyle]="compStyles()">
  <!-- class="hide-scrollbars" -->
  <div class="please-subscribe-component">
    <h1>Elevate Your Dance Journey</h1>
    <br />
    <p>
      Thank you for registering with <span class="domain-name">DanceComps.com.au</span>. We are delighted to welcome you
      to our premier platform for dance competitions, festivals, and events.
    </p>
    <br />
    <p>
      To enable us to continue to provide you with the most comprehensive and up-to-date information on Australia's
      vibrant dance scene, we kindly invite you to subscribe to our service.
    </p>
    <br />
    <div class="commitment">Our commitment to excellence includes:</div>
    <ul>
      <li>Exclusive access to competition, festival and event details and schedules</li>
      <li>Notifications, based on your selections, for competitions and festivals across Australia</li>
      <li>In-depth coverage of classical ballet and contemporary dance events</li>
    </ul>
    <br />
    <p>Without a subscription, we show limited information for many of the competitions and festivals that we track.</p>
    <p>Join us in celebrating the art of dance and staying at the forefront of Australia's dance community.</p>
    <br />
    <div class="button-container">
      <ft-dialog-button
        class="button-component skip-button"
        buttonText="Skip for now"
        [textPosition]="'after'"
      ></ft-dialog-button>
      <ft-dialog-button
        class="button-component subscribe-button"
        [iconDefinition]="subscriptionService.buttonIcon"
        [buttonText]="subscriptionService.subscribeText()"
        [tooltip]="subscriptionService.subscribeTooltip()"
        [textPosition]="'after'"
        [disabled]="subscriptionService.subscribeButtonDisabled()"
        [clickFunction]="subscriptionService.subscribeDialog()"
      ></ft-dialog-button>
    </div>
  </div>
</div>
