import { DOCUMENT } from '@angular/common';
import { APP_ID, DestroyRef, Inject, Injectable, Signal, computed, inject, signal } from '@angular/core';
import { type Observable, catchError, finalize, map, of, retry, shareReplay } from 'rxjs';

import { HttpBackend, HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';

import { type AuthResult, InitSessionTokens, type SessionTokens, Userinfo } from '@ft/lib/models';

import { FT_LogError } from '@furnas-technology/common-library/functions';

import {
	AUTH_URL,
	CognitoUrls,
	FT_RetryConfig,
	IS_BASIC_AUTH,
	LOGIN_CALLBACK,
	LOGIN_URL,
	LOGOUT_CALLBACK,
	LOGOUT_URL,
	STRIPE_PK,
} from '@ft/lib/core-lib';

const SESSION_TOKENS_KEY = 'sessionTokens';

const ACCESS_TOKEN_KEY = 'accessToken';
const ID_TOKEN_KEY = 'idToken';
const SESSION_OTHER_KEY = 'sessionOtherInfo';

export const CurrentUrl = window.location.origin;
export const ApiKey = 'X-Api-Key';
export const ApiAppId = 'X-Api-AppId';

type SessionOtherInfo = Pick<SessionTokens, 'token_type' | 'expires_in'>;

export type ApiXHeaders = {
	ApiKey: string;
	ApiKeyValue: Signal<string>;
	ApiAppId: string;
	ApiAppIdValue: Signal<string>;
};

export type GetTokensResult = {
	success: boolean;
	message: string;
};

type ValidateResponse = {
	valid: boolean;
	message?: string;
	auth_time?: number;
	client_id?: string;
	exp?: number;
	iat?: number;
	iss?: string;
	scope?: string;
	token_use?: string;
	sub?: string;
	version?: number;
	username?: string;
	userinfo?: Userinfo;
};

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CookieStorageService } from '@ft/lib/cookies-lib';
import { AppIdValues } from '@ft/lib/core-lib';
import { GlobalStore, LastRouteService } from '@ft/lib/global-lib';
import { NotifyService } from '@ft/lib/snackbar-lib';
import { AuthStore } from '../store/auth.store';

const AUTH_TIMEOUT = 25000;
// type AutologinStatus = 'not started' | 'started' | 'success' | 'failed';

type SyncResult = {
	result: boolean;
	message: string;
	subscription_ids: string[];
};

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private httpBackendModule = inject(HttpBackend);
	private httpBackend: HttpClient;
	private cookieStorage = inject(CookieStorageService);
	private authStore = inject(AuthStore);
	private notifyService = inject(NotifyService);

	private lastRouteService = inject(LastRouteService);
	private gss = inject(GlobalStore);
	destroyRef = inject(DestroyRef);

	initialised = false;
	autologinAttempted = false;

	readonly sessionTokens = signal<SessionTokens>({ ...InitSessionTokens });

	username = computed(() => this.authStore.username());
	isAuthenticated = this.authStore.isAuthenticated;
	authOrInprogress = this.authStore.authOrInprogress;
	isSubscribedOrAdmin = this.authStore.isSubscribedOrAdmin;

	constructor(
		@Inject(DOCUMENT) private document: Document,
		@Inject(LOGIN_CALLBACK) private loginCallback: string,
		@Inject(LOGOUT_CALLBACK) private logoutCallback: string,
		@Inject(LOGOUT_URL) private logoutUrl: string,
		@Inject(AUTH_URL) private authUrl: string,
		@Inject(LOGIN_URL) private loginUrl: string,
		@Inject(APP_ID) private appId: AppIdValues,
		@Inject(STRIPE_PK) private stripePK: string,
	) {
		console.debug(`${this.constructor.name} - constructor - AUTH_URL=${this.authUrl} (${new Date().toISOString()})`);
		this.httpBackend = new HttpClient(this.httpBackendModule);

		// init tokens
		this.initialiseSessionTokens();

		// initialised
		this.initialised = true;
	}

	/**
	 * Initialise session tokens from local storage
	 */
	initialiseSessionTokens(): void {
		try {
			// load from local storage
			const sessionTokens = this.getStoredSessionTokens();
			if (sessionTokens) {
				this.updateSessionTokens(sessionTokens);
			} else {
				this.updateSessionTokens(null);
			}
		} catch (err: unknown) {
			FT_LogError(err, this.constructor.name, `initialiseSessionTokens`);
			this.updateSessionTokens(null);
		}
	}

	/**
	 * got to login url
	 */
	performLogin(fromRoute?: string): void {
		try {
			console.debug(`${this.constructor.name} - performLogin - fromRoute=${fromRoute}`);

			if (fromRoute) {
				const x = fromRoute.startsWith('/') ? fromRoute : `/${fromRoute}`;
				this.lastRouteService.saveLastRoute(x);
			}

			const configUrl = this.authStore.selectApiAppConfig()?.loginUrl ?? '';
			const url = encodeURI(configUrl);
			this.document.location.href = url;
		} catch (err: unknown) {
			FT_LogError(err, this.constructor.name, `performLogin(${fromRoute ?? ''})`);
		}
	} // end login

	/**
	 * go to logout url
	 */
	performLogout(notifyResult = true): void {
		try {
			this.authStore.setAuthStatus('logout in progress');
			const configUrl = this.authStore.selectApiAppConfig()?.logoutUrl ?? '';
			const url = encodeURI(configUrl);

			// set logged out
			this.setUserLoggedOut();

			// go to logout url if it exists
			if (url) {
				this.document.location.href = url;
			} else {
				this.gss.setGoToRoute('home');
			}
		} catch (err: unknown) {
			FT_LogError(err, this.constructor.name, `performLogout`);
			this.authStore.setAuthStatus('failed');
		}
	} // end logout

	/**
	 * clear all values for user to fully log out
	 */
	setUserLoggedOut() {
		this.authStore.setAuthenticated(false);
		this.updateSessionTokens(null);
	}

	/**
	 * setAuthenticated based on authStatus - clears userinfo (can have authToken that is not valid)
	 */

	private updateSessionTokens(updatedTokens: SessionTokens | undefined | null): void {
		// clear user if clearing
		if (!updatedTokens?.access_token) {
			this.authStore.setAuthenticated(false);
		}

		// update existing tokens with new token values
		const mergedSessionTokens = updatedTokens
			? Object.assign(this.sessionTokens(), updatedTokens)
			: { ...InitSessionTokens };

		this.authStore.setAccessTokenAndIdToken(mergedSessionTokens.access_token ?? '', mergedSessionTokens.id_token ?? '');

		this.sessionTokens.update((value) => ({ ...value, ...mergedSessionTokens }));

		// update saved tokens once initialised
		if (this.initialised) this.storeSessionTokens(mergedSessionTokens);
	}

	/**
	 * returns Session tokens using code -- logs in the user
	 */
	exchangeCodeForTokens(code: string, attrs = true): Observable<GetTokensResult> {
		// verify a code was passed
		if (!code) {
			return of({ success: false, message: 'authentication code is blank or undefined' });
		}
		// load values for call
		const data = { code: code };
		const context = { context: new HttpContext().set(IS_BASIC_AUTH, true) };

		// create headers
		const headers = this.getBasicHeaders();

		const url = CognitoUrls.token(this.authUrl, this.loginCallback, code, attrs);
		this.authStore.setAuthStatus('login in progress');
		return this.httpBackend
			.post<AuthResult>(url, data, { params: new HttpParams(), headers: headers, ...context })
			.pipe(
				map((authResult: AuthResult) => {
					// verify valid signin result - update result
					const success = this.isSigninSuccessful(authResult);
					if (success) {
						// update session tokens
						this.processAuthResult(authResult);
						this.authStore.setAuthStatus('success');
						// update user information if exists, else get user from API
						if (authResult.userinfo) {
							this.authStore.setUserinfo(authResult.userinfo);
						} else {
							this.getUserinfoFromApi();
						}

						// return result
						return { success: true, message: `${authResult.username}` };
					} else {
						this.authStore.setAuthStatus('failed');
						this.updateSessionTokens(null);
						return { success: false, message: `Unsuccessful login` };
					}
				}),
				retry(FT_RetryConfig),
				catchError((err: unknown) => {
					this.authStore.setAuthenticated(false);
					this.authStore.setAuthStatus('failed');
					const errMsg = FT_LogError(err, this.constructor.name, `exchangeCodeForTokens`);
					return of({ success: false, message: errMsg });
				}),
				finalize(() => {
					console.debug(`${this.constructor.name} - exchangeCodeForTokens - for code=${code} COMPLETED`);
				}),
			);
	} // end exchangeCodeForTokens

	/**
	 * Use refresh token to get code for auto login
	 */
	public refreshTokens(accessToken: string): Observable<AuthResult | null> {
		// ensure access token exists
		if (!accessToken) {
			return of(null);
		}

		// load values for call
		this.authStore.setAuthStatus('refresh in progress');
		const headers = this.getBasicHeaders();
		const data = { access_token: accessToken };
		const context = { context: new HttpContext().set(IS_BASIC_AUTH, true) };
		const url = CognitoUrls.refresh(this.authUrl, true);
		return this.httpBackend
			.post<AuthResult>(url, data, { params: new HttpParams(), headers: headers, ...context })
			.pipe(
				map((authResult: AuthResult) => {
					if (this.isSigninSuccessful(authResult) && authResult.success) {
						console.debug(`${this.constructor.name} - refreshTokens - authResult.success=${authResult.success}`);
						this.processAuthResult(authResult);
						if (authResult.userinfo) {
							this.authStore.setUserinfo(authResult.userinfo);
						}
					} else {
						console.debug(
							`${this.constructor.name} - refreshTokens - authResult.success=${authResult.success}, message=${authResult.message}`,
						);
						this.updateSessionTokens(null);
					}
					this.authStore.setAuthStatus('success');
					return authResult;
				}),
				retry(FT_RetryConfig),
				shareReplay({ refCount: true, bufferSize: 1 }),
				catchError((err: unknown) => {
					FT_LogError(err, this.constructor.name, `refreshTokens`);
					this.authStore.setAuthStatus('failed');
					this.updateSessionTokens(null);
					return of(null);
				}),
				finalize(() => {
					console.debug(`🎉 ${this.constructor.name} - refreshTokens COMPLETED`);
				}),
			);
	} // end refreshTokens

	/**
	 * getUserinfoFromApi
	 */
	public getUserinfoFromApi(): void {
		// return if not authenticated
		const accessToken = this.authStore.isAuthenticated() ? (this.sessionTokens()?.access_token ?? '') : '';
		if (!accessToken) {
			this.authStore.clearUserinfo();
			return;
		}

		// get bearer auth headers
		const headers = this.getBearerHeaders(accessToken);
		const url = CognitoUrls.userinfo(this.authUrl);
		this.httpBackend
			.get<Userinfo>(url, { params: new HttpParams(), headers: headers })
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				map((cognitoUserinfo: Userinfo) => {
					this.authStore.setUserinfo(cognitoUserinfo);
					return cognitoUserinfo;
				}),
				retry(FT_RetryConfig),
				catchError((err: unknown) => {
					FT_LogError(err, this.constructor.name, `getUserinfoFromApi`);
					this.authStore.clearUserinfo();
					return of(null);
				}),
				finalize(() => console.debug(`👀 ${this.constructor.name} - getUserinfoFromApi COMPLETED`)),
			)
			.subscribe();
	} // end getUserinfoFromApi

	/**
	 * Perform autologin
	 */
	autoLogin(notify = false): Observable<boolean> {
		console.debug(
			`${this.constructor.name} - autoLogin - clientId=${
				this.authStore.selectConfigCognito()?.clientId
			}, autologinAttempted=${this.autologinAttempted}`,
		);
		if (this.autologinAttempted) return of(false);
		this.autologinAttempted = true;

		// skip if autologin completed or started
		if (this.authStore.authStatus() !== 'none') {
			console.warn(
				`${this.constructor.name} - autoLogin - already occurred - authStatus$=${this.authStore.authStatus()}`,
			);
			return of(false);
		}

		// login in progress
		this.authStore.setAuthStatus('login in progress');

		// if already authenticated, skip
		if (this.authStore.isAuthenticated()) {
			console.warn(`${this.constructor.name} - autoLogin - already authenticated`);
			this.authStore.setAuthStatus('success');
			return of(false);
		}

		// if no access token - failed
		if (!this.sessionTokens()?.access_token) {
			console.warn(`${this.constructor.name} - autoLogin - no access token`);
			this.authStore.setAuthStatus('failed');
			return of(false);
		}

		// if no session values, then failed
		const cognito = this.authStore.selectConfigCognito();
		if (!cognito?.clientId || !cognito?.poolId) {
			console.warn(`${this.constructor.name} - autoLogin - missing cognitoClientId or cognitoPoolId`);
			this.authStore.setAuthStatus('failed');
			return of(false);
		}

		// validate access token and then refresh token if invalid
		this.authStore.setAuthStatus('refresh in progress');
		const accessToken = this.sessionTokens().access_token;

		if (notify) this.notifyService.info(`Attempting to authenticate`, 'ℹ', 3000);
		return this.refreshTokens(accessToken).pipe(
			map((authResult) => {
				if (authResult?.access_token && authResult.expires_in && authResult.token_type) {
					this.authStore.setAuthStatus('success');
					return true;
				} else {
					this.authStore.setAuthStatus('failed');
					return false;
				}
			}),
			catchError((err: unknown) => {
				console.warn(`${this.constructor.name} - autoLogin - refreshTokens error: `, err);
				this.authStore.setAuthStatus('failed');
				return of(false);
			}),
			finalize(() => {
				console.debug(`${this.constructor.name} - autoLogin - COMPLETED - authStatus$=${this.authStore.authStatus()}`);
			}),
		);
	} // end autoLogin

	/**
	 * Update observables holding session values
	 */
	private processAuthResult(authResult: AuthResult): void {
		this.updateSessionTokens(authResult);
		this.authStore.setAuthenticated(true);
	} // end processAuthResult

	/**
	 * Determine if this is successful signin
	 */
	isSigninSuccessful(authResult: AuthResult): boolean {
		const curTime = new Date().getTime() * 0.001;
		if (!!authResult.id_token && !!authResult.expires_in && !!authResult.token_type && !!authResult.access_token) {
			return true;
		}

		return false;
	}

	storeSessionTokens(sessionTokens: SessionTokens): void {
		try {
			const key = `${this.appId}_${SESSION_TOKENS_KEY}`;

			// tokens
			this.cookieStorage.set(ACCESS_TOKEN_KEY, sessionTokens.access_token ?? '', 90);
			this.cookieStorage.set(ID_TOKEN_KEY, sessionTokens.id_token ?? '', 90);
			const otherInfo: SessionOtherInfo = {
				token_type: sessionTokens.token_type,
				expires_in: sessionTokens.expires_in,
			};
			this.cookieStorage.set(SESSION_OTHER_KEY, JSON.stringify(otherInfo), 90);
		} catch (err: unknown) {
			console.error(`${this.constructor.name} - error storing session tokens, error=`, err);
			FT_LogError(err, `storeSessionTokens`);
		}
	}

	getStoredSessionTokens(): SessionTokens | undefined {
		try {
			const access_token = this.cookieStorage.get(ACCESS_TOKEN_KEY) ?? '';
			const id_token = this.cookieStorage.get(ID_TOKEN_KEY) ?? '';
			const otherInfoString = this.cookieStorage.get(SESSION_OTHER_KEY) ?? '';
			const otherInfo: SessionOtherInfo = otherInfoString
				? JSON.parse(otherInfoString)
				: { token_type: '', expires_in: 0 };
			const sessionTokens: SessionTokens = {
				access_token: access_token,
				id_token: id_token,
				token_type: otherInfo.token_type,
				expires_in: otherInfo.expires_in,
			};

			return sessionTokens;
		} catch (err: unknown) {
			FT_LogError(err, `getStoredSessionTokens`);
			return undefined;
		}
	} // end getStoredSessionTokens

	/**
	 * Helper methods
	 */
	getApiHeaderValues(): ApiXHeaders {
		return {
			ApiKey: ApiKey,
			ApiKeyValue: this.authStore.selectCognitoToken,
			ApiAppId: ApiAppId,
			ApiAppIdValue: this.authStore.selectCognitoAppId,
		};
	}

	getBasicHeaders(): HttpHeaders {
		const { ApiKey, ApiKeyValue, ApiAppId, ApiAppIdValue } = this.getApiHeaderValues();
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Basic ${ApiKeyValue}`,
			[ApiKey]: ApiKeyValue(),
			[ApiAppId]: ApiAppIdValue(),
		});

		return headers;
	}

	getBearerHeaders(accessToken: string): HttpHeaders {
		const { ApiKey, ApiKeyValue, ApiAppId, ApiAppIdValue } = this.getApiHeaderValues();
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${accessToken}`,
			[ApiKey]: ApiKeyValue(),
			[ApiAppId]: ApiAppIdValue(),
		});

		return headers;
	}
} // end class
