import { Injectable, computed, inject } from '@angular/core';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { AuthStore } from '@ft/lib/auth-lib';
import { CommonDialogService } from '@ft/lib/dialogs-lib';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { StripeSubscriptionComponent } from './stripe-subscription/stripe-subscription.component';

@Injectable({
	providedIn: 'root',
})
export class SubscriptionService {
	private layout = inject(LayoutInfoService);
	private dialogService = inject(CommonDialogService);
	private authStore = inject(AuthStore);

	buttonIcon = faCreditCard;

	/**
	 * Subscribe button
	 */
	subscribeTooltip = computed(() => {
		return this.authStore.isSubscribed() ? `Manage your subscription with Stripe` : `Subscribe to access all features`;
	});

	subscribeText = computed(() => {
		return this.authStore.isSubscribed() ? `Manage Subscription` : `Subscribe`;
	});

	subscribeDialog = computed(() => {
		return () => this.openSubscriptionDialog();
	});

	subscribeButtonDisabled = computed(() => {
		return !this.authStore.isAuthenticated();
	});

	constructor() {}

	/**
	 * Open dialog
	 */
	openSubscriptionDialog(): void {
		const width = this.layout.isMobile() || this.layout.isLT_small() ? '100vw' : '840px';
		const maxWidth = '100vw';
		const subscribeOrManage = this.authStore.isSubscribed() ? 'manage' : 'subscribe';

		this.dialogService.openDialog(StripeSubscriptionComponent, {
			panelClass: 'rounded-xlarge',
			width: 'auto',
			maxWidth: maxWidth,
			data: { subscribeOrManage: subscribeOrManage },
		});
	}
} // end class
