"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimeStamps = void 0;
const tslib_1 = require("tslib");
const modelOptions_1 = require("./modelOptions");
let TimeStamps = class TimeStamps {};
exports.TimeStamps = TimeStamps;
exports.TimeStamps = TimeStamps = tslib_1.__decorate([(0, modelOptions_1.modelOptions)({
  schemaOptions: {
    timestamps: true
  }
})
/**
 * This class can be used for already existing type information for the Timestamps
 */], TimeStamps);
