export const isValidDate = value => {
  if (value instanceof Date && !Number.isNaN(new Date(value).getTime())) {
    return true;
  } else {
    return false;
  }
};
export const isEmptyObject = obj => {
  if (obj === null) return true;
  return typeof obj === 'object' && !Object.keys(obj).length;
};
export const FT_hasProperty = (obj, propName) => {
  return typeof obj === 'object' && obj !== null && propName in obj;
};
export const FT_getPropertyValue = (obj, propName) => {
  return FT_hasProperty(obj, propName) ? obj[propName] : undefined;
};
/**
 * Perform a deep copy of an object - avoiding functions or invalid objects
 */
export const FT_structuredClone = source => {
  try {
    if (typeof source !== 'function' && typeof source !== 'object') return structuredClone(source);
    if (source === null || source === undefined) return structuredClone(source);
    if (Array.isArray(source) || source instanceof Date) return structuredClone(source);
    if (typeof source === 'function') return source;
    const clonedObj = {};
    for (const entry of Object.entries(source)) {
      const key = entry[0];
      clonedObj[key] = FT_deepCopy(entry[1]);
    }
    return clonedObj;
  } catch (err) {
    console.error(`❌ FT_deepCopy: error=`, err);
    return source;
  }
};
export const FT_deepCopy = obj => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(item => FT_deepCopy(item));
  }
  const clonedObj = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      clonedObj[key] = FT_deepCopy(obj[key]);
    }
  }
  return clonedObj;
};
/**
 * get the keys of an object
 */
export const FT_objectKeys = obj => {
  if (!obj) return [];
  return Object.keys(obj);
};
// Returns false if objects are the same, else array of changed fields. Only compares matching properties.
export const FT_isDifferent = (ref, obj) => {
  // handle exception if ref or obj are undefined/null
  if (ref == null && obj == null) return false;
  if (ref == null || obj == null) return true;
  const res = FT_deepObjectDiff(ref, obj);
  return res;
}; // end isDifference
export const FT_isObject = value => {
  return typeof value === 'object' && value !== null;
};
const FT_deepObjectDiff = (obj1, obj2) => {
  if (obj1 == null && obj2 == null) return false;
  if (obj1 == null || obj2 == null) return true;
  const entries1 = Object.entries(obj1);
  const entries2 = Object.entries(obj2);
  if (entries1.length !== entries2.length) {
    return true;
  }
  for (const entry1 of entries1) {
    const entry2 = entries2.find(item => item[0] === entry1[0]);
    if (!entry2) return true;
    const val1 = entry1[1];
    const val2 = entry2[1];
    if (FT_isObject(val1) && FT_isObject(val2)) {
      if (FT_deepObjectDiff(val1, val2)) return true;
    } else if (val1 !== val2) {
      return true;
    }
  }
  return false;
};
// copy non-null values
export const FT_copyNonNulls = (record, from) => {
  try {
    // if either one is falsy, return as is
    if (!record || !from) return;
    for (const key of Object.keys(record)) {
      const fromValue = from[key];
      if (fromValue !== null && typeof fromValue !== 'undefined') {
        record[key] = fromValue;
      }
    }
  } catch (err) {
    console.error(`❌ FT_copyNonNulls: record/from, error=`, record, from, err);
  }
};
export const FT_copyExistingNonNulls = (record, from) => {
  if (!record || !from) return record;
  for (const key in record) {
    const fromValue = from[key];
    if (fromValue !== undefined && fromValue !== null) {
      record[key] = fromValue;
    }
  }
  return record;
};
const FT_serializableTypes = ['string', 'number', 'bigint', 'boolean', 'object', 'undefined', 'null'];
const FT_isSerializable = x => FT_serializableTypes.includes(typeof x);
export const FT_removeUnserialisableProperties = obj => {
  const result = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (FT_isSerializable(value)) {
        result[key] = value;
      }
    }
  }
  return result;
};
// const FT_serializableTypes = ['string', 'number', 'bigint', 'boolean', 'object', 'undefined', 'null'] as const;
// type Serializable = (typeof FT_serializableTypes)[number];
// const FT_isSerializable = (x: unknown): x is Serializable => FT_serializableTypes.includes(x as Serializable);
// export const FT_removeUnserialisableProperties = <T extends object>(obj: T): Partial<T> => {
//   const result: Partial<T> = {};
//   for (const [key, value] of Object.entries(obj)) {
//     if (FT_isSerializable(typeof value)) {
//       result[key] = value;
//     } else {
//       // nothing to see here
//     }
//   }
//   return result;
// };
/**
 * Provides default values for array of missing / null / undefined properties
 */
export const FT_defaultValues = (rows, defaultValue = '') => {
  try {
    // return empty array if we do not have any array
    if (!rows || !Array.isArray(rows)) {
      return [];
    }
    const nonnullRows = rows.filter(row => row !== null && typeof row !== 'undefined');
    // return if empty
    if (nonnullRows.length === 0) return nonnullRows;
    const resultRows = nonnullRows.map(obj => {
      if (typeof obj === 'object') {
        return FT_defaultValue(obj, defaultValue);
      } else {
        return obj || defaultValue;
      }
    });
    return resultRows;
  } catch (err) {
    console.error(`❌ FT_defaultValues: rows/defaultValue, error=`, rows, defaultValue, err);
    return [];
  }
};
export const FT_defaultValue = (obj, defaultValue = '') => {
  try {
    // return object if null, undefined
    if (obj === null || typeof obj === 'undefined') return obj;
    const newObj = Object.assign({}, obj);
    for (const key in obj) {
      if (obj[key] === null || typeof obj[key] === 'undefined') {
        newObj[key] = defaultValue;
      }
    }
    return newObj; // return the modified object
  } catch (err) {
    console.error(`❌ FT_defaultValue: obj, error=`, obj, err);
    return {};
  }
};
export const FT_getProperties = (propertyList, obj) => {
  const record = {};
  for (const prop of propertyList) {
    if (typeof prop === 'string') {
      record[prop] = obj[prop] ?? null;
    }
  }
  return record;
};
export const FT_removeNullArrayEntries = obj => {
  if (obj === null || typeof obj !== 'object') return obj;
  /**
   * Needs to copy the array values to a new array
   */
  if (Array.isArray(obj)) {
    return FT_removeNullFromArray(obj);
  } else {
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'object' && value !== null && Array.isArray(value)) {
        obj[key] = FT_removeNullArrayEntries(value);
      }
    }
    return obj;
  }
};
export const FT_removeNullFromArray = array => {
  const filteredArray = array.filter(item => item !== null);
  return filteredArray;
};
// generic sort function
export const FT_sort = compareFn => {
  return source => source.slice().sort(compareFn);
};
export const FT_sortCompare = (a, b, isAsc) => {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
};
export function FT_removeFunctions(obj) {
  const result = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && typeof obj[key] !== 'function') {
      result[key] = obj[key];
    }
  }
  return result;
}
export const FT_hasOwnProperty = (obj, key) => {
  return Object.prototype.hasOwnProperty.call(obj, key);
};
export const hasProp = (obj, key) => {
  return !!(typeof obj === 'object' && obj !== null && obj !== undefined && key in obj);
};
export const IsPropertyOfObject = (obj, value) => {
  return typeof obj === 'object' && obj !== null && isKeyable(value) && value in obj;
};
export const IsPropertyOfObjectCaseInsensitive = (obj, value) => {
  if (typeof value === 'string') {
    const lowerCaseValue = value.toLowerCase();
    const keys = Object.keys(obj).map(key => key.toLowerCase());
    return keys.includes(lowerCaseValue);
  }
  return false;
};
export const GetPropertyValueCaseInsensitive = (obj, value) => {
  if (typeof value === 'string') {
    const lowerCaseValue = value.toLowerCase();
    const key = Object.keys(obj).find(key => key.toLowerCase() === lowerCaseValue);
    return key ? obj[key] : undefined;
  }
  return undefined;
};
export const isKeyable = value => {
  return typeof value === 'string' || typeof value === 'number' || typeof value === 'symbol';
};
