<div class="ft-upload-component upload-dialog-component">
  <!-- card header -->
  <div mat-dialog-title class="ft-dialog-title">
    <!-- card header  -->
    <div class="title">
      <fa-icon [icon]="faCameraRetro"></fa-icon>
      <h4 class="header-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{ headerTitle() }}</h4>
    </div>

    <div class="title-options">
      <ft-close-button (closeClicked)="close()"></ft-close-button>
    </div>
  </div>

  <!-- card content -->
  <mat-dialog-content class="ft-dialog-content">
    <!-- form -->
    <!-- [(ngModel)]="displayedImageSrc()" -->
    <form novalidate (ngSubmit)="onSubmit()" class="upload-component-form">
      @if (!isDataOrUrl()) {
        <h5 class="upload-component-message">{{ message }}</h5>
        <mat-form-field class="upload-component-form-field" #uploadformfield>
          <mat-label class="label-form-field">Image address (for example, https://...)</mat-label>
          <input
            matInput
            id="imagesource"
            class="image-url upload-component-form-input"
            name="imagesource"
            type="text"
            [value]="displayedImageSrc()"
            (input)="onChange($event)"
            [disabled]="isDataOrUrl()"
            [ngClass]="{ 'no-show': isDataOrUrl() }"
            (ngModelChange)="onChange($event)"
          />
          @if (displayedImageSrc()) {
            <button
              matSuffix
              mat-icon-button
              class="upload-component-image-button"
              aria-label="clear imageUrl"
              (click)="onClear()"
              [ngClass]="{ 'no-show': isDataOrUrl() }"
              [disabled]="isDataOrUrl()"
            >
              <fa-icon matTooltip="Clear image address" [icon]="faXmark" [class]="iconClasses"></fa-icon>
            </button>
          }
        </mat-form-field>
      }

      <div class="upload-component-button">
        <button mat-button type="button" (click)="uploadFile()" [disabled]="isExternalUrl()">Upload local file</button>
      </div>

      @if (displayedImageSrc()) {
        <div class="upload-component-preview">
          @if (uploadType() === 'image') {
            <img [src]="displayedImageSrc()" (load)="uploadImageLoaded($event)" alt="Preview Image" appSpinner />
          } @else if (uploadType() === 'pdf') {
            <ft-pdf-viewer
              class="pdf"
              [height]="'200px'"
              [width]="'200px'"
              [pdfSrc]="displayedImageSrc()"
            ></ft-pdf-viewer>
          }
        </div>
      }

      <!-- end form -->
    </form>
  </mat-dialog-content>

  <!-- card actions  -->
  <mat-dialog-actions class="ft-dialog-actions">
    <button mat-button (click)="onClose()">Cancel</button>
    <button mat-button type="button" aria-label="clear imageSrc" (click)="onClear()">Clear</button>
    <button
      type="submit"
      mat-button
      class="submit-button"
      color="primary"
      (click)="onSubmit()"
      [disabled]="reference === displayedImageSrc()"
    >
      Save
    </button>
  </mat-dialog-actions>

  @if (errorMessage) {
    <span class="error-message color-warn">{{ errorMessage }} </span>
  }
</div>
