import { SaveToS3 } from '../aws-integrations/index.js';
import { FT_LogError } from './logging-functions.js';
import { IsValidBase64 } from './string-functions.js';
export const AcceptTypes = {
  image: `image/*`,
  pdf: `application/pdf`,
  '': ``,
  csv: `text/csv,application/csv`,
  spreadsheet: `application/vnd.ms-excel,application/vnd.apple.numbers`,
  document: `application/vnd.ms-word,application/vnd.apple.pages,text/*`,
  ppt: `application/vnd.ms-powerpoint,application/vnd.apple.keynote`
};
export const ImageSuffixes = {
  image: ['png', 'jpeg', 'jpg', 'svg', 'tiff'],
  pdf: ['pdf'],
  '': [],
  csv: ['csv'],
  spreadsheet: ['xlsx'],
  document: ['docx'],
  ppt: ['pptx']
};
export const AcceptTypesArray = () => {
  const result = [];
  for (const entry of Object.entries(AcceptTypes)) {
    const types = entry[1].split(',');
    const key = entry[0];
    for (const type of types) {
      result.push({
        acceptType: key,
        value: type
      });
    }
  }
  return result;
};
export const ImageSuffixesArray = () => {
  const result = [];
  for (const entry of Object.entries(ImageSuffixes)) {
    const types = entry[1];
    const key = entry[0];
    for (const type of types) {
      result.push({
        acceptType: key,
        value: type
      });
    }
  }
  return result;
};
export const UploadAcceptTypes = uploadType => {
  if (!uploadType) return [];
  const acceptTypes = [];
  const acceptType = AcceptTypes[uploadType];
  if (acceptType) {
    acceptTypes.push(...acceptType.split(','));
  }
  return acceptTypes.filter(x => !!x);
};
export const ValidateUploadFileType = (fileType, uploadType) => {
  // if no upload type filter, valid type
  if (!uploadType) return true;
  // if no filetype, invalid
  if (!fileType) return false;
  const fileTypeParts = fileType.split('/').map(x => x.toLowerCase());
  const acceptTypes = UploadAcceptTypes(uploadType);
  for (const acceptType of acceptTypes) {
    // match full type
    if (acceptType.toLowerCase() === fileType.toLowerCase()) return true;
    // match type category
    const parts = acceptType.split('/').map(x => x.toLowerCase());
    if (parts.length > 1 && parts[1] === '*' && parts[0] === fileTypeParts[0]) return true;
  }
  return false;
};
export const GetImageType = imageSrc => {
  if (!imageSrc) return '';
  const ilower = imageSrc.toLowerCase();
  if (IsValidBase64(imageSrc)) {
    for (const accept of AcceptTypesArray()) {
      const parts = accept.value.split('/');
      const needle = parts[1] === '*' ? parts[0] : accept.value;
      if (ilower.startsWith(needle.toLowerCase())) {
        return accept.acceptType;
      }
    }
  } else {
    for (const accept of ImageSuffixesArray()) {
      if (ilower.endsWith(`.${accept.value}`)) {
        return accept.acceptType;
      }
    }
  }
  return '';
};
export const getBackgroundParams = (width, height) => {
  if (height > 600 && width > 600) {
    return {
      imageSize: 'cover',
      imageRepeat: 'no-repeat'
    };
  } else if (width > 600) {
    return {
      imageSize: 'cover',
      imageRepeat: 'no-repeat'
    };
  } else if (height > 600) {
    return {
      imageSize: 'cover',
      imageRepeat: 'no-repeat'
    };
  } else {
    return {
      imageSize: 'cover',
      imageRepeat: 'no-repeat'
    };
  }
};
export const ScrubAndSaveModifiedLogo = async (logo, identifier = '', fileName = '') => {
  try {
    // check for empty
    if (!logo || typeof logo !== 'string') return logo;
    if (logo.startsWith('favicon')) return '';
    if (!logo.toLowerCase().startsWith('data')) return logo;
    const result = await SaveToS3(logo, identifier, fileName);
    return result;
  } catch (err) {
    FT_LogError(err, `ScrubAndSaveModifiedLogo`, `${identifier}, ${fileName}`);
    return logo;
  }
};
