/**
 * Transforms a camelCase string into a readable text format
 * @example textify('helloWorld!')
 * // Hello world!
 */
export function FT_textify(text) {
  try {
    return text.replace(/([A-Z])/g, char => ` ${char.toLowerCase()}`).replace(/^([a-z])/, char => char.toUpperCase());
  } catch (err) {
    return text;
  }
}
export const RemoveWhiteSpaceAndSpecialChars = value => {
  try {
    const regex = /[^a-zA-Z0-9]/g;
    const scrubbedValue = value.replace(regex, '');
    return scrubbedValue;
  } catch {
    return '';
  }
};
export function FT_generateStringArrayFromEnum(e) {
  return Object.values(e);
}
export function FT_replaceSpecialCharsAndSpacesWith(str, replaceWith) {
  if (!str) return '';
  return str.replace(/[^A-Z0-9]+/gi, replaceWith);
}
export const FT_replaceStringFirst = (str, pattern, replaceWith) => {
  if (typeof str !== 'string') return '';
  return str.replace(pattern, replaceWith);
};
export const FT_replaceStringAll = (str, pattern, replaceWith) => {
  if (typeof str !== 'string') return '';
  return str.replaceAll(pattern, replaceWith);
};
export const FT_firstAndLastChars = (input, chars = 10) => {
  if (!input || chars < 1) return '';
  if (chars < input.length) return input;
  const startChars = input.slice(0, chars);
  const endChars = input.slice(-1 * chars);
  return `${startChars}...${endChars}`;
};
export const crapRegexTrail = /([a-z]|[0-9])$/gi;
export const crapRegexStart = /^([a-z]|[0-9])/gi;
export const crapCharacters = ['.', ',', '~', '`', '/', '\\'];
/**
 * removes trailing crap from a string
 */
export const removeTrailingCrap = iValue => {
  try {
    if (!iValue) return iValue;
    // const regex = /[^\w]+$/;
    const regex = /[^\da-zA-Z]+$/;
    const value = iValue.replace(regex, '');
    return value;
  } catch (err) {
    console.error(`❌ removeTrailingCrap: `, err);
    return iValue;
  }
};
/**
 * removes trailing crap from a string
 */
export const removeStartingCrap = iValue => {
  try {
    if (!iValue) return iValue;
    // const regex = /^[^\w]+/;
    const regex = /^[^\da-zA-Z]+/;
    const value = iValue.replace(regex, '');
    return value;
  } catch (err) {
    console.error(`❌ removeStartingCrap: `, err);
    return iValue;
  }
};
export const First20Chars = (value, length = 20) => {
  try {
    if (value === null || value === undefined || typeof value !== 'string') {
      return '';
    }
    const strLength = Math.max(length, 1);
    return `${String(value).substring(0, strLength)}${String(value).length > strLength ? '...' : ''}`;
  } catch (err) {
    console.error(`❌ First20Chars: `, err);
    return '';
  }
};
export const Last20Chars = (value, length = 20) => {
  try {
    if (value === null || value === undefined || typeof value !== 'string') {
      return '';
    }
    const strLength = Math.max(length, 1);
    return `${String(value).length > strLength ? '...' : ''}${String(value).slice(-length)}`;
  } catch (err) {
    console.error(`❌ Last20Chars: `, err);
    return '';
  }
};
// const urlRegex = /^(https?:\/\/)?[\w\-~\,\+.,;:@&%=]+(\.[\w\-~\,\+.,;:@&%=]+)+([/?#][\w\-~.,;:@&%=+/]*)?$/;
const urlRegex = /^(https?:\/\/)?[\w\-~\/\,\+.,;:@&%=]+(\.[\w\-~\/\,\+.,;:@&%=]+)+([/?#][\w\-~\/.,;:@&%=+/]*)?$/;
export const IsValidUrl = urlString => {
  try {
    if (!urlString || typeof urlString !== 'string') return false;
    return urlRegex.test(urlString);
  } catch (err) {
    console.error(`❌ IsValidUrl: `, err);
    return true;
  }
};
const base64Regex = /^data:([a-z]+\/[a-z]+);base64,([a-zA-Z0-9+\/]+(?:=*)*)$/i;
export const IsValidBase64 = base64String => {
  if (!base64String || typeof base64String !== 'string') return false;
  return base64Regex.test(base64String);
};
const base64ImageRegex = /^data:image\/[a-zA-Z]+;base64,([A-Za-z0-9+/]{4})*(([A-Za-z0-9+/]{2}==)|([A-Za-z0-9+/]{3}=))?$/;
export const IsValidBase64Image = base64String => {
  if (!base64String || typeof base64String !== 'string') return false;
  return base64ImageRegex.test(base64String);
};
const base64PdfRegex = /^data:application\/pdf;base64,([A-Za-z0-9+/]{4})*(([A-Za-z0-9+/]{2}==)|([A-Za-z0-9+/]{3}=))?$/;
export const IsValidBase64Pdf = base64String => {
  if (!base64String || typeof base64String !== 'string') return false;
  return base64PdfRegex.test(base64String);
};
/**
 * Generate a proper - first letter of each word capitalised
 */
export const ProperString = value => {
  try {
    if (!value) return '';
    // trim incoming value
    let result = value.trim();
    if (result.length === 1) return result.toUpperCase();
    // is it inside parenthesis
    const inParen = InParenthesis(result);
    if (inParen) result = RemoveParenthesis(result);
    const parts = result.split(/\s+/g);
    const properParts = parts.map(part => {
      const partInParen = InParenthesis(part);
      const scrubbedPart = partInParen ? RemoveParenthesis(part) : part;
      const first = scrubbedPart.slice(0, 1).toUpperCase();
      const remainder = scrubbedPart.slice(1).toLowerCase();
      const properPart = first + remainder;
      return partInParen ? `(${properPart})` : properPart;
    });
    const properValue = properParts.join(' ');
    return inParen ? `(${properValue})` : properValue;
  } catch (err) {
    console.error(`❌ ProperString: `, err);
    return value;
  }
};
export const InParenthesis = value => {
  try {
    return !!(value && typeof value === 'string' && value.trim().length > 2 && value.trim().startsWith('(') && value.trim().endsWith(')'));
  } catch (err) {
    console.error(`❌ InParenthesis: `, err);
    return false;
  }
};
export const RemoveParenthesis = value => {
  try {
    if (InParenthesis(value)) {
      return value.trim().slice(1).slice(0, -1);
    } else {
      return value.trim();
    }
  } catch (err) {
    console.error(`❌ RemoveParenthesis: `, err);
    return value;
  }
};
export const ParseCurrency = value => {
  try {
    if (typeof value === 'number') return value;
    if (value === null || value === undefined || String(value).trim() === '') return 0;
    // Remove any non-numeric characters except for the decimal point
    const numericValue = value.replace(/[^0-9.-]+/g, '');
    // Parse the cleaned string into a float
    return Number.parseFloat(numericValue);
  } catch (err) {
    console.error(`❌ parseCurrency: value=${value}, err=`, err);
    return 0;
  }
};
