import { Injectable, computed, linkedSignal, signal } from '@angular/core';
import { AllFilters, FieldFilter, FilterValue } from '@furnas-technology/common-library/filters';
import { RegisteredComponentFilter } from '../src/active-filter.model';

type ActiveComponentFilter = RegisteredComponentFilter<unknown> & { lastUpdated: number };

export type UpdateFilterForFieldnamesInput = {
	fieldnames: PropertyKey[] | PropertyKey;
	operation: AllFilters | undefined;
	filterValues: FilterValue[] | undefined;
};

@Injectable({
	providedIn: 'root',
})
export class ActiveFilterStore {
	private activeComponentFilter = signal<ActiveComponentFilter | null>(null);
	private lastUpdated = signal<number>(0);
	private registeredFilters = signal<RegisteredComponentFilter<unknown>[]>([]);

	/**
	 * computed values
	 */
	selectActiveComponentFilter = linkedSignal(() => this.activeComponentFilter());
	hasActiveComponentFilter = computed(() => !!this.activeComponentFilter());
	selectRegisteredFilters = linkedSignal(() => this.registeredFilters());
	selectActiveSearchString = linkedSignal(() => this.activeComponentFilter()?.searchString ?? '');
	selectActiveFieldFilters = linkedSignal(() => this.activeComponentFilter()?.fieldFilters ?? []);
	selectActiveSearchBarFields = linkedSignal(() => this.activeComponentFilter()?.searchBarFields ?? []);
	selectActiveSearchBarInFilterComponent = linkedSignal(
		() => this.activeComponentFilter()?.searchBarInFilterComponent ?? false,
	);
	selectShowSearchBar = linkedSignal(() => this.activeComponentFilter()?.showSearchBar ?? false);
	selectLastUpdated = linkedSignal(() => this.lastUpdated());

	visibleFilterCount = computed(() => {
		console.debug(`${this.constructor.name} - visibleFilterCount`);
		const fieldFilters = this.activeComponentFilter()?.fieldFilters ?? [];
		const visibleFilters = fieldFilters.filter((x: FieldFilter<unknown>) => x.isVisible);

		for (const ff of fieldFilters) {
			console.debug(
				`${this.constructor.name} - fieldFilter - ${ff.fieldnames}, operation=${ff.operation}, filterValues=${ff.filterValues}, enteredFilterValues=${ff.enteredFilterValues}, defaultOperation=${ff.defaultOperation}, defaultFilterValues=${ff.defaultFilterValues}, _defaultFilterValues=${ff.defaultFilterValues}`,
			);
		}

		return visibleFilters.length;
	});

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);
	}

	/**
	 * update registered filters with current active filter
	 */
	saveActiveFilter() {
		if (!this.hasActiveComponentFilter()) return;
		const activeComponentFilter = this.activeComponentFilter();
		if (!activeComponentFilter?.filterName) return;

		try {
			// get index of registered filter for active filter
			const currentFilters = [...this.registeredFilters()];
			const index = this.registeredFilters().findIndex((x) => x.filterName === activeComponentFilter.filterName);
			if (index === -1) {
				currentFilters.push(activeComponentFilter);
			} else {
				currentFilters[index] = activeComponentFilter;
			}

			// update registered filters
			this.registeredFilters.set(currentFilters);
		} catch (err: unknown) {
			console.error(`❌ ${this.constructor.name} - saveActiveFilter=${activeComponentFilter?.filterName}, err=`, err);
		}
	}

	/**
	 * set the current search string
	 */
	setActiveSearchString(searchString: string): void {
		const activeComponentFilter = this.activeComponentFilter();
		if (!activeComponentFilter?.filterName) return;

		const newComponentFilter = { ...activeComponentFilter, searchString: searchString };
		this.updateActiveComponentFilter(newComponentFilter);
	}

	updateActiveComponentFilter(
		activeComponentFilter: ActiveComponentFilter | RegisteredComponentFilter<unknown> | undefined,
	): void {
		// set updated time to ensure update triggers change
		this.setUpdatedTime();
		if (activeComponentFilter) {
			const updatedFilter = { ...activeComponentFilter, lastUpdated: this.lastUpdated() };
			this.activeComponentFilter.set(updatedFilter);
			this.saveActiveFilter();
		} else {
			this.activeComponentFilter.set(null);
		}
	}

	setUpdatedTime(): void {
		this.lastUpdated.set(new Date().getTime());
	}

	updateActiveFieldFilters(updatedFieldFilters: FieldFilter<unknown>[] = []): void {
		const currentActiveFilter = this.activeComponentFilter();
		if (!currentActiveFilter) return;

		// update the field filters
		currentActiveFilter.fieldFilters = updatedFieldFilters;
		this.updateActiveComponentFilter(currentActiveFilter);
	}

	updateFilterForFieldnames(input: UpdateFilterForFieldnamesInput): void {
		const activeComponentFilter = this.activeComponentFilter();
		if (!activeComponentFilter || !activeComponentFilter?.filterName) return;

		const { fieldnames, operation, filterValues } = input;

		/**
		 * determine if existing field filter
		 */
		const fieldname = Array.isArray(fieldnames) ? fieldnames[0] : fieldnames;
		const index = activeComponentFilter.fieldFilters.findIndex(
			(item) => item.fieldnames.length && item.fieldnames[0] === fieldname,
		);

		/**
		 * if existing field filter undefined ... error and return
		 */
		if (index > -1) {
			const fieldFilter = activeComponentFilter.fieldFilters[index];

			/**
			 * If the field filter is not valid, return
			 */
			if (!fieldFilter) {
				console.error(
					`❌ ${
						this.constructor.name
					} - updateFilterForFieldnames - fieldFilter is undefined despite finding name=${String(fieldname)}`,
				);
				return;
			}

			/**
			 * test if there are any changes to the fieldFilter
			 */
			const newOperation = operation ?? '';
			const newFilterValues = filterValues ?? [];

			const previousOperation = fieldFilter.operation ?? '';
			const previousFilterValues = fieldFilter.filterValues ?? [];

			const isTheSame = this.hasSameElements(previousFilterValues, newFilterValues);

			if (previousOperation === newOperation && isTheSame) {
				console.debug(
					`${this.constructor.name} - updateFilterForFieldnames - NO CHANGES FOUND FOR fieldname=${String(fieldname)}`,
				);
				return;
			}

			fieldFilter.operation = newOperation;
			fieldFilter.filterValues = newFilterValues;

			console.debug(
				`${this.constructor.name} - updateFilterForFieldnames - UPDATE - fieldname=${String(fieldname)}, fieldFilter=`,
				fieldFilter,
			);

			this.updateStoreFieldFilter(fieldFilter);
		} else {
			const fieldFilter = new FieldFilter({
				fieldnames: fieldnames,
				operation: operation,
				filterValues: filterValues,
			});

			console.debug(
				`${this.constructor.name} - updateFilterForFieldnames - NEW - fieldname=${String(fieldname)}, isValidFilter=${
					fieldFilter.isValidFilter
				}, fieldFilter=`,
				fieldFilter,
			);
			this.updateStoreFieldFilter(fieldFilter);
		}
	}

	updateStoreFieldFilter(fieldFilter: FieldFilter<unknown>) {
		console.debug(
			`👉 ${this.constructor.name} - watch - updateStoreFieldFilter=${fieldFilter.fieldnames}, isValidFilter=${fieldFilter.isValidFilter}, filterValues=${fieldFilter.filterValues},  fieldFilter=`,
			fieldFilter,
		);
		const activeComponentFilter = this.activeComponentFilter();
		if (!activeComponentFilter || !activeComponentFilter?.filterName) return;

		const index = activeComponentFilter.fieldFilters.findIndex(
			(item) => item.fieldnames.length && item.fieldnames[0] === fieldFilter.fieldnames[0],
		);

		try {
			const fieldFilters = activeComponentFilter.fieldFilters ?? [];
			if (index > -1 && fieldFilter.isValidFilter) {
				fieldFilters[index] = fieldFilter;
			} else if (index > -1) {
				fieldFilters.splice(index, 1);
			} else {
				fieldFilters.push(fieldFilter);
			}

			this.updateActiveFieldFilters(fieldFilters);
		} catch (err: unknown) {
			console.error(`❌ ${this.constructor.name} - updateStoreFieldFilter=${fieldFilter.fieldnames}, err=`, err);
		}
	}

	setActiveFilter(filterName: string) {
		// validations
		if (!filterName) return;
		if (this.activeComponentFilter()?.filterName === filterName) return;

		// update active filter
		const filter = this.registeredFilters().find((item) => item.filterName === filterName);
		if (filter) {
			if (!filter.searchString) filter.searchString = '';
			if (!filter.fieldFilters) filter.fieldFilters = [];
		}
		this.updateActiveComponentFilter(filter);

		console.debug(
			`👉 ${this.constructor.name} - setActiveFilter - ACTIVE filter.filterName=${filter?.filterName}, searchString=${filter?.searchString}`,
		);
	}

	/**
	 * registers field filters - defines  filterName, component, searchString, searchBarFields, initialFilters, etc.
	 */
	registerComponentFilter(
		filter: RegisteredComponentFilter<unknown>,
		makeActive: boolean = false,
		replaceFilter: boolean = false,
	) {
		if (!filter.filterName) return;

		console.debug(
			`👉 ${this.constructor.name} - watch - registerComponentFilter - makeActive=${makeActive}, replaceFilter=${replaceFilter}, filter=`,
			filter,
		);

		try {
			const currentFilters = [...this.registeredFilters()];
			const index = currentFilters.findIndex((item) => item.filterName === filter.filterName);
			if (index === -1) {
				console.debug(
					`👉 ${this.constructor.name} - registerComponentFilter - NEW filter.filterName=${filter.filterName}`,
					filter,
				);
				currentFilters.push(filter);
				this.registeredFilters.set(currentFilters);
			} else if (index > -1 && replaceFilter) {
				console.debug(
					`👉 ${this.constructor.name} - registerComponentFilter - REPLACE filter.filterName=${filter.filterName}`,
				);
				currentFilters[index] = filter;
				this.registeredFilters.set(currentFilters);
			}

			// activate filter
			if (makeActive) {
				this.setActiveFilter(filter.filterName);
			}
		} catch (err: unknown) {
			console.error(`❌ ${this.constructor.name} - registerComponentFilter=${filter.filterName}, err=`, err);
		}
	}

	clearActiveFilter(): void {
		this.activeComponentFilter.set(null);
	}

	clearAllFilters() {
		this.registeredFilters.set([]);
		this.clearActiveFilter();
	}

	hasSameElements<T>(arrayA: T[], arrayB: T[]): boolean {
		// If arrays have different lengths, they can't have the same elements
		if (arrayA.length !== arrayB.length) {
			return false;
		}

		// Convert arrays to Sets to remove duplicates
		const setA = new Set(arrayA);
		const setB = new Set(arrayB);

		// If the sets have different sizes, they contain different elements
		if (setA.size !== setB.size) {
			return false;
		}

		// Check if every element in setA exists in arrayB
		return [...setA].every((item) => setB.has(item));
	}
}
