import { isDataRecord } from './record-functions.js';
/**
 * determine if field is nested
 */
export const FT_isNested = fieldname => {
  return typeof fieldname === 'string' && fieldname.toString().includes('.');
};
export const FT_isStringOrNumber = value => {
  return !!(typeof value === 'string' || typeof value === 'number');
};
export const FT_isNullOrUndefined = value => {
  return !!(value === null || value === undefined);
};
export const FT_isEmpty = value => {
  if (value === null || value === undefined) return true;
  if (typeof value === 'string' && String(value).trim().length === 0) return true;
  return false;
};
export const FT_isJson = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
export const FT_generateId = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
};
export const RoundedSeconds = () => {
  return Math.round(new Date().getTime() * 0.001);
};
export const FT_coerceBoolean = value => {
  return value != null && `${value}` !== 'false';
};
export const isBase64File = obj => {
  return !!(typeof obj === 'object' && obj !== null && 'name' in obj);
};
const base64String = /data:([a-z]+\/[a-z]+);base64,([a-zA-Z0-9+\/]+(?:=*)*)/i;
export const GetBase64Strings = value => {
  if (!value || typeof value !== 'string') {
    console.warn(`GetBase64Strings - value is EMPTY`);
    return null;
  }
  const matches = value.match(base64String);
  return matches;
};
export const ScrubStringValue = value => {
  if (value === null || value === undefined) return '';
  if (typeof value === 'string') {
    return String(value).trim();
  }
  return value;
};
export const GetMin = (objArray, key) => {
  return GetMinOrMax(objArray, key, true);
};
export const GetMax = (objArray, key) => {
  return GetMinOrMax(objArray, key, false);
};
export const GetMinOrMax = (objArray, key, min = true) => {
  let result = undefined;
  for (const obj of objArray) {
    if (min) {
      if (result === undefined || result === null || obj[key] < result) {
        result = obj[key];
      }
    } else {
      if (result === undefined || result === null || obj[key] > result) {
        result = obj[key];
      }
    }
  }
  return result;
};
/**
 * Input: an array of records. Fills in missing keys and converts null/undefined to string
 */
export const isNonEmptyRecord = (record, keys) => {
  try {
    if (!record || !isDataRecord(record)) return false;
    let result = false;
    for (const key of keys) {
      const value = record[key];
      if (!FT_isEmpty(value) && String(value).trim() !== '') {
        result = true;
        break;
      }
    }
    return result;
  } catch (err) {
    console.error(`❌ isNonEmptyRecord - error:`, err);
    return true;
  }
};
