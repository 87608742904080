import { DestroyRef, Injectable, inject } from '@angular/core';
import { NotifyFlag, NotifyService } from '@ft/lib/snackbar-lib';
import { FT_getErrorText } from '@furnas-technology/common-library/functions';

export type DataErrorObj = {
	success: boolean;
	data: unknown | undefined;
	error: string;
};

@Injectable({
	providedIn: 'root',
})
export class ErrorService {
	destroyRef = inject(DestroyRef);
	notifyService = inject(NotifyService);

	constructor() {}

	handleDataError(err: unknown, caller: string, func: string, notify: NotifyFlag): DataErrorObj {
		const { errMessage, errStatus } = FT_getErrorText(err);
		const message = !errMessage || errMessage.match(/Unknown Error/i) ? `An error occurred` : errMessage;

		if (notify) {
			if (notify === true || notify === 'error') {
				this.notifyService.error(`${message}`);
			} else if (notify === 'info') {
				this.notifyService.info(`${message}`);
			} else if (notify === 'success') {
				this.notifyService.success(`${message}`);
			} else if (notify === 'warn') {
				this.notifyService.warn(`${message}`);
			}
		}

		return {
			success: false,
			data: undefined,
			error: errMessage,
		} as DataErrorObj;
	}
} // end class
