import { ComponentType } from '@angular/cdk/portal';
import { Injectable, inject } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { isCreatableRow, isMutableRow } from '@ft/lib/core-lib';
import { DeleteDocumentRequest, EditDocumentRequest } from '@ft/lib/crud-helper-lib';
import { CommonDialogService, FT_ConfirmResult, FT_DialogData } from '@ft/lib/dialogs-lib';
import { Has_Id } from '@ft/lib/signal-store-lib';
import { CoreModeType } from '@furnas-technology/angular-library';
import { finalize, take } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class FT_DocumentService {
	private dialogService = inject(CommonDialogService);

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);
	}

	/**
	 * view document dialog
	 */
	viewDocument<T>(
		viewComponent: ComponentType<unknown>,
		id: string,
		closeOthers: boolean = true,
		matConfig: MatDialogConfig<T> | undefined = {},
	): void {
		this.dialogService.viewDialog<T>(id, viewComponent, closeOthers, matConfig);
	}

	/**
	 * CRUDs
	 */
	addDocument<T>(
		addComponent: ComponentType<unknown>,
		request: EditDocumentRequest,
		createRowFunction: (row: Partial<T>) => void,
		matConfig: MatDialogConfig<T> | undefined = {},
	): void {
		console.debug(`${this.constructor.name} - addDocument - request=`, request);

		/**
		 * scrub request
		 */
		const reqModeType = request.modeType ? request.modeType : !!request.fromId ? 'Duplicate' : 'Add New';
		const dialogRequest: FT_DialogData<T> = {
			fromId: request.fromId ?? '',
			selectedId: { id: request.id ?? '', timestamp: new Date().getTime() },
			modeType: reqModeType,
		};

		/**
		 * call dialogService to edit
		 */
		console.debug(`${this.constructor.name} - addDocument - dialogRequest=`, dialogRequest);
		const dialgRef = this.dialogService.editDialog<T>(
			addComponent,
			dialogRequest,
			request.closeOthers ?? true,
			matConfig,
		);
		if (!dialgRef) return;
		dialgRef
			.afterClosed()
			.pipe(finalize(() => console.debug(`${this.constructor.name} - FINALIZE - addDocument`)))
			.subscribe((result) => {
				console.debug(`${this.constructor.name} - addDocument - SUBSCRIBE - addDocument - result=`, result);
				if (!result?.success) return;
				if (!result.data) {
					console.error(
						`❌ ${this.constructor.name} - addDocument - success=${result.success}, result.data is undefined`,
					);
					return;
				}
				if (!!createRowFunction) createRowFunction(result.data as Partial<T>);
			});
	}

	editDocument<T>(
		editComponent: ComponentType<unknown>,
		request: EditDocumentRequest,
		createRowFunction: (row: T) => void,
		mutateRowFunction: (row: T) => void,
		matConfig: MatDialogConfig<T> | undefined = {},
	): void {
		console.debug(`${this.constructor.name} - editDocument - request=`, request);

		/**
		 * scrub request
		 */
		const reqModeType = request.modeType || 'Edit';
		const dialogRequest: FT_DialogData<T> = {
			modeType: reqModeType,
			fromId: request.fromId ?? '',
			selectedId: { id: request.id ?? '', timestamp: new Date().getTime() },
		};

		console.debug(`${this.constructor.name} - editDocument - dialogRequest=`, dialogRequest);
		const dialgRef = this.dialogService.editDialog<T>(
			editComponent,
			dialogRequest,
			request.closeOthers ?? true,
			matConfig,
		);
		if (!dialgRef) return;
		dialgRef
			.afterClosed()
			.pipe(finalize(() => console.debug(`${this.constructor.name} - FINALIZE - editDocument`)))
			.subscribe((result) => {
				console.debug(`${this.constructor.name} - SUBSCRIBE - editDocument - result=`, result);
				if (!result?.success) return;
				if (!result.data) {
					console.error(
						`❌ ${this.constructor.name} - editDocument - success=${result.success}, result.data is undefined, request=`,
						request,
					);
					console.debug(
						`${this.constructor.name} - editDocument - editComponent=${editComponent.name}, result=`,
						result,
					);
					return;
				}

				const modeType = result.modeType || reqModeType;

				const rowData = result.data as T;
				if (CoreModeType(modeType) === 'Edit' && isMutableRow<T>(rowData)) {
					if (!!mutateRowFunction) mutateRowFunction(rowData);
				} else if (CoreModeType(modeType) === 'Add' && isCreatableRow<T>(rowData)) {
					if (!!createRowFunction) createRowFunction(rowData);
				}
			});
	}

	deleteDocument<T>(
		request: DeleteDocumentRequest,
		nameProperty: keyof T,
		entities: T[],
		deleteFunction: (_id: string, notifyValue: string) => void,
	): void {
		console.debug(`${this.constructor.name} - deleteDocument`);

		const id = request.id;
		const notify = request.notify ?? true;

		if (!id) {
			console.error(`❌ ${this.constructor.name} - deleteDocument - id is undefined`);
			return;
		}

		/**
		 * Ensure id is passed
		 */
		const document = entities.find((item) => (item as Has_Id)._id === id);
		const notifyValue = !!document && !!nameProperty ? (document as T)[nameProperty] || 'record' : `record`;

		this.dialogService
			.deletePrompt(String(notifyValue))
			.pipe(
				take(1),
				finalize(() => console.debug(`${this.constructor.name} - deleteDocument - FINALIZE`)),
			)
			.subscribe((confirmResult) => {
				if (confirmResult === FT_ConfirmResult.Confirm) {
					if (deleteFunction) deleteFunction(id, String(notifyValue));
				}
			});
	}
} // end class
