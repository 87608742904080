import { Directive, ElementRef, effect, inject, input, output } from '@angular/core';

export type HeightResults = { totalHeight: number; totalElements: number };

@Directive({
	selector: '[getHeight]',
	standalone: true,
	exportAs: 'getHeight',
})
export class GetHeightDirective {
	private hostElement = inject(ElementRef);

	querySelector = input.required<string>();
	expectedElements = input<number>(0);

	heightResults = output<HeightResults>();

	private elementHeights: number[] = [];
	private totalHeight = 0;

	constructor() {
		effect(() => {
			if (this.querySelector() || this.expectedElements()) {
				setTimeout(() => this.measureHeights(), 0);
			}
		});
	}

	public calculateHeight(): HeightResults {
		this.measureHeights();
		return { totalHeight: this.totalHeight, totalElements: this.elementHeights.length };
	}

	public getElementHeights(): number[] {
		return [...this.elementHeights];
	}

	private measureHeights(): void {
		if (!this.querySelector()) {
			console.error(`❌ ${this.constructor.name}: No querySelector provided`);
			return;
		}

		this.elementHeights = [];
		this.totalHeight = 0;
		const elements = this.hostElement.nativeElement.querySelectorAll(this.querySelector());
		console.debug(
			`${this.constructor.name} - measureHeights - querySelector=${this.querySelector()}, elements=${elements.length}, hostElement=`,
			this.hostElement.nativeElement,
		);
		console.debug(`${this.constructor.name} - measureHeights - elements=`, elements);

		for (const element of elements) {
			const height = element.offsetHeight;
			this.elementHeights.push(height);
			this.totalHeight += height;
		}

		console.debug(
			`${this.constructor.name} - measureHeights - totalHeight=${this.totalHeight}, totalElements=${this.elementHeights.length}`,
			this.elementHeights,
		);
		this.heightResults.emit({ totalHeight: this.totalHeight, totalElements: this.elementHeights.length });
	}
}
