import { ComponentType } from '@angular/cdk/portal';
import { MatDialogConfig } from '@angular/material/dialog';
import { CreatableRow, MutableRow, SelectedId } from '@ft/lib/core-lib';
import { ModeType } from '@furnas-technology/angular-library';
import { Observable } from 'rxjs';

// export type FT_DialogUpdateResult<T> = {
// 	modeType: ModeType;
// 	extraInfo?: unknown;
// 	formData?: Partial<T>;
// } & {
// 	[K: string]: T | ModeType | undefined; // Allow either T or ModeType as values
// };

type PartialTWithId<T> = Partial<T> & { _id: string };

export type FT_DialogUpdateResult<T> = {
	success: boolean;
	modeType: ModeType | null;
	extraInfo?: unknown;
	formData?: Partial<T>;
	data: T | PartialTWithId<T> | Partial<T> | null;
};

export const FT_DefaultDialogUpdateResult = <T>(
	values: Partial<FT_DialogUpdateResult<T>> = {},
): FT_DialogUpdateResult<T> => ({
	success: values.success ?? false,
	modeType: values.modeType ?? null,
	data: values.data ?? null,
	formData: values.formData ?? undefined,
	extraInfo: values.extraInfo ?? undefined,
});

export const isDialogUpdateResult = (obj: unknown): obj is FT_DialogUpdateResult<unknown> => {
	if (typeof obj !== 'object' || obj === null) {
		return false;
	}

	if (!('modeType' in obj) || !('success' in obj) || !('data' in obj)) {
		return false;
	}

	return true;
};

// export const isStandardDialogResult = (obj: unknown): obj is FT_StandardDialogResult => {
// 	if (typeof obj !== 'object' || obj === null) {
// 		return false;
// 	}

// 	return 'success' in obj && ('data' in obj || 'formData' in obj);
// };

export type FT_DialogData<D> = {
	record?: D | null;
	modeType?: ModeType;
	selectedId?: SelectedId;
	closeOthers?: boolean;
	fromId?: string;
	[key: string]: unknown;
};

export type FT_MatDialogConfig<D = unknown> = Omit<MatDialogConfig<D>, 'data'> & {
	data?: FT_DialogData<D> | null;
};

export type OpenDynamicDialogInput<TComponent, TData> = {
	component: ComponentType<TComponent>;
	data?: TData;
	closeOthers?: boolean;
	panelClass?: string;
	closeFlag?: Observable<boolean>;
	config?: Omit<MatDialogConfig, 'data' | 'panelClass'>;
};

// Replace with your actual types
export interface GenericDialogModel {
	modeType: ModeType;
	id: string;
	selectedId: SelectedId;
}

export type EditDialogInput<T> = {
	modeType: ModeType;
	id: string;
	closeOthers: boolean;
	resultProperty: string;
	editComponent: ComponentType<unknown>;
};

export type EditDialogOutput<T> = { modeType: ModeType; extraInfo?: unknown } & {
	[key: string]: MutableRow<T> | CreatableRow<T> | undefined;
};

export type ViewDialogInput<T> = {
	modeType: ModeType;
	id: string;
	closeOthers: boolean;
	viewComponent: ComponentType<unknown>;
};

export type DeleteDialogInput<T> = {
	id: string;
	promptProperty: keyof T;
	promptValue?: string;
	closeOthers: boolean;
	selectByIdFunction: (id: string) => T | undefined;
	deleteFunction: (_id: string) => void;
};

export type SelectByIdMethod = <T>(id: string) => Observable<T | undefined>;
export type ConfirmDeleteDialogInput<T> = {
	id: string;
	promptProperty: keyof T;
	closeOthers: boolean;
	selectFunction$: SelectByIdMethod;
	deleteFunction: (_id: string, notifyValue?: string) => void;
};
