import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	LOCALE_ID,
	computed,
	inject,
	input,
	output,
} from '@angular/core';

import { NgClass, NgStyle } from '@angular/common';

import { issueCustomTooltipDefaults } from '@ft/lib/models';

import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';

import {} from '@furnas-technology/angular-library/pipes';
import { Observable, isObservable } from 'rxjs';
import { ConditionalAsyncComponent } from '../conditional-async/conditional-async.component';
import { TableButtonPressed, TableColumn } from '../table-column.model';
import { DataRow, DisplayableValue } from '../table-generic.model';

@Component({
	selector: 'ft-display-cell',
	templateUrl: './display-cell.component.html',
	styleUrls: ['./display-cell.component.scss'],
	imports: [ConditionalAsyncComponent, NgClass, NgStyle, MatTooltipModule],
	providers: [
		{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: issueCustomTooltipDefaults },
		{ provide: LOCALE_ID, useValue: navigator.language || 'en-AU' },
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayCellComponent<T extends object> {
	destroyRef = inject(DestroyRef);

	column = input.required<TableColumn<T>>();
	element = input.required<DataRow<T>>();
	rowId = input<string>();
	dataRowsChanged = input<number>(0);
	tooltipValue = input<string>();
	displayableValue = input.required<DisplayableValue | Observable<DisplayableValue>>();

	buttonPressed = output<TableButtonPressed<T>>();

	isHovered = false;

	cellStyles = computed<{ [key: string]: string | number }>(() => {
		const additionalClasses: { [key: string]: string | number } = {};
		if (this.column().wrapText) additionalClasses['overflow-wrap'] = 'break-word';
		if (this.column().wrapText === false) additionalClasses['text-wrap'] = 'nowrap';
		return additionalClasses;
	});

	valueIsObservable = computed<boolean>(() => isObservable(this.displayableValue()));

	observableValue = computed(() => {
		return this.displayableValue() as Observable<DisplayableValue>;
	});

	nonObservableValue = computed<DisplayableValue>(() => {
		return this.displayableValue() as DisplayableValue;
	});

	constructor() {}
}
