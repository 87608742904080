import { FT_isNested } from './miscellaneous-functions.js';
export const FT_getFieldValue = (record, key) => {
  const fieldValue = FT_isNested(key) ? FT_getNestedValue(record, key) : FT_getNonNestedValue(record, key);
  return fieldValue;
};
export const FT_getNonNestedValue = (record, key) => {
  return record[key];
};
export const FT_getNestedValue = (obj, key) => {
  const nestedValue = key.split('.').reduce((o, k) => o && typeof o === 'object' && k in o ? o[k] : undefined, obj);
  return nestedValue;
};
export const FT_getNestedValueV2 = (obj, key) => {
  const keys = key.split('.');
  let current = obj;
  // Limit to 5 levels of depth
  if (keys.length > 5) return undefined;
  for (let i = 0; i < keys.length && i < 5; i++) {
    if (current && typeof current === 'object' && !Array.isArray(current) && keys[i] in current) {
      current = current[keys[i]];
    } else {
      return undefined; // Return undefined if the key doesn't exist
    }
  }
  return current;
};
