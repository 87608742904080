import { DestroyRef, Injectable, type WritableSignal, inject, signal } from '@angular/core';
import {} from '@angular/core/rxjs-interop';
import { type AppImage, type AppImageKey, type AppLogo } from '@ft/lib/models';
import { IsValidUrl } from '@furnas-technology/common-library/functions';

type GlobalImages = {
	[key: AppImageKey]: WritableSignal<string>;
};

@Injectable({
	providedIn: 'root',
})
export class ImageStoreService {
	destroyRef = inject(DestroyRef);
	img: GlobalImages = {};

	defaultBackground = signal<string>('');
	dynamicBackgroundImage = signal<string>('');
	backgroundImages = signal<string[]>([]);

	lastIndex = -1;

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);
	}

	loadAppImages(baseUrl: string, appImages: AppImage[]): void {
		// load images from environment
		for (const appImage of appImages) {
			const scrubbedUrl =
				appImage.imageUrl && (IsValidUrl(appImage.imageUrl) || appImage.imageUrl.match(/^assets/i))
					? appImage.imageUrl
					: `${baseUrl}${appImage.imageUrl}`;

			const image: AppImage = { imageName: appImage.imageName, imageUrl: scrubbedUrl };

			// load image
			if (this.img[appImage.imageName]) {
				this.img[appImage.imageName].set(scrubbedUrl);
			} else {
				this.img[appImage.imageName] = signal<string>(scrubbedUrl);
			}

			// update GSS with images
			if (appImage.imageUrl) {
				if (appImage.imageName === 'background') {
					this.defaultBackground.set(appImage.imageUrl);
					this.dynamicBackgroundImage.set(appImage.imageUrl);
				}
			} // end if imageUrl
		} // end loop appImages
	}

	loadAppLogos(baseUrl: string, appLogos: AppLogo[]): void {
		for (const appLogo of appLogos) {
			this.loadAppImages(baseUrl, [{ imageName: appLogo.logoName, imageUrl: appLogo.url }]);
		}
	}

	addBackgroundImage(imageUrl: string | string[]): void {
		const imageUrls = Array.isArray(imageUrl) ? imageUrl : [imageUrl];
		const urls = imageUrls.filter((url) => url && this.backgroundImages().findIndex((item) => item === url) === -1);

		if (urls.length) {
			this.backgroundImages.set([...this.backgroundImages(), ...urls]);
			this.dynamicBackgroundImage.set(urls[0]);
		}
	}
} // end class
