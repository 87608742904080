export const DIALOG_DEFAULTS = {
	minWidth: '400px',

	smallWidth: '40vw',
	normalWidth: '60vw',
	largeWidth: '80vw',
	xlargeWidth: '90vw',

	confirmWidth: '10vw',
	confirmMinWidth: '200px',
	confirmMaxWidth: '300px',

	smallHeight: '40vh',
	normalHeight: '60vh',
	largeHeight: '80vh',
	xlargeHeight: '90vh',
};

export const GetNotificationValue = <T>(record: T, notifyKey?: keyof T, notifyValue?: string): string => {
	if (!record || !notifyKey) return notifyValue ?? '';
	const value = record[notifyKey];
	return value ? String(value) : (notifyValue ?? '');
};
