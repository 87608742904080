"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Plugins = exports.plugin = void 0;
const constants_1 = require("./internal/constants");
const utils_1 = require("./internal/utils");
const logSettings_1 = require("./logSettings");
/**
 * Add a mongoose Middleware-Plugin
 * @param mongoosePlugin The Plugin to plug-in
 * @param options Options for the Plugin, if any
 * @example Example:
 * ```ts
 * @plugin(pluginFunctionHere, { optionsHere: true })
 * class ClassName {}
 * ```
 */
function plugin(mongoosePlugin, options) {
  // don't check if options is an object, because any plugin could make it anything
  return target => {
    logSettings_1.logger.info('Adding plugin "%s" to "%s" with options: "%o"', mongoosePlugin?.name || '<anonymous>', (0, utils_1.getName)(target), options);
    const plugins = Array.from(Reflect.getMetadata(constants_1.DecoratorKeys.Plugins, target) ?? []);
    plugins.push({
      mongoosePlugin,
      options
    });
    Reflect.defineMetadata(constants_1.DecoratorKeys.Plugins, plugins, target);
  };
}
exports.plugin = plugin;
exports.Plugins = plugin;
