export const GroupByProperty = <T, K extends keyof T>(
	records: T | T[],
	property: K,
): Set<T[K] extends (infer U)[] ? U : T[K]> => {
	try {
		const recordArray = Array.isArray(records) ? records : [records];
		const valueSet = new Set<T[K] extends (infer U)[] ? U : T[K]>();
		if (!recordArray.length) return valueSet;

		let validPropertyFound = false;
		for (const rec of recordArray) {
			const propValue = rec[property];
			if (propValue === undefined) continue;
			if (!validPropertyFound) validPropertyFound = true;
			if (Array.isArray(propValue)) {
				for (const pv of propValue) {
					valueSet.add(pv);
				}
			} else {
				valueSet.add(propValue as T[K] extends (infer U)[] ? U : T[K]);
			}
		}

		if (!validPropertyFound) {
			console.warn(`GroupByProperty - no valid property found for ${String(property)} in records=`, records);
		}

		return valueSet;
	} catch (err: unknown) {
		console.error(`❌ GroupByProperty - error:`, err);
		return new Set<T[K] extends (infer U)[] ? U : T[K]>();
	}
};

export const GroupByPropertyWithCount = <T, K extends keyof T>(
	records: T | T[],
	property: K,
): Map<T[K] extends infer U ? U : T[K], { group: T[K]; count: number; property: K }> => {
	/**
	 * Initialize data map
	 */
	const groupedDataMap = new Map<T[K] extends infer U ? U : T[K], { group: T[K]; count: number; property: K }>();

	try {
		const recordArray = Array.isArray(records) ? records : [records];

		/**
		 * No further processing if no records
		 */
		if (!recordArray.length) return groupedDataMap;

		let validPropertyFound = false;
		for (const rec of recordArray) {
			let propValue = rec[property];
			if (propValue === undefined || propValue === null) continue;
			if (!validPropertyFound) validPropertyFound = true;

			// default blank value
			if (typeof propValue === 'string' && propValue.trim() === '') propValue = '*Not Specified' as T[K];

			const valuesToAdd = Array.isArray(propValue) ? (propValue as T[K][]) : ([propValue] as T[K][]);

			for (const valueToAdd of valuesToAdd) {
				if (valueToAdd === undefined || valueToAdd === null) continue;
				const typedValue = valueToAdd as T[K] extends infer U ? U : T[K];

				/**
				 * Create new map group if not already exists
				 */
				if (!groupedDataMap.has(typedValue)) {
					groupedDataMap.set(typedValue, { group: valueToAdd, count: 0, property: property });
				}

				/**
				 * Update existing map group
				 */

				const existingGroup = groupedDataMap.get(typedValue);
				if (!existingGroup) continue;
				existingGroup.count++;
			}
		}

		if (!validPropertyFound) {
			console.warn(`GroupByPropertyWithCount - no valid property found for ${String(property)} in records=`, records);
		}

		return groupedDataMap;
	} catch (err: unknown) {
		console.error(`❌ GroupByPropertyWithCount - error:`, err);
		return groupedDataMap;
	}
};
