import { Constructor } from '@angular/cdk/table';
import { InjectionToken } from '@angular/core';
import { ModeType } from '@furnas-technology/angular-library';
import { IdToString } from '@furnas-technology/common-library/models';

export type AppIdValues = 'furnas-info' | 'furnas-technology' | 'dancecomps' | 'bookings';

export const isAppIdValues = (obj: unknown): obj is AppIdValues => {
	if (typeof obj === 'string') {
		return !!(['furnas-info', 'furnas-technology', 'dancecomps', 'bookings'] as AppIdValues[]).includes(
			obj as AppIdValues,
		);
	}
	return false;
};

export type SelectedId = {
	id: string;
	timestamp: number;
};

export const DefaultSelectedId: SelectedId = {
	id: '',
	timestamp: 0,
};

export type ImagePosition = 'center' | 'top' | 'bottom' | 'left' | 'right';
export type ImageFit = 'contain' | 'cover' | 'fill' | 'inside' | 'outside';

export const RemoveIdFromObject = <T>(obj: T): T | Omit<T & object & Record<'_id', unknown>, '_id'> => {
	if (typeof obj === 'object' && obj !== null && '_id' in obj) {
		const { _id, ...rest } = obj;
		return rest;
	}
	return obj;
};

export type CreatableRow<T> = Omit<Partial<T>, 'deleted' | '_id'>;
export const isCreatableRow = <T>(obj: unknown): obj is CreatableRow<T> => {
	if (typeof obj !== 'object' || obj === null) {
		console.error(`❌ isCreatableRow - not an object, obj=${!!obj ? typeof obj : 'null'} `);
		return false;
	}

	if (!('_id' in obj) && !('deleted' in obj)) {
		return true;
	}
	if ('_id' in obj && obj._id === '') return true;

	console.error(`❌ isCreatableRow - obj is contains _id or deleted properties, obj=`, obj);
	return false;
};

export type MutableRow<T> = Partial<T> & { _id: string };
export const isMutableRow = <T>(obj: unknown): obj is MutableRow<T> => {
	if (typeof obj !== 'object' || obj === null) {
		console.error(`❌ isMutableRow - not an object, obj=${!!obj ? typeof obj : 'null'} `);
		return false;
	}

	if ('deleted' in obj && obj.deleted === true) {
		console.debug(`❌ isMutableRow - row is deleted, deleted=${obj.deleted}, obj=`, obj);
		return false;
	}

	if (!('_id' in obj)) {
		console.error(`❌ isMutableRow - row missing _id, row=`, obj);
		return false;
	}

	const _id = obj._id;
	if (typeof _id !== 'string') {
		console.error(`❌ isMutableRow - row _id is not a string, _id=${typeof _id}, row=`, obj);
		return false;
	}

	if (_id.trim().length === 0) {
		console.error(`❌ isMutableRow - _id is blank, obj=`, obj);
		return false;
	}

	// valid if passed all checks
	return true;
};

export const MongoIdsToStringArray = (ids: unknown[]): string[] => {
	if (!ids) return [];
	const idStrings = ids.map((id: unknown) => {
		return typeof id === 'string' ? IdToString(id) : '';
	});
	return idStrings;
};

export const GetMutableObject = <T>(
	modeType: ModeType,
	id: string,
	classConstructor: Constructor<T>,
	selectDocument?: (id: string) => T | undefined,
): { obj: T; reference: string } => {
	if (modeType.startsWith('Add')) {
		const hydratedObj = new classConstructor();
		const reference = JSON.stringify(hydratedObj);
		return { obj: hydratedObj, reference };
	}
	/**
	 * Retrieve document
	 */
	const obj = id && selectDocument ? selectDocument(id) : undefined;

	/**
	 * duplicate document
	 */
	if (id && modeType === 'Duplicate' && typeof obj === 'object' && obj !== null) {
		const objWithoutId = RemoveIdFromObject(obj);
		const hydratedObj = new classConstructor({ ...objWithoutId });
		const reference = JSON.stringify(hydratedObj);
		return { obj: hydratedObj, reference };
	}

	/**
	 * existing document
	 */
	const hydratedObj = obj ? new classConstructor(obj) : new classConstructor({ _id: id });
	return { obj: hydratedObj, reference: JSON.stringify(hydratedObj) };
};

export type WelcomeText = {
	headerText: string;
	loginText: string;
	welcomeText: string;
	welcomeTextSmall: string;
	welcomeArtefactsEndpoint: string;
};

export const WELCOME_TEXT = new InjectionToken<WelcomeText>('WELCOME_TEXT');
