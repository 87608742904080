import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ComponentRef,
	DestroyRef,
	InjectionToken,
	OnInit,
	computed,
	effect,
	inject,
	input,
	output,
	signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
	FT_DefaultDialogUpdateResult,
	FT_DialogData,
	FT_DialogUpdateResult,
	FT_MatDialogConfig,
} from '@ft/lib/dialogs-lib';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { NotifyService } from '@ft/lib/snackbar-lib';
import { ModeType, getHeaderTitleText, getSubmitTitleText } from '@furnas-technology/angular-library';
import { EmptyObject, FT_removeNullArrayEntries } from '@furnas-technology/common-library/functions';
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, filter, tap } from 'rxjs';
// import { NotifyService } from '@ft/lib/snackbar-lib';

export const FT_DIALOG_DATA = new InjectionToken<FT_MatDialogConfig<unknown>>('FT_DIALOG_DATA');
export const FT_COMPONENT_REF = new InjectionToken<ComponentRef<unknown> | null>('FT_COMPONENT_REF');
// export const IS_DIALOG = new InjectionToken<boolean>('IS_DIALOG');

@Component({
	selector: '',
	standalone: true,
	templateUrl: './ft-standard.component.html',
	styleUrls: ['./ft-standard.component.scss'],
	imports: [ReactiveFormsModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FT_StandardComponent<TData> implements OnInit, AfterViewInit {
	protected destroyRef = inject(DestroyRef);
	protected dialogRef = inject(MatDialogRef<unknown, FT_DialogUpdateResult<TData>>);
	protected dialogData = inject<FT_DialogData<TData>>(MAT_DIALOG_DATA, { optional: true });
	protected notifyService = inject(NotifyService);
	protected fb = inject(FormBuilder);
	protected layout = inject(LayoutInfoService);

	model = signal<TData | null>(null);
	reference: string = '';

	requestData = input<FT_DialogData<TData>>({
		modeType: 'Info',
		selectedId: { id: '', timestamp: 0 },
		closeOthers: true,
		record: null,
		fromId: '',
	});

	compResult = output<FT_DialogUpdateResult<TData>>();

	form = new FormGroup({});

	formGroup$ = new BehaviorSubject<FormGroup | null>(null);
	allErrors: EmptyObject = {};

	documentTitle = signal<string>('');

	isDialog = computed(() => !!this.dialogData);

	configData = computed<FT_DialogData<TData>>(() => {
		const data = this.isDialog() ? (this.dialogData ?? this.requestData()) : this.requestData();
		return data;
	});

	modeType = computed(() => this.configData().modeType || 'Info');
	isInfoModeType = computed(() => this.modeType() === 'Info');
	selectedId = computed(() => this.configData().selectedId);
	configId = computed(() => this.selectedId()?.id ?? '');
	fromId = computed(() => this.configData().fromId ?? '');
	getMutableId = computed(() => {
		if (this.modeType() === 'Duplicate') {
			return this.configData().fromId ?? '';
		} else {
			return this.configId();
		}
	});
	dataRecord = computed(() => this.configData().record ?? null);

	// readonly componentRef: ComponentRef<T> | null;
	recordName = signal<string>('');
	submitTitle = signal<string>('');
	headerTitle = signal<string>('');

	formValueChanges$ = this.form.valueChanges as Observable<TData>;
	formValueChanges = toSignal(this.formValueChanges$.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(1000)));

	// @Optional() @Inject(FT_COMPONENT_REF) readonly componentRef?: ComponentRef<unknown> | null
	constructor() {
		console.debug(`${this.constructor.name} - constructor`);

		effect(() => {
			// modeType changes
			this.setTitles(this.modeType());
		});
	}

	setTitles(modeType: ModeType): void {
		this.submitTitle.set(getSubmitTitleText(modeType));
		this.headerTitle.set(getHeaderTitleText(modeType, this.recordName()));
	}

	ngOnInit(): void {
		console.debug(`${this.constructor.name} - ngOnInit - configData=`, this.configData());
		console.debug(`${this.constructor.name} - ngOnInit - dialogData=`, this.dialogData);
		this.customOnInit();
	}
	ngAfterViewInit(): void {}

	closeButton() {
		console.debug(`${this.constructor.name} - closeButton - isDialog=${this.isDialog}`);
	}

	close(result?: Partial<FT_DialogUpdateResult<TData>>) {
		console.debug(`${this.constructor.name} - close - isDialog=${this.isDialog()}, result=`, result);
		const closeResult = Object.assign(FT_DefaultDialogUpdateResult<TData>({ modeType: this.modeType() }), result ?? {});

		if (this.isDialog()) {
			this.dialogRef?.close(closeResult);
		} else {
			this.compResult.emit(closeResult);
		}
	}

	dismiss(): void {
		this.close({ success: false });
	}

	handleEscapeKeyPress(): void {
		this.close({ success: false });
	}

	/**
	 * Cleanse data from edit component / dialog
	 */
	cleanseData(
		data: Partial<TData>,
		referenceData: string = '',
	): {
		cleansedData: Partial<TData> | undefined;
		hasChanges: boolean;
	} {
		const noNulls = !!data ? FT_removeNullArrayEntries(data) : undefined;
		const modRef = !!noNulls ? JSON.stringify(noNulls) : '';
		const hasChanges = !!(referenceData !== modRef);
		return { cleansedData: noNulls, hasChanges };
	}

	/**
	 * Process the submit
	 */
	onSubmit() {
		console.debug(`${this.constructor.name} - onSubmit - isInfoModeType=${this.isInfoModeType()}`);
		if (this.isInfoModeType()) this.close();

		/**
		 * if invalid form exit
		 */
		if (!this.form.valid) return;

		/**
		 * Process the data
		 */
		if (!this.preProcessForm()) return;
		const data = this.model();
		this.submitResult(data, this.reference);
	}

	preProcessForm(): boolean {
		// return validation result
		return true;
	}

	customOnInit() {
		// nothing to see here
	}

	/**
	 * Close or emit result
	 */
	submitResult(data: TData | null | undefined, referenceData: string = '', notify = true): void {
		console.debug(
			`${this.constructor.name} - submitResult - data=${!!data}, referenceData=${!!referenceData}, notify=${notify}`,
		);
		/**
		 * if no data, close
		 */
		if (!data) {
			this.close({ success: true });
			return;
		}

		/**
		 * Cleanse data and emit/close
		 */
		const { cleansedData, hasChanges } = this.cleanseData(data, referenceData);

		if (!cleansedData || !hasChanges) {
			if (notify) this.notifyService.info(`No changes to save`);
			this.close({ success: true });
			return;
		}

		this.close({ success: true, data: cleansedData });
	}

	formDebounce() {
		this.formGroup$
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				filter((x) => x !== null),
				debounceTime(700),
				distinctUntilChanged(),
				tap((formGroup: FormGroup | null) => {
					if (formGroup) {
						this.handleError(formGroup);
					}
				}),
			)
			.subscribe();
	}

	handleError(formGroup: FormGroup) {
		if (formGroup.invalid) {
			this.allErrors = {};
			this.getAllErrors(formGroup);
		}
	}

	getAllErrors(formGroup: FormGroup): void {
		if (formGroup.controls === null || formGroup.controls === undefined) return;

		if (Array.isArray(formGroup.controls)) {
			for (const controlName in formGroup.controls) {
				const control = formGroup.controls[controlName];
				if (control.invalid) {
					if (control.errors) {
						this.allErrors[controlName] = control.errors;
					} else if (control instanceof FormGroup) {
						this.getAllErrors(control); // Recursively traverse nested groups
					}
				} // if control.invalid
			}
		}

		if (typeof formGroup.controls === 'object') {
			const controlNames = Object.keys(formGroup.controls);
			for (const controlName of controlNames) {
				const control = formGroup.controls[controlName];
				if (control.invalid) {
					if (control.errors) {
						this.allErrors[controlName] = control.errors;
					} else if (control instanceof FormGroup) {
						this.getAllErrors(control); // Recursively traverse nested groups
					} else if (control instanceof FormArray) {
						for (const fControl of control.controls) {
							this.getAllErrors(fControl as FormGroup);
						}
					}
				} // if control.invalid
			}
		}
	}
}
