<div class="page-totals">
  @if (showing() != null) {
    Showing: {{ showing() }}
  }
  @if (selectLoaded() != null) {
    of {{ selectLoaded() }}
  }
  {{ title() }}
  @if (selectLoaded() !== selectTotal() && selectTotal()) {
    <span>(Total: {{ selectTotal() }} )</span>
  }
</div>
