import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
	selector: 'ft-page-totals',
	templateUrl: './page-totals.component.html',
	styleUrls: ['./page-totals.component.scss'],
	imports: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTotalsComponent {
	title = input.required<string>(); // InputSignal<string>
	showing = input.required<number | null>(); // InputSignal<number>
	selectLoaded = input<number | null>(); // InputSignal<number>
	selectTotal = input<number | null>(); // InputSignal<number>

	constructor() {}
}
