import { inject } from '@angular/core';
import {
	type ActivatedRouteSnapshot,
	type CanActivateFn,
	type ResolveFn,
	Router,
	type RouterStateSnapshot,
	type UrlTree,
} from '@angular/router';

import { toObservable } from '@angular/core/rxjs-interop';
import { CookieStorageService } from '@ft/lib/cookies-lib';
import { FT_LogError } from '@furnas-technology/common-library/functions';
import { type Observable, catchError, filter, map, of } from 'rxjs';
import { AuthStore } from '../store/auth.store';
import { AuthService } from './auth.service';

type CanActivateFnReturn = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;
type ResolveFnReturn<T> = Observable<T> | Promise<T> | T;

type AuthValues = {
	isAuthenticated: boolean;
	isSubscribed: boolean;
	isSubscribedOrAdmin: boolean;
	isAdmin: boolean;
	isUser: boolean;
};

/**
 * Check if logged in and in admin group
 */
export const adminGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
): CanActivateFnReturn => {
	const router = inject(Router);
	console.debug(`AuthGuard - adminGuard - route=`, route);
	return getAuthStatus(state.url).pipe(
		map((authValues) => {
			const isAdmin = !!(authValues.isAdmin && authValues.isAuthenticated);
			console.debug(`AuthGuard - adminGuard - isAdmin=${isAdmin}`);
			if (!isAdmin) router.navigate(['/home']);
			return isAdmin;
		}),
	);
};

/**
 * Check if logged in and in user group
 */
export const userGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
): CanActivateFnReturn => {
	console.debug(`AuthGuard - userGuard - route=`, route);
	return getAuthStatus(state.url).pipe(
		map((authValues) => {
			const router = inject(Router);
			const isUser = !!(!!(authValues.isUser || authValues.isAdmin) && authValues.isAuthenticated);
			if (!isUser) router.navigate(['/home']);
			return isUser;
		}),
	);
};

/**
 * Check if logged in
 */
export const loggedInGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
): CanActivateFnReturn => {
	const router = inject(Router);
	const cookieStorage = inject(CookieStorageService);

	console.debug(`AuthGuard - loggedInGuard - route=`, route);

	return getAuthStatus(state.url).pipe(
		map((authValues) => {
			if (!authValues.isAuthenticated) {
				console.debug(
					`AuthGuard - loggedInGuard - return FALSE routeisAuthenticated=${authValues.isAuthenticated}, route=`,
					route,
				);
				return false;
			} else {
				console.debug(`AuthGuard - loggedInGuard - SUCCESS - isAuthenticated=${authValues.isAuthenticated}`);
				return authValues.isAuthenticated;
			}
		}),
	);
};

/**
 * error guard
 */
export const ErrorRedirectGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
): CanActivateFnReturn => {
	const router = inject(Router);
	console.debug(`ErrorRedirectGuard - route=`, route);
	const error = route.queryParams['error'];
	if (!!error) {
		const errorDescription = route.queryParams['error_description'] ?? '';
		console.debug(`ErrorRedirectGuard - error=${error}`);
		router.navigate(['/home']);

		return false;
	} else {
		return true;
	}
};

/**
 * Check if subscribed in
 */
export const subscribedGuard: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
): CanActivateFnReturn => {
	const router = inject(Router);
	console.debug(`AuthGuard - subscribedGuard - route=`, route);

	return getAuthStatus(state.url).pipe(
		map((authValues) => {
			const isSubscribedOrAdmin = !!(authValues.isSubscribedOrAdmin && authValues.isAuthenticated);
			console.debug(`AuthGuard - subscribedGuard - isSubscribedOrAdmin=${isSubscribedOrAdmin}`);
			if (!isSubscribedOrAdmin) router.navigate(['/home']);
			return isSubscribedOrAdmin;
		}),
	);
};

export const routeResolved: ResolveFn<string> = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
): ResolveFnReturn<string> => {
	console.debug(`AuthGuard - routeResolved - route=`, route);
	return '';
};

export const getAuthStatus = (url: string): Observable<AuthValues> => {
	const auth = inject(AuthService);
	const authStore = inject(AuthStore);

	console.debug(`AuthGuard - getAuthStatus - url=${url}`);

	return toObservable(authStore.authInProgress).pipe(
		filter((authInProgress) => authInProgress === false),
		map((_authInProgress) => {
			console.debug(
				`AuthGuard - authStore.isAuthenticated=${authStore.isAuthenticated()}, url=${url}, access_token=${!!authStore.selectAccessToken()}`,
			);

			// if not authenticated, attempt to login
			if (!authStore.isAuthenticated() && !!authStore.selectAccessToken() && url) {
				console.debug(`AuthGuard - call performLogin`);
				auth.performLogin(url);
			}

			console.debug(`AuthGuard - getAuthStatus - isAuthenticated=${authStore.isAuthenticated()}`);

			return {
				isAuthenticated: !!authStore.isAuthenticated(),
				isSubscribed: !!authStore.isSubscribed(),
				isSubscribedOrAdmin: !!authStore.isSubscribedOrAdmin(),
				isAdmin: !!authStore.isAdmin(),
				isUser: !!authStore.isUser(),
			};
		}),
		catchError((err: unknown) => {
			FT_LogError(err, `authGuard`, `getAuthStatus`);
			return of({
				isAuthenticated: false,
				isSubscribed: false,
				isSubscribedOrAdmin: false,
				isAdmin: false,
				isUser: false,
			});
		}),
	);
}; // end authStatus
