// shared-values.service.ts
import { Injectable, computed, inject, signal } from '@angular/core';
import {} from '@angular/core/rxjs-interop';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { TableColumn } from './table-column.model';

const DEFAULT_ITEM_SIZE = 52;
const DEFAULT_HEADER_SIZE = 56;

@Injectable()
export class FT_TableService<T extends object = object> {
	private layout = inject(LayoutInfoService);
	/**
	 * table classes
	 */
	windowClasses = computed(() => this.layout.windowClasses());
	cssClasses = computed(() => this.layout.cssClasses());

	defaultItemSize = signal<number>(DEFAULT_ITEM_SIZE);
	defaultHeaderSize = signal<number>(DEFAULT_HEADER_SIZE);
	defaultCardHeight = signal<number>(DEFAULT_ITEM_SIZE * 2);

	screenRect = signal<DOMRectReadOnly | undefined>(undefined);

	screenWidthHeight = computed<string>(() => {
		return `${this.screenRect()?.width.toFixed()}x${this.screenRect()?.height.toFixed()}`;
	});

	/**
	 * columns
	 */
	tableColumns = signal<TableColumn<T>[]>([]);

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);
	}
}
