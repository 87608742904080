import { ChangeDetectionStrategy, Component, OnInit, input } from '@angular/core';
import { SafeHtmlPipe } from '@furnas-technology/angular-library/pipes';

@Component({
	selector: 'ft-display-embedded-video',
	templateUrl: './display-embedded-video.component.html',
	styleUrls: ['./display-embedded-video.component.scss'],
	imports: [SafeHtmlPipe],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayEmbeddedVideoComponent implements OnInit {
	embeddedHtml = input.required<string>(); // InputSignal<string>

	constructor() {}

	ngOnInit() {
		console.debug(`${this.constructor.name} - ngOnInit`);
		this.registerCustomElement();
	}

	private registerCustomElement() {
		class ModalTemplate extends HTMLElement {
			constructor() {
				super();
			}
		}

		if (!customElements.get('modal-template')) {
			customElements.define('modal-template', ModalTemplate);
			console.debug(`${this.constructor.name} - customElements=`, customElements);
		}
	}
}
