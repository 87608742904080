import { Injectable, computed, effect, inject } from '@angular/core';
import { UPDATEDAT_DESC } from '@ft/lib/core-lib';

import { SearchFilter } from '@furnas-technology/angular-library';

import { ApolloQueryResult, FetchPolicy } from '@apollo/client';
import { ApolloError } from '@apollo/client/core';
import { Apollo, MutationResult, TypedDocumentNode } from 'apollo-angular';

import { HttpErrorResponse } from '@angular/common/http';
import { AuthStore } from '@ft/lib/auth-lib';
import { ErrorService } from '@ft/lib/error-service-lib';
import { NotifyService } from '@ft/lib/snackbar-lib';
import { FT_LogError, FT_getErrorText } from '@furnas-technology/common-library/functions';
import { Observable, catchError, delay, map, of, retry } from 'rxjs';
import {
	ChunkResult,
	GraphqlCreateOutput,
	GraphqlCreateRequest,
	GraphqlDeleteRequest,
	GraphqlMutateRequest,
	GraphqlQueryInput,
	GraphqlUpdateOutput,
	ReadInput,
} from './graphql-data.model';
import {
	FT_GraphQLDeleteResult,
	FT_GraphQLResponse,
	GetApolloErrorMessage,
	GetQueryData,
	HasMore,
} from './graphql.constants';

const NoCache: FetchPolicy = 'no-cache';
const NetworkOnly: FetchPolicy = 'network-only';
const CacheFirst: FetchPolicy = 'cache-first';

const textError = (status: number | undefined, message: string | undefined): string => {
	if (status != null && message) return `(${status}) ${message}`;
	if (status != null) return `(${status}) unknown error`;
	if (message) return message;
	return 'unknown error';
};

@Injectable({ providedIn: 'root' })
export class GraphqlDataService {
	private authStore = inject(AuthStore);

	notifyService = inject(NotifyService);
	errorService = inject(ErrorService);
	apollo = inject(Apollo);

	prevAuthenticated = false;

	useName = computed<string>(() => {
		return 'default';
	});

	constructor() {
		/**
		 * Clear cache when user logs out - was authenticated and now is not
		 */
		effect(() => {
			if (!this.authStore.isAuthenticated() && this.prevAuthenticated) {
				this.clearCache();
			}
			this.prevAuthenticated = this.authStore.isAuthenticated() ?? false;
		});
	}

	// create searchFilter only using lastupdatedAt
	lastUpdateSearchFilter(lastUpdatedAt = ''): SearchFilter {
		// build searchFilter
		let searchFilter: SearchFilter = {};
		if (lastUpdatedAt) {
			searchFilter = {
				_operators: {
					updatedAt: { gt: lastUpdatedAt },
				},
			};
		}
		return searchFilter;
	}

	/**
	 * Retrieves people from API ... updates indexDB and Store
	 */
	getDataFromApi<T>(
		getManyQuery: GraphqlQueryInput,
		resultProperty: string,
		skip: number,
		limit: number,
		searchFilter: SearchFilter,
		sortKey: string = UPDATEDAT_DESC,
	): Observable<ChunkResult<T>> {
		// set limits - ensure greater than zero
		const apiSkip = Math.max(skip, 0);
		const apiLimit = Math.max(limit, 0);

		// set sort
		// const sort = Object.keys(searchFilter).length > 0 ? UPDATEDAT_DESC : sortKey;
		const sort = sortKey || UPDATEDAT_DESC;

		return this.getDataFunction(getManyQuery, searchFilter, sort, apiSkip, apiLimit).pipe(
			map((response: FT_GraphQLResponse) => {
				const getDataResult = GetQueryData<T[]>(response, resultProperty) ?? [];
				return getDataResult;
			}),
			map((data: T[]) => {
				const hasMore = HasMore(data.length, apiLimit);
				const rowCount = data.length;

				return {
					records: data ?? [],
					rowCount: rowCount,
					skip: apiSkip,
					hasMore: hasMore,
					error: false,
				};
			}),
		);
	} // end getDataFromApi

	getDataFunction<T>(
		getManyQuery: GraphqlQueryInput,
		searchFilter: SearchFilter = {},
		sort = UPDATEDAT_DESC,
		skip = 0,
		limit = 0,
		fetchPolicy: FetchPolicy = CacheFirst,
	): Observable<FT_GraphQLResponse> {
		return this.apollo
			.use(this.useName())
			.query({
				query: getManyQuery,
				fetchPolicy: fetchPolicy,
				variables: {
					filter: searchFilter,
					sort: sort,
					skip: skip,
					limit: limit,
				},
				context: { isQuery: true },
			})
			.pipe(
				map((response: ApolloQueryResult<unknown>) => {
					if (response.error) {
						const { status, message } = GetApolloErrorMessage(response.error);
						return { data: response.data, error: { status: status, message: message } };
					} else {
						return { data: response.data };
					}
				}),
				retry({
					delay: (err: unknown, retryCount) => {
						if (err instanceof ApolloError) {
							const { status, message } = GetApolloErrorMessage(err);
							console.error(
								`❌ getDataFunction retryCount=${retryCount} - ApolloError - status=${status}, message=${message}`,
							);
						} else if (err instanceof HttpErrorResponse) {
							const { status, message } = GetApolloErrorMessage(err);
							console.error(
								`❌ getDataFunction retryCount=${retryCount} - HttpErrorResponse - status=${status}, message=${message}`,
							);
						} else {
							console.error(`❌ getDataFunction retryCount=${retryCount} - unknown error - err=`, err);
						}
						// if (retryCount < 2) {
						return of(err).pipe(delay(500)); // Retry after 500 milliseconds
						// }

						// return throwError(() => err);
					},
					count: 1, // Retry only once
				}),
				catchError((err: unknown) => {
					const { status, message } = GetApolloErrorMessage(err);
					return of({ data: null, error: { status: status, message: message } });
				}),
			);
	} // end getDataFunction

	/**
	 * Perform api query
	 */
	query$<T>(
		query: TypedDocumentNode<unknown, unknown>,
		isPublic: boolean = false,
		dataProperty?: string,
	): Observable<T | null> {
		let api: Observable<ApolloQueryResult<unknown>>;
		console.debug(`query$ - isPublic=${isPublic}, name=${this.useName()}`);
		if (isPublic) {
			api = this.apollo.use(this.useName()).query({
				query: query,
				fetchPolicy: NetworkOnly,
				context: { isQuery: true },
			});
		} else {
			api = this.apollo.query({
				query: query,
				fetchPolicy: NetworkOnly,
				context: { isQuery: true },
			});
		}

		return api.pipe(
			map((response: ApolloQueryResult<unknown>) => {
				if (response.error) {
					const { status, message } = GetApolloErrorMessage(response.error);
					console.error(`❌ query$: ${textError(status, message)}`);
					return null;
				} else {
					return dataProperty ? GetQueryData<T>({ data: response.data }, dataProperty) : (response.data as T);
				}
			}),
		);
	}

	/**
	 * CRUD OPERATIONS
	 */

	/**
	 * Generic update function
	 */
	graphqlUpdate<T>(
		updateRequest: GraphqlMutateRequest<T>,
		resultProperty: string,
	): Observable<GraphqlUpdateOutput<T> | null> {
		return this.apollo.mutate(updateRequest).pipe(
			map((mutationResult: MutationResult) => {
				const data = mutationResult.data;
				const dataResult = data[resultProperty];

				const result: GraphqlCreateOutput<T> = {
					recordId: dataResult?.recordId ?? undefined,
					record: dataResult?.record ?? undefined,
				};

				return result;
			}),
			catchError((err: unknown) => {
				FT_LogError(err, this.constructor.name, `graphqlUpdate (${resultProperty})`);
				return of(null);
			}),
		);
	} // end update

	/**
	 * Generic create function
	 */
	graphqlCreate<T>(
		createRequest: GraphqlCreateRequest<T>,
		resultProperty: string,
	): Observable<GraphqlCreateOutput<T> | null> {
		return this.apollo.mutate(createRequest).pipe(
			map((mutationResult: MutationResult) => {
				const data = mutationResult.data;
				const dataResult = data[resultProperty];

				const result: GraphqlCreateOutput<T> = {
					recordId: dataResult?.recordId ?? undefined,
					record: dataResult?.record ?? undefined,
				};

				return result;
			}),
			catchError((err: unknown) => {
				FT_LogError(err, this.constructor.name, `graphqlCreate`);
				return of(null);
			}),
		);
	} // end create

	/**
	 * Generic delete function
	 */
	deleteDocument<T>(
		deletRequest: GraphqlDeleteRequest,
		_id: string,
		resultProperty: string,
	): Observable<FT_GraphQLDeleteResult | null> {
		return this.apollo.mutate(deletRequest).pipe(
			map((deleteResult: MutationResult) => {
				const data: FT_GraphQLDeleteResult = resultProperty ? deleteResult.data[resultProperty] : deleteResult.data;
				return data;
			}),
			catchError((err: unknown) => {
				const errResponse: FT_GraphQLDeleteResult = {
					status: 'failed',
					documentId: _id,
					documentDesc: '',
					message: '',
					recordId: undefined,
				};

				if (err instanceof ApolloError) {
					const { status, message } = GetApolloErrorMessage(err);
					errResponse.message = message;
				} else {
					const errMsg = FT_getErrorText(err);
					errResponse.message = errMsg.errMessage;
				}

				return of(errResponse);
			}),
		);
	} // end deleteDocument

	/**
	 * Generic read function
	 */
	read<T>(input: ReadInput<T>): Observable<T | null> {
		// get from Graphql API
		return this.apollo
			.use(this.useName())
			.query({
				query: input.apiRead,
				fetchPolicy: NoCache,
				context: { isQuery: true },
			})
			.pipe(
				map((response: ApolloQueryResult<unknown>) => {
					if (response.error) {
						const { status, message } = GetApolloErrorMessage(response.error);
						console.error(`❌ read:  ${textError(status, message)}`);
						return null;
					} else {
						const record = GetQueryData<T>({ data: response.data }, input.resultProperty) ?? null;
						return record;
					}
				}),
				catchError((err: unknown) => {
					FT_LogError(err, this.constructor.name, `read`);
					return of(null);
				}),
			);
	} // end read

	/**
	 * Generic read many function
	 */
	readMany<T>(input: ReadInput<T>): Observable<T[]> {
		// get from Graphql API
		return this.apollo
			.use(this.useName())
			.query({
				query: input.apiRead,
				fetchPolicy: NoCache,
				context: { isQuery: true },
			})
			.pipe(
				map((response: ApolloQueryResult<unknown>) => {
					if (response.error) {
						const { status, message } = GetApolloErrorMessage(response.error);
						console.error(`❌ read: ${textError(status, message)}`);
						return [];
					} else {
						const records = input.resultProperty
							? GetQueryData<T[]>({ data: response.data }, input.resultProperty)
							: [];
						return records;
					}
				}),
				catchError((err: unknown) => {
					FT_LogError(err, this.constructor.name, `readMany`);
					return of([]);
				}),
			);
	} // end readMany

	async clearCache() {
		await this.apollo.client.clearStore();
	}
} // end class
