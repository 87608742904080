import {
	getState,
	patchState,
	signalStore,
	type,
	watchState,
	withComputed,
	withHooks,
	withMethods,
	withProps,
	withState,
} from '@ngrx/signals';
import {
	entityConfig,
	removeAllEntities,
	removeEntity,
	setEntities,
	setEntity,
	withEntities,
} from '@ngrx/signals/entities';

import { APP_ID, Signal, computed, effect, inject } from '@angular/core';
import { DefaultSelectedId, isMutableRow } from '@ft/lib/core-lib';
import { InitTotalsRecord } from '@ft/lib/models';
import { UPDATE_STATUS, updateStateIsProcessing } from '@furnas-technology/angular-library';

import { ComponentType } from '@angular/cdk/portal';
import { ActiveFilterService } from '@ft/lib/active-filter-lib';
import { AuthStore } from '@ft/lib/auth-lib';
import { IS_PRODUCTION } from '@ft/lib/core-lib';
import {
	CrudHelperService,
	DeleteRowRequest,
	FilteredDataNotOmitted,
	FilteredDataSelected,
	GetPagedDataResponse,
} from '@ft/lib/crud-helper-lib';
import { CommonDialogService } from '@ft/lib/dialogs-lib';
import { GenericIndexedDbService, IndexedDBFactoryService } from '@ft/lib/indexeddb-lib';
import { FT_LogError, FT_getErrorMessage } from '@furnas-technology/common-library/functions';
import { catchError, finalize, of, take, tap } from 'rxjs';
import {
	CalcLastUpdatedValue,
	HydrateRecord,
	HydrateRecords,
	MethodNotImplemented,
	SelectMaxTimestamps,
	SortByProperty,
	isHasTimestamps,
} from './signal-store.functions';
import {
	FT_PropertyKey,
	FT_SignalStoreRequest,
	FT_SignalStoreState,
	HasTimestamps,
	Has_Id,
	isHas_Id,
} from './signal-store.models';

export type SignalStoreType<T> = ReturnType<typeof createSignalStore<T>>;

export const createSignalStore = <T>(request: FT_SignalStoreRequest<T>) => {
	const signalStoreName = request.signalStoreName;
	const getPagedDataRequest = request.getPagedDataRequest;
	const createRowRequest = request.createRowRequest;
	const mutateRowRequest = request.mutateRowRequest;
	const apiDelete = request.apiDelete ?? undefined;
	const editResultProperty = request.editResultProperty;
	const editComponent = request.editComponent ?? undefined;
	const nameProperty = request.nameProperty;
	const sortProperty = request.sortProperty;
	const searchBarFields = request.searchBarFields ?? [];
	const sortFunction = request.sortFunction;
	const classConstructor = request.classConstructor;

	let indexDbService: GenericIndexedDbService | undefined | null;

	const defaultInitialState: FT_SignalStoreState<T> = {
		updateStatus: UPDATE_STATUS.blank,
		loadStatus: UPDATE_STATUS.blank,
		indexLoaded: false,
		error: null,
		selectedId: DefaultSelectedId,
		totals: InitTotalsRecord,
		lastEntityUpdated: 0,
		editComponent: null,
		overrideSearchString: '',
	};
	const initialState = request.initialState ?? defaultInitialState;

	const myEntityConfig = entityConfig<T>({
		entity: type<T>(),
		// collection: '_my',
		selectId: (record: T) => (record as Has_Id)._id,
	});

	const createdSignalStore = signalStore(
		{ protectedState: true, providedIn: 'root' },
		withState<FT_SignalStoreState<T>>(initialState),
		withEntities(myEntityConfig),
		withProps(() => ({
			_crudHelper: inject(CrudHelperService),
			_dialogService: inject(CommonDialogService),
			_afService: inject(ActiveFilterService),
			_authStore: inject(AuthStore),
		})),

		withProps(() => ({
			_indexDbService: !!request.indexedDBConfig
				? inject(IndexedDBFactoryService).createIndexedDBService(request.indexedDBConfig)
				: null,
		})),

		/**
		 * computed documents
		 */
		withComputed((store) => ({
			hello: computed(() => `${signalStoreName} - hello`),

			documents: computed(() => {
				const records = store.entities();
				return records;
			}),
		})),

		withComputed(({ documents }) => ({
			documentsLoaded: computed(() => documents().length),
			documentIds: computed(() => documents().map((record) => (record as Has_Id)._id)),
			count: computed(() => documents().length),
		})),
		withComputed((store) => ({
			isLoading: computed(
				() => !!(updateStateIsProcessing(store.loadStatus()) || updateStateIsProcessing(store.updateStatus())),
			),
			isLoaded: computed(() => (['success', 'loaded', 'failed'] as UPDATE_STATUS[]).includes(store.loadStatus())),

			selectSelectedId: computed(() => store.selectedId()),
			selectSelectedIdId: computed(() => store.selectedId().id ?? ''),
			selectedDocument: computed(() =>
				store.entities().find((document) => isHas_Id(document) && document._id === store.selectedId().id),
			),
			selectLoadStatus: computed(() => store.loadStatus()),

			sortedDocuments: computed(() => {
				// if no documents, return empty array
				if (!store.documents().length) return [];

				// use first document as keys
				const firstDocument = store.documents()[0];

				if (sortFunction) {
					return (store.documents() as T[]).sort(sortFunction);
				} else if (sortProperty) {
					return SortByProperty(store.documents(), sortProperty);
				} else {
					MethodNotImplemented('sortedDocuments', 'sortProperty is undefined');
					return [];
				}
			}),

			selectLastUpdatedAt: computed(() => {
				const { maxUpdatedAt } = SelectMaxTimestamps(store.documents());
				return maxUpdatedAt;
			}),
		})),

		withComputed(({ _afService, ...store }) => ({
			filteredDocuments: computed(() => {
				console.debug(
					`${signalStoreName} - filteredDocuments - searchBarFields, selectActiveFieldFilters=`,
					searchBarFields,
					_afService.selectActiveFieldFilters(),
				);

				const fdocs = _afService.filterData<T>({
					records: store.entities() as T[],
					searchBarFields: searchBarFields,
					fieldFilters: _afService.selectActiveFieldFilters(),
					lastChange: _afService.selectLastUpdated(),
				});

				console.debug(
					`${signalStoreName} - filteredDocuments - fdocs=${fdocs.length}, searchBarFields=${searchBarFields.length}`,
				);
				return fdocs;
			}),

			filteredDocumentsBySearchBarOnly: computed(() => {
				return _afService.filterDataBySearchBar<T>({
					records: store.entities() as T[],
					searchBarFields: searchBarFields,
					fieldFilters: [],
					lastChange: new Date().getTime(),
					searchStringOverride: store.overrideSearchString(),
				});
			}),
		})),

		withComputed((store) => ({
			filteredDocumentsNotOmitted: computed<T[]>(() => {
				const fdocs = store.filteredDocuments();
				const filteredDataNotOmitted = FilteredDataNotOmitted(fdocs);
				console.debug(
					`${signalStoreName} - filteredDocumentsNotOmitted - fdocs=${fdocs.length}, filteredDataNotOmitted=${filteredDataNotOmitted.length}, searchBarFields=${searchBarFields.length}`,
				);
				return filteredDataNotOmitted;
			}),

			filteredDocumentsSelected: computed(() => {
				const fdocs = store.filteredDocuments();
				const filteredDataSelected = FilteredDataSelected(fdocs);
				return filteredDataSelected;
			}),
		})),

		withMethods((store) => ({
			updateLoadStatus(loadStatus: UPDATE_STATUS): void {
				patchState(store, { loadStatus: loadStatus });
			},
		})),

		withMethods((store) => ({
			documentIdsAndField(
				fieldname: FT_PropertyKey<T>,
			): { _id: string | number; fieldname: keyof T; value: T[keyof T] }[] {
				const records = store.entities();
				const mappedRecords = records.map((record) => ({
					_id: (record as Has_Id)._id,
					fieldname: fieldname,
					value: record[fieldname],
				}));
				return mappedRecords;
			},
		})),

		/**
		 * primary selects
		 */
		withMethods(({ entityMap, loadStatus }) => ({
			selectDocumentById(id: string) {
				console.debug(
					`${signalStoreName} - selectDocumentById - id=${id}, loadStatus=${loadStatus()}, entityMap=${
						Object.keys(entityMap()).length
					}`,
				);
				const selectedDocument = entityMap()[id] ?? undefined;
				return selectedDocument;
			},
		})),

		/**
		 * entity methods
		 */
		withMethods(({ ...store }) => ({
			upsertDocument(document: T, skipIndexUpdate: boolean = false) {
				const hydratedDocument = HydrateRecord(document, request.classConstructor);
				patchState(store, setEntity(hydratedDocument, myEntityConfig), { lastEntityUpdated: Date.now() });
				// update indexed db
				if (!skipIndexUpdate && store._indexDbService) {
					store._indexDbService.upsertData(store._indexDbService.tableName(), [document]);
				}
			},
			upsertDocuments(documents: T[], skipIndexUpdate: boolean = false) {
				const hydratedDocuments = HydrateRecords(documents, request.classConstructor);
				patchState(store, setEntities(hydratedDocuments, myEntityConfig), {
					lastEntityUpdated: Date.now(),
				});
				// update indexed db
				if (!skipIndexUpdate && store._indexDbService) {
					store._indexDbService.upsertData(store._indexDbService.tableName(), documents);
				}
			},
			removeDocument(id: string, skipIndexUpdate: boolean = false) {
				console.debug(`${signalStoreName} - removeDocument - id=${id}`);
				patchState(store, removeEntity(id), { lastEntityUpdated: Date.now() });
				// update indexed db
				if (!skipIndexUpdate && store._indexDbService) {
					store._indexDbService.deleteData(store._indexDbService.tableName(), id).subscribe();
				}
			},
			clearDocuments(skipIndexUpdate: boolean = false) {
				patchState(store, removeAllEntities(), { lastEntityUpdated: Date.now() });
				// update indexed db
				if (!skipIndexUpdate && store._indexDbService) {
					store._indexDbService.clearStore(store._indexDbService.tableName()).subscribe();
				}
			},

			setSelectedId(id: string) {
				console.debug(`${signalStoreName} - setSelectedId - id=${id}`);
				patchState(store, { selectedId: { id: id, timestamp: new Date().getTime() } });
			},

			setEditComponent(component: ComponentType<unknown>) {
				patchState(store, { editComponent: component });
			},

			setOverrideSearchString(searchString: string) {
				patchState(store, { overrideSearchString: searchString });
			},

			setIndexLoadex(loadedFlag: boolean) {
				console.debug(`${signalStoreName} - setIndexLoadex - loadedFlag=${loadedFlag}`);
				patchState(store, { indexLoaded: loadedFlag });
			},

			setIndexedDBService(_indexedDBService: GenericIndexedDbService) {
				console.debug(
					`${signalStoreName} - setIndexedDBService - indexedDBService=${_indexedDBService?.constructor.name ?? ''}`,
				);
				indexDbService = _indexedDBService;
			},
		})),

		withMethods(({ _crudHelper, ...store }) => ({
			loadAllDocuments(): void {
				console.debug(`${signalStoreName} - loadAllDocuments - BEG - getPagedDataRequest=`, getPagedDataRequest);
				if (!getPagedDataRequest) {
					MethodNotImplemented('loadAllDocuments', 'getPagedDataRequest is undefined');
					return;
				}

				console.debug(`${signalStoreName} - loadAllDocuments - set loadStatus=${UPDATE_STATUS.processing}`);
				patchState(store, { loadStatus: UPDATE_STATUS.processing });

				if (store.documents().length > 5 && isHasTimestamps(store.documents()[0])) {
					getPagedDataRequest.lastUpdated$ = of(CalcLastUpdatedValue(store.documents() as unknown as HasTimestamps[]));
				}

				/**
				 * set store upsert function to update as each page is loaded
				 */
				getPagedDataRequest.storeUpsertFunction = store.upsertDocuments;
				_crudHelper
					.getPagedData<T>(getPagedDataRequest)
					.pipe(
						take(1),
						finalize(() => {
							console.debug(`${signalStoreName} - loadAllDocuments - FINALIZE`);
						}),
						catchError((err: unknown) => {
							const result: GetPagedDataResponse<T> = {
								success: false,
								loadedCount: 0,
								lastUpdatedAt: '',
								message: FT_getErrorMessage(err),
								pagesReturned: 0,
								data: [],
							};
							return of(result);
						}),
					)
					.subscribe((result) => {
						console.debug(`${signalStoreName} - loadAllDocuments - result`, result);
						const loadStatus = result.success ? UPDATE_STATUS.loaded : UPDATE_STATUS.failed;
						patchState(store, { loadStatus: loadStatus });
					});
			},

			createRow(row: Partial<T>): void {
				console.debug(`${signalStoreName} - createRow`);
				if (!createRowRequest) {
					MethodNotImplemented('createRow', 'createRowRequest is undefined');
					return;
				}
				patchState(store, { updateStatus: UPDATE_STATUS.processing });
				const createRow = isHas_Id(row) && !row._id ? { ...row, _id: undefined } : row;
				if (isHas_Id(createRow) && createRow._id !== undefined) {
					console.error(`❌ ${signalStoreName} - createRow - Unable to document, contains an existing _id`);
					patchState(store, { updateStatus: UPDATE_STATUS.failed });
					return;
				}

				createRowRequest.row = createRow;
				_crudHelper
					.createRow<T>(createRowRequest)
					.pipe(
						take(1),
						finalize(() => {
							console.debug(`${signalStoreName} - createRow - FINALIZE`);
						}),
					)
					.subscribe((result) => {
						console.debug(`${signalStoreName} - createRow - result`, result);
						const updateStatus = result.success ? UPDATE_STATUS.success : UPDATE_STATUS.failed;
						if (result.success && result.record) {
							store.upsertDocument(result.record);
						}
						patchState(store, { updateStatus: updateStatus });
					});
			},

			mutateRow(row: Partial<T>): void {
				console.debug(`${signalStoreName} - mutateRow - row=`, row);
				if (!mutateRowRequest) {
					MethodNotImplemented('mutateRow', 'mutateRowRequest is undefined');
					return;
				}
				/**
				 * check if row is mutable
				 */
				if (!isMutableRow(row)) {
					console.error(`❌ ${signalStoreName} - mutateRow - Unable to update document, missing _id`);
					patchState(store, { updateStatus: UPDATE_STATUS.failed });
					return;
				}

				mutateRowRequest.row = row;
				patchState(store, { updateStatus: UPDATE_STATUS.processing });

				// save existing row and eagerly update the store
				const existingRow = isHas_Id(row) ? store.entityMap()[row._id] : undefined;
				if (isHas_Id(row)) {
					store.upsertDocument(row as T);
				}

				_crudHelper
					.mutateRow<T>(mutateRowRequest)
					.pipe(
						take(1),
						finalize(() => {
							console.debug(`${signalStoreName} - mutateRow - FINALIZE`);
							if (updateStateIsProcessing(store.updateStatus())) {
								patchState(store, { updateStatus: UPDATE_STATUS.failed });
							}
						}),
					)
					.subscribe((result) => {
						console.debug(`${signalStoreName} - mutateRow - result`, result);
						const updateStatus = result.success ? UPDATE_STATUS.success : UPDATE_STATUS.failed;
						if (result.success && result.record) {
							store.upsertDocument(result.record);
						} else if (!result.success && existingRow) {
							store.upsertDocument(existingRow);
						}
						patchState(store, { updateStatus: updateStatus });
					});
			},

			deleteRow(id: string, notifyValue: string): void {
				console.debug(`${signalStoreName} - deleteRow - id=`, id);
				if (!apiDelete) {
					MethodNotImplemented('deleteRow', 'apiDelete is undefined');
					return;
				}

				const deleteRowRequest: DeleteRowRequest<T> = {
					apiDelete: (_id) => apiDelete(_id),
					apiDeleteProp: 'deleteResult',
					_id: id,
					notify: true,
					notifyValue: notifyValue,
				};

				patchState(store, { updateStatus: UPDATE_STATUS.processing });

				_crudHelper
					.deleteRow<T>(deleteRowRequest)
					.pipe(
						take(1),
						finalize(() => {
							console.debug(`${signalStoreName} - deleteRow - FINALIZE`);
							if (updateStateIsProcessing(store.updateStatus())) {
								patchState(store, { updateStatus: UPDATE_STATUS.failed });
							}
						}),
					)
					.subscribe((result) => {
						console.debug(`${signalStoreName} - deleteRow - result`, result);
						const updateStatus = result.success ? UPDATE_STATUS.success : UPDATE_STATUS.failed;
						if (result.success) {
							store.removeDocument(id);
						}
						patchState(store, { updateStatus: updateStatus });
					});
			},
		})),

		withHooks({
			onInit({ _authStore, ...store }) {
				effect(() => {
					const state = getState(store);
					const isAuthenticated = _authStore.isAuthenticated();
					console.debug(
						`🚥 createSignalStore - ${signalStoreName} - EFFECT - authenticated=${isAuthenticated}, state=`,
						state,
					);
				});

				const appId = inject(APP_ID);
				const isProduction = inject(IS_PRODUCTION);
				const isDev = !isProduction;
				// const authStore = inject(AuthStore);
				const authStore = _authStore;

				if (isDev) {
					console.debug(
						`🚥 createSignalStore - ${signalStoreName} - onInit - appId=${appId} - ${
							isProduction ? 'production' : 'dev'
						}`,
					);
				}

				// /**
				//  * Indexed DB
				//  */
				// const indexedDBConfig = request.indexedDBConfig;
				// if (!!indexedDBConfig) {
				// 	indexDbService = inject(IndexedDBFactoryService).createIndexedDBService(indexedDBConfig);
				// }

				/**
				 * set watch state
				 */
				watchState(store, (state) => {
					if (isDev) {
						console.log(`🚥 ${signalStoreName} - [watchState] counter isLoading=${store.isLoading()}, state`, state);
					}
				}); // logs state changes

				/**
				 * Initialise data - set function to call based on whether indexed DB is used
				 */
				let functionToCall: () => void;

				/**
				 * Initialise using indexed DB
				 */
				if (store._indexDbService) {
					console.debug(
						`${signalStoreName} - InitialiseSignalStore - USING INDEXED DB=${store._indexDbService.getDbName()}`,
					);
					functionToCall = () =>
						initialiseDataFromIndexedDb(
							signalStoreName,
							store._indexDbService,
							store.upsertDocuments,
							store.loadAllDocuments,
							authStore.username,
							store.setIndexLoadex,
						);
				} else {
					/**
					 * Initialise without indexed DB
					 */
					console.debug(`${signalStoreName} - InitialiseSignalStore - NOT USING INDEXED DB`);
					functionToCall = () => store.loadAllDocuments();
				}

				/**
				 * Set up callbacks for the store depending on the loadOn property using indexed DB
				 */
				if (request.loadOn === 'auth') {
					authStore.addAuthenticatedCallback(() => functionToCall());
				} else if (request.loadOn === 'authNotSubscribed') {
					authStore.addAuthenticatedCallback(() => functionToCall());
				} else if (request.loadOn === 'subscribe') {
					authStore.addSubscribedCallback(() => functionToCall());
				} else {
					functionToCall();
				}
			},
			onDestroy(store) {
				console.debug(`🚥 ${signalStoreName} - onDestroy`);
			},
		}),
	);

	/**
	 * Return the created signal store
	 */
	return createdSignalStore;
};

const initialiseDataFromIndexedDb = <T>(
	signalStoreName: string,
	indexDbService: GenericIndexedDbService | undefined | null,
	upsertDocuments: (documents: T[], skipIndexUpdate: boolean) => void,
	loadAllDocuments: () => void,
	username: Signal<string> | undefined,
	setIndexLoaded?: (loadedFlag: boolean) => void,
) => {
	console.debug(
		`${signalStoreName} - initialiseDataFromIndexedDb - indexDbTablename=${
			indexDbService?.tableName() ?? ''
		}, username=${username ? username() : ''}`,
	);
	if (!indexDbService) return;
	const tableName = indexDbService.tableName();

	indexDbService
		.getAllRecords<T>(tableName)
		.pipe(
			take(1),
			tap((idxResponse) => {
				const records = idxResponse.records ?? ([] as T[]);
				console.debug(
					`${signalStoreName} - initialiseDataFromIndexedDb - upsertDocuments from indexedDB=${tableName}, records=${records.length}`,
				);
				upsertDocuments(records, true);
				if (setIndexLoaded) {
					setIndexLoaded(true);
				}
			}),
			finalize(() => {
				console.debug(
					`${signalStoreName} - initialiseDataFromIndexedDb - FINALIZE - calling loadAllDocuments ${tableName}`,
				);
				loadAllDocuments();
			}),
			catchError((err: unknown) => {
				FT_LogError(err, signalStoreName, `initialiseDataFromIndexedDb - loadFromIndex ${tableName}`);
				return of(null);
			}),
		)
		.subscribe();
};
