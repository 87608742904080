import { ChangeDetectionStrategy, Component, type OnInit, computed, inject, input, output } from '@angular/core';

import { NgClass } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { LayoutInfoService } from '@ft/lib/screen-lib';

type ClickFunction = () => void | Promise<void>;

// @Input() callbackFunction: () => void = () => {};

@Component({
	selector: 'ft-dialog-button',
	templateUrl: './dialog-button.component.html',
	styleUrls: ['./dialog-button.component.scss'],
	imports: [FontAwesomeModule, MatButtonModule, MatTooltipModule, NgClass],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogButtonComponent implements OnInit {
	protected layout = inject(LayoutInfoService);

	iconDefinition = input<IconDefinition | undefined>(undefined);
	buttonText = input<string>('');
	tooltip = input<string | undefined>(undefined);
	textPosition = input<'before' | 'after' | 'above' | 'below'>('below');
	color = input<string>('	primary');
	disabled = input<boolean>(false);

	clickFunction = input<ClickFunction | undefined>(undefined);

	// Event emitters
	buttonClicked = output<boolean>();

	contentClasses = computed(() => {
		return this.textPosition() === 'above' || this.textPosition() === 'below' ? 'flex-column' : '';
	});

	buttonClasses = computed(() => {
		return {
			'icon-only': !!this.iconDefinition() && !this.buttonText(),
			'text-only': !this.iconDefinition() && !!this.buttonText(),
			'icon-text': !!this.iconDefinition() && !!this.buttonText(),
		};
	});

	contactTooltip = 'Send comments and upload documents to be included on the site';
	contactNotLoggedIn = 'You must sign up and log in to be able to send feedback and documents';

	constructor() {}

	async ngOnInit() {}

	handleClick() {
		console.debug(`${this.constructor.name} - handleClick - clickFunction=`, this.clickFunction());
		/**
		 * emit button clicked event
		 */
		this.buttonClicked.emit(true);

		/**
		 * Call click function
		 */
		const clickFunction = this.clickFunction();
		if (clickFunction) {
			clickFunction();
		}
	}
}
