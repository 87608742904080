import { CreateFilterValuePermutations } from './filter-functions.js';
import { AllFilterValues, BooleanFilterValues, isFilterableFieldType, NoValueFilterValues } from './filter-types.model.js';
export class FieldFilter {
  fieldnames;
  filterDataType;
  operation = '';
  filterValuePermutations = undefined;
  _filterValues = [];
  enteredFilterValues = [];
  defaultOperation = undefined;
  defaultFilterValuePermutations = undefined;
  _defaultFilterValues = undefined;
  // private _matchingSearchBarFields: SearchBarField<T>[] = [];
  constructor(init) {
    this.fieldnames = Array.isArray(init.fieldnames) ? init.fieldnames : [init.fieldnames];
    this.filterDataType = init.filterDataType ?? 'string';
    // load operation and default operation
    this.operation = init.operation ?? '';
    this.defaultOperation = init.defaultOperation ?? undefined;
    // load values and default values
    if (init.filterValues) this.filterValues = this.scrubFilterValues(init.filterValues);
    if (init.defaultFilterValues) this.defaultFilterValues = this.scrubFilterValues(init.defaultFilterValues);
  } // end constructor
  get isNoFilterValueOperation() {
    return !!(this.operation && NoValueFilterValues.findIndex(item => item === this.operation) > -1);
  }
  get operationRequiresFilterValues() {
    return !this.isNoFilterValueOperation;
  }
  get isNoFilterValueDefaultOperation() {
    return !!(this.defaultOperation && NoValueFilterValues.findIndex(item => item === this.defaultOperation) > -1);
  }
  get defaultOperationRequiresFilterValues() {
    return !this.isNoFilterValueDefaultOperation;
  }
  get isNoFilterValueActiveOperation() {
    return !!(this.activeOperation && NoValueFilterValues.findIndex(item => item === this.activeOperation) > -1);
  }
  get activeOperationRequiresFilterValues() {
    return !this.isNoFilterValueActiveOperation;
  }
  get filterValues() {
    return this._filterValues ?? [];
  }
  set filterValues(values) {
    this._filterValues = values ? values.map(x => String(x)) : [];
    const fvLength = this._filterValues.length;
    this.enteredFilterValues = fvLength ? this._filterValues.map(x => String(x)) : [];
    this.filterValuePermutations = fvLength ? CreateFilterValuePermutations(this._filterValues, this.filterDataType) : [];
  }
  get defaultFilterValues() {
    return this._defaultFilterValues;
  }
  set defaultFilterValues(values) {
    this._defaultFilterValues = values;
    // load default filter permutations
    if (this._defaultFilterValues?.length) {
      this.defaultFilterValuePermutations = CreateFilterValuePermutations(this._defaultFilterValues, this.filterDataType);
    } else {
      this.defaultFilterValuePermutations = undefined;
    }
  }
  get usingDefault() {
    // if no default filter, not using default
    if (!this.defaultOperation) return false;
    /**
     * same operation
     */
    if (this.activeOperation === this.defaultOperation) {
      // if no filter value for operation, we are using the default operation
      if (this.isNoFilterValueActiveOperation) return true;
      // check if value lengths are the same and all values match
      if (this.filterValues?.length !== this.defaultFilterValues?.length) return false;
      for (const filterValue of this.filterValues) {
        if (!this.defaultFilterValues.find(x => x === filterValue)) return false;
      }
      return true;
    } else {
      return false;
    }
  }
  /**
   * active operation - if there is a default, use the default
   */
  get activeOperation() {
    if (!!this.operation) return this.operation;
    if (!!this.defaultOperation) return this.defaultOperation;
    return '';
  }
  get activeFilterValues() {
    if (!!this.defaultOperation && this.defaultOperation === this.activeOperation && !this.filterValues.length) {
      return this.defaultFilterValues ?? [];
    } else {
      return this.filterValues;
    }
  }
  get activeFilterValuePermutations() {
    if (!!this.defaultOperation && this.defaultOperation === this.activeOperation && !this.filterValues.length) {
      return this.defaultFilterValuePermutations ?? [];
    } else {
      return this.filterValuePermutations ?? [];
    }
  }
  scrubFilterValues(values) {
    const scrubbedValues = [];
    for (const value of values) {
      const scrubbedValue = this.scrubFilterValue(value);
      if (scrubbedValue !== undefined && scrubbedValue !== '' && scrubbedValues.findIndex(item => item === scrubbedValue) === -1) {
        scrubbedValues.push(scrubbedValue);
      }
    }
    return scrubbedValues;
  }
  scrubFilterValue(value) {
    if (typeof value === 'string') return value.trim();
    if (typeof value === 'number') return value;
    if (typeof value === 'boolean') return value;
    return undefined;
  }
  get isVisible() {
    return this.isValidFilter && !this.usingDefault;
  }
  get isBooleanFilter() {
    if (!this.activeOperation) return false;
    return BooleanFilterValues.findIndex(item => item === this.activeOperation) > -1;
  }
  get requiresFilterValues() {
    return !this.hasNoFilterValues;
  }
  get hasNoFilterValues() {
    if (!this.activeOperation) return true;
    return NoValueFilterValues.findIndex(item => item === this.activeOperation) > -1;
  }
  get isValidFilter() {
    if (!this.fieldnames || !this.fieldnames.length) {
      return false;
    }
    // operation must exist to be active
    if (!this.activeOperation) {
      return false;
    }
    // active operation must be valid
    if (!AllFilterValues.includes(this.activeOperation)) {
      return false;
    }
    if (this.activeOperationRequiresFilterValues && !this.activeFilterValuePermutations.length) {
      return false;
    }
    return true;
  }
  filterFieldValue(fieldValue) {
    // skip if not valid
    if (!this.isValidFilter) return true;
    // filter fieldvalue
    let compareResult = false;
    if (fieldValue && Array.isArray(fieldValue)) {
      for (const value of fieldValue) {
        compareResult = this.filterCompareToValue(value);
        if (compareResult === true) {
          break;
        }
      }
    } else {
      compareResult = this.filterCompareToValue(fieldValue);
    }
    return compareResult;
  }
  /**
   * Filter our field value to the comparison value
   */
  filterCompareToValue(fieldValue) {
    // special checks for null or undefined field value
    if (fieldValue === null || fieldValue === undefined) {
      return this.selectValueIsNullOrUndefined();
    }
    if (!isFilterableFieldType(fieldValue)) {
      return this.selectValueIsNotFilterable();
    } // end special checks
    // no-value filter ... operation checks fieldvlaue exists or boolean
    if (this.hasNoFilterValues) {
      if (this.activeOperation === 'yes') {
        return Boolean(fieldValue);
      }
      if (this.activeOperation === 'no') {
        return !Boolean(fieldValue);
      }
      if (this.activeOperation === 'empty') {
        return this.isFieldValueEmpty(fieldValue);
      }
      if (this.activeOperation === 'notEmpty') {
        return !this.isFieldValueEmpty(fieldValue);
      }
      return true;
    }
    // loop through each possible filter value - need one hit to be true
    let compareResult = false;
    for (const filterValuePermutation of this.activeFilterValuePermutations) {
      let filterResult = false;
      if (this.activeOperation === 'contains') {
        filterResult = this.containsFilter(fieldValue, filterValuePermutation);
      } else if (this.activeOperation === 'equals') {
        filterResult = this.equalsFilter(fieldValue, filterValuePermutation);
      } else if (this.activeOperation === 'doesNotContain') {
        filterResult = !this.containsFilter(fieldValue, filterValuePermutation);
      } else if (this.activeOperation === 'notEqual') {
        filterResult = !this.equalsFilter(fieldValue, filterValuePermutation);
      }
      if (filterResult) {
        compareResult = filterResult;
        break;
      }
    } // end for loop
    return compareResult;
  } // end filterCompareToValue
  isFieldValueEmpty(fieldValue) {
    if (typeof fieldValue === 'string' || typeof fieldValue === 'number') {
      return !Boolean(fieldValue);
    } else if (fieldValue == null) {
      return true;
    } else {
      return false;
    }
  }
  selectValueIsNullOrUndefined() {
    if (['empty', 'no', 'less than', 'less than or equal', 'notEqual'].includes(this.activeOperation)) {
      return true;
    } else {
      return false;
    }
  } // end selectValueIsNullOrUndefined
  selectValueIsNotFilterable() {
    if (['doesNotContain', 'notEmpty', 'notEqual', 'numericNotEquals'].includes(this.activeOperation)) {
      return true;
    } else {
      return false;
    }
  } // end selectValueIsNotFilterable
  containsFilter(fieldValue, ffv) {
    return String(fieldValue).search(ffv.valueRx) > -1;
  }
  equalsFilter(fieldValue, ffv) {
    if (this.filterDataType === 'string') {
      return String(fieldValue).toLowerCase() === ffv.valueLc;
    } else {
      return fieldValue === ffv.value;
    }
  }
} // end FieldFilter
