import { DragDropModule } from '@angular/cdk/drag-drop';
import {
	type AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	type Signal,
	computed,
	effect,
	forwardRef,
	inject,
	input,
	output,
	signal,
} from '@angular/core';

import { type ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, type SafeResourceUrl, type SafeUrl } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
	faCirclePlus,
	faFilePdf,
	faImage,
	faKeyboard,
	faSpinner,
	faSquareMinus,
} from '@fortawesome/free-solid-svg-icons';

import { NgClass, NgStyle } from '@angular/common';

import { NgxImageCompressService } from 'ngx-image-compress';

import { MatDialog } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
	FT_LogError,
	First20Chars,
	UploadAcceptTypes,
	UploadType,
	ValidateUploadFileType,
} from '@furnas-technology/common-library/functions';
import { map, take, tap } from 'rxjs';

import { AppSpinner } from '@ft/lib/directives';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { NotifyService } from '@ft/lib/snackbar-lib';
import { ImageUploadService, MaxPixels } from '../image-upload.service';
import { FT_PdfViewerComponent } from '../pdf-viewer/pdf-viewer.component';
import { FT_UploadDialog, FT_UploadDialogData, UploadResult } from '../upload-dialog/upload-dialog.component';

type PositionKey = 'top' | 'bottom' | 'left' | 'right ';

type StylesObject = {
	[key: string]: string | number;
};

const ValidUploadType = (uploadType: UploadType): boolean => {
	return ['image', 'pdf'].includes(uploadType);
};

@Component({
	selector: 'ft-magic-image',
	templateUrl: './magic-image.component.html',
	styleUrls: ['./magic-image.component.scss'],
	imports: [AppSpinner, NgClass, NgStyle, DragDropModule, FontAwesomeModule, MatTooltipModule, FT_PdfViewerComponent],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => MagicImageComponent),
			multi: true,
		},
		NgxImageCompressService,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MagicImageComponent implements AfterViewInit, ControlValueAccessor {
	private sanitizer = inject(DomSanitizer);
	private dialog = inject(MatDialog);
	private notifyService = inject(NotifyService);
	private imageUpload = inject(ImageUploadService);
	private layout = inject(LayoutInfoService);

	/**
	 * Input parameters
	 */
	imageSrc = input<string>('');
	inputLabel = input<string>('');
	disabled = input<boolean>(false);
	width = input<string>('');
	height = input<string>('');
	imageMaxPixels = input<number>(MaxPixels);
	multiple = input<boolean>(false);
	uploadType = input<UploadType>('image');

	changedImageSrc = output<string>();

	faCirclePlus = faCirclePlus;
	faSquareMinus = faSquareMinus;
	faKeyboard = faKeyboard;
	faFilePdf = faFilePdf;
	faImage = faImage;
	faSpinner = faSpinner;

	dropArea: HTMLElement | null = null;
	isHovered = signal<boolean>(false);
	isDragover = false;

	showHoverIcon = computed(() => this.isHovered() && !this.disabled());

	imgWidth = computed(() => this.width() ?? '');
	imgHeight = computed(() => this.height() ?? '');

	acceptType = computed(() => {
		const acceptTypes = UploadAcceptTypes(this.uploadType());
		return acceptTypes.join(',');
	});

	imageStyle = computed(() => {
		const styles: StylesObject = {};

		if (this.imgWidth() && this.imgHeight()) {
			styles['max-width'] = this.imgWidth();
			styles['max-height'] = this.imgHeight();
			styles['height'] = 'auto';
			styles['width'] = 'auto';
			styles['object-fit'] = 'contain';
		} else if (this.imgWidth()) {
			styles['width'] = this.imgWidth();
			styles['height'] = 'auto';
			styles['object-fit'] = 'contain';
		} else if (this.imgHeight()) {
			styles['width'] = 'auto';
			styles['height'] = this.imgHeight();
			styles['object-fit'] = 'contain';
		} else {
			styles['width'] = 'auto';
			styles['height'] = 'auto';
			styles['object-fit'] = 'contain';
		}

		return styles;
	});

	placeholderImage = `assets/lib/icons/no-image-x.jpg`;

	displayedImageSrc = signal('');
	displayedImageSrcSafe = computed(() => {
		try {
			if (!this.displayedImageSrc()) return undefined;

			return this.sanitizer.bypassSecurityTrustUrl(this.displayedImageSrc());
		} catch (err: unknown) {
			console.error(`❌ ${this.constructor.name} - safeImageSrc - err=`, err);
			return undefined;
		}
	});

	base64Src = computed(() => {
		if (this.isUrl() || !this.displayedImageSrc()) return '';

		const parts = this.displayedImageSrc().split(';');
		if (parts.length > 1 && parts[1].startsWith('base64,')) {
			const base64part = parts[1].slice(7);
			return base64part;
		} else {
			return '';
		}
	});

	isUrl = computed(() => !this.displayedImageSrc()?.startsWith('data:'));

	imageType: Signal<UploadType> = computed(() => {
		if (this.displayedImageSrc()?.toLowerCase().endsWith('.pdf')) {
			return 'pdf';
		} else if (
			this.displayedImageSrc() &&
			(this.displayedImageSrc().toLowerCase().endsWith('.png') ||
				this.displayedImageSrc().toLowerCase().endsWith('.jpg') ||
				this.displayedImageSrc().toLowerCase().endsWith('.jpeg') ||
				this.displayedImageSrc().toLowerCase().endsWith('.gif') ||
				this.displayedImageSrc().toLowerCase().endsWith('.svg') ||
				this.displayedImageSrc().toLowerCase().endsWith('.tiff'))
		) {
			return 'image';
		} else if (this.uploadType() === 'image') {
			return 'image';
		} else if (this.uploadType() === 'pdf') {
			return 'pdf';
		} else {
			return 'image';
		}
	});

	showClickElem = computed(
		() => this.isHovered() && !this.disabled() && (!this.displayedImageSrc() || ValidUploadType(this.imageType())),
	);

	dialogSize = computed(() => {
		let width = '';
		let height = '';
		let maxWidth = 'unset';
		let maxHeight = 'unset';

		if (this.layout.gtSmallScreen()) {
			width = `clamp(500px, 80vw, 800px)`;
			height = `clamp(400px, 80vh, 700px)`;
		} else {
			height = `99vh`;
			width = `99vw`;
			maxWidth = `600px`;
			maxHeight = `600px`;
		}
		return { width, height, maxWidth, maxHeight };
	});

	constructor() {
		/**
		 * On change of imageSrc, update displayedImageSrc
		 */
		effect(() => {
			console.debug(`${this.constructor.name} - effect - imageSrc CHANGED`);
			this.displayedImageSrc.set(this.imageSrc());
		});
	}

	ngAfterViewInit(): void {
		const dropArea = document.getElementById('#drop-area');
		if (dropArea) {
			document.getElementById('#drop-area')?.addEventListener('change', this.handleFileSelect, false);
		}
	} // end ngAfterViewInit

	setImageSrc() {
		// nothing to see here
	} // end setImageSrc

	uploadFile(event: Event) {
		if (this.disabled()) return;

		const inputElement = event.target as HTMLInputElement;
		const selectedFiles: FileList | null = inputElement.files;

		if (selectedFiles && selectedFiles.length > 0) {
			const selectedFile: File = selectedFiles[0];

			// Now you can work with the selectedFile and its properties
			this.convertFile(selectedFiles);
		}
	}

	handleFileSelect(event: Event) {
		if (this.disabled()) return;
	}

	onFileDropped(event: Event) {
		if (this.disabled()) return;
		this.isDragover = false;
		event.preventDefault();

		// process dropp
		// this.convertFile(event.dataTransfer?.files);
	}

	onDragOver(event: DragEvent) {
		if (this.disabled()) return;
		this.isDragover = true;
		// Prevent default behavior
		event.preventDefault();
	}

	onDragLeave(event: DragEvent) {
		if (this.disabled()) return;
		this.isDragover = false;
		// Prevent default behavior
		event.preventDefault();
	}

	onDrop(event: DragEvent) {
		// Prevent default behavior
		event.preventDefault();
		event.stopPropagation();
		if (this.disabled()) return;

		// Access dropped files
		const files: FileList | undefined = event.dataTransfer?.files;

		// Check if any file was dropped
		if (files && files.length > 0) {
			// Loop through dropped files
			let valid = true;
			for (let i = 0; i < files.length; i++) {
				const droppedFile = files[i];

				// Validate file type (optional)
				const validFileType = ValidateUploadFileType(droppedFile.type, this.uploadType());
				if (!validFileType) {
					// Handle invalid file type (optional)
					this.notifyService.error(`Invalid file type (${droppedFile.type}), unable to upload ${droppedFile.name}`);
					valid = false;
					break;
				}
			}

			// if valid, convert files
			if (valid) {
				this.convertFile(files);
			}
		}
	}

	imageError(evt: Event) {
		console.debug(`${this.constructor.name} - imageError`);
		this.displayedImageSrc.set(this.placeholderImage);
	}

	async convertFile(files: FileList | undefined): Promise<void> {
		if (!files || files.length === 0) return;

		const f: File = files[0];
		if (!f) return;

		if (f.type.match(/image|application/)) {
			const reader: FileReader = new FileReader();

			reader.onloadend = async (event: ProgressEvent<FileReader>) => {
				const base64String = reader.result;

				// compress file
				if (typeof base64String === 'string') {
					console.debug(
						`${this.constructor.name} - convertFile - maxPixels=${this.imageMaxPixels}, base64String=${First20Chars(base64String, 40)}`,
					);
					this.imageUpload
						.compressFile(base64String, this.imageMaxPixels())
						.pipe(
							take(1),
							tap((compressedString: string) => {
								if (compressedString) {
									this.changeImageSrc(compressedString);
								}
							}),
						)
						.subscribe();
				}
			};

			reader.onprogress = (event: ProgressEvent<FileReader>) => {
				if (event.lengthComputable) {
					const progress: number = (event.loaded / event.total) * 100;
				}
			};

			reader.onerror = (err: unknown) => {
				FT_LogError(err, this.constructor.name, `convertFile`);
				this.changeImageSrc('');
			};

			reader.readAsDataURL(f);
		}
	} // end convertFile

	changeImageSrc(imageSrc: string): void {
		this.displayedImageSrc.set(imageSrc);
		this.changedImageSrc.emit(imageSrc);
	}

	// Function to call when the imageSrc changes
	onChange = (imageSrc: string) => {
		// nothing to see here
	};

	// Function to call when the input is touched (when a star is clicked).
	onTouched = () => {};

	// Allows Angular to update the model (rating).
	// Update the model and changes needed for the view here.
	writeValue(imageSrc: string): void {
		// nothing to see here
	}

	// Allows Angular to register a function to call when the model (rating) changes.
	// Save the function as a property to call later here.
	registerOnChange(fn: (imageSrc: string) => void): void {
		this.onChange = fn;
	}

	// Allows Angular to register a function to call when the input has been touched.
	// Save the function as a property to call later here.
	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	onClick(evt: unknown) {
		if (ValidUploadType(this.imageType()) || !this.displayedImageSrc()) {
			if (!this.disabled()) {
				this.uploadImageDialog(evt);
			}
		}
	}

	onHover(evt: unknown) {
		this.uploadImageDialog(evt);
	}

	safeImageSrc(imageSrc: string): string | SafeResourceUrl | SafeUrl | File | undefined {
		try {
			if (!imageSrc) return undefined;

			return this.sanitizer.bypassSecurityTrustUrl(imageSrc);
		} catch (err: unknown) {
			console.error(`❌ ${this.constructor.name} - safeImageSrc - err=`, err);
			return undefined;
		}
	}

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	uploadImageDialog(evt: Event | MouseEvent | any) {
		const position: { [key: string]: string } = {};

		const data: FT_UploadDialogData = {
			title: 'Select or Upload Image',
			message: `Enter image address or click upload`,
			imageSrc: this.displayedImageSrc(),
			uploadType: this.uploadType(),
		};

		console.debug(
			`${this.constructor.name} - uploadImageDialog - dialogSize=${this.dialogSize().width}, dialogHeight=${this.dialogSize().height}`,
		);

		// Open the new dialog
		const dialogRef = this.dialog.open(FT_UploadDialog, {
			height: `${this.dialogSize().height}`,
			width: `${this.dialogSize().width}`,
			maxWidth: `${this.dialogSize().width}`,
			maxHeight: `${this.dialogSize().width}`,
			data: data,
		});

		return dialogRef
			.afterClosed()
			.pipe(
				map((uploadResult: UploadResult | undefined) => {
					console.debug(`${this.constructor.name} - dialog uploadResult=`, uploadResult);
					if (uploadResult?.clear) {
						this.changeImageSrc('');
					} else if (uploadResult?.imageSrc) {
						this.changeImageSrc(uploadResult.imageSrc);
					}
				}),
			)
			.subscribe();
	}
}
