import { InjectionToken } from '@angular/core';

export type EnvironmentConfigInput = {
	baseApiUrl: string;
	production: boolean;
	appName: string;
	stripePT: string;
	stripePK: string;
	stripePortal: string;
	googleConversionTag: string;
	stage: string;
};

export interface EnvironmentConfig {
	production: boolean;
	stage: string;

	appName: string;
	buildDate: string | undefined;

	baseUrl: string;
	authUrl: string;
	backgroundsUrl?: string;

	stripePT?: string;
	stripePK?: string;
	stripePortal?: string;

	graphdefaultUrl?: string;
	graphqlPublicUrl?: string;

	loginUrl: string;
	homeUrl: string;
	logoutUrl: string;

	loginCallback: string;
	logoutCallback: string;

	autoLogoutMinutes: number;
	downloadUrl?: string;
	portalIssueBaseUrl?: string;
	domainsUrl?: string;

	googleConversionTag?: string;

	logoSquare: string; // 180x180
	logoLandscape: string; // 240x135
	logoPortrait: string; // 200x300
	icon: string; // 120x120
	background: string; // 1920x1080
	logoLandscapeTransparent: string; // 320x180
	logoSquare512: string; // 512x512
	logoText: string; // 320x180
}

export const APP_ENVIRONMENT = new InjectionToken<EnvironmentConfig>('APP_ENVIRONMENT');
