@if (!displayCardsView()) {
  <div
    class="table-generic-component"
    color="primary"
    [ngClass]="layout.cssClasses()"
    resizeObserver
    (resize)="onResize($event)"
  >
    <section class="table-section mat-elevation-z8" #tableSection tabindex="0">
      @if (tableColumns()) {
        <div #tableContainer class="table-container">
          <cdk-virtual-scroll-viewport
            class="cdk-viewport"
            headerHeight="headerHeight"
            autosize
            [itemSize]="itemSize"
            [tvsItemSize]="itemSize"
            bufferMultiplier="0.5"
          >
            <table
              mat-table
              #mattable
              [dataSource]="dataSource"
              [trackBy]="tableTrack"
              matSort
              (matSortChange)="onSort($event)"
              panelClass="ft-table"
              class="table-element"
              [attr.data-rows-changed]="dataRowsChanged()"
            >
              @for (column of tableColumns(); track ci; let ci = $index) {
                <ng-container
                  [matColumnDef]="column.name"
                  [sticky]="layout.isGT_medium() ? (column.sticky ?? false) : false"
                  [stickyEnd]="layout.isGT_medium() ? (column.stickyEnd ?? false) : false"
                >
                  <ng-container>
                    <th
                      mat-header-cell
                      *matHeaderCellDef
                      class="ft-th-cell"
                      [attr.data-type]="column.columnType"
                      [attr.data-column]="column.name"
                      [attr.data-sticky]="layout.isGT_medium() ? (column.sticky ?? false) : false"
                      [ngClass]="column.headerClass"
                      [ngStyle]="column.headerStyle"
                      [attr.data-header-style]="column.headerStyleString || 'NONE'"
                      [mat-sort-header]="column.name"
                      [disabled]="!column.isSortable"
                      arrowPosition="before"
                      disableClear="true"
                    >
                      <!-- <mat-sort-header-arrow *matSortHeaderArrow></mat-sort-header-arrow> -->

                      @if (['actions', 'image'].includes(column.columnType)) {
                        <div class="strong">{{ column.label }}</div>
                      } @else {
                        <div class="strong" style="margin-right: 5px">{{ column.label }}</div>
                        <ft-column-filter [column]="column" (filterChange)="onFilter($event)"></ft-column-filter>
                      }
                    </th>
                    <!-- data cell -->
                    <td
                      mat-cell
                      *matCellDef="let element"
                      [ngClass]="column.cellClass"
                      [ngStyle]="column.cellStyle"
                      [attr.data-cell-style]="column.cellStyleString || 'NONE'"
                      [attr.data-col-type]="column.columnType || 'NONE'"
                      [attr.data-elementId]="column.elementId"
                      [attr.data-row-id]="element?._id ?? ''"
                      [attr.data-has-valueFn]="column.valueFn ? 'yes' : 'no'"
                      [attr.data-templateRef]="column.templateRefName"
                      role="data"
                    >
                      @if (column.editable) {
                        <ft-edit-cell
                          [column]="column"
                          [element]="element"
                          [rowId]="element._id"
                          [displayableValue]="column.displayableValue(element)"
                          [tooltipValue]="tooltipValue(column, element)"
                          (changedColumn)="onChangedColumn($event)"
                        ></ft-edit-cell>
                      } @else if (column.columnType === 'actions') {
                        <ft-display-actions-cell
                          [column]="column"
                          [element]="element"
                          [rowId]="element._id"
                          (buttonPressed)="onActionClicked($event)"
                        ></ft-display-actions-cell>
                      } @else if (column.columnType === 'image') {
                        <ft-display-image-cell
                          [column]="column"
                          [element]="element"
                          [rowId]="element._id"
                          [displayableValue]="column.displayableValue(element)"
                          [tooltipValue]="tooltipValue(column, element)"
                          (buttonPressed)="onActionClicked($event)"
                        ></ft-display-image-cell>
                      } @else {
                        <ft-display-cell
                          [column]="column"
                          [element]="element"
                          [rowId]="element._id"
                          [displayableValue]="column.displayableValue(element)"
                          [dataRowsChanged]="dataRowsChanged()"
                          [tooltipValue]="tooltipValue(column, element)"
                          (buttonPressed)="onActionClicked($event)"
                        ></ft-display-cell>
                      }
                    </td>
                    <td
                      mat-footer-cell
                      *matFooterCellDef
                      [ngStyle]="column.cellStyle"
                      [ngClass]="column.cellClass"
                    ></td>
                  </ng-container>
                </ng-container>
              }
              <!-- end of column definitions -->

              <!-- render header row -->
              <tr
                mat-header-row
                class="ft-table-header-row"
                *matHeaderRowDef="displayedColumnNames(); sticky: true"
                #headerRow
              ></tr>

              <!-- render data rows -->
              <tr
                mat-row
                color="accent"
                class="ft-table-data-row"
                *matRowDef="let row; let first = first; let last = last; let odd = odd; columns: displayedColumnNames()"
                [ngClass]="[odd ? 'odd' : 'even', last ? 'last' : '', first ? 'first' : '']"
              ></tr>

              <!-- <tr mat-footer-row *matFooterRowDef="displayedColumnNames(); sticky: true"></tr> -->
            </table>
          </cdk-virtual-scroll-viewport>
        </div>
      }
    </section>
  </div>
} @else {
  <ft-display-cards
    class="cards-section"
    resizeObserver
    (resize)="onResize($event)"
    [ngClass]="tableService.cssClasses()"
    [tableColumns]="tableColumns()"
    [data]="dataSource.data"
    [dataRowsChanged]="dataRowsChanged()"
    [defaultCardHeight]="defaultCardHeight()"
    (buttonPressed)="onActionClicked($event)"
  ></ft-display-cards>
}
