import { MatTooltipDefaultOptions } from '@angular/material/tooltip';

export type FT_SamePropTypeOnly<T> = {
	[P: string]: T;
};

interface TableField {
	name: string;
	title: string;
}

interface FormControl {
	name: string;
	title: string;
}

export type TableColumnsConfig = FT_SamePropTypeOnly<TableField>;
export type FormConfig = FT_SamePropTypeOnly<FormControl>;

export class MatMenuOption {
	icon: string = '';
	title: string = '';
	activityInfo: MatMenuActivity = { isInActive: true };
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	execute: () => void = undefined as any;
}

interface MatMenuActivity {
	isInActive: boolean;
	inactivityReason?: () => string;
}

export const issueCustomTooltipDefaults: MatTooltipDefaultOptions = {
	showDelay: 300,
	hideDelay: 300,
	touchendHideDelay: 500,
};

export type PanelCardElement = {
	_id: string;
	outlineKey: string;
	width: number;
	height: number;
};
