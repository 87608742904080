import { InjectionToken } from '@angular/core';
import type { Data, Route } from '@angular/router';
import type { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { ComponentFilterDefinition, InitComponentFilterDefinition } from '@ft/lib/active-filter-lib';

export type AuthenticatedStatus = 'authenticated' | 'unauthenticated' | '';

type ShowOrHide = 'show' | 'hide';

export type NavbarType = 'hide' | 'normal' | 'large';

export type NavbarSettings = {
	navbarType: NavbarType;
	hasSidebar: boolean;
	leftLogo: ShowOrHide;
	pageItems: ShowOrHide;
	rightLogo: ShowOrHide;
	profile: ShowOrHide;
	showSearchBar: boolean; // default to true
};

export const APP_NAVBAR_SETTINGS = new InjectionToken<NavbarSettings>('APP_NAVBAR_SETTINGS');

export type FT_RouteData<T> = {
	icon: IconDefinition;
	allowedGroups: string[];
	authenticatedStatus: AuthenticatedStatus;
	children: Route[];
	noRoute: boolean;
	hasSidebar: boolean;
	showInNavbar: boolean;
	componentFilter: ComponentFilterDefinition<T> | undefined;
	searchBarInFilterComponent: boolean; // default to false
	navbarSettings: NavbarSettings; // default to DefaultNavbarSettings
	[key: string]: unknown;
};

export const IsRouteData = (data: unknown): data is FT_RouteData<unknown> => {
	return !!(data && typeof data === 'object' && 'icon' in data);
};

export type FT_RouteDataInput<T> = Partial<FT_RouteData<T>>;

export const FT_CreateRouteData = <T>(data: Data | FT_RouteDataInput<T>): FT_RouteData<T> => {
	const allowedGroups = (data?.allowedGroups as string[]) ?? ([] as string[]);
	const authenticatedStatus = (
		allowedGroups.length > 0 ? 'authenticated' : (data.authenticatedStatus ?? '')
	) as AuthenticatedStatus;

	const routeData: FT_RouteData<T> = {
		...data,
		icon: data.icon ?? '',
		allowedGroups: allowedGroups,
		authenticatedStatus: authenticatedStatus,
		children: data.children ?? [],
		noRoute: !!data.noRoute,
		hasSidebar: !!data.hasSidebar,
		showInNavbar: !!data.showInNavbar,
		componentFilter: data.componentFilter ?? InitComponentFilterDefinition,
		searchBarInFilterComponent: data.searchBarInFilterComponent === undefined ? false : data.searchBarInFilterComponent, // default to false
		navbarSettings: data.navbarSettings ?? {},
	};
	return routeData;
};
