import { InjectionToken } from '@angular/core';
import { Route } from '@angular/router';

export const APP_NAME = new InjectionToken<string>('APP_NAME');
export const AUTH_URL = new InjectionToken<string>('AUTH_URL');
export const BASE_URL = new InjectionToken<string>('BASE_URL');
export const HOME_URL = new InjectionToken<string>('HOME_URL');

export const LOGIN_CALLBACK = new InjectionToken<string>('LOGIN_CALLBACK');
export const LOGOUT_CALLBACK = new InjectionToken<string>('LOGOUT_CALLBACK');

export const LOGOUT_URL = new InjectionToken<string>('LOGOUT_URL');
export const LOGIN_URL = new InjectionToken<string>('LOGIN_URL');
export const BUILD_DATE = new InjectionToken<string>('BUILD_DATE');

export const IGNORED_URLS = new InjectionToken<string>('IGNORED_URLS');
export const DEFAULT_LANGUAGE = new InjectionToken<string>('DEFAULT_LANGUAGE');

export const IS_PRODUCTION = new InjectionToken<boolean>('IS_PRODUCTION');
export const HAS_NOTIFICATIONS = new InjectionToken<boolean>('HAS_NOTIFICATIONS');

export const GRAPHQL_PUBLIC_URI = new InjectionToken<string>('GRAPHQL_PUBLIC_URI');
export const GRAPHQL_DEFAULT_URI = new InjectionToken<string>('GRAPHQL_DEFAULT_URI');

export const ADMIN_GROUPS = new InjectionToken<string[]>('ADMIN_GROUPS');
export const USER_GROUPS = new InjectionToken<string[]>('USER_GROUPS');
export const ROUTES = new InjectionToken<Route[]>('ROUTES');

export const STRIPE_PK = new InjectionToken<string>('STRIPE_PK');
export const STRIPE_PT = new InjectionToken<string>('STRIPE_PT');

export const INDEXDB_STORE_NAME = new InjectionToken<string>('INDEXDB_STORE_NAME');
export const INDEXDB_STORE_VERSION = new InjectionToken<number>('INDEXDB_STORE_VERSION');
