import { AfterViewInit, Directive, ElementRef, OnInit, Renderer2, effect, inject, input } from '@angular/core';

@Directive({
	standalone: true,
	selector: '[appTaperedBorder]',
})
export class AppTaperedBorderDirective implements OnInit, AfterViewInit {
	private renderer = inject(Renderer2);
	private el: ElementRef<HTMLElement> = inject(ElementRef<HTMLElement>);

	appTaperedBorder = input<string>('rgba(255, 0, 0, 0.2)'); // default color
	position = input<'top' | 'bottom' | 'before' | 'after'>('bottom'); // Default position
	positionMargin = input<string>('1.5vh');

	constructor() {
		effect(() => {
			this.createTaperedBorder(this.appTaperedBorder(), this.position(), this.positionMargin());
		});
	}
	ngOnInit() {}

	ngAfterViewInit(): void {}

	private createTaperedBorder(borderColor: string, position: 'top' | 'bottom' | 'before' | 'after', posMargin: string) {
		/**
		 * bounding rect
		 */
		const br = this.el.nativeElement.getBoundingClientRect();
		const height = br.height;
		const topPx = `calc(-${br.height}px - ${posMargin})`;

		const borderContainer = this.renderer.createElement('div');
		this.renderer.setStyle(borderContainer, 'position', 'relative');
		this.renderer.setStyle(borderContainer, 'width', '80%');
		this.renderer.setStyle(borderContainer, 'margin', '0 auto');
		if (position === 'top') {
			this.renderer.setStyle(borderContainer, 'top', topPx);
		}

		const afterElement = this.renderer.createElement('div');
		this.renderer.setStyle(afterElement, 'content', '""');
		this.renderer.setStyle(afterElement, 'position', 'absolute');
		this.renderer.setStyle(afterElement, 'left', '50%');
		this.renderer.setStyle(afterElement, 'width', '100%');
		this.renderer.setStyle(afterElement, 'height', '3px');
		this.renderer.setStyle(afterElement, 'transform', 'translateX(-50%)');
		this.renderer.setStyle(afterElement, 'border-radius', '50%');

		// this.renderer.setStyle(afterElement, 'background', borderColor);
		this.renderer.setStyle(afterElement, 'border', '3px solid');
		this.renderer.setStyle(afterElement, 'border-image-slice', 1);
		this.renderer.setStyle(
			afterElement,
			'border-image-source',
			`linear-gradient(to right, rgba(0, 0, 0, 0) 0%, ${borderColor} 50%, rgba(0, 0, 0, 0) 100%)`,
		);
		this.renderer.setStyle(afterElement, 'border-bottom', '0');

		// const beforeElement = this.renderer.createElement('div');
		// this.renderer.setStyle(beforeElement, 'content', '""');
		// this.renderer.setStyle(beforeElement, 'position', 'absolute');
		// this.renderer.setStyle(beforeElement, 'left', '50%');
		// this.renderer.setStyle(beforeElement, 'width', '100%');
		// this.renderer.setStyle(beforeElement, 'height', '1px');
		// this.renderer.setStyle(beforeElement, 'background', borderColor);
		// this.renderer.setStyle(beforeElement, 'transform', 'translateX(-50%)');

		// Set position based on the input parameter
		switch (position) {
			case 'top':
				this.renderer.setStyle(afterElement, 'top', '0');
				// this.renderer.setStyle(beforeElement, 'top', '0');
				break;
			case 'bottom':
				this.renderer.setStyle(afterElement, 'bottom', '0');
				// this.renderer.setStyle(beforeElement, 'bottom', '0');
				break;
			case 'before':
				this.renderer.setStyle(afterElement, 'top', '50%');
				this.renderer.setStyle(afterElement, 'transform', 'translateY(-50%) translateX(-100%)');
				// this.renderer.setStyle(beforeElement, 'top', '50%');
				// this.renderer.setStyle(beforeElement, 'transform', 'translateY(-50%) translateX(-100%)');
				break;
			case 'after':
				this.renderer.setStyle(afterElement, 'top', '50%');
				this.renderer.setStyle(afterElement, 'transform', 'translateY(-50%) translateX(0)');
				// this.renderer.setStyle(beforeElement, 'top', '50%');
				// this.renderer.setStyle(beforeElement, 'transform', 'translateY(-50%) translateX(0)');
				break;
		}

		// Append the border elements to the container
		this.renderer.appendChild(borderContainer, afterElement);
		// this.renderer.appendChild(borderContainer, beforeElement);
		this.renderer.appendChild(this.el.nativeElement, borderContainer);

		console.debug(
			`${this.constructor.name} - createTaperedBorder - el.height=${this.el.nativeElement.offsetHeight}, el.offsetTop=${this.el.nativeElement.offsetTop}, boudingRect=`,
			br,
		);
	}
}

// Append the border elements to the container
// this.renderer.appendChild(borderContainer, afterElement);
// this.renderer.appendChild(borderContainer, beforeElement);
// this.renderer.appendChild(this.el.nativeElement, borderContainer);
