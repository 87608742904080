import { ComponentType } from '@angular/cdk/portal';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '@ft/lib/auth-lib';
import { UPDATEDAT_ASC } from '@ft/lib/core-lib';
import { GetNotificationValue, MutableRow, isMutableRow } from '@ft/lib/core-lib';
import { ErrorService } from '@ft/lib/error-service-lib';
import {
	ChunkResult,
	FT_GraphQLDeleteResult,
	GraphqlCreateOutput,
	GraphqlCreateRequest,
	GraphqlDataService,
	GraphqlDeleteRequest,
	GraphqlMutateRequest,
	GraphqlQueryInput,
	GraphqlUpdateOutput,
} from '@ft/lib/graphql-lib';
import { NotifyService } from '@ft/lib/snackbar-lib';
import { FieldList, ModeType, OperatorFilter, SearchFilter, VariableList } from '@furnas-technology/angular-library';
import { FilteredRecord } from '@furnas-technology/common-library/filters';
import { FT_LogError, FT_getErrorMessage } from '@furnas-technology/common-library/functions';
import { Observable, catchError, concatMap, exhaustMap, finalize, map, of } from 'rxjs';

export type CrudOper = 'create' | 'delete' | 'update';

export type CrudResult<T, U extends string> = {
	success: boolean;
	crudOperation: string;
	msg: string;
	recordId: string;
} & {
	[key in U]?: Partial<T>;
};

export type EditDocumentRequest = {
	fromId?: string;
	id?: string;
	notify?: boolean;
	modeType?: ModeType;
	closeOthers?: boolean;
	resultProperty?: string;
	editComponentOverride?: ComponentType<unknown>;
};

export type DeleteDocumentRequest = {
	id: string;
	notify?: boolean;
};

/**
 * Type Definitions
 */
export const FilteredDataNotOmitted = <T>(data: FilteredRecord<T>[]): T[] => {
	try {
		return data.filter((x) => x.omitted !== true).map((x) => x.record);
	} catch (err: unknown) {
		console.error(`❌ filteredDataNotOmitted - error:`, err);
		return [];
	}
};

export const FilteredDataSelected = <T>(data: FilteredRecord<T>[]): T[] => {
	try {
		return data.filter((x) => x.selected === true).map((x) => x.record);
	} catch (err: unknown) {
		console.error(`❌ FilteredDataSelected - error:`, err);
		return [];
	}
};

export type StoreUpsertManyFunction<T> = (records: T[]) => void;

export type SearchFilterType = 'username';

/**
 * load all values
 */
export type GetPagedDataRequest<T> = {
	getManyProperty: string;
	getManyApi: GraphqlQueryInput;
	chunkSize?: number;
	lastUpdated$?: Observable<string>;
	storeUpsertFunction?: StoreUpsertManyFunction<T>;
	searchFilterTypes?: SearchFilterType[];
};

export type PagedData<T> = {
	records: T[];
	pages: number;
};

export type GetPagedDataResponse<T> = {
	success: boolean;
	loadedCount: number;
	lastUpdatedAt: string;
	message?: string;
	pagesReturned: number;
	data: T[];
};

export type PagedDataInput<T> = {
	variableList: VariableList;
	searchFilter: SearchFilter;
	sortKey: string;
	skip: number;
	chunkSize: number | undefined;
	getManyApi: GraphqlQueryInput;
	getManyProperty: string;
	storeUpsertFunction: StoreUpsertManyFunction<T> | undefined;
};

export type PagedDataOutput<T> = {
	success: boolean;
	records: T[];
	message?: string;
	pagesReturned: number;
};

/**
 * CRUD types
 */
export type CreateRowRequest<T> = {
	apiCreate: (record: Partial<T>) => GraphqlCreateRequest<T>;
	apiCreateProp: string;

	row: Partial<T>;
	notify: boolean | undefined;
	notifyKey?: keyof T;
	notifyValue?: string;
};

export type CrudResponse<T, U extends string> = {
	success: boolean;
	msg: string;
	recordId: string;
} & {
	[key in U]?: T;
};

export type MutateRowRequest<T> = {
	apiMutate: (record: MutableRow<T>) => GraphqlMutateRequest<T>;
	apiMutateProp: string;

	row: MutableRow<T>;
	notify: boolean | undefined;
	notifyKey?: keyof T;
	notifyValue?: string;
};

export type DeleteRowRequest<T> = {
	apiDelete: (_id: string) => GraphqlDeleteRequest;
	apiDeleteProp: string;
	_id: string;
	notify: boolean | undefined;
	notifyValue?: string;
};

@Injectable({
	providedIn: 'root',
})
export class CrudHelperService {
	private notifyService = inject(NotifyService);
	private errorService = inject(ErrorService);
	private graphqlService = inject(GraphqlDataService);
	private authService = inject(AuthService);

	getPagedData<T>(request: GetPagedDataRequest<T>): Observable<GetPagedDataResponse<T>> {
		console.debug(`${this.constructor.name} - getPagedData BEG`);
		const getManyProperty = request.getManyProperty;
		const chunkSize = request.chunkSize ?? 100;
		const getManyApi = request.getManyApi;
		const lastUpdated$ = request.lastUpdated$ ?? of('');
		const storeUpsertFunction = request.storeUpsertFunction;

		const msgPrefix = `getPagedData - getMany prop=${getManyProperty}`;

		/**
		 * This allows us to filter the data based on the current user if the API does not support filtering
		 */
		const initSearchFilter: SearchFilter = {};
		if (request.searchFilterTypes?.length) {
			if (request.searchFilterTypes.includes('username')) {
				initSearchFilter['username'] = this.authService.username();
			}
		}

		const loadAllResult: GetPagedDataResponse<T> = {
			success: false,
			loadedCount: 0,
			lastUpdatedAt: '',
			message: undefined,
			pagesReturned: 0,
			data: [],
		};

		const result = lastUpdated$.pipe(
			exhaustMap((lastUpdated: string) => {
				console.debug(`${this.constructor.name} - ${msgPrefix} - lastUpdated=${lastUpdated}`);
				// initialize operator filter
				const operatorFilter: OperatorFilter = { _operators: {} };
				const fieldList: FieldList = {};

				if (lastUpdated) {
					operatorFilter._operators['updatedAt'] = { gte: lastUpdated };
				}

				// searchFilter for query
				const variableList = Object.keys(operatorFilter._operators).length > 0 ? operatorFilter : {};
				const searchFilter = Object.assign(initSearchFilter, fieldList, variableList);
				// const searchFilter = Object.assign({}, fieldList, variableList);
				const sortKey = UPDATEDAT_ASC;

				const pagedDataInput: PagedDataInput<T> = {
					variableList: variableList,
					searchFilter: searchFilter,
					sortKey: sortKey,
					skip: 0,
					chunkSize: chunkSize ?? 100,
					getManyApi: getManyApi,
					getManyProperty: getManyProperty,
					storeUpsertFunction: storeUpsertFunction,
				};

				const pagedData: PagedData<T> = {
					records: [],
					pages: 0,
				};

				return this.getPagedDataByPage<T>(pagedDataInput, pagedData).pipe(
					map((pagedData: PagedDataOutput<T>) => {
						console.debug(
							`${this.constructor.name} - GetPagedData - exhaustMap - lastUpdated=${lastUpdated}, loadedCount=${pagedData.records.length}`,
						);

						loadAllResult.success = pagedData.success;
						loadAllResult.loadedCount = pagedData.records.length;
						loadAllResult.message = pagedData.message;
						loadAllResult.pagesReturned = pagedData.pagesReturned;
						loadAllResult.data = loadAllResult.data.concat(pagedData.records);
						return loadAllResult;
					}),
				);
			}),

			catchError((err: unknown) => {
				loadAllResult.message = FT_LogError(err, 'GetPagedData (exhaustMap)');
				loadAllResult.success = false;
				return of(loadAllResult);
			}),
			finalize(() => {
				console.debug(`${msgPrefix} - FINALIZE`);
			}),
		);

		return result;
	} // end getAll

	/**
	 * getPagedDataByPage
	 */
	getPagedDataByPage<T>(pagedDataInput: PagedDataInput<T>, pagedData: PagedData<T>): Observable<PagedDataOutput<T>> {
		const msgPrefix = `getPagedDataByPage (${pagedDataInput.getManyProperty}, skip=${pagedDataInput.skip}, chunkSize=${pagedDataInput.chunkSize})`;
		console.debug(`${this.constructor.name} - ${msgPrefix} BEG - pagedDataInput=`, pagedDataInput);
		return this.getPageOfData(pagedDataInput).pipe(
			concatMap((pageOfData: ChunkResult<T>) => {
				// process data
				this.processPageOfData(pageOfData, pagedDataInput, pagedData);

				// if more records, compute next skip
				if (pageOfData.hasMore && pageOfData.records.length) {
					pagedDataInput.skip += pageOfData.records.length;
					return this.getPagedDataByPage(pagedDataInput, pagedData).pipe(
						map((nextData: PagedDataOutput<T>) => {
							return nextData;
						}), // Concatenate results
					);
				} else {
					const result: PagedDataOutput<T> = {
						success: true,
						records: pagedData.records,
						message: '',
						pagesReturned: pagedData.pages,
					};

					return of(result); // Return final data if no more pages
				}
			}),
			catchError((err: unknown) => {
				const errMessage = FT_getErrorMessage(err);
				console.error(`❌ ${msgPrefix}:`, errMessage);
				return of({
					success: false,
					records: [],
					message: errMessage,
					pagesReturned: 0,
				});
			}),
			finalize(() => console.debug(`🏁 ${msgPrefix} - FINALIZE`)),
		);
	}

	/**
	 * Interface to Graphql service to get a page of data from the API
	 */
	getPageOfData<T>(input: PagedDataInput<T>): Observable<ChunkResult<T>> {
		const msgPrefix = `getPageOfData (${input.getManyProperty}, skip=${input.skip}, chunkSize=${input.chunkSize})`;
		// override initial chunk size
		let chunkSize = input.chunkSize ?? 0;
		if (!input.skip && chunkSize >= 10) {
			chunkSize = Math.ceil(chunkSize * 0.25);
		}
		// Implement your logic to call the API with params and return the observable
		return this.graphqlService
			.getDataFromApi<T>(
				input.getManyApi,
				input.getManyProperty,
				input.skip,
				chunkSize,
				input.searchFilter,
				input.sortKey,
			)
			.pipe(
				catchError((err: unknown) => {
					const errMessage = FT_LogError(err, msgPrefix);
					return of({
						records: [],
						rowCount: 0,
						skip: input.skip,
						hasMore: false,
						error: true,
						errorMessage: errMessage,
					});
				}),
				finalize(() => console.debug(`🏁 ${msgPrefix} - FINALIZE`)),
			);
	}

	processPageOfData<T>(chunkResult: ChunkResult<T>, input: PagedDataInput<T>, pagedData: PagedData<T>): void {
		try {
			console.debug(
				`${this.constructor.name} - processPageOfData - chunkResult, input, pagedData=`,
				chunkResult,
				input,
				pagedData,
			);
			// only increment page if data returned
			if (chunkResult.records?.length) {
				pagedData.pages += 1;
			}

			// process page
			const page = pagedData.pages;
			const records: T[] = chunkResult.records;

			// accumulate retrieved records
			pagedData.records = pagedData.records.concat(records);

			// update the NGRX store - does not wait for all pages to be loaded before updating the store
			if (typeof input.storeUpsertFunction === 'function') {
				console.debug(`${this.constructor.name} - processPageOfData - calling storeUpsertFunction `);
				input.storeUpsertFunction(records);
			} else {
				console.debug(`${this.constructor.name} - processPageOfData - no storeUpsertFunction`);
			}
		} catch (err: unknown) {
			FT_LogError(err, this.constructor.name, `processPageOfData`);
		}
	} // end processPageOfData

	/**
	 * CRUD methods
	 */
	createRow<T>(request: CreateRowRequest<T>): Observable<CrudResponse<T, 'record'>> {
		const crudOper: CrudOper = 'create';

		const operResult: CrudResponse<T, 'record'> = {
			success: false,
			recordId: '',
			record: undefined,
			msg: '',
		};

		// api command
		const graphqlCommand$: Observable<GraphqlCreateOutput<T> | null> = this.graphqlService.graphqlCreate<T>(
			request.apiCreate(request.row),
			request.apiCreateProp,
		);

		// concatMap to ensure command completes
		const cmdOutput$ = graphqlCommand$.pipe(
			concatMap((value) => {
				operResult.recordId = value?.recordId ?? '';
				operResult.record = (value?.record as T) ?? undefined;
				const notificationValue = GetNotificationValue(value?.record as T, request.notifyKey, request.notifyValue);

				if (value?.recordId) {
					operResult.success = true;
					if (!!request.notify) this.notifySuccess(crudOper, notificationValue || 'record');
				} else {
					operResult.success = false;
					if (!!request.notify) this.notifyFailure(crudOper, notificationValue || 'record');
				}

				// return result
				return of(operResult);
			}),
			catchError((err: unknown) => {
				const dataErrorObj = this.errorService.handleDataError(err, 'createRow', crudOper, !!request.notify);
				operResult.success = false;
				operResult.msg = dataErrorObj.error;
				if (!!request.notify) this.notifyError(crudOper, 'record');
				return of(operResult);
			}),
		);

		return cmdOutput$;
	} // end create

	mutateRow<T>(request: MutateRowRequest<T>): Observable<CrudResponse<T, 'record'>> {
		console.debug(`${this.constructor.name} - mutateRow`);
		const crudOper: CrudOper = 'update';
		const notify = !!request.notify;
		const row = request.row;

		const operResult: CrudResponse<T, 'record'> = {
			success: false,
			recordId: '',
			record: undefined,
			msg: '',
		};

		if (!isMutableRow(row)) {
			operResult.recordId = row['_id'];
			operResult.record = row;
			operResult.msg = `row is not mutable`;
			if (!!request.notify) this.notifyError(crudOper, 'record is not valid for update');
			return of(operResult);
		}

		// api command
		const graphqlCommand$: Observable<GraphqlUpdateOutput<T> | null> = this.graphqlService.graphqlUpdate<T>(
			request.apiMutate(row),
			request.apiMutateProp,
		);

		const cmdOutput$ = graphqlCommand$.pipe(
			concatMap((value) => {
				operResult.recordId = value?.recordId ?? '';
				operResult.record = (value?.record as T) ?? undefined;
				const notificationValue = GetNotificationValue(value?.record as T, request.notifyKey, request.notifyValue);

				if (value?.recordId) {
					operResult.success = true;
					if (!!request.notify) this.notifySuccess(crudOper, notificationValue || 'record');
				} else {
					operResult.success = false;
					if (!!request.notify) this.notifyFailure(crudOper, notificationValue || 'record');
				}

				// return result
				return of(operResult);
			}),
		);

		return cmdOutput$;
	} // end update

	deleteRow<T>(request: DeleteRowRequest<T>): Observable<CrudResponse<T, 'none'>> {
		console.debug(`${this.constructor.name} - deleteRow`);
		const crudOper: CrudOper = 'delete';

		const operResult: CrudResponse<T, 'none'> = {
			success: false,
			recordId: request._id,
			msg: '',
		};

		if (!request._id) {
			operResult.msg = `id is blank for ${crudOper}`;
			if (!!request.notify) this.notifyError(crudOper, 'id cannot be blank');
			return of(operResult);
		}

		// api command
		const graphqlCommand$: Observable<FT_GraphQLDeleteResult | null> = this.graphqlService.deleteDocument<T>(
			request.apiDelete(request._id),
			request._id,
			request.apiDeleteProp,
		);

		const cmdOutput$ = graphqlCommand$.pipe(
			concatMap((value: FT_GraphQLDeleteResult | null) => {
				console.debug(`${this.constructor.name} - delete value=`, value);

				if (value?.status === 'success') {
					operResult.success = true;
					if (!!request.notify)
						this.notifyService.success(`Success: ${crudOper}d ${request.notifyValue || 'record'}`, '👍');
				} else if (value?.status === 'not found') {
					operResult.success = true;
					if (!!request.notify)
						this.notifyService.warn(
							`Warning: Unable to ${crudOper}, ${request.notifyValue || 'record'} not found`,
							'😳',
						);
				} else if (value?.status === 'failed') {
					operResult.success = false;
					operResult.msg = value.message;
					if (!!request.notify) this.notifyError(crudOper, value.message);
				} else if (value?.recordId) {
					operResult.success = true;
					if (!!request.notify)
						this.notifyService.success(`Success: ${crudOper}d ${request.notifyValue || 'record'}`, '👍');
				} else {
					operResult.success = false;
					if (!!request.notify) this.notifyError(crudOper, String(request.notifyValue) || 'record');
				}

				// return result
				return of(operResult);
			}),
		);

		return cmdOutput$;
	} // end deleteRow

	/**
	 * Helper methods
	 */
	notifyError(crudOper: CrudOper | string, notifyValue: string): void {
		this.notifyService.error(
			`Unexpected error attempting to ${crudOper}${notifyValue ? `, ${notifyValue}` : ''}`,
			'❌',
		);
	}

	notifySuccess(crudOper: CrudOper | string, notifyValue: string): void {
		this.notifyService.success(`Success: ${crudOper} ${notifyValue}`, '👍');
	}

	notifyFailure(crudOper: CrudOper | string, notifyValue: string): void {
		this.notifyService.error(`Error: Unable to ${crudOper} ${notifyValue}`, '😢');
	}

	notifyInfo(message: string): void {
		this.notifyService.info(message);
	}
}
