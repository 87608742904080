import { AppIdValues } from '@ft/lib/core-lib';
import { FT_copyNonNulls, FT_getPropertyValue } from '@furnas-technology/common-library/functions';
import { AppImageKey } from './image.model';

type CognitoConfig = {
	domain: string;
	poolId: string;
	clientId: string;
	arn: string;
	wellknown: string;
	region: string;
	clientName: string;
	scopes: string;
	url: string;
};

type S3Config = {
	bucket: string;
	region: string;
	arn: string;
	urlBase: string;
};

// OLD
// version: string = '';
// cognitoAppId: string = '';
// cognitoDomain: string = '';
// cognitoClientId: string = '';
// cognitoResponseType: string = '';
// cognitoScope: string = '';
// cognitoToken: string = '';
// cognitoPoolId: string = '';
// cognitoWellKnown: string = '';
// loginUrl: string = '';
// logoutUrl: string = '';
// expires_at: number = 0;

// s3Bucket: string = '';
// s3Region: string = '';
// s3Arn: string = '';
// s3UrlBase: string = '';
// imageUrl: string = '';

// favicon: string = '';
export class ApiAppConfig {
	version: string = '';
	appId: AppIdValues | '' = '';
	lambdaRegion: string = '';
	token: string = '';
	imageUrl: string = '';
	favicon: string = '';

	loginUrl: string = '';
	logoutUrl: string = '';

	s3: S3Config = {
		bucket: '',
		region: '',
		arn: '',
		urlBase: '',
	};
	cognito: CognitoConfig = {
		domain: '',
		poolId: '',
		clientId: '',
		arn: '',
		wellknown: '',
		region: '',
		clientName: '',
		scopes: '',
		url: '',
	};

	constructor(init?: Partial<ApiAppConfig>) {
		if (init) {
			FT_copyNonNulls(this, init);
		}
	}
}

export const isApiAppConfig = (obj: unknown): obj is ApiAppConfig => {
	return !!(typeof obj === 'object' && obj !== null && 'appId' in obj && 's3' in obj && 'cognito' in obj);
};

export type UserSettings = {
	language: string;
};

export type KeyValue = {
	key: string;
	value: string;
};

export type AppLogo = {
	logoName: AppImageKey;
	url: string;
};
export class AppSettings {
	[key: string]: KeyValue[] | string | string[] | AppLogo[];

	appId: string = '';
	logos: AppLogo[] = [];
}

export const isAppSettings = (obj: unknown): obj is AppSettings => {
	if (typeof obj === 'object' && obj !== null) {
		return !!Object.hasOwn(obj, 'appId');
	}
	return false;
};

export type SessionValues = {
	loadedFromApi: boolean;
	config: ApiAppConfig;
	settings: AppSettings;
};

export const isSessionValues = (obj: unknown): obj is SessionValues => {
	// ensure object and not null
	if (typeof obj === 'object' && obj !== null) {
		if (Object.hasOwn(obj, 'config') && Object.hasOwn(obj, 'settings')) {
			const appDomain = FT_getPropertyValue(obj, 'config');
			const appSettings = FT_getPropertyValue(obj, 'settings');
			if (isAppSettings(appSettings) && isApiAppConfig(appDomain)) {
				return true;
			}
		}
	}
	return false;
};
