import { MergeUniqueArrays } from '../functions/array-functions.js';
import { FT_isNested } from '../functions/miscellaneous-functions.js';
/**
 * constants
 */
export const BasicFilterValues = ['', 'equals', 'notEqual'];
export const BooleanFilterValues = ['', 'yes', 'no'];
export const StringFilterValues = ['', 'contains', 'doesNotContain', 'equals', 'notEqual', 'empty', 'notEmpty'];
export const NumericFilterValues = ['', 'greater than', 'greater than or equal', 'less than', 'less than or equal', 'equals', 'notEqual', 'numericEquals', 'numericNotEquals'];
export const AllFilterValues = MergeUniqueArrays([...BasicFilterValues, ...BooleanFilterValues, ...StringFilterValues, ...NumericFilterValues]);
export const FullFieldFilterValues = ['equals', 'notEqual'];
// no value operations
export const NoValueFilterValues = ['empty', 'notEmpty', 'yes', 'no'];
/**
 * RegEx patterns
 */
export const PossibleYearPattern = /\d{4}/;
export const PossibleDatePattern = /(.+)([\-/\.])(.+)/;
export const YearMonthPattern = /^\d{4}[-/\.]\d{2}$/;
export const MonthYearPattern = /^\d{2}[-/\.]\d{4}$/;
export const YearMonthTextPattern = /^\d{4}[-/\.]\w{3,}$/;
export const MonthYearTextPattern = /\w{3,}[-/\.]\d{4}$/;
export const YearPattern = /^\d{4}$/;
export const NonNumericPattern = /\D/;
export const AllNumbersPattern = /^\d+$/;
export const bornDiedYearPattern = /(?:d|b|died|born):\s*\d{4}-(0[1-9]|1[0-2])/g;
export const bornDiedIsoDatePattern = /(?:d|b|died|born):\s*\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])/g;
export const bornDiedPattern = /\,|b:+.|born:+.|d:(?:\s)[d4]|died:*.|\s/;
export class SearchBarField {
  fieldname;
  filterDataTypes;
  isNested;
  constructor(fieldname, filterDataTypes) {
    this.fieldname = fieldname;
    this.isNested = FT_isNested(this.fieldname.toString());
    this.filterDataTypes = filterDataTypes;
  }
}
export const FilterDisplay = {
  '': {
    desc: 'All',
    numDesc: '',
    shortDesc: ''
  },
  equals: {
    desc: 'Equals',
    numDesc: '=',
    shortDesc: 'Equals'
  },
  notEqual: {
    desc: 'Does not equal',
    numDesc: '!=',
    shortDesc: 'Not equal'
  },
  yes: {
    desc: 'Yes',
    numDesc: '!=0',
    shortDesc: 'Y'
  },
  no: {
    desc: 'No',
    numDesc: '=0',
    shortDesc: 'N'
  },
  contains: {
    desc: 'Contains',
    numDesc: '',
    shortDesc: 'Contains'
  },
  doesNotContain: {
    desc: 'Does not contain',
    numDesc: '',
    shortDesc: 'Not in'
  },
  empty: {
    desc: 'Empty',
    numDesc: '0',
    shortDesc: 'Empty'
  },
  notEmpty: {
    desc: 'Is Not Empty',
    numDesc: '!=0',
    shortDesc: 'Not empty'
  },
  'greater than': {
    desc: 'Greater Than',
    numDesc: '>',
    shortDesc: 'GT'
  },
  'greater than or equal': {
    desc: 'Greater than or equal',
    numDesc: '>=',
    shortDesc: 'GTE'
  },
  'less than': {
    desc: 'Less than',
    numDesc: '<',
    shortDesc: 'LT'
  },
  'less than or equal': {
    desc: 'Less than or equal',
    numDesc: '<=',
    shortDesc: 'LTE'
  },
  numericEquals: {
    desc: 'Equals',
    numDesc: '=',
    shortDesc: 'Equals'
  },
  numericNotEquals: {
    desc: 'Does not equal',
    numDesc: '!=',
    shortDesc: 'Not equal'
  }
};
export const ComparisonFilters = [{
  filterName: 'equals',
  ...FilterDisplay.equals
}, {
  filterName: 'notEqual',
  ...FilterDisplay.notEqual
}, {
  filterName: 'greater than or equal',
  ...FilterDisplay['greater than or equal']
}, {
  filterName: 'greater than',
  ...FilterDisplay['greater than']
}, {
  filterName: 'less than or equal',
  ...FilterDisplay['less than or equal']
}, {
  filterName: 'less than',
  ...FilterDisplay['less than']
}];
export const isFilterableFieldType = value => {
  return ['string', 'number', 'boolean'].includes(typeof value);
};
