<div
  class="display-cell-component"
  color="primary"
  [matTooltip]="tooltipValue()"
  [matTooltipDisabled]="!column().hasTooltip || !tooltipValue()"
  (mouseenter)="isHovered = true"
  (mouseleave)="isHovered = false"
>
  @if (columnType() === 'image') {
    @if (displayableValue()) {
      <img
        class="image-cell"
        [src]="displayableValue()"
        [attr.data-element]="column().elementId"
        [attr.data-src]="displayableValue()"
        [ngStyle]="column().imageStyle"
        [alt]="column().name + ' image'"
        appSpinner
      />
    } @else {
      <fa-icon [icon]="faImage"></fa-icon>
    }
  }
</div>
