import { HttpError } from 'http-errors';
export const FT_getErrorText = (err, _caller, _func) => {
  let errMessage = '';
  let errStatus = 500;
  /**
   * Handle if error is a string
   */
  if (typeof err === 'string') {
    return {
      errMessage: err || 'Not specified',
      errStatus: 0
    };
  }
  if (typeof err !== 'object') {
    return {
      errMessage: String(err),
      errStatus: 0
    };
  }
  if (err === null || err === undefined) {
    return {
      errMessage: 'Empty error',
      errStatus: 0
    };
  }
  if (err instanceof HttpError) {
    return {
      errMessage: getHttpErrorMessage(err),
      errStatus: err.status
    };
  }
  if (err instanceof Error) {
    return {
      errMessage: `[${err.name}] ${err.message}`,
      errStatus: 500
    };
  }
  /**
   * Process and error object
   */
  errStatus = 'status' in err && typeof err.status === 'number' ? err.status : 500;
  if ('message' in err && typeof err.message === 'string') {
    errMessage = err.message;
  } else if ('messages' in err && Array.isArray(err.messages)) {
    errMessage = err.messages.map(x => String(x)).join(', ');
  }
  if ('cause' in err && !!err.cause) {
    errMessage += ` Cause: ${String(err.cause)}`;
  }
  return {
    errMessage: errMessage || 'unknown error',
    errStatus
  };
}; // end FT_getErrorText
/**
 * retrieves the error message from an HttpError
 */
const getHttpErrorMessage = err => {
  if (err.status === 401) {
    return `Not authenticated`;
  } else if (err.status === 403) {
    return `Not permitted`;
  } else if (err.status === 404) {
    return `Not found`;
  } else if (err.status === 409) {
    return `Conflict`;
  } else if (err.status === 413) {
    return `Payload too large`;
  } else if (err.status === 422) {
    return `Unprocessable entity`;
  } else if (err.status === 500) {
    return `Internal server error`;
  } else if (err.status === 501) {
    return `Not implemented`;
  } else if (err.status === 502) {
    return `Bad gateway`;
  } else if (err.status === 503) {
    return `Service unavailable`;
  } else if (err.status === 504) {
    return `Gateway timeout`;
  } else {
    return err.message || `unknown error`;
  }
};
const overrideError = (errStatus, errMessage, errorOverrides) => {
  if (errorOverrides && Array.isArray(errorOverrides) && errorOverrides.findIndex(item => item.status === errStatus) > -1) {
    return errorOverrides.find(item => item.status === errStatus)?.message ?? `Unknown Error`;
  } else {
    return errMessage;
  }
};
const GetCallerAndFunc = (caller, func) => {
  if (caller && func) {
    return `${caller} (${func}) :`;
  } else if (caller) {
    return `${caller} :`;
  } else if (func) {
    return `${func} :`;
  } else {
    return '';
  }
};
export const FT_LogFullError = (err, caller, func, errorOverrides) => {
  const callerAndFunc = GetCallerAndFunc(caller ?? '', func ?? '');
  const {
    errMessage,
    errStatus
  } = FT_getErrorText(err);
  console.error(`❌ ${callerAndFunc}${errMessage} - err=`, err);
  return overrideError(errStatus, errMessage, errorOverrides ?? undefined);
};
export const FT_LogError = (err, caller, func, errorOverrides) => {
  const callerAndFunc = GetCallerAndFunc(caller ?? '', func ?? '');
  const {
    errMessage,
    errStatus
  } = FT_getErrorText(err);
  console.error(`❌ ${callerAndFunc}${errMessage}`);
  return overrideError(errStatus, errMessage, errorOverrides ?? undefined);
};
export const FT_Log = (message, ...optionalParams) => {
  console.log(`${message}`, optionalParams);
};
export const FT_isErrorWithMessage = errObj => {
  return !!(typeof errObj === 'object' && errObj !== null && 'message' in errObj);
};
export const FT_getErrorMessage = (err, _forScreen = false) => {
  const {
    errMessage
  } = FT_getErrorText(err);
  return errMessage;
};
export const CatchErrorPromise = async promise => {
  try {
    const data = await promise;
    return [undefined, data];
  } catch (err) {
    return [err];
  }
};
export const CatchErrorFunc = func => {
  try {
    const result = func;
    return [undefined, result];
  } catch (err) {
    return [err];
  }
};
