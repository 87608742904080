<div class="ft-pdf-viewer" [ngStyle]="pdfStyle()" #pdfContainer>
  @if (displayedPdfSrc()) {
    @if (isData()) {
      <fa-icon [icon]="faFilePdf"></fa-icon>
    } @else {
      <pdf-viewer
        #pdfviewer
        style="display: block"
        [ngStyle]="pdfStyle()"
        [src]="displayedPdfSrc()"
        [render-text]="renderText()"
        scale="1"
        [zoom-scale]="zoomScale()"
        [zoom]="1"
        [show-all]="showAll()"
        [attr.stick-to-page]="showAll() ? stickToPage() : ''"
        [attr.page]="showAll() ? page() : ''"
        [original-size]="originalSize()"
        [fit-to-page]="fitToPage()"
        [autoresize]="autoresize()"
        (page-rendered)="renderedPage()"
        (after-load-complete)="pdfLoaded($event)"
        (error)="onError($event)"
        (on-progress)="onProgress($event)"
      ></pdf-viewer>
    }
    @if (!pdfIsLoaded() && displayedPdfSrc()) {
      <div class="image-loading color-accent" [ngStyle]="pdfStyle()">
        <div class="spinner"><fa-icon [icon]="faSpinner"></fa-icon></div>
      </div>
    }
  } @else {
    <fa-icon [icon]="faFilePdf"></fa-icon>
  }
</div>
