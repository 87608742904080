import { DestroyRef, Injectable, inject } from '@angular/core';
import { MatSnackBar, type MatSnackBarConfig, type MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FT_getErrorMessage } from '@furnas-technology/common-library/functions';
import { finalize } from 'rxjs';

export type NotifyFlag = boolean | 'warn' | 'error' | 'info' | 'success';

const INFO_CLASS = ['ft-snackbar', 'ft-snackbar-info'];
const WARN_CLASS = ['ft-snackbar', 'ft-snackbar-warning'];
const ERROR_CLASS = ['ft-snackbar', 'ft-snackbar-error'];
const SUCCESS_CLASS = ['ft-snackbar', 'ft-snackbar-success'];
const DEFAULT_ACTION = 'Close';

const INFO_DURATION = 5500;
const SUCCESS_DURATION = 3500;
const WARN_DURATION = 8500;
const ERROR_DURATION = 12500;

const INFO_ACTION = 'ℹ︎';
const SUCCESS_ACTION = '👍';
const WARN_ACTION = '⚠️';
const ERROR_ACTION = '❌';

const OVERRIDE_DURATION: number | undefined = undefined;

@Injectable({
	providedIn: 'root',
})
export class NotifyService {
	destroyRef = inject(DestroyRef);
	snackBar = inject(MatSnackBar);

	constructor() {}

	/** Success **/
	success(
		message: string,
		action: string = SUCCESS_ACTION,
		duration = SUCCESS_DURATION,
		verticalPosition: MatSnackBarVerticalPosition = 'bottom',
		dismissedCallback?: () => void,
		actionCallback?: () => void,
	): void {
		const config: MatSnackBarConfig = {
			panelClass: SUCCESS_CLASS,
			duration: OVERRIDE_DURATION ?? duration,
			announcementMessage: message,
			verticalPosition: verticalPosition,
		};
		this.openSnackBar(message, action, config, dismissedCallback, actionCallback);
	}

	/** Info **/
	info(
		message: string,
		action: string = INFO_ACTION,
		duration = INFO_DURATION,
		verticalPosition: MatSnackBarVerticalPosition = 'bottom',
		dismissedCallback?: () => void,
		actionCallback?: () => void,
	): void {
		const config: MatSnackBarConfig = {
			panelClass: INFO_CLASS,
			duration: OVERRIDE_DURATION ?? duration,
			announcementMessage: message,
			verticalPosition: verticalPosition,
		};

		this.openSnackBar(message, action, config, dismissedCallback, actionCallback);
	}

	/** warning **/
	warn(
		message: string,
		action: string = WARN_ACTION,
		duration = WARN_DURATION,
		verticalPosition: MatSnackBarVerticalPosition = 'bottom',
		dismissedCallback?: () => void,
		actionCallback?: () => void,
	): void {
		const config: MatSnackBarConfig = {
			panelClass: WARN_CLASS,
			duration: OVERRIDE_DURATION ?? duration,
			announcementMessage: message,
			verticalPosition: verticalPosition,
		};

		this.openSnackBar(message, action, config, dismissedCallback, actionCallback);
	}

	/* Error **/
	error(
		pError: unknown,
		action: string = ERROR_ACTION,
		duration = ERROR_DURATION,
		verticalPosition: MatSnackBarVerticalPosition = 'bottom',
		dismissedCallback?: () => void,
		actionCallback?: () => void,
	): void {
		const errMessage = typeof pError === 'string' ? pError.trim() : FT_getErrorMessage(pError, true);

		const config: MatSnackBarConfig = {
			panelClass: ERROR_CLASS,
			duration: OVERRIDE_DURATION ?? duration,
			announcementMessage: errMessage,
			verticalPosition: verticalPosition,
			horizontalPosition: 'center',
		};

		this.openSnackBar(errMessage, action, config, dismissedCallback, actionCallback);
	} // end error

	openSnackBar(
		message: string,
		action: string,
		config: MatSnackBarConfig,
		dismissedCallback?: () => void,
		actionCallback?: () => void,
	): void {
		const snackBarRef = this.snackBar.open(message, action || DEFAULT_ACTION, config);

		snackBarRef
			.afterDismissed()
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				finalize(() => console.debug(`${this.constructor.name} snackBar dismissed`)),
			)
			.subscribe(() => {
				if (dismissedCallback) dismissedCallback();
			});

		snackBarRef
			.onAction()
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				finalize(() => console.debug(`${this.constructor.name} snackBar action`)),
			)
			.subscribe(() => {
				if (actionCallback) actionCallback();
			});
	} // end openSnackBar
}
