import { Type } from '@angular/core';

export type HasExists<T> = T & { EXISTS: boolean };

const existsValue = (obj: unknown): boolean => {
	return typeof obj === 'object' && obj !== null && 'EXISTS' in obj ? !!obj.EXISTS : false;
};

export const ScrubRecords = <T>(data: T[], ObjectClass: Type<T>, force = false): T[] => {
	const scrubbedRows: T[] = [];
	for (const row of data) {
		if (force || !existsValue(row)) {
			const scrubbedRow = new ObjectClass(row);
			scrubbedRows.push(scrubbedRow);
		} else {
			scrubbedRows.push(row);
		}
	}
	return scrubbedRows;
};

export const ScrubRecord = <T>(data: T, ObjectClass: Type<T>): T => {
	return new ObjectClass(data);
};
