<div class="ft-dialog-component select-dialog-component">
  <!-- card header -->
  <div mat-dialog-title class="ft-dialog-title">
    <!-- card header  -->
    <div class="title">
      <fa-icon [icon]="faClipboardQuestion"></fa-icon>
      <h4 cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{ headerTitle() }}</h4>
    </div>

    <div class="title-options">
      <ft-close-button (closeClicked)="close()"></ft-close-button>
    </div>
  </div>

  <!-- card content -->
  <!-- [ngStyle]="{ height: suggestedHeight() }" -->
  <!-- bindLabel="value" -->
  <!-- [items]="selectOptions()" -->
  <!-- bindValue="key"  -->
  <mat-dialog-content class="ft-dialog-content">
    <mat-label>{{ prompt() }}</mat-label>
    <div class="select-container">
      @for (opt of selectOptions$ | async; track optIndex; let optIndex = $index) {
      <mat-option [value]="opt.key" (click)="onSelect(opt)">
        <fa-icon [icon]="faCircleDot"></fa-icon>
        {{ opt.value }}
      </mat-option>
      }
    </div>
  </mat-dialog-content>

  <!-- card actions  -->
  <mat-dialog-actions class="ft-dialog-actions">
    <button mat-button (click)="close()" aria-label="Cancel">Cancel</button>
    <!-- <button mat-button (click)="select()" color="primary" cdkFocusInitial>Select</button> -->
  </mat-dialog-actions>
</div>
