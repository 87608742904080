import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	ElementRef,
	OnDestroy,
	type OnInit,
	type Signal,
	computed,
	inject,
	input,
	signal,
	viewChild,
} from '@angular/core';

import { DomSanitizer, type SafeResourceUrl, type SafeUrl } from '@angular/platform-browser';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFilePdf, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { NgStyle } from '@angular/common';

import { MatTooltipModule } from '@angular/material/tooltip';
import { First20Chars, IsValidBase64 } from '@furnas-technology/common-library/functions';
import {
	type PDFDocumentProxy,
	type PDFProgressData,
	PdfViewerComponent,
	PdfViewerModule,
	type ZoomScale,
} from 'ng2-pdf-viewer';

@Component({
	selector: 'ft-pdf-viewer',
	templateUrl: './pdf-viewer.component.html',
	styleUrls: ['./pdf-viewer.component.scss'],
	imports: [NgStyle, FontAwesomeModule, MatTooltipModule, PdfViewerModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FT_PdfViewerComponent implements OnInit, OnDestroy {
	destroyRef = inject(DestroyRef);
	sanitizer = inject(DomSanitizer);

	pdfViewer = viewChild<PdfViewerComponent>('pdfviewer');
	pdfContainer = viewChild<ElementRef<HTMLDivElement>>('pdfContainer');

	pdfSrc = input<string>(''); // base64 image
	height = input<string>('auto');
	width = input<string>('auto');
	maxHeight = input<string>('');

	showAll = input<boolean>(false);
	page = input<number>(1);
	stickToPage = input<boolean>(false);
	originalSize = input<boolean>(false);
	fitToPage = input<boolean>(false);
	autoresize = input<boolean>(true);
	renderText = input<boolean>(true);

	zoomScale = input<ZoomScale>('page-fit');

	faFilePdf = faFilePdf;
	faSpinner = faSpinner;

	private pdfDocument: PDFDocumentProxy | undefined;
	pdfIsLoaded = signal(false);
	totalPages = signal(0);
	loadedPercent = signal(0);

	pdfPage = signal(0);
	numPages = signal(0);

	pdfWidth = computed(() => ({ width: Number.isNaN(Number(this.width())) ? this.width() : `${this.width()}px` }));
	pdfHeight = computed(() => ({ height: Number.isNaN(Number(this.height())) ? this.height() : `${this.height()}px` }));

	pdfMaxHeight = computed(() => {
		if (!this.maxHeight()) {
			return {};
		} else {
			return { 'max-height': Number.isNaN(Number(this.maxHeight())) ? this.maxHeight() : `${this.maxHeight()}px` };
		}
	});

	pdfStyle = computed(() => {
		const style = Object.assign({}, this.pdfWidth(), this.pdfHeight(), this.pdfMaxHeight());
		return style;
	});

	isData = computed(() => !!(this.displayedPdfSrc() && IsValidBase64(this.displayedPdfSrc())));

	displayedPdfSrc = computed<string>(() => {
		if (this.pdfSrc()?.startsWith('data:')) {
			const bString = this.createPdfBlob(this.pdfSrc());
			return bString;
		} else {
			return this.pdfSrc();
		}
	});

	safePdfSrc: Signal<string | SafeResourceUrl | SafeUrl | File> = computed(() => {
		if (this.displayedPdfSrc()) {
			return this.sanitizer.bypassSecurityTrustUrl(this.displayedPdfSrc());
		} else {
			return '';
		}
	});

	constructor() {}

	ngOnInit() {}

	ngOnDestroy(): void {
		if (this.pdfDocument) {
			this.pdfDocument.cleanup();
			this.pdfDocument.destroy();
		}
	}

	pdfLoaded(evt: PDFDocumentProxy) {
		this.pdfDocument = evt;
		this.pdfIsLoaded.set(true);
		this.totalPages.set(evt.numPages);
		this.numPages.set(evt.numPages ?? 0);
	}

	renderedPage() {
		this.pdfPage.set(this.numPages() > 0 ? 1 : 0);
	}

	onProgress(progressData: PDFProgressData) {
		const pct = progressData.total ? Math.ceil((progressData.loaded / progressData.total) * 100) : 0;
		if (pct > 0) return;
		this.loadedPercent.set(pct);
		// do anything with progress data. For example progress indicator
	}

	onError(pdfError: unknown) {
		console.debug(`${this.constructor.name} - pdfError - pdfError=`, pdfError);
		this.pdfIsLoaded.set(true);
	}

	createPdfBlob(source: string): string {
		const parts = source.split(';');
		let byteCharacters: string = '';
		if (parts.length > 1 && parts[1]?.startsWith('base64,')) {
			const base64part = parts[1].slice(7);
			console.debug(`${this.constructor.name} - base64part=${First20Chars(base64part)}`);
			byteCharacters = atob(base64part);
		} else {
			return '';
		}

		const byteArrays = [];
		for (let offset = 0; offset < byteCharacters.length; offset += 512) {
			const slice = byteCharacters.slice(offset, offset + 512);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: 'application/pdf' });
		const url = window.URL.createObjectURL(blob);

		console.debug(`${this.constructor.name} - createPdfBlob - url=${url}`);
		return url;
	}
}
