import {
	type AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	ElementRef,
	LOCALE_ID,
	type OnInit,
	type TemplateRef,
	ViewEncapsulation,
	computed,
	effect,
	inject,
	input,
	output,
	signal,
	viewChild,
} from '@angular/core';

import { NgClass, NgStyle } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSort, MatSortModule, type Sort } from '@angular/material/sort';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { issueCustomTooltipDefaults } from '@ft/lib/models';
import {
	FT_NestedKeyOf,
	FT_NestedValue,
	FT_getNestedValue,
	FT_getPropertyValue,
	FT_hasProperty,
} from '@furnas-technology/common-library/functions';
import { BehaviorSubject, isObservable, take, tap } from 'rxjs';

import {
	// VIRTUAL_SCROLL_STRATEGY,
	CdkVirtualScrollViewport,
	ScrollingModule,
	// FixedSizeVirtualScrollStrategy,
	// VirtualScrollStrategy,
} from '@angular/cdk/scrolling';

import { MatTable, MatTableModule } from '@angular/material/table';

import { LayoutInfoService, WindowClass } from '@ft/lib/screen-lib';

import { FT_isEmpty } from '@furnas-technology/common-library/functions';

import { TableVirtualScrollDataSource, TableVirtualScrollModule } from 'ng-table-virtual-scroll';

// import { CoreTableFilterComponent } from '../table-filter/filter.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ResizeObserverDirective } from '@furnas-technology/angular-library/directives';
import { IdToString } from '@furnas-technology/common-library/models';
import { ColumnFilterComponent } from '../column-filter/column-filter.component';
import { DisplayActionsCellComponent } from '../display-actions/display-actions.component';
import { DisplayCardsComponent } from '../display-cards/display-cards.component';
import { DisplayCellComponent } from '../display-cell/display-cell.component';
import { DisplayImageCellComponent } from '../display-image/display-image.component';
import { EditCellComponent } from '../edit-cell/edit-cell.component';
import { FT_TableService } from '../ft-table.service';
import { ChangedTableColumnValue, ColumnFilter, TableButtonPressed, TableColumn } from '../table-column.model';
import { IsActiveFilter, RequiresFilterValue } from '../table-functions';
import { DataRow, NestedKeyOf, RawCellValue, TableButton, TableColumnType } from '../table-generic.model';

const DEFAULT_ITEM_SIZE = 52;
const DEFAULT_HEADER_SIZE = 56;

type ValidFilterType = 'string' | 'number' | 'boolean';

const isValidFilterType = (value: unknown): value is ValidFilterType => {
	return !!(typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean');
};

type ColumnTemplateInterface = {
	type: TableColumnType;
	ref: TemplateRef<unknown>;
};

@Component({
	selector: 'ft-display-table',
	templateUrl: './display-table.component.html',
	styleUrls: ['./display-table.component.scss'],
	encapsulation: ViewEncapsulation.None,
	imports: [
		CdkVirtualScrollViewport,
		ColumnFilterComponent,
		DisplayActionsCellComponent,
		DisplayCellComponent,
		DisplayCardsComponent,
		DisplayImageCellComponent,
		EditCellComponent,
		FontAwesomeModule,
		FormsModule,
		MatButtonModule,
		MatSortModule,
		MatTableModule,
		NgClass,
		NgStyle,
		ResizeObserverDirective,
		ReactiveFormsModule,
		ScrollingModule,
		TableVirtualScrollModule,
	],
	providers: [
		{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: issueCustomTooltipDefaults },
		{ provide: LOCALE_ID, useValue: navigator.language || 'en-AU' },
		{
			provide: FT_TableService,
			// useFactory: () => new SharedValuesService<SharedValues>(),
		},
	],

	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayTableComponent<T extends object> implements OnInit, AfterViewInit {
	protected destroyRef = inject(DestroyRef);
	protected layout = inject(LayoutInfoService);
	protected tableService = inject(FT_TableService<T>);

	columns = input<TableColumn<T>[]>([]);
	databaseRows = input.required<T[]>();
	dataRowsChanged = input<number>(0);
	defaultCardHeight = input<number>(DEFAULT_ITEM_SIZE * 2);
	displayCardsOnWindowClass = input<WindowClass | ''>('lt-medium');

	buttonPressed = output<TableButtonPressed<T>>();
	filteredRowCount = output<number>();
	changedColumn = output<ChangedTableColumnValue<T>>();

	viewport = viewChild(CdkVirtualScrollViewport, { read: CdkVirtualScrollViewport });
	sort = viewChild(MatSort);
	tableRef = viewChild('mattable', { read: MatTable });
	tableSection = viewChild('tableSection', { read: ElementRef<HTMLElement> });

	displayedColumnNames = signal<string[]>([]);
	// tableColumns = signal<TableColumn<T>[]>([]);
	// tableColumns = linkedSignal(() => this.tableService.tableColumns());
	tableColumns = this.tableService.tableColumns;
	displayCardsView = computed(() => {
		if (!this.displayCardsOnWindowClass()) return false;
		return this.tableService.windowClasses().findIndex((x) => x === this.displayCardsOnWindowClass()) > -1;
	});

	dataSource = new TableVirtualScrollDataSource<DataRow<T>>([]);

	updatedRows: { [key: string | number]: Record<string, string | number | boolean> } = {};

	columnTemplates: ColumnTemplateInterface[] = [];
	itemSize: number = DEFAULT_ITEM_SIZE;
	headerHeight: number = DEFAULT_HEADER_SIZE;

	prevSort: Sort | undefined = undefined;

	faImage = faImage;

	scrolledIndex$ = new BehaviorSubject(0);
	scrollTop$ = new BehaviorSubject<number>(0);

	updatedFilters = signal<number>(0);
	columnFilters = signal<ColumnFilter<T>[]>([]);

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);

		this.dataSource.sort = this.sort() ?? null;
		this.dataSource.sortData = this.sortTableData.bind(this);

		/**
		 * Monitor for changes to datarows
		 */
		effect(() => {
			if (this.databaseRows() || this.dataRowsChanged()) {
				this.updateDataSource(this.filterData());
				this.updateViewport();
			}
		});

		/**
		 * Monitor for column changes
		 */
		effect(() => {
			const columnNames = this.columns()
				.map((x) => x.name)
				.join(', ');

			console.debug(`${this.constructor.name} - EFFECT - columns changed, columns=${this.columns().length}  `);
			this.loadColumns(this.columns());
			this.updateViewport();
			this.viewport()?.scrollToIndex(0);
		});

		/**
		 * Monitor for changes to layout and screen size
		 */
		effect(() => {
			if (this.tableService.screenWidthHeight()) {
				this.updateViewport();
			}
		});
	}

	ngOnInit() {
		this.viewport()?.scrollToIndex(0);
	}

	ngAfterViewInit(): void {
		const viewportSize = this.viewport()?.getViewportSize();
		console.debug(`${this.constructor.name} - ngAfterViewInit - getViewportSize=${viewportSize}`);
		setTimeout(() => {
			if (!viewportSize) {
				this.viewport()?.checkViewportSize();
				window.dispatchEvent(new Event('resize'));
			}
		}, 0);
	} // end ngAfterViewInit

	updateDataSource(visibleRows: DataRow<T>[]): void {
		this.filteredRowCount.emit(visibleRows.length);
		this.dataSource.data = visibleRows;
	}

	private updateLayout(adjust = 0): void {
		if (this.tableSection() && this.tableSection instanceof ElementRef) {
			const width = this.tableSection()?.nativeElement.offsetWidth ?? 0 + adjust;
			const height = this.tableSection()?.nativeElement.offsetHeight ?? 0 + adjust;
		}
	}

	private filterData(): DataRow<T>[] {
		const filters = this.columnFilters();
		const dataRows = this.databaseRows() as DataRow<T>[];
		const filteredRows = this.performFilter(dataRows, filters);
		console.debug(`👉 ${this.constructor.name} - filterData - filteredRows.length=${filteredRows.length}`);
		return filteredRows;
	}

	getRowHeight(viewport: CdkVirtualScrollViewport, query: string): number {
		if (!viewport) return DEFAULT_ITEM_SIZE;

		let itemSize = DEFAULT_ITEM_SIZE;
		const nativeElement = viewport.elementRef.nativeElement;

		// process first row
		const firstRow = nativeElement.querySelector(query) as HTMLTableRowElement;
		if (firstRow) {
			itemSize = firstRow.offsetHeight ?? firstRow.clientHeight ?? DEFAULT_ITEM_SIZE;
		}

		// process all rows
		let totalHeight = 0;
		let totalRows = 0;
		const allRows = nativeElement.querySelectorAll(query);

		// biome-ignore lint/complexity/noForEach: <explanation>
		allRows.forEach((row) => {
			const rx = row as HTMLTableRowElement;
			const rh = rx.offsetHeight ?? rx.clientHeight ?? DEFAULT_ITEM_SIZE;
			totalHeight += rh;
			totalRows += 1;
		});

		const avgItemSize = Math.round(totalRows ? totalHeight / totalRows : 0);
		if (avgItemSize) itemSize = avgItemSize;
		return itemSize;
	}

	tableContentChanged(event: unknown) {
		const nativeElement = this.viewport()?.elementRef.nativeElement;
		this.updateLayout();
	}

	tableTrack(index: number, row: unknown) {
		if (FT_hasProperty(row, '_id')) {
			const value = FT_getPropertyValue(row, '_id');
			if (value || typeof value === 'number') return value;
		}
		return index;
	}

	handleClick(
		event: MouseEvent | undefined,
		action: TableButton,
		col: TableColumn<T>,
		dataRow: DataRow<T> | undefined,
	) {
		const pressed: TableButtonPressed<T> = {
			tableAction: action,
			column: col,
			dataRow: dataRow,
			_id: String(dataRow?.['_id'] ?? ''),
			event: event,
		};

		this.buttonPressed.emit(pressed);
	} // end handleClick

	onActionClicked(evt: TableButtonPressed<T>) {
		if (evt) this.buttonPressed.emit(evt);
	}

	editRow(dataRow: DataRow<T> | undefined) {
		if (dataRow?.['_id']) {
			dataRow['isEdit'] = true;
		} else if (dataRow) {
			dataRow['isEdit'] = false;
		}
	}

	addRow() {
		const newRow = {
			id: '',
		};
		// this.dataSource.data = [newRow, ...this.dataSource.data];
	}

	removeRow(id: string) {
		// this.buttonPressed.emit({ type: 'delete', _id: id });
	}

	inputHandler(e: Event, id: number, key: string) {
		if (!this.updatedRows[id]) {
			this.updatedRows[id] = {};
		}

		const inputElement = e.target as HTMLInputElement;
		this.updatedRows[id][key] = inputElement.validity?.valid;
	}

	disableSubmit(id: number) {
		if (this.updatedRows[id]) {
			return Object.values(this.updatedRows[id]).some((item) => item === false);
		}
		return false;
	}

	selectAll(event: MouseEvent) {}

	loadColumns(columns: TableColumn<T>[]) {
		// notify if no columns passed
		if (!columns.length) {
			console.warn(`⚠️ ${this.constructor.name} - loadColumns - no columns passed`);
		}
		// load tableColumns
		const tableColumns = columns.map((col) => {
			// col.sticky = this.layout.isGT_medium() ? Boolean(col.sticky) : false;
			col.sticky = Boolean(col.sticky);
			col.stickyEnd = Boolean(col.stickyEnd);
			return col;
		});

		this.tableColumns.set(tableColumns);

		const displayedColumnNames = this.tableColumns()
			.filter((col) => col.hidden !== true)
			.map((col) => col.name);
		this.displayedColumnNames.set(displayedColumnNames);
	}

	updateViewport() {
		this.viewport()?.checkViewportSize();
		const viewportSize = this.viewport()?.getViewportSize();
		console.debug(`${this.constructor.name} - updateViewport - getViewportSize=${viewportSize}`);
	}

	/**
	 * Custom table sorting using table column
	 */
	sortTableData(data: DataRow<T>[], sort: MatSort | null): DataRow<T>[] {
		console.debug(
			`${this.constructor.name} - sortTableData - sort=${sort?.active ?? ''} (${sort?.direction ?? ''}), data=${
				data.length
			}`,
		);
		if (!data.length) return [];
		if (!sort?.active || !sort?.direction) return data;

		const isAsc = sort.direction === 'asc' ? 1 : -1;

		// determine if override sortFieldProperty
		let sortField = sort.active;
		const sortTableColumn = this.tableColumns().find((item) => item.name === sort.active);
		if (!!sortTableColumn?.sortFieldProperty) {
			sortField = String(sortTableColumn.sortFieldProperty);
		}

		const sorted = data.sort((a, b) => {
			const aValue = this.sortDataAccessor(a as DataRow<T>, sortField as keyof T);
			const bValue = this.sortDataAccessor(b as DataRow<T>, sortField as keyof T);

			if (typeof aValue === 'string' && typeof bValue === 'string') {
				const x = aValue.localeCompare(bValue);
				return x * isAsc;
			} else {
				return (aValue > bValue ? 1 : -1) * isAsc;
			}
		});

		// update our sorted data
		return sorted;
	}

	/**
	 * Changes to sorting
	 */
	onSort(sort: Sort) {
		this.dataSource.sort = this.sort() ?? null;
		return;
	}

	sortDataAccessor(data: DataRow<T>, property: keyof T | NestedKeyOf<T>): string | number {
		try {
			if (!data || !property) return '';

			// get value of property in data
			let value:
				| FT_NestedValue<DataRow<T>, FT_NestedKeyOf<NonNullable<DataRow<T>>>>
				| DataRow<T>[keyof T]
				| ''
				| string = '';

			if (property === '_id') {
				const idValue = data._id ? IdToString(data._id) : '';
				value = idValue;
			} else if (typeof property === 'string' && property.includes('.')) {
				const nestedKey = property as FT_NestedKeyOf<NonNullable<DataRow<T>>>;
				const nestedValue = FT_getNestedValue(data, nestedKey);
				value = nestedValue;
			} else {
				const propKey = property as keyof T;
				const propValue = data[propKey];
				value = propValue;
			}

			// if value is undefined, check if function
			if (value === undefined) {
				const field = this.tableColumns().find((item) => item.name === property);
				if (field) {
					const functionValue = field.displayableValue(data);

					if (isObservable(functionValue)) {
						let unwrappedValue: string | number = '';
						functionValue.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((val) => {
							unwrappedValue = this.getSortableValue(val);
						});
						return unwrappedValue;
					} else {
						return this.getSortableValue(functionValue);
					}
				}

				return '';
			} else {
				const x = this.getSortableValue(value);
				return x;
			}
		} catch (err: unknown) {
			console.error(`❌ ${this.constructor.name} - sortDataAccessor property=${String(property)}:`, err);
			return '';
		}
	}

	onFilter(columnFilter: ColumnFilter<T> | null) {
		console.debug(
			`${this.constructor.name} - onFilter - column=${columnFilter?.column?.name}, columnFilter=`,
			columnFilter,
		);

		if (!columnFilter) return;
		const column = columnFilter.column;

		// get existing column filter
		const existingIndex = this.columnFilters().findIndex((item) => item.column.name === column.name);

		console.debug(`${this.constructor.name} - onFilter - existingIndex=${existingIndex}`);

		/**
		 * Remove column filter if undefined value
		 */
		if (!IsActiveFilter(columnFilter.comparison, columnFilter.value)) {
			if (existingIndex > -1) {
				this.columnFilters.update((columnFilters) => {
					columnFilters.splice(existingIndex, 1);
					return columnFilters;
				});
			}
			this.updatedFilters.set(new Date().getTime());
			this.updateDataSource(this.filterData());
			return;
		}

		// update existing filter
		if (existingIndex > -1) {
			const prevOperation = this.columnFilters()[existingIndex].comparison;
			const prevValue = this.columnFilters()[existingIndex].value;
			if (prevOperation !== columnFilter.comparison || prevValue !== columnFilter.value) {
				this.columnFilters.update((columnFilters) => {
					columnFilters[existingIndex] = { ...columnFilter };
					return columnFilters;
				});
				this.updatedFilters.set(new Date().getTime());
				this.updateDataSource(this.filterData());
			} else {
				console.debug(
					`👉 ${this.constructor.name} - onFilter - ${column.name} filter already exists, no update needed`,
				);
			}
		} else {
			this.columnFilters.update((columnFilters) => {
				columnFilters.push({ ...columnFilter });
				return columnFilters;
			});
			this.updatedFilters.set(new Date().getTime());
			this.updateDataSource(this.filterData());
		}

		console.debug(
			`${this.constructor.name} - onFilter - this.columnFilters=${this.columnFilters().length}, columnFilters=`,
			this.columnFilters(),
		);
	}

	performFilter(data: DataRow<T>[], activeFilters: ColumnFilter<T>[]): DataRow<T>[] {
		// filter activeFilters
		const filteredActiveFilters = activeFilters.filter((activeFilter) => {
			return !(
				!activeFilter.comparison ||
				(FT_isEmpty(activeFilter.value) && RequiresFilterValue(activeFilter.comparison))
			);
		});

		console.debug(
			`${this.constructor.name} - performFilter - filteredActiveFilters.length=${filteredActiveFilters.length}, data.length=${data.length}`,
		);

		// return if no filters
		if (filteredActiveFilters.length === 0) return data;

		// loop through datarecords
		const filteredRows = data.filter((row: DataRow<T>) => {
			let omittedCount = 0;
			let filterCount = 0;
			let selectedCount = 0;
			// loop through filters
			for (const filter of filteredActiveFilters) {
				filterCount += 1;
				const { omitted, selected } = filter.column.cellFilter(row, filter);
				if (selected) selectedCount += 1;
				if (omitted) omittedCount += 1;

				if (omittedCount > 0) break;
			}

			// process results
			if (omittedCount > 0 || selectedCount < filterCount) {
				return false;
			} else {
				return true;
			}
		});

		return filteredRows;
	}

	onScrollEnd(event: Event) {
		this.viewport()
			?.elementScrolled()
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				take(1),
				tap((x) => {
					// nothing to see here
				}),
			)
			.subscribe();
	}

	onScroll(event: Event) {
		const target = event.target as HTMLElement;
		const scrollTop = target?.scrollTop ?? 0;
	}

	onScrolledIndexChange(scrolledIndex: number) {
		this.scrolledIndex$.next(scrolledIndex);
	}

	onChangedColumn(changedColumn: ChangedTableColumnValue<T>) {
		if (!changedColumn.rowId) return;
		this.changedColumn.emit(changedColumn);
	}

	tooltipValue(column: TableColumn<T>, element: DataRow<T>) {
		const value = column.tooltipValue(element);
		return value;
	}

	// (FT_NestedValue<DataRow<T>, FT_NestedKeyOf<NonNullable<DataRow<T>>>> & number)

	getSortableValue(value: string | number | boolean | object | RawCellValue<T> | undefined): string | number {
		if (typeof value === 'string' || typeof value === 'number') {
			return value;
		} else if (value === null || value === undefined) {
			return '';
		} else if (typeof value === 'object') {
			return JSON.stringify(value);
		} else {
			return String(value);
		}
	}

	/**
	 * Screen resize
	 */
	onResize(event: DOMRectReadOnly) {
		console.debug(`${this.constructor.name} - onResize - event=`, event);
		this.tableService.screenRect.set(event);
	}
}
