import { UrlMatchResult, UrlSegment } from '@angular/router';

const MatchingElements = (arrA: string[], arrB: string[]): number => {
	let count = 0;
	const minLength = Math.min(arrA.length, arrB.length);

	for (let i = 0; i < minLength; i++) {
		if (arrA[i].trim().toLowerCase() === arrB[i].trim().toLowerCase()) {
			count++;
		} else {
			break; // Stop checking after the first mismatch
		}
	}

	return count;
};

export function AppUrlMatcher(
	url: UrlSegment[],
	paramId: string = 'contentTitle',
	matchingPaths: string[] = [],
): UrlMatchResult | null {
	if (!url.length) return null;
	const urlPaths = url.map((u) => u.path);

	const matches = MatchingElements(urlPaths, matchingPaths);
	if (!matches) return null;

	const matchedUrl = url.slice(0, matches);
	console.debug(`AppUrlMatcher - MATCHED - matches=${matches}, url=${matchedUrl.map((x) => x.path).join()}`);

	if (url.length > matches && !!paramId) {
		const param = url[matches];
		const paramSegment = new UrlSegment(param?.path ?? '', {});
		const posParams = { [paramId]: paramSegment };
		return { consumed: url.slice(0, matches + 1), posParams };
	} else {
		return { consumed: url.slice(0, matches) };
	}
}
