<mat-card class="metric-card elevation-medium" appearance="raised">
  <mat-card-header>
    <mat-card-title>{{ cardTitle() }}</mat-card-title>
  </mat-card-header>
  <mat-card-content class="metric-card-content">
    <div class="metric-value" [matMenuTriggerFor]="selectionMenu">
      @if (isLoading() && !cardValue()) {
        <span>Loading...</span>
        <img src="assets/lib/images/loading-circle.gif" class="loading-image" height="auto" width="40px" />
      } @else {
        {{ cardValue() }}
        <fa-icon [icon]="faChevronDown"></fa-icon>
      }
    </div>
    <mat-menu #selectionMenu="matMenu" class="selection-menu">
      <div class="selection-menu-header">{{ cardLabel() }}</div>
      @for (item of selectionItems(); track item.selectionValue) {
        <button mat-menu-item (click)="onSelectionClick(item)">
          <span>{{ item.selectionValue }}</span>
          <span class="selection-count">{{ item.count }}</span>
        </button>
      }
    </mat-menu>
  </mat-card-content>
</mat-card>
