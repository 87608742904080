import { HttpErrorResponse } from '@angular/common/http';
import { ApolloError } from '@apollo/client/core';
import { FT_getErrorText } from '@furnas-technology/common-library/functions';

export type FT_GraphQLResponse = {
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	data: any;
	error?: { status: number; message: string };
	// error?: ApolloError;
};

// type NewMTYPE = { data: string[] } & Omit<MTYPE, "data">;

export type FT_MutateData<T, U extends string> = {
	[key in U]: {
		recordId: string;
		record: Partial<T>;
	};
};

export type FT_GraphQLDeleteResult = {
	status: 'success' | 'not found' | 'failed';
	documentId: string;
	documentDesc: string;
	message: string;
	recordId?: string;
};

export type FT_DeleteData<U extends string> = {
	[key in U]: FT_GraphQLDeleteResult;
};

export const createGraphQLErrorResult = (id: string | string, message: string = ''): FT_GraphQLDeleteResult => {
	return {
		status: 'failed',
		documentId: id,
		documentDesc: '',
		message: message,
	};
};

export type FT_GraphqlMutationResult<T, U extends string> = {
	success: boolean;
	error: string;
	recordId: string;
} & {
	[key in U]: T;
};

export const createGraphQLMutationResult = <T, U extends string>(
	recordId: string,
	recordNameAndObject: Record<U, T>,
): FT_GraphqlMutationResult<T, U> => {
	return {
		success: true,
		error: '',
		recordId: recordId,
		...recordNameAndObject,
	};
};

export type FT_GraphQLQueryData<T, U extends string> = {
	[key in U]: Partial<T>;
};

export type FT_GraphQLResponseData<T, U extends string> = {
	data: {
		[key in U]: Partial<T>;
	};
};

export const GetQueryData = <T>(response: FT_GraphQLResponse, recordName: string): T => {
	let errorMsg = ``;

	// handle good response
	if (response?.data?.[recordName]) {
		const rx: T = response.data[recordName];
		return rx;
	} else if (!response) {
		errorMsg = `Response object is empty`;
	} else if (response.error) {
		errorMsg = `(${response.error.status}) ${response.error.message}`;
	} else {
		errorMsg = `No data, no errors found`;
	}

	throw new Error(errorMsg);
};

export const GetApolloErrorMessage = (err: unknown): { status: number; message: string } => {
	console.debug(`GetApolloErrorMessage - err=`, err);
	if (err instanceof ApolloError) {
		if (err.cause) {
			const cause = err.cause;
			if (cause instanceof HttpErrorResponse) {
				return {
					status: cause.error.status,
					message: cause.error.message,
				};
			} else if (objHasMessage(cause)) {
				return {
					status: 400,
					message: cause.message,
				};
			} else {
				return {
					status: 400,
					message: err.message,
				};
			}
		} else {
			return {
				status: 400,
				message: err.message,
			};
		}
	} else {
		const { errMessage, errStatus } = FT_getErrorText(err);
		return { status: errStatus, message: errMessage };
	}
};

export const HasMore = (dataLength: number, limit: number): boolean => {
	// return if no dataLength
	if (!dataLength || Number.isNaN(dataLength) || dataLength === 0) return false;

	// return
	return dataLength >= limit;
};

export type HasMessage<T extends object = object> = {
	messsage: string;
};

export const objHasMessage = <T>(obj: T): obj is HasMessage & T => {
	return !!(obj != null && typeof obj === 'object' && 'message' in obj);
};
