import { FieldFilter, FieldFilterInput, SearchBarField } from '@furnas-technology/common-library/filters';
import { FT_FilterComponent } from './filter.functions';

export type RegisteredComponentFilter<T> = {
	filterName: string;
	component: FT_FilterComponent | undefined;
	searchString: string;
	initialFilters: FieldFilterInput[];
	fieldFilters: FieldFilter<T>[];
	searchBarFields: SearchBarField<T>[];
	showSearchBar: boolean;
	searchBarInFilterComponent: boolean;
};

export const InitComponentFilter: RegisteredComponentFilter<unknown> = {
	filterName: '',
	component: undefined,
	searchString: '',
	initialFilters: [],
	fieldFilters: [],
	searchBarFields: [],
	showSearchBar: false,
	searchBarInFilterComponent: false,
};
