import { FT_objectKeys } from '@furnas-technology/common-library/functions';

/**
 * For a given object, returns only the fields listed or all fields that do not start with underscore
 * and removes null array entries
 */
export const FT_propsRecord = <T extends object>(obj: T, fields?: (keyof T)[] | undefined): Partial<T> => {
	const record: Partial<T> = {};

	if (fields) {
		for (const field of fields) {
			record[field] = obj[field];
		}
	} else {
		const keys: (keyof T)[] = FT_objectKeys(obj);
		for (const key of keys) {
			if (
				String(key).startsWith('_') ||
				['createdAt', 'updatedAt', 'deletedAt', 'retrievedAt', 'deleted', 'active', 'EXISTS'].includes(String(key))
			) {
				// delete record[key];
				// do nothing
			} else {
				const value = obj[key];
				const valueString = JSON.stringify(value);
				if (!!value && Array.isArray(value) && value.length > 0) {
					const scrubbedValue = value.map((item: T[keyof T]) => {
						if (typeof item === 'object' && item !== null) {
							return FT_removeInvalidGraphqlKeys(String(key), item);
						} else {
							return item;
						}
					});
					record[key] = scrubbedValue as T[keyof T];
				} else {
					record[key] = value;
				}

				const recordString = JSON.stringify(record[key]);
				if (valueString !== recordString) {
				}
				// __typename
			}
		}
	}

	return record;
};

const FT_removeInvalidGraphqlKeys = <T extends object>(key: string, obj: T): T => {
	const record: Partial<T> = {};
	const keys: (keyof T)[] = FT_objectKeys(obj);
	let removed = false;
	for (const key of keys) {
		if (String(key) !== '__typename') {
			record[key] = obj[key];
		} else {
			removed = true;
		}
	}

	return record as T;
};
