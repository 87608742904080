import { ChangeDetectionStrategy, Component, DestroyRef, Inject, OnInit, computed, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircleDot, faClipboardQuestion } from '@fortawesome/free-solid-svg-icons';

import { MatButtonModule } from '@angular/material/button';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { AsyncPipe } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { CloseIconButtonComponent } from '@furnas-technology/angular-library/components';
import { NgSelectModule } from '@ng-select/ng-select';
import { Observable, isObservable, of } from 'rxjs';

export interface FT_SelectDialogData {
	title: string;
	prompt: string;
	options: SelectOption[] | Observable<SelectOption[]>;
}

export type SelectOption = {
	key: string | number;
	value: string | number | Observable<string | number>;
};

export interface SelectResult {
	status: 'cancel' | 'select';
	selectedKey?: string | number;
}

@Component({
	selector: 'ft-select-dialog',
	templateUrl: './select-dialog.component.html',
	styleUrls: ['./select-dialog.component.scss'],
	imports: [
		AsyncPipe,
		CloseIconButtonComponent,
		DragDropModule,
		FontAwesomeModule,
		MatButtonModule,
		MatDialogModule,
		MatSelectModule,
		NgSelectModule,
		ReactiveFormsModule,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FT_SelectDialog implements OnInit {
	destroyRef = inject(DestroyRef);
	dialogRef = inject(MatDialogRef<FT_SelectDialog>);

	faClipboardQuestion = faClipboardQuestion;
	faCircleDot = faCircleDot;

	selectForm: FormGroup;
	selectOptions$: Observable<SelectOption[]> = of([]);

	headerTitle = computed(() => this.selectDialog.title ?? '');
	prompt = computed(() => this.selectDialog.prompt ?? '');

	constructor(@Inject(MAT_DIALOG_DATA) public selectDialog: FT_SelectDialogData) {
		this.selectForm = new FormGroup({
			selectedItem: new FormControl(null),
		});
	}

	ngOnInit() {
		if (isObservable(this.selectDialog.options)) {
			this.selectOptions$ = this.selectDialog.options;
		} else {
			this.selectOptions$ = of(this.selectDialog.options);
		}

		this.selectForm = new FormGroup({
			selectedItem: new FormControl(null),
		});
	}

	onSelect(evt: SelectOption) {
		console.debug(`${this.constructor.name} - onSelect - evt=`, evt);
		const res: SelectResult = { status: 'select', selectedKey: evt.key };
		this.dialogRef.close(res);
	}

	close() {
		const res: SelectResult = { status: 'cancel' };
		this.dialogRef.close(res);
	}
}
