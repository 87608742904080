<div class="ft-dialog-component auto-size">
  <!-- card header -->
  <div mat-dialog-title>
    <!-- card header  -->
    <div class="title">
      <fa-icon [icon]="faClipboardQuestion"></fa-icon>
      <h4 cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{ headerTitle() }}</h4>
    </div>

    <div class="title-options">
      <ft-close-button (closeClicked)="close()"></ft-close-button>
    </div>
  </div>

  <!-- card content -->
  <mat-dialog-content>
    <h4><div [innerHTML]="message"></div></h4>
  </mat-dialog-content>

  <!-- card actions  -->
  <mat-dialog-actions class="ft-dialog-actions">
    <button mat-button (click)="close()" aria-label="Cancel">Cancel</button>
    @if (showConfirm()) {
      <button mat-button (click)="confirm()" color="primary" cdkFocusInitial aria-label="Confirm">Confirm</button>
    }
  </mat-dialog-actions>
</div>
