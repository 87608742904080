<div class="display-cards-component" color="primary">
  <cdk-virtual-scroll-viewport
    dynamicSize
    [defaultItemHeight]="defaultCardHeight()"
    querySelector=".cdk-virtual-item"
    class="width-100-pct"
  >
    <div
      *cdkVirtualFor="let item of data(); index as i; even as even; odd as odd; last as last; first as first"
      class="cdk-virtual-item"
      [ngClass]="{ evenRow: !!even, oddRow: !!odd, firstRow: !!first, lastRow: !!last }"
      [id]="item._id"
    >
      <mat-card
        class="mobile-data-card mat-elevation-z2"
        [ngClass]="[!!odd ? 'odd-card' : 'even-card', !!last ? 'last-card' : '', !!first ? 'first-card' : '']"
      >
        <!-- Card Header - Title and Subtitle -->
        @if (titleColumns().length || subtitleColumns().length) {
          <mat-card-header>
            @if (titleColumns().length) {
              <mat-card-title>
                @for (
                  titleColumn of titleColumns();
                  track titleColumn.name;
                  let idx = $index, e = $even, o = $odd, f = $first, l = $last
                ) {
                  <!-- {{ titleColumn?.displayableValue(item) }} -->
                  <ng-container
                    *ngTemplateOutlet="
                      displayColumnValueTemplate;
                      context: {
                        $implicit: item,
                        column: titleColumn,
                        index: idx,
                        evenRow: e,
                        oddRow: o,
                        lastRow: l,
                        firstRow: f,
                        float: idx === 0 ? 'float-left' : 'float-right',
                      }
                    "
                  ></ng-container>
                }
              </mat-card-title>
            }

            <!-- Card Subtitle - Second Column -->
            @if (subtitleColumns().length) {
              <mat-card-subtitle [ngClass]="subtitleColumns()[0].columnType || ''">
                @for (
                  subtitleColumn of subtitleColumns();
                  track subtitleColumn.name;
                  let idx = $index, e = $even, o = $odd, f = $first, l = $last
                ) {
                  <ng-container
                    *ngTemplateOutlet="
                      displayColumnValueTemplate;
                      context: {
                        $implicit: item,
                        column: subtitleColumn,
                        index: idx,
                        evenRow: e,
                        oddRow: o,
                        lastRow: l,
                        firstRow: f,
                        float: idx === 0 ? 'float-left' : 'float-right',
                      }
                    "
                  ></ng-container>
                }
              </mat-card-subtitle>
            }
          </mat-card-header>
        }

        <!-- Card Content - Middle Columns -->
        <mat-card-content>
          @for (column of contentColumns(); track column.name; let ci = $index) {
            <div class="content-row">
              <div class="content-label strong">{{ column.label }} :</div>
              <ng-container
                *ngTemplateOutlet="
                  displayColumnValueTemplate;
                  context: {
                    $implicit: item,
                    column: column,
                    index: ci,
                    evenRow: even,
                    oddRow: odd,
                    lastRow: last,
                    firstRow: first,
                    float: 'float-none',
                  }
                "
              ></ng-container>
            </div>
          }
        </mat-card-content>

        <!-- Card Actions - Last Column -->
        @if (actionColumns().length) {
          <mat-card-actions>
            @for (column of actionColumns(); track column.name; let actionIndex = $index) {
              <ft-display-actions-cell
                [column]="tableColumns()[tableColumns().length - 1]"
                [element]="item"
                [rowId]="item._id"
                (buttonPressed)="onActionClicked($event)"
              ></ft-display-actions-cell>
            }
          </mat-card-actions>
        }
      </mat-card>
    </div>
  </cdk-virtual-scroll-viewport>
</div>

<ng-template
  #displayColumnValueTemplate
  let-element
  let-column="column"
  let-index="index"
  let-oddRow="oddRow"
  let-evenRow="evenRow"
  let-lastRow="lastRow"
  let-firstRow="firstRow"
  let-float="float"
>
  <!-- display column value -->
  <div class="content-value" [ngClass]="float" [attr.data-name]="column.name">
    @if (column.columnType === 'image') {
      <ft-display-image-cell
        [column]="column"
        [element]="element"
        [rowId]="element._id"
        [displayableValue]="column.displayableValue(element)"
        [tooltipValue]="tooltipValue(column, element)"
        (buttonPressed)="onActionClicked($event)"
      ></ft-display-image-cell>
    } @else if (column.editable) {
      <ft-edit-cell
        [column]="column"
        [element]="element"
        [rowId]="element._id"
        [displayableValue]="column.displayableValue(element)"
        [tooltipValue]="tooltipValue(column, element)"
        (changedColumn)="onChangedColumn($event)"
      ></ft-edit-cell>
    } @else {
      <ft-display-cell
        [column]="column"
        [element]="element"
        [rowId]="element._id"
        [displayableValue]="column.displayableValue(element) || (column.cardDefaultValue ?? '')"
        [dataRowsChanged]="dataRowsChanged()"
        [tooltipValue]="tooltipValue(column, element)"
        (buttonPressed)="onActionClicked($event)"
      ></ft-display-cell>
    }
  </div>
</ng-template>
