import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

import { type Observable, catchError, filter, switchMap, take, tap, throwError } from 'rxjs';

import { ApiXHeaders, AuthService } from './auth.service';

import { toObservable } from '@angular/core/rxjs-interop';
import { IS_BASIC_AUTH, IS_PUBLIC_API } from '@ft/lib/core-lib';
import { AuthResult } from '@ft/lib/models';
import { FT_LogError } from '@furnas-technology/common-library/functions';
import { AuthStore } from '../store/auth.store';

const REFRESH_STATUS_CODES: number[] = [401, 403];
const FUNCTION_NAME = `AuthInterceptorFn`;

type UrlContext = {
	baseUrl: string;
	isGraphql: boolean;
	isGraphqlPublic: boolean;
};

const baseUrl = (request: HttpRequest<unknown>): string => {
	try {
		const url = request.url.split('?')[0].split('/').slice(-1);
		return url.length > 0 ? url[0] : '';
	} catch (err: unknown) {
		return '';
	}
};

export function LoggingInterceptorFn(
	request: HttpRequest<unknown>,
	next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
	return next(request).pipe(
		tap((event) => {
			let desc = '';
			if (event.type === HttpEventType.Sent) {
				desc = 'Sent';
			} else if (event.type === HttpEventType.Response) {
				desc = 'Response';
			} else if (event.type === HttpEventType.UploadProgress) {
				desc = 'UploadProgress';
			} else if (event.type === HttpEventType.DownloadProgress) {
				desc = 'DownloadProgress';
			} else if (event.type === HttpEventType.ResponseHeader) {
				desc = 'ResponseHeader';
			} else if (event.type === HttpEventType.User) {
				desc = 'User';
			}

			// console.debug(`LoggingInterceptorFn - tap - event.type=${event.type}-${desc}`);
		}),
	);
}

export function AuthInterceptorFn(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
	// AuthService
	const auth = inject(AuthService);
	const authStore = inject(AuthStore);

	// if public API, then pass on
	const isPublic = request.context.get(IS_PUBLIC_API);
	if (isPublic) {
		return next(request);
	}

	// get context values
	const urlContext: UrlContext = getUrlContext(request);

	const interceptedRequest$ = toObservable(authStore.authInProgress).pipe(
		// Wait until authStatus is not 'in progress'
		filter((authInProgress) => !authInProgress),
		// Only take the first emission after 'in progress' is done
		take(1),
		// Switch to the actual request execution
		switchMap((_authInProgress) => {
			const accessToken = authStore.selectAccessToken();
			return requestHandler(request, next, auth, accessToken);
		}),
	);

	return interceptedRequest$;
} // end AuthInterceptorFn

const requestHandler = (
	request: HttpRequest<unknown>,
	next: HttpHandlerFn,
	auth: AuthService,
	accessToken: string,
): Observable<HttpEvent<unknown>> => {
	// add apikeys
	const apiXHeaders = auth.getApiHeaderValues();
	const appIdValue = apiXHeaders.ApiAppIdValue();
	const apiKeyValue = apiXHeaders.ApiKeyValue();

	if (!appIdValue) {
		console.error(`❌ ${FUNCTION_NAME} - No appId exists`);
	}

	// add appropriate authorisation
	const isBasicAuth = request.context.get(IS_BASIC_AUTH);

	const tokenRequest = isBasicAuth
		? setAuthorizationBasicToken(request, apiKeyValue, apiXHeaders)
		: setAuthorizationBearerToken(request, accessToken, apiXHeaders);

	// handleRequest
	const handledRequest$ = next(tokenRequest).pipe(
		catchError((err: unknown) => {
			// check if we want to refresh
			if (err instanceof HttpErrorResponse && REFRESH_STATUS_CODES.includes(err.status)) {
				// if no access token, nothing to do
				if (!accessToken) {
					console.log(`${FUNCTION_NAME} - handleRefresh - could not refresh, no access token`);
					return throwError(() => err);
				}

				// attempt to refresh the token
				return auth.refreshTokens(accessToken).pipe(
					switchMap((authResult: AuthResult | null) => {
						const accessToken = authResult?.access_token ?? '';
						if (authResult?.access_token) {
							const refreshRequest = setAuthorizationBearerToken(request, accessToken, apiXHeaders);
							return next(refreshRequest);
						} else {
							console.log(`${FUNCTION_NAME} - handleRefresh - refreshTokens failed to provide an access token,`);
							return throwError(() => new Error(`❌ ${FUNCTION_NAME} failed to refresh token`));
						}
					}),
				);
			}

			// Handle too large of payload
			if (err instanceof HttpErrorResponse && err.status === 413) {
				console.error(`❌ ${FUNCTION_NAME} - payload size error`);
				return throwError(() => err);
			}

			// if unexpected error, then display
			if (!(err instanceof HttpErrorResponse)) {
				const requestError = FT_LogError(err, FUNCTION_NAME);
			}

			// return of(err);
			console.error(`❌ ${FUNCTION_NAME} - unexpected error in request handler - err=`, err);
			return throwError(() => new Error(`AuthInterceptorFn error found`));
		}),
	);

	return handledRequest$;
}; // end requestHandler

function getUrlContext(request: HttpRequest<unknown>): UrlContext {
	const urlBase = baseUrl(request);
	console.debug(`🔵 ${FUNCTION_NAME} - getUrlContext - urlBase=${urlBase}`);

	const isGraphql = !!urlBase.match(/graphql/i);
	const isGraphqlPublic = !!urlBase.match(/graphqlPublic/i);

	return {
		baseUrl: urlBase,
		isGraphql: isGraphql,
		isGraphqlPublic: isGraphqlPublic,
	};
} // end getUrlContext

const setAuthHeaders = (
	request: HttpRequest<unknown>,
	apiXHeaders: ApiXHeaders,
	authorization: string,
): HttpRequest<unknown> => {
	return request.clone({
		setHeaders: {
			[apiXHeaders.ApiKey]: apiXHeaders.ApiKeyValue(),
			[apiXHeaders.ApiAppId]: apiXHeaders.ApiAppIdValue(),
			Authorization: authorization,
		},
	});
};

// const addApiKeys = (request: HttpRequest<unknown>, apiXHeaders: ApiXHeaders): HttpRequest<unknown> => {
//   if (apiXHeaders.ApiKeyValue() && apiXHeaders.ApiAppIdValue()) {
//     return request.clone({
//       headers: request.headers
//         .set(apiXHeaders.ApiKey, apiXHeaders.ApiKeyValue())
//         .set(apiXHeaders.ApiAppId, apiXHeaders.ApiAppIdValue()),
//     });
//   } else if (apiXHeaders.ApiKeyValue()) {
//     return request.clone({
//       headers: request.headers.set(apiXHeaders.ApiKey, apiXHeaders.ApiKeyValue()),
//     });
//   } else if (apiXHeaders.ApiAppIdValue()) {
//     return request.clone({
//       headers: request.headers.set(apiXHeaders.ApiAppId, apiXHeaders.ApiAppIdValue()),
//     });
//   } else {
//     return request;
//   }
// };

function setAuthorizationBasicToken(
	request: HttpRequest<unknown>,
	basicToken: string,
	apiXHeaders: ApiXHeaders,
): HttpRequest<unknown> {
	const fullToken = basicToken.startsWith('Basic ') ? basicToken : `Basic ${basicToken}`;
	return setAuthHeaders(request, apiXHeaders, fullToken);
	// return request.clone({ headers: request.headers.set('Authorization', fullToken) });
	// const authRequest = request.clone({ headers: request.headers.append('Authorization', fullToken) });
	// return authRequest;
}

function setAuthorizationBearerToken(
	request: HttpRequest<unknown>,
	accessToken: string,
	apiXHeaders: ApiXHeaders,
): HttpRequest<unknown> {
	// return without authorisation if access token is empty
	// if (!accessToken) return request;

	const fullToken = accessToken.startsWith('Bearer ') ? accessToken : `Bearer ${accessToken}`;
	return setAuthHeaders(request, apiXHeaders, fullToken);
	// const authRequest = request.clone({ headers: request.headers.append('Authorization', fullToken) });
	// return authRequest;
}
