import { ChangeDetectionStrategy, Component, DestroyRef, Inject, type OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPaste } from '@fortawesome/free-solid-svg-icons';

import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

export interface PasteDialogData {
	title: string;
	label: string;
	placeHolder: string;
	rows: string[];
	fieldList: string[];
}

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { CloseIconButtonComponent } from '@furnas-technology/angular-library/components';

@Component({
	selector: 'confirm-dialog',
	templateUrl: './paste-text-rows.component.html',
	styleUrls: ['./paste-text-rows.component.scss'],
	imports: [
		CloseIconButtonComponent,
		DragDropModule,
		FontAwesomeModule,
		MatButtonModule,
		MatDialogModule,
		MatFormFieldModule,
		MatInputModule,
		ReactiveFormsModule,
		TextFieldModule,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasteTextRowsComponent implements OnInit {
	destroyRef = inject(DestroyRef);
	dialogRef = inject(MatDialogRef);
	// dialogRef = inject(MatDialogRef<PasteTextRowsComponent>),
	fb = inject(FormBuilder);

	form = new FormGroup({});
	rows = new FormControl();

	// rows?: string[];

	faPaste = faPaste;

	constructor(@Inject(MAT_DIALOG_DATA) public data: PasteDialogData) {}

	ngOnInit() {
		const rows = this.data?.rows ? this.data.rows.join('\n') : '';
		this.rows.setValue(rows);
	}

	onSubmit() {
		console.debug(`${this.constructor.name} - this.rows=`, this.rows);
		if (this.rows.value) {
			const rows = this.rows.value.split('\n').filter((x: string) => x?.trim());
			this.data.rows = rows;

			this.dialogRef.close(rows);
			return;
		}

		this.dialogRef.close();
	}

	close() {
		this.dialogRef.close();
	}

	handleChange(ev: unknown): void {
		console.debug(`${this.constructor.name} -  handleChange - this.rows=`, this.rows);
	}
}
