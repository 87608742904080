import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	type OnInit,
	inject,
	linkedSignal,
	signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { faCirclePlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { TableColumn } from '@ft/lib/tables-lib';

export const getColumnDefinitions = <T extends object>(data: T): TableColumn<T>[] => {
	const columnDefinitions: TableColumn<T>[] = [];
	return columnDefinitions;
};

@Component({
	selector: 'ft-standard-management',
	templateUrl: './ft-standard-management.component.html',
	styleUrls: ['./ft-standard-management.component.scss'],
	imports: [FontAwesomeModule, MatButtonModule, MatDialogModule, MatDatepickerModule, MatNativeDateModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FT_StandardManagementComponent<TData extends object> implements OnInit {
	protected cdr = inject(ChangeDetectorRef);

	rowsShowing = signal(0);

	faCirclePlus = faCirclePlus;
	faEdit = faEdit;
	faTrash = faTrash;

	// displayedColumns: string[] = [];
	stickyColumns = signal<string[]>([]);

	columns = linkedSignal<TableColumn<TData>[]>(() => {
		const colDefs = getColumnDefinitions<TData>(columnDefinitions as TData);
		return colDefs;
	});

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);
	}

	ngOnInit(): void {}

	/** Whether the button toggle group contains the id as an active value. */
	isSticky(id: string) {
		return this.stickyColumns().indexOf(id) !== -1;
	}
}

const columnDefinitions: TableColumn<object>[] = [];
