export const EnvironmentUrls = (baseApiUrl: string) => ({
	baseApiUrl,
	authUrl: baseApiUrl,
	loginUrl: '/login',
	homeUrl: '/home',
	logoutUrl: '/logout',
	imageUrl: `${baseApiUrl}image/`,
	graphdefaultUrl: `${baseApiUrl}graphql`,
	graphqlPublicUrl: `${baseApiUrl}graphqlQuery`,
});
