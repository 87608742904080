import { Directive, ElementRef, Renderer2, effect, inject, input } from '@angular/core';

@Directive({
	selector: '[addOpacity]',
	standalone: true,
})
export class AddOpacityDirective {
	private el = inject(ElementRef);
	private renderer = inject(Renderer2);

	addOpacity = input<number>(0.5);
	targetProperty = input<string>('backgroundColor');

	constructor() {
		effect(() => {
			this.processRequest(this.addOpacity(), this.targetProperty(), this.el.nativeElement);
		});
	}

	private processRequest(opacity: number, targetProperty: string, element: HTMLElement | undefined = undefined) {
		if (!targetProperty || !element) return;
		const computedStyle: CSSStyleDeclaration = getComputedStyle(element);
		const currentColor = computedStyle.getPropertyValue(this.convertCamelToKebabCase(this.targetProperty()));

		// Only process if we have a color to work with
		if (currentColor && currentColor !== 'transparent') {
			setTimeout(() => this.addOpacityToProperty(currentColor, element), 0);
		}
	}

	private addOpacityToProperty(currentColor: string, element: HTMLElement) {
		const colorWithOpacity = this.addOpacityToColor(currentColor, this.addOpacity());
		this.renderer.setStyle(element, this.targetProperty(), colorWithOpacity);
	}

	private addOpacityToColor(color: string, opacity: number): string {
		const scrubbedColor = color.trim();

		// Detect format and convert to rgba
		if (scrubbedColor.startsWith('#')) {
			// Convert hex to rgba
			return this.hexToRgba(color, opacity);
		} else if (scrubbedColor.startsWith('rgb(')) {
			// Convert rgb to rgba
			return scrubbedColor.replace('rgb(', 'rgba(').replace(')', `, ${opacity})`);
		} else if (scrubbedColor.startsWith('rgba(')) {
			// Already rgba, just modify the alpha value
			return this.updateRgbaOpacity(color, opacity);
		} else if (scrubbedColor.startsWith('hsl(')) {
			// Convert hsl to hsla
			return scrubbedColor.replace('hsl(', 'hsla(').replace(')', `, ${opacity})`);
		} else if (scrubbedColor.startsWith('hsla(')) {
			// Already hsla, modify the alpha
			return this.updateHslaOpacity(color, opacity);
		}

		// Return original if can't determine format
		return color;
	}

	private hexToRgba(hex: string, opacity: number): string {
		// Remove # if present
		let scrubbedHex = hex.replace('#', '');

		// Convert 3-digit hex to 6-digit
		if (scrubbedHex.length === 3) {
			scrubbedHex = scrubbedHex
				.split('')
				.map((c) => c + c)
				.join('');
		}

		// Parse hex values
		const r = Number.parseInt(scrubbedHex.substring(0, 2), 16);
		const g = Number.parseInt(scrubbedHex.substring(2, 4), 16);
		const b = Number.parseInt(scrubbedHex.substring(4, 6), 16);

		return `rgba(${r}, ${g}, ${b}, ${opacity})`;
	}

	private updateRgbaOpacity(rgba: string, opacity: number): string {
		return rgba.replace(/rgba\(([^,]+),([^,]+),([^,]+),[^)]+\)/, `rgba($1,$2,$3,${opacity})`);
	}

	private updateHslaOpacity(hsla: string, opacity: number): string {
		return hsla.replace(/hsla\(([^,]+),([^,]+),([^,]+),[^)]+\)/, `hsla($1,$2,$3,${opacity})`);
	}

	// Helper method to convert camelCase to kebab-case for CSS properties
	private convertCamelToKebabCase(camelCase: string): string {
		return camelCase.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
	}
}
