"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Prop = exports.prop = void 0;
const tslib_1 = require("tslib");
const constants_1 = require("./internal/constants");
const utils = tslib_1.__importStar(require("./internal/utils"));
const logSettings_1 = require("./logSettings");
/**
 * Set Property Options for the property below
 * @param options The Options to Set
 * @param kind Overwrite auto-inferred PropType
 * @example
 * ```ts
 * class ClassName {
 *   @prop()
 *   public someProp?: string;
 *
 *   @prop({ type: () => [String] })
 *   public someArrayProp?: string[];
 *
 *   @prop({ type: () => String })
 *   public someMapProp?: Map<string, string>;
 * }
 * ```
 */
function prop(options, kind) {
  return (target, key) => {
    options = options ?? {};
    const existingMapForTarget = Reflect.getOwnMetadata(constants_1.DecoratorKeys.PropCache, target);
    if (utils.isNullOrUndefined(existingMapForTarget)) {
      Reflect.defineMetadata(constants_1.DecoratorKeys.PropCache, new Map(), target);
    }
    const mapForTarget = existingMapForTarget ?? Reflect.getOwnMetadata(constants_1.DecoratorKeys.PropCache, target);
    mapForTarget.set(key, {
      options,
      target,
      key,
      propType: kind
    });
    logSettings_1.logger.debug('Added "%s.%s" to the Decorator Cache', utils.getName(target.constructor), key);
  };
}
exports.prop = prop;
exports.Prop = prop;
