import { ChangeDetectionStrategy, Component, DestroyRef, Inject, type OnInit, computed, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faClipboardQuestion } from '@fortawesome/free-solid-svg-icons';

import { MatButtonModule } from '@angular/material/button';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CloseIconButtonComponent } from '@furnas-technology/angular-library/components';

export interface FT_ConfirmDialogData {
	title: string;
	message: string;
	hideConfirm?: boolean;
}

export enum FT_ConfirmResult {
	Confirm = 'Confirm',
	Cancel = 'Cancel',
}

@Component({
	selector: 'ft-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.scss'],
	imports: [CloseIconButtonComponent, FontAwesomeModule, MatButtonModule, MatDialogModule, DragDropModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FT_ConfirmDialog implements OnInit {
	destroyRef = inject(DestroyRef);
	dialogRef = inject(MatDialogRef<FT_ConfirmDialog>);

	faClipboardQuestion = faClipboardQuestion;

	constructor(@Inject(MAT_DIALOG_DATA) public confirmDialog: FT_ConfirmDialogData) {}

	headerTitle = computed(() => this.confirmDialog.title);
	showConfirm = computed(() => !this.confirmDialog.hideConfirm);

	get message() {
		return this.confirmDialog.message;
	}

	ngOnInit() {}

	confirm() {
		this.dialogRef.close(FT_ConfirmResult.Confirm);
	}

	close() {
		this.dialogRef.close(FT_ConfirmResult.Cancel);
	}
}
