import {
	ComponentRef,
	DestroyRef,
	Directive,
	ElementRef,
	OnInit,
	Renderer2,
	ViewContainerRef,
	computed,
	signal,
} from '@angular/core';

import { inject, input } from '@angular/core';
import { FT_FaSpinnerComponent } from '@ft/lib/components';

@Directive({
	standalone: true,
	selector: 'img[appSpinner]',
})
export class AppSpinner implements OnInit {
	protected destroyRef = inject(DestroyRef);
	private renderer = inject(Renderer2);
	private vcr = inject(ViewContainerRef);

	private parentEl: ElementRef<HTMLElement> = inject(ElementRef<HTMLElement>);

	private wrapperEl: HTMLElement | undefined;

	private spinnerComponentRef!: ComponentRef<FT_FaSpinnerComponent>;
	private showSpinnerTimeout: ReturnType<typeof setTimeout> | undefined;
	private observer: MutationObserver | undefined;

	appSpinnerDelay = input<number>(300);
	appSpinnerTransitionDuration = input<number>(300);
	maxFontSize = input<number>(100);
	minFontSize = input<number>(8);

	parentWH = signal({ width: 0, height: 0 });
	fontSize = computed(() => {
		const minLength = Math.max(
			this.minFontSize(),
			Math.min(this.parentWH().width, this.parentWH().height, this.maxFontSize()),
		);

		return `${minLength}px`;
	});

	constructor() {}

	ngOnInit() {
		this.setupWrapper();
		this.setupImage();
		this.setupSpinner();
		this.setupMutationObserver();
		this.startLoadingTimer();

		this.destroyRef.onDestroy(() => {
			this.destroySpinnerComponent();
		});
	}

	destroySpinnerComponent() {
		clearTimeout(this.showSpinnerTimeout);
		this.observer?.disconnect();
		this.spinnerComponentRef?.destroy();
	}

	private setupWrapper() {
		this.renderer.setStyle(this.parentEl.nativeElement, 'position', 'relative');

		/**
		 * Set parent
		 */
		const parent = this.renderer.parentNode(this.parentEl.nativeElement);
		const parentRect = parent.getBoundingClientRect();
		this.parentWH.set({ width: parentRect.width, height: parentRect.height });
		this.renderer.setStyle(parent, 'position', 'relative');

		// /**
		//  * Create wrapper
		//  */
		// this.wrapperEl = this.renderer.createElement('div');
		// this.renderer.addClass(this.wrapperEl, 'ft-spinner-wrapper');
		// this.renderer.setStyle(this.wrapperEl, 'position', 'aboslute');
		// this.renderer.setStyle(this.wrapperEl, 'display', 'inline-block');
		// this.renderer.setStyle(this.wrapperEl, 'top', `0`);
		// this.renderer.setStyle(this.wrapperEl, 'left', `0`);
		// this.renderer.setStyle(this.wrapperEl, 'width', `${parentRect.width}px`);
		// this.renderer.setStyle(this.wrapperEl, 'height', `${parentRect.height}px`);
		// this.renderer.setStyle(this.wrapperEl, 'animation', 'spinme 3s linear infinite');
		// this.renderer.setStyle(this.wrapperEl, 'border-radius', '50%');
		// this.renderer.setStyle(this.wrapperEl, 'z-index', '500');
		// this.renderer.setStyle(this.wrapperEl, 'font-size', this.fontSize());

		// if (parent) {
		// 	this.renderer.insertBefore(parent, this.wrapperEl, this.parentEl.nativeElement.nextSibling);
		// }

		// this.renderer.appendChild(this.parentEl.nativeElement, this.wrapperEl);
	}

	private setupImage() {
		this.renderer.setStyle(this.parentEl.nativeElement, 'opacity', '0');
		this.renderer.setStyle(
			this.parentEl.nativeElement,
			'transition',
			`opacity ${this.appSpinnerTransitionDuration()}ms ease-out`,
		);

		this.renderer.listen(this.parentEl.nativeElement, 'load', () => this.handleImageLoad());
		this.renderer.listen(this.parentEl.nativeElement, 'error', () => this.handleImageError());
	}

	private setupSpinner() {
		this.spinnerComponentRef = this.vcr.createComponent(FT_FaSpinnerComponent);
		const spinnerElement = this.spinnerComponentRef.location.nativeElement;

		const parent = this.renderer.parentNode(this.parentEl.nativeElement);

		this.renderer.addClass(spinnerElement, 'ft-spinner-element');
		this.renderer.setStyle(spinnerElement, 'position', 'absolute');
		this.renderer.setStyle(spinnerElement, 'top', '0');
		this.renderer.setStyle(spinnerElement, 'bottom', '0');
		this.renderer.setStyle(spinnerElement, 'left', '0');
		this.renderer.setStyle(spinnerElement, 'right', '0');

		this.renderer.setStyle(spinnerElement, 'justify-self', 'center');
		this.renderer.setStyle(spinnerElement, 'align-self', 'center');

		// this.renderer.setStyle(spinnerElement, 'width', '100%');
		// this.renderer.setStyle(spinnerElement, 'height', '100%');
		this.renderer.setStyle(spinnerElement, 'width', `${this.parentWH().width}px`);
		this.renderer.setStyle(spinnerElement, 'height', `${this.parentWH().height}px`);

		this.renderer.setStyle(spinnerElement, 'opacity', '0');
		this.renderer.setStyle(spinnerElement, 'transition', `opacity ${this.appSpinnerTransitionDuration()}ms ease-out`);

		this.renderer.setStyle(spinnerElement, 'display', 'flex');
		this.renderer.setStyle(spinnerElement, 'justify-content', 'center');
		this.renderer.setStyle(spinnerElement, 'align-items', 'center');

		this.renderer.setStyle(spinnerElement, 'animation', 'spinme 3s linear infinite');
		this.renderer.setStyle(spinnerElement, 'border-radius', '50%');
		this.renderer.setStyle(spinnerElement, 'z-index', '500');
		this.renderer.setStyle(spinnerElement, 'font-size', this.fontSize());

		// this.renderer.appendChild(this.wrapperEl, spinnerElement);
		if (parent) {
			this.renderer.insertBefore(parent, spinnerElement, this.parentEl.nativeElement.nextSibling);
		}
	}

	private setupMutationObserver() {
		this.observer = new MutationObserver((mutations) => {
			for (const mutation of mutations) {
				if (mutation.attributeName === 'src') {
					this.resetState();
				}
			}
		});

		this.observer.observe(this.parentEl.nativeElement, { attributes: true });
	}

	private startLoadingTimer() {
		this.showSpinnerTimeout = setTimeout(() => {
			this.renderer.setStyle(this.spinnerComponentRef.location.nativeElement, 'opacity', '1');
		}, this.appSpinnerDelay());
	}

	private handleImageLoad() {
		clearTimeout(this.showSpinnerTimeout);
		this.renderer.setStyle(this.spinnerComponentRef.location.nativeElement, 'opacity', '0');
		this.renderer.setStyle(this.parentEl.nativeElement, 'opacity', '1');
		this.destroySpinnerComponent();
	}

	private handleImageError() {
		clearTimeout(this.showSpinnerTimeout);
		this.renderer.setStyle(this.spinnerComponentRef.location.nativeElement, 'opacity', '0');
		// Optional: Add error handling
	}

	private resetState() {
		clearTimeout(this.showSpinnerTimeout);
		this.renderer.setStyle(this.parentEl.nativeElement, 'opacity', '0');
		this.renderer.setStyle(this.spinnerComponentRef.location.nativeElement, 'opacity', '0');
		this.renderer.setStyle(this.spinnerComponentRef, 'display', 'none');
		this.startLoadingTimer();
	}
}
