import { ListRange } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport, VirtualScrollStrategy } from '@angular/cdk/scrolling';
import { inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ItemHeights, SCROLL_BUFFER_AFTER, SCROLL_BUFFER_BEFORE, ViewportRange } from './virtual-scroll.model';
import { VirtualScrollService } from './virtual-scroll.service';

const intersects = (a: ViewportRange, b: ViewportRange): boolean => {
	const aStart = a[0];
	const aEnd = a[1];
	const bStart = b[0];
	const bEnd = b[1];
	if (bStart >= aStart && bStart <= aEnd) return true;
	if (bEnd >= aStart && bEnd <= aEnd) return true;
	return false;
};
// (a[0] <= b[0] && b[0] <= a[1]) || (a[0] <= b[1] && b[1] <= a[1]) || (b[0] < a[0] && a[1] < b[1]);

const clamp = (min: number, value: number, max: number): number => Math.min(Math.max(min, value), max);
const isEqual = <T>(a: T, b: T) => a === b;
const last = <T>(value: T[]): T => value[value.length - 1];

export class DynamicSizeVirtualScrollStrategy implements VirtualScrollStrategy {
	private vss = inject(VirtualScrollService);
	private viewport: CdkVirtualScrollViewport | null = null;

	/**
	 * Emits when the index of the first element visible in the viewport changes.
	 */
	private scrolledIndexChange$ = new Subject<number>();
	public scrolledIndexChange: Observable<number> = this.scrolledIndexChange$.pipe(distinctUntilChanged());

	private itemHeights: ItemHeights = [];
	private contentSize = 0;
	private itemsLength = 0;

	constructor(
		private defaultItemHeight: number,
		private querySelector: string,
	) {}

	/**
	 * Attaches this scroll strategy to a viewport.
	 * @param viewport The viewport to attach this strategy to.
	 */
	attach(viewport: CdkVirtualScrollViewport) {
		console.debug(`DynamicSizeVirtualScrollStrategy - attach - **** viewport attached - viewport=`, viewport);
		this.viewport = viewport;
		this.updateRenderedRange('attach');
	}

	/**
	 * Detaches this scroll strategy from the viewport.
	 */
	detach() {
		this.scrolledIndexChange$.complete();
		this.viewport = null;
		this.itemHeights = [];
		this.contentSize = 0;
		this.itemsLength = 0;
	}

	/**
	 * Called when the viewport is scrolled (debounced using requestAnimationFrame).
	 */
	onContentScrolled() {
		if (!this.viewport) return;
		this.updateRenderedRange('onContentScrolled');
	}

	/**
	 * Called when the length of the data changes
	 */
	onDataLengthChanged() {
		if (!this.viewport) return;
		this.itemsLength = this.viewport.getDataLength();

		/**
		 * Default item heights
		 */
		for (let i = this.itemHeights.length; i < this.itemsLength; i++) {
			this.itemHeights.push(this.defaultItemHeight);
		}
		this.updateTotalContentSize();

		const renderedRange = this.viewport.getRenderedRange();
		console.debug(
			`DynamicSizeVirtualScrollStrategy - onDataLengthChanged - dataLength=${this.itemsLength}, renderedRange=${renderedRange?.start}->${renderedRange?.end}`,
		);
		this.updateRenderedRange('onDataLengthChanged');
	}

	/**
	 * Called when the range of items rendered in the DOM has changed
	 */
	onContentRendered() {
		if (!this.viewport) return;
		console.debug(`DynamicSizeVirtualScrollStrategy - onContentRendered`);
		this.vss.measureRenderedItems(this.viewport, this.itemHeights, this.defaultItemHeight);
		const isDiff = this.updateTotalContentSize();
		if (isDiff) {
			this.updateRenderedRange('onContentRendered');
		}
	}

	/**
	 * Called when the offset of the rendered items changed
	 */
	onRenderedOffsetChanged() {
		if (!this.viewport) return;
		console.debug(`DynamicSizeVirtualScrollStrategy - onRenderedOffsetChanged`);
		this.vss.measureRenderedItems(this.viewport, this.itemHeights, this.defaultItemHeight);
		this.updateTotalContentSize();
	}

	/**
	 * Scroll to the offset for the given index.
	 * @param index The index of the element to scroll to.
	 * @param behavior The ScrollBehavior to use when scrolling.
	 */
	scrollToIndex(index: number, behavior: ScrollBehavior) {
		console.debug(
			`DynamicSizeVirtualScrollStrategy - scrollToIndex - index=${index}, behavior=${behavior}, viewport=${!!this.viewport}`,
		);
		if (!this.viewport) return;
		this.viewport?.scrollToOffset(this.getItemOffset(index), behavior);
	}

	/**
	 * ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- -----
	 * INTERNAL METHODS
	 * ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- -----
	 */

	/**
	 * Call to update the default item height
	 */
	public updateItemHeights(defaultItemHeight: number) {
		this.defaultItemHeight = defaultItemHeight;
	}

	/**
	 * get height of the item at the given index
	 */
	private getItemHeight(index: number): number {
		return index < this.itemHeights.length ? this.itemHeights[index] : this.defaultItemHeight;
	}

	/**
	 * get offset to the item at the given index
	 */
	private getItemOffset(index: number): number {
		const items = this.itemHeights.slice(0, index);
		let offset = 0;
		for (let i = 0; i < items.length; i++) {
			const height = this.getItemHeight(i);
			offset += height;
		}

		return offset;
	}

	/**
	 * get the height of the total content (all items)
	 */
	private getTotalContentSize(): number {
		return this.getItemOffset(this.itemsLength);
	}

	/**
	 * Determine list range based on scroll offset and viewport size
	 */
	private getVisibleRange(scrollOffset: number, viewportSize: number): ListRange {
		const visibleOffsetRangePx: ViewportRange = [scrollOffset, scrollOffset + viewportSize];
		console.debug(
			`DynamicSizeVirtualScrollStrategy - getVisibleRange - from=${visibleOffsetRangePx[0]}, to=${visibleOffsetRangePx[1]}, itemHeights=${this.itemHeights.length}, itemsLength=${this.itemsLength}`,
		);

		const visibleStartPx = visibleOffsetRangePx[0];
		const visibleEndPx = visibleOffsetRangePx[1];
		let startItem = -1;
		let endItem = 0;
		let currentOffset = 0;

		for (let i = 0; i < this.itemHeights.length; i++) {
			/**
			 * Determine start item
			 */
			if (startItem < 0 && currentOffset >= visibleStartPx) startItem = i;

			/**
			 * Determine end item
			 */
			if (startItem >= 0 && currentOffset <= visibleEndPx) endItem = i;

			/**
			 * Are we past the end?
			 */
			if (currentOffset > visibleEndPx) {
				break;
			}

			/**
			 * Compute current offset
			 */
			currentOffset += this.itemHeights[i];
		}

		const startRange = clamp(0, startItem - SCROLL_BUFFER_BEFORE, this.itemHeights.length - 1);
		const endRange = clamp(0, endItem + SCROLL_BUFFER_AFTER, this.itemHeights.length);

		console.debug(`DynamicSizeVirtualScrollStrategy - getVisibleRange - start=${startRange}, end=${endRange}`);

		return { start: startRange, end: endRange };
	}

	private updateRenderedRange(caller: string) {
		if (!this.viewport) return;

		/**
		 * Get sizes of rendered items - set total content size
		 */
		this.vss.measureRenderedItems(this.viewport, this.itemHeights, this.defaultItemHeight);
		this.updateTotalContentSize();

		/**
		 * Get viewport size and scroll offset
		 */
		const viewportSize = this.viewport.getViewportSize();
		const scrollOffset = this.viewport.measureScrollOffset();
		const oldRange = this.viewport?.getRenderedRange();

		console.debug(
			`DynamicSizeVirtualScrollStrategy - updateRenderedRange - caller=${caller}, scrollOffset=${scrollOffset}, viewportSize=${viewportSize}, oldRange=${oldRange.start}->${oldRange.end}`,
		);

		const newRange = this.getVisibleRange(scrollOffset, viewportSize);
		console.debug(
			`DynamicSizeVirtualScrollStrategy - updateRenderedRange - newRange=${newRange.start}->${newRange.end}, oldRange=${oldRange.start}->${oldRange.end}`,
		);

		if (isEqual(newRange, oldRange)) return;

		this.viewport.setRenderedRange(newRange);
		this.viewport.setRenderedContentOffset(this.getItemOffset(newRange.start));
		this.scrolledIndexChange$.next(newRange.start);
	}

	private updateTotalContentSize(): boolean {
		const contentSize = this.getTotalContentSize();
		const avgContentSize = !!this.itemsLength ? Math.ceil(contentSize / this.itemsLength) : 0;
		this.viewport?.setTotalContentSize(contentSize + avgContentSize);

		console.debug(`DynamicSizeVirtualScrollStrategy - contentSize=${contentSize}`);
		const isDiff = this.contentSize !== contentSize;
		this.contentSize = contentSize;
		return isDiff;
	}

	// private measureRenderedItems(): void {
	// 	if (!this.viewport) return;

	// 	const renderedRange = this.viewport.getRenderedRange(); // range of items rendered
	// 	const renderedItems = this.viewport.elementRef.nativeElement.querySelectorAll('.cdk-virtual-item'); // rendered items
	// 	const renderedItemsLength = renderedItems?.length ?? 0;
	// 	console.debug(
	// 		`DynamicSizeVirtualScrollStrategy - measureRenderedItems - renderedRange=${renderedRange?.start}->${renderedRange?.end}, renderedItems.length=${renderedItemsLength}, itemsLength=${this.itemsLength}`,
	// 	);

	// 	const renderedRangeLength = renderedRange.end - renderedRange.start;
	// 	if (
	// 		renderedRangeLength !== renderedItemsLength &&
	// 		renderedRange.start > 0 &&
	// 		renderedRange.end > 0 &&
	// 		renderedItemsLength > 0
	// 	) {
	// 		console.error(
	// 			`❌ DynamicSizeVirtualScrollStrategy - measureRenderedItems - renderedRangeLength=${renderedRangeLength}, renderedItemsLength=${renderedItemsLength} - are multiple divisions assigned class cdk-virtual-item?	`,
	// 		);
	// 		return;
	// 	}

	// 	/**
	// 	 * Get actual height of the rendered items
	// 	 */
	// 	for (let i = renderedRange.start; i < renderedRange.end && i >= 0; i++) {
	// 		const renderedItemsIndex = i - renderedRange.start;

	// 		const height =
	// 			renderedItemsIndex >= 0 && renderedItemsIndex < renderedItemsLength
	// 				? renderedItems[renderedItemsIndex].getBoundingClientRect().height
	// 				: this.defaultItemHeight;

	// 		if (this.itemHeights[i] !== height) {
	// 			this.itemHeights[i] = height;
	// 		}
	// 	}
	// }
}
