import { Coordinate } from 'ol/coordinate';
import { fromLonLat } from 'ol/proj';

export type LatLngLiteral = { lat: number; lng: number };

export const MARKER_ZOOM_WIDTHS = [
	{ fromZoom: 0, toZoom: 6, width: 22 },
	{ fromZoom: 7, toZoom: 8, width: 24 },
	{ fromZoom: 9, toZoom: 10, width: 26 },
	{ fromZoom: 11, toZoom: 12, width: 28 },
	{ fromZoom: 13, toZoom: 14, width: 30 },
	{ fromZoom: 15, toZoom: 16, width: 32 },
	{ fromZoom: 17, toZoom: 18, width: 34 },
	{ fromZoom: 19, toZoom: 99, width: 36 },
];

export const MAP_EVENTS = [
	`bounds_changed`,
	`center_changed`,
	`click`,
	`contextmenu`,
	`dblclick`,
	`drag`,
	`dragend`,
	`dragstart`,
	`heading_changed`,
	`idle`,
	`maptypeid_changed`,
	`mousemove`,
	`mouseout`,
	`mouseover`,
	`projection_changed`,
	`resize`,
	`rightclick`,
	`tilesloaded`,
	`tilt_changed`,
	`zoom_changed`,
	`hover`,
	`pointermove`,
] as const;
export type MAP_EVENT = (typeof MAP_EVENTS)[number];

// enum MAP_EVENT {
//   bounds_changed = `bounds_changed`,
//   center_changed = `center_changed`,
//   click = `click`,
//   contextmenu = `contextmenu`,
//   dblclick = `dblclick`,
//   drag = `drag`,
//   dragend = `dragend`,
//   dragstart = `dragstart`,
//   heading_changed = `heading_changed`,
//   idle = `idle`,
//   maptypeid_changed = `maptypeid_changed`,
//   mousemove = `mousemove`,
//   mouseout = `mouseout`,
//   mouseover = `mouseover`,
//   projection_changed = `projection_changed`,
//   resize = `resize`,
//   rightclick = `rightclick`,
//   tilesloaded = `tilesloaded`,
//   tilt_changed = `tilt_changed`,
//   zoom_changed = `zoom_changed`,
// }

export type MAP_COORDS = {
	lat: number;
	lng: number;
};

export const MAP_BOUNDS = {
	NZ: {
		north: -34.36,
		south: -47.35,
		west: 166.28,
		east: -175.81,
	},
	AU: {
		north: -9.0,
		south: -44.0,
		west: 112.0,
		east: 154.0,
	},
};

export const LatLngToCoordinate = (latlngLiteral: LatLngLiteral): Coordinate => {
	const coord = fromLonLat([latlngLiteral.lng ?? 0, latlngLiteral.lat ?? 0]);
	return coord;
};

export const MAP_CENTERS = {
	AU: { lat: -25.274399, lng: 133.775131 },
};

export interface LatLng {
	lat: number;
	lng: number;
}

export const CityLatLngs: Record<string, LatLng> = {
	Sydney: { lat: -33.86785, lng: 151.20732 },
	Melbourne: { lat: -37.814, lng: 144.96332 },

	Brisbane: { lat: -27.46794, lng: 153.02809 },
	Perth: { lat: -31.95224, lng: 115.8614 },
	Adelaide: { lat: -34.92866, lng: 138.59863 },
	'Gold Coast': { lat: -28.00029, lng: 153.43088 },
	Canberra: { lat: -35.28346, lng: 149.12807 },
	Newcastle: { lat: -32.92953, lng: 151.7801 },
	Wollongong: { lat: -34.424, lng: 150.89345 },
	Geelong: { lat: -38.14711, lng: 144.36069 },
	Hobart: { lat: -42.87936, lng: 147.32941 },
	Townsville: { lat: -19.26639, lng: 146.80569 },
	Cairns: { lat: -16.92366, lng: 145.76613 },
	Ballarat: { lat: -37.56622, lng: 143.84957 },
	'Logan City': { lat: -27.63917, lng: 153.10944 },
	Toowoomba: { lat: -27.56056, lng: 151.95386 },
	Darwin: { lat: -12.46113, lng: 130.84185 },
	Mandurah: { lat: -32.5269, lng: 115.7217 },
	Mackay: { lat: -21.15345, lng: 149.16554 },
	Bundaberg: { lat: -24.86621, lng: 152.3479 },
	Auckland: { lat: -37.06, lng: 174.58 },
};

export type GeoLocation = {
	lat: number;
	lng: number;
};

export type GeoBounds = {
	northeast: {
		lat: number;
		lng: number;
	};
	southwest: {
		lat: number;
		lng: number;
	};
};

// enum MAP_EVENT {
//   bounds_changed = `bounds_changed`,
//   center_changed = `center_changed`,
//   click = `click`,
//   contextmenu = `contextmenu`,
//   dblclick = `dblclick`,
//   drag = `drag`,
//   dragend = `dragend`,
//   dragstart = `dragstart`,
//   heading_changed = `heading_changed`,
//   idle = `idle`,
//   maptypeid_changed = `maptypeid_changed`,
//   mousemove = `mousemove`,
//   mouseout = `mouseout`,
//   mouseover = `mouseover`,
//   projection_changed = `projection_changed`,
//   resize = `resize`,
//   rightclick = `rightclick`,
//   tilesloaded = `tilesloaded`,
//   tilt_changed = `tilt_changed`,
//   zoom_changed = `zoom_changed`,
// }

export interface LatLng {
	lat: number;
	lng: number;
}
