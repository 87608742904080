import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	LOCALE_ID,
	computed,
	inject,
	input,
	output,
} from '@angular/core';

import {} from '@angular/common';

import { issueCustomTooltipDefaults } from '@ft/lib/models';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';

import { MatButtonModule } from '@angular/material/button';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { Observable } from 'rxjs';
import { TableButtonPressed, TableColumn } from '../table-column.model';
import { DataRow, DisplayableValue, TableButton } from '../table-generic.model';

@Component({
	selector: 'ft-display-actions-cell',
	templateUrl: './display-actions.component.html',
	styleUrls: ['./display-actions.component.scss'],
	imports: [FontAwesomeModule, MatButtonModule, MatTooltipModule],
	providers: [
		{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: issueCustomTooltipDefaults },
		{ provide: LOCALE_ID, useValue: navigator.language || 'en-AU' },
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayActionsCellComponent<T extends object> {
	destroyRef = inject(DestroyRef);

	column = input.required<TableColumn<T>>();
	element = input.required<DataRow<T>>();
	rowId = input<string>();
	dataRowsChanged = input<number>(0);

	buttonPressed = output<TableButtonPressed<T>>();

	faImage = faImage;
	isHovered = false;

	elementId = computed(() => this.column().elementId);
	elementValue = computed(() => {
		const elementId = this.column().elementId as keyof T;
		return this.element()[elementId];
	});

	rawValue = computed(() => this.column().rawValue(this.element()));

	displayableValue = computed<DisplayableValue | Observable<DisplayableValue>>(() => {
		return this.column().displayableValue(this.element());
	});

	tooltipValue = computed(() => this.column().tooltipValue(this.element()));
	columnType = computed(() => this.column().columnType);
	mainClass = computed(() => this.column().mainClass);

	afterLoad = false;

	constructor() {}

	onActionClick(
		event: MouseEvent | undefined,
		action: TableButton,
		col: TableColumn<T>,
		dataRow: (DataRow<T> & { _id?: string }) | undefined,
	) {
		const pressed: TableButtonPressed<T> = {
			tableAction: action,
			column: col,
			dataRow: dataRow,
			_id: String(dataRow?._id ?? ''),
			event: event,
		};

		this.buttonPressed.emit(pressed);
	} // end onActionClick
}
