<button
  #filterMenuTrigger
  [matMenuTriggerFor]="filterMenu"
  class="filter-component-trigger clickable"
  [class.active-filter]="hasActiveFilter()"
  [ngClass]="hasActiveFilter() ? 'color-accent strong' : ''"
  disableRipple="true"
  (click)="openFilter($event)"
  aria-label="filter-trigger-button"
>
  <fa-icon
    class="filter-icon"
    [style.color]="hasActiveFilter() ? 'accent' : ''"
    [ngClass]="[hasActiveFilter() ? 'color-accent strong' : '']"
    [icon]="faFilter"
  ></fa-icon>
</button>

<mat-menu #filterMenu="matMenu" class="filter-menu filter-menu-panel" hasBackdrop="true" panelClass="filter-menu-panel">
  <div class="filter-menu-content">
    @if (isSelectFilter()) {
      <form [formGroup]="filterForm" class="filter-menu-form">
        <mat-card class="filter-menu-card">
          <div #ngSelectContainer class="filter-ng-select-container">
            <ng-select
              #ngSelect
              id="ngSelect"
              class="column-filter"
              [items]="columnFilterOptions()"
              [multiple]="column().columnFilterMultiple"
              [hideSelected]="true"
              bindValue="value"
              bindLabel="option"
              [clearable]="true"
              [virtualScroll]="true"
              [placeholder]="placeholder()"
              formControlName="value"
              [ngModel]="selectedValues()"
              (change)="onChangeSelectedValues($event)"
              (click)="$event.stopPropagation()"
              (open)="onSelectEvent('open')"
              (close)="onSelectEvent('close')"
              (blur)="onSelectEvent('blur')"
              dropdownPosition="bottom"
            >
              <!-- <ng-select [items]="items" bindLabel="name" bindValue="id">
              <ng-template ng-option-tmp let-item="item">
                <div>{{ item.name }}</div>
                <small>{{ getAdditionalInfo(item) }}</small>
              </ng-template> -->

              <!-- <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" /> {{ item.name }}
              </ng-template> -->
              <!-- <ng-template ng-option-tmp let-item="item" let-index="index">item to option-tmp: {{ item }} </ng-template> -->

              <!-- <ng-template ng-label-tmp let-item="item"> item to label-tmp: {{ item }} </ng-template> -->
            </ng-select>
          </div>
        </mat-card>
      </form>
    } @else {
      <form [formGroup]="filterForm" class="filter-menu-form">
        <mat-card class="filter-menu-card">
          <mat-card-content class="filter-menu-card-content">
            @if (isBoolean()) {
              <div class="boolean-options">
                <mat-radio-group aria-label="Boolean Filter" class="radio-group" formControlName="comparison">
                  @for (option of availableFilters(); track option.comparison) {
                    <mat-radio-button
                      [value]="option.comparison"
                      (click)="$event.stopPropagation()"
                      [aria-label]="option.comparison"
                      (change)="onBooleanChange($event)"
                    >
                      {{ option.label }}</mat-radio-button
                    >
                  }
                </mat-radio-group>
              </div>
            } @else {
              <mat-form-field (click)="$event.stopPropagation()" aria-required="true">
                <mat-label>Comparison</mat-label>
                <mat-select formControlName="comparison" required="true">
                  @for (option of availableFilters(); track $index) {
                    <mat-option [value]="option.comparison">
                      {{ option.label }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>

              @if (showValueInput()) {
                <mat-form-field (click)="$event.stopPropagation()">
                  <mat-label>Value</mat-label>
                  <input matInput [type]="inputType()" formControlName="value" />
                </mat-form-field>
              }
            }
          </mat-card-content>

          <mat-card-actions align="end" class="filter-menu-card-actions">
            <button mat-button type="button" (click)="close($event)">Close</button>
            <button mat-button type="button" (click)="onClear($event)">Clear</button>
            <button mat-raised-button color="primary" type="submit" (click)="$event.stopPropagation(); onApply()">
              Apply
            </button>
          </mat-card-actions>
        </mat-card>
      </form>
    }
  </div>
</mat-menu>
