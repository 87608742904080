import { Type } from '@angular/core';
import { FilterDataType, SearchBarField } from '@furnas-technology/common-library/filters';
import { ComponentFilterDefinition } from './filter.model';

export type FT_FilterComponent = Type<unknown>;

export type FT_FilterComponentInputs<T> = {
	[key in keyof T]: T[keyof T];
} & {
	appFilters?: boolean;
};

export type ComponentFilters<T> = {
	[key: string]: ComponentFilterDefinition<T>;
};

export const getMatchingSearchBarFields = <T>(
	searchBarFields: SearchBarField<T>[],
	filterDataType: FilterDataType,
): (keyof T)[] => {
	const matchingFields: (keyof T)[] = [];

	for (const searchBarField of searchBarFields) {
		if (searchBarField.filterDataTypes.findIndex((item) => item === filterDataType) > -1) {
			matchingFields.push(searchBarField.fieldname as keyof T);
		}
	}
	return matchingFields;
};
