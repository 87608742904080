import {
	AfterViewInit,
	CUSTOM_ELEMENTS_SCHEMA,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Inject,
	OnInit,
	Renderer2,
	effect,
	inject,
	signal,
	viewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthStore } from '@ft/lib/auth-lib';
import { STRIPE_PK } from '@ft/lib/core-lib';
import { EnvironmentService } from '@ft/lib/environment-lib';
import { Stripe } from '@stripe/stripe-js';
import {} from 'rxjs';

const PRICING_TABLE = 'prctbl_1PYKr9Bd4pDUi81IapXfSBvx';

export type SubscriptionData = {
	subscribeOrManage: string;
};

@Component({
	selector: 'ft-stripe-subscription',
	templateUrl: './stripe-subscription.component.html',
	styleUrls: ['./stripe-subscription.component.scss'],
	imports: [],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripeSubscriptionComponent implements OnInit, AfterViewInit {
	private renderer = inject(Renderer2);
	private el = inject(ElementRef);
	private authStore = inject(AuthStore);
	private environment = inject(EnvironmentService);

	pricingTable = viewChild<ElementRef>('pricingtable');

	userinfo = this.authStore.userinfo;
	afterViewInit = signal<boolean>(false);

	private readonly pricingTableId = PRICING_TABLE;

	stripe = signal<Stripe | null>(null);
	stripePK = this.environment.stripePK;
	stripePT = this.environment.stripePT;
	stripePortal = this.environment.stripePortal;

	constructor(
		@Inject(STRIPE_PK) protected stripePublishableKey: string,
		@Inject(MAT_DIALOG_DATA) public subscriptionData: SubscriptionData,
	) {
		console.debug(`${this.constructor.name} - subscriptionData=`, this.subscriptionData);

		effect(() => {
			if (this.userinfo().id && this.afterViewInit()) {
				if (this.pricingTable() && this.subscriptionData.subscribeOrManage !== 'manage') {
					console.debug(
						`${this.constructor.name} - EFFECT - email=${this.userinfo().email}, id=${this.userinfo().id},  userinfo=`,
						this.userinfo(),
					);

					console.debug(
						`${this.constructor.name} - stripePT=${this.environment.stripePT}, stripePK=${this.environment.stripePK}`,
					);

					const pt = this.pricingTable();
					if (pt) {
						this.renderer.setAttribute(pt.nativeElement, 'publishable-key', this.stripePK());
						this.renderer.setAttribute(pt.nativeElement, 'pricing-table-id', this.stripePT());

						this.renderer.setAttribute(pt.nativeElement, 'customerEmail', this.userinfo().email);
						this.renderer.setAttribute(pt.nativeElement, 'customer-email', this.userinfo().email);

						this.renderer.setAttribute(pt.nativeElement, 'clientReferenceId', this.userinfo().username);
						this.renderer.setAttribute(pt.nativeElement, 'client-reference-id', this.userinfo().username);

						this.renderer.setStyle(pt.nativeElement, 'max-height', '96vh');
						this.renderer.setStyle(pt.nativeElement, 'max-width', '100vw');
						this.renderer.setStyle(pt.nativeElement, 'width', '630px');
						this.renderer.setStyle(pt.nativeElement, 'margin', 'auto');

						// this.renderer.setStyle(pt.nativeElement, 'width', 'clamp(300px, 600px, 100vw)');

						console.debug(`${this.constructor.name} - EL - pt=`, pt.nativeElement);

						const script = this.renderer.createElement('script');
						script.src = 'https://js.stripe.com/v3/pricing-table.js';
						script.async = true;
						this.renderer.setAttribute(script, 'customer-email', this.userinfo().email);
						this.renderer.setAttribute(script, 'client-reference-id', this.userinfo().username);
						this.renderer.appendChild(pt.nativeElement, script);
					}
				} else if (this.subscriptionData.subscribeOrManage === 'manage' && this.stripePortal()) {
					const prefilled = this.userinfo().email
						? `?prefilled_email=${encodeURIComponent(this.userinfo().email)}`
						: '';
					const url = this.stripePortal() + prefilled;
					window.open(url, '_blank', 'noopener,noreferrer');
				}
			}
		});
	}

	ngOnInit() {
		// console.debug(`${this.constructor.name} - stripePublishableKey=${this.stripePublishableKey}`);
	}

	ngAfterViewInit() {
		this.afterViewInit.set(true);
	}
}
