"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SearchIndex = exports.searchIndex = void 0;
const constants_1 = require("./internal/constants");
const utils_1 = require("./internal/utils");
const logSettings_1 = require("./logSettings");
/**
 * Defines a search index for this Class which will then be added to the Schema.
 *
 * @param description - The description of the search index to add.
 * @example Example:
 * ```ts
 * @searchIndex({ name: 'descriptionIndex', definition: { mappings: { dynamic: true }}})
 * class ClassName {}
 * ```
 * @remarks Search indices are only supported when connected to MongoDB Atlas.
 */
function searchIndex(description) {
  return target => {
    logSettings_1.logger.info('Adding "%o" Search Indexes to %s', description, (0, utils_1.getName)(target));
    const searchIndices = Array.from(Reflect.getOwnMetadata(constants_1.DecoratorKeys.SearchIndex, target) ?? []);
    searchIndices.push(description);
    Reflect.defineMetadata(constants_1.DecoratorKeys.SearchIndex, searchIndices, target);
  };
}
exports.searchIndex = searchIndex;
exports.SearchIndex = searchIndex;
