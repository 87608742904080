import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'ft-fa-spinner',
	templateUrl: './ft-fa-spinner.component.html',
	styleUrls: ['./ft-fa-spinner.component.scss'],
	imports: [FontAwesomeModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FT_FaSpinnerComponent {
	faSpinner = faSpinner;
}
