import { NgClass } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	LOCALE_ID,
	OnInit,
	computed,
	effect,
	input,
	output,
	signal,
	viewChild,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';

import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatMenu, MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faClose, faFilter } from '@fortawesome/free-solid-svg-icons';
import { map, of } from 'rxjs';
import { ColumnFilter, ColumnFilterOption, TableColumn } from '../table-column.model';
import { IsActiveFilter } from '../table-functions';
import { FILTER_COMPARISON, FILTER_OPTIONS, FilterOption } from '../table-generic.model';

import { NgSelectModule } from '@ng-select/ng-select'; // Import NgSelectModule

type ColumnFilterValues = {
	comparison: FILTER_COMPARISON | '';
	value: string | null | string[]; // allow array of strings
};

@Component({
	selector: 'ft-column-filter',
	templateUrl: './column-filter.component.html',
	styleUrls: ['./column-filter.component.scss'],
	imports: [
		FontAwesomeModule,
		FormsModule,
		MatButtonModule,
		MatCardModule,
		MatFormFieldModule,
		MatInputModule,
		MatMenuModule,
		MatOptionModule,
		MatRadioModule,
		MatSelectModule,
		NgClass,
		// NgLabelTemplateDirective,
		// NgOptionTemplateDirective,
		NgSelectModule,
		ReactiveFormsModule,
	],
	providers: [{ provide: LOCALE_ID, useValue: navigator.language || 'en-AU' }],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnFilterComponent<T extends object> implements OnInit, AfterViewInit {
	column = input.required<TableColumn<T>>();
	filterChange = output<ColumnFilter<T> | null>();

	filterMenuTrigger = viewChild('filterMenuTrigger', { read: MatMenuTrigger });
	filterMenu = viewChild('filterMenu', { read: MatMenu });
	ngSelectContainer = viewChild('ngSelectContainer', { read: ElementRef });
	ngSelect = viewChild('ngSelect', { read: ElementRef });

	faFilter = faFilter;
	faClose = faClose;

	filterForm = new FormGroup({
		comparison: new FormControl<FILTER_COMPARISON | ''>(''),
		value: new FormControl<string | null | string[]>(null), // Update the type of value
	});

	isSelectFilter = computed<boolean>(() => !!this.column().isSelectFilter);
	selectedValues = computed<string[]>(() => (this.filterForm.get('value')?.value as string[]) ?? []);

	comparisonValue = toSignal<FILTER_COMPARISON | ''>(
		this.filterForm.get('comparison')?.valueChanges.pipe(
			takeUntilDestroyed(),
			map((x) => x ?? ''),
		) ?? of(''),
	);

	hasActiveFilter = computed(() => {
		const appliedValue = this.appliedValues().value;
		return IsActiveFilter(this.appliedValues().comparison, appliedValue);
	});

	availableFilters = computed<FilterOption[]>(() => {
		return FILTER_OPTIONS.filter((option) => option.applicableTypes.includes(this.column().columnType));
	});

	isBoolean = computed<boolean>(() => this.column().columnType === 'boolean');

	inputType = computed<string>(() => {
		switch (this.column().columnType) {
			case 'number':
			case 'currency':
				return 'number';
			case 'date': {
				if (this.comparisonValue() === 'month') {
					return 'month';
				} else {
					return 'date';
				}
			}
			default:
				return 'text';
		}
	});

	showValueInput = computed<boolean>(() => {
		return (
			!this.isBoolean() &&
			this.comparisonValue() !== 'empty' &&
			this.comparisonValue() !== 'notEmpty' &&
			!this.isSelectFilter()
		);
	});

	appliedValues = signal<ColumnFilterValues>({
		comparison: '',
		value: null,
	});

	placeholder = computed(() => {
		if (!!this.column().isSelectFilter) {
			return `Select item${this.column().columnFilterMultiple ? 's' : ''}`;
		}
		return '';
	});

	columnFilterOptions = computed(() => {
		const rows = this.column()?.columnFilterOptions;
		if (!rows) return [];
		const cfos: ColumnFilterOption[] = [];
		for (const row of rows) {
			cfos.push({ value: row.value, option: row.option ?? row.value.toString() ?? '' });
		}
		return cfos;
	});

	constructor() {
		effect(() => {
			this.updateValueVisibility();
		});
	}

	ngOnInit() {
		this.initComparison();
	}

	ngAfterViewInit(): void {}

	onSelectEvent(action: string) {
		console.debug(`${this.constructor.name} - onSelectEvent - action=${action}`);

		if (action === 'blur') {
			this.emitChange();
		}
	}

	/**
	 * Comparison methods
	 */

	defaultComparison(): FILTER_COMPARISON | '' {
		if (!!this.column().isSelectFilter) {
			return 'eq';
		} else if (this.column().columnType === 'text') {
			return 'contains';
		} else if (this.column().columnType === 'number') {
			return 'eq';
		} else if (this.column().columnType === 'date') {
			return 'eq';
		}
		return '';
	}

	initComparison() {
		this.filterForm.patchValue({ comparison: this.defaultComparison() });
	}

	openFilter(evt: Event) {
		evt.stopImmediatePropagation();
		evt.stopPropagation();
		evt.preventDefault();
		if (this.appliedValues().comparison === '' || this.appliedValues().value === null) {
			this.appliedValues.update((av) => ({ ...av, comparison: this.defaultComparison() }));
		}

		console.debug(`${this.constructor.name} - openFilter - appliedValues=`, this.appliedValues());
		this.filterForm.patchValue({ comparison: this.appliedValues().comparison, value: this.appliedValues().value });
	}

	private updateValueVisibility() {
		if (!this.showValueInput()) {
			this.filterForm.patchValue({ value: '' });
		}
	}

	onClear(evt?: Event) {
		if (evt) {
			evt.stopPropagation();
			evt.preventDefault();
		}
		this.filterForm.reset();
		this.initComparison();
		this.updateAppliedValues();
		this.emitChange();
		this.close();
	}

	onApply() {
		if (!this.filterForm.valid) return;

		if (this.filterForm.valid) {
			this.updateAppliedValues();
			this.emitChange();
			this.close();
		}
	}

	onChangeSelectedValues(values: string | string[]) {
		console.debug(
			`${this.constructor.name} - onChangeSelectedValues - column=${this.column().name}, value=`,
			values,
			this.column(),
		);

		console.debug(`${this.constructor.name} - onChangeSelectedValues - appliedValues=`, this.appliedValues().value);

		const formValue = this.filterForm.get('value')?.value ?? null;
		console.debug(`${this.constructor.name} - onChangeSelectedValues - formValue=`, formValue);

		const appliedValues = formValue || null;
		this.appliedValues.update((av) => ({ ...av, value: appliedValues }));
	}

	updateAppliedValues() {
		this.appliedValues.set({
			comparison: this.filterForm.get('comparison')?.value ?? '',
			value: this.filterForm.get('value')?.value ?? null, // Use null instead of ''
		});
	}

	emitChange() {
		console.debug(
			`${this.constructor.name} - emitChange - this.filterForm.valid=${this.filterForm.valid}, value=`,
			this.appliedValues().value,
		);
		const filter: ColumnFilter<T> = {
			column: this.column(),
			comparison: this.appliedValues().comparison,
			value: this.appliedValues().value ?? null, // Use null instead of ''
		};
		this.filterChange.emit(filter);
	}

	close(evt?: Event) {
		if (evt) {
			evt.stopPropagation();
			evt.preventDefault();
		}

		console.debug(`${this.constructor.name} - close - appliedValues=`, this.appliedValues());
		if (this.filterMenuTrigger()) {
			this.filterMenuTrigger()?.closeMenu();
		}
	}

	stopPropagation(event: Event) {
		event.stopPropagation();
		event.preventDefault();
	}

	onBooleanChange(event: MatRadioChange) {
		console.debug(`${this.constructor.name} - onComparisonChange - MatRadioChange=`, event);
	}
}
