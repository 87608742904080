import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, map, of, switchMap, take } from 'rxjs';
import { FT_ConfirmDialog, FT_ConfirmDialogData, FT_ConfirmResult } from './confirm-dialog/confirm-dialog.component';
import {
	FT_SelectDialog,
	FT_SelectDialogData,
	SelectOption,
	SelectResult,
} from './select-dialog/select-dialog.component';

export type SelectThenPromptInput<T> = {
	id: string | string[];
	keyProperty: keyof T;
	promptProperty: keyof T;
	title: string;
	message: string;
	selectFunction$: (id: string | string[]) => Observable<T | T[] | undefined[] | undefined>;
};

@Injectable({
	providedIn: 'root',
})
export class PromptService {
	private dialog = inject(MatDialog);

	constructor() {}

	/**
	 * Remove active focus from the document
	 */
	private removeActiveFocus() {
		try {
			if (document.activeElement instanceof HTMLElement) {
				document.activeElement.blur();
			}
		} catch (err: unknown) {
			console.error(`❌ ${this.constructor.name} - removeActiveFocus - error=`, err);
		}
	}

	confirmationPrompt(
		matConfig: MatDialogConfig<FT_ConfirmDialogData>,
		closeOthers = false,
	): Observable<FT_ConfirmResult> {
		this.removeActiveFocus();
		if (closeOthers) {
			const openDialogs = this.dialog.openDialogs;
			for (const openDialog of openDialogs) {
				// openDialog.close();
			}
		}

		// Open the new dialog
		const dialogRef = this.dialog.open(FT_ConfirmDialog, matConfig);
		return dialogRef.afterClosed().pipe(map((x: FT_ConfirmResult) => x));
	}

	/**
	 * Create options list using observable or convert non-observable to observable
	 */
	selectThenPrompt<T>(input: SelectThenPromptInput<T>): Observable<SelectResult> {
		const options$: Observable<SelectOption | SelectOption[] | undefined> = input.selectFunction$(input.id).pipe(
			/**
			 * map selection function results to SelectResult
			 */
			map((selResult: T | T[] | undefined | undefined[]) => {
				if (!selResult) {
					if (Array.isArray(input.id)) {
						return [] as SelectOption[];
					} else {
						return undefined;
					}
				} else if (Array.isArray(selResult) && !selResult.length) {
					return [] as SelectOption[];
				} else if (Array.isArray(selResult)) {
					return selResult
						.map((x) => (x ? { key: x[input.keyProperty], value: x[input.promptProperty] } : undefined))
						.filter((x) => x !== undefined) as SelectOption[];
				} else {
					return selResult[input.keyProperty]
						? ({ key: selResult[input.keyProperty], value: selResult[input.promptProperty] } as SelectOption)
						: undefined;
				}
			}),
		);

		/**
		 * Prompt for selection using options
		 */

		return options$.pipe(
			switchMap((options) => {
				if (!options) {
					const sr: SelectResult = { status: 'cancel' };
					return of(sr);
				} else if (Array.isArray(options)) {
					return this.selectionPrompt({ title: input.title, prompt: input.message, options: options }).pipe(
						take(1),
						map((result: SelectResult) => {
							return result;
						}),
					);
				} else {
					const sr: SelectResult = { status: 'select', selectedKey: options.key };
					return of(sr);
				}
			}),
		);
	}

	selectionPrompt(data: FT_SelectDialogData, closeOthers = false): Observable<SelectResult> {
		this.removeActiveFocus();
		if (closeOthers) {
			const openDialogs = this.dialog.openDialogs;
			for (const openDialog of openDialogs) {
				// openDialog.close();
			}
		}

		const dialogConfig = { height: 'auto', data } as MatDialogConfig<FT_SelectDialogData>;
		const dialogRef = this.dialog.open(FT_SelectDialog, dialogConfig);
		return dialogRef.afterClosed().pipe(map((x: SelectResult) => x));
	}
} // end class
