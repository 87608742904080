"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Post = exports.Pre = exports.post = exports.pre = void 0;
const constants_1 = require("./internal/constants");
const errors_1 = require("./internal/errors");
const utils_1 = require("./internal/utils");
const logSettings_1 = require("./logSettings");
// TSDoc for the hooks can't be added without adding it to *every* overload
const hooks = {
  pre(...args) {
    return target => addToHooks(target, 'pre', args);
  },
  post(...args) {
    return target => addToHooks(target, 'post', args);
  }
};
/**
 * Add a hook to the hooks Array
 * @param target Target Class
 * @param hookType What type is it
 * @param args All Arguments, that should be passed-through
 */
function addToHooks(target, hookType, args) {
  // Convert Method to array if only a string is provided
  const methods = Array.isArray(args[0]) ? args[0] : [args[0]];
  const func = args[1];
  const hookOptions = args[2];
  (0, utils_1.assertion)(typeof func === 'function', () => new errors_1.ExpectedTypeError('fn', 'function', func));
  if (args.length > 3) {
    logSettings_1.logger.warn(`"addToHooks" parameter "args" has a length of over 3 (length: ${args.length})`);
  }
  logSettings_1.logger.info('Adding hooks for "[%s]" to "%s" as type "%s"', methods.join(','), (0, utils_1.getName)(target), hookType);
  switch (hookType) {
    case 'post':
      const postHooks = Array.from(Reflect.getMetadata(constants_1.DecoratorKeys.HooksPost, target) ?? []);
      postHooks.push({
        func,
        methods,
        options: hookOptions
      });
      Reflect.defineMetadata(constants_1.DecoratorKeys.HooksPost, postHooks, target);
      break;
    case 'pre':
      const preHooks = Array.from(Reflect.getMetadata(constants_1.DecoratorKeys.HooksPre, target) ?? []);
      preHooks.push({
        func,
        methods,
        options: hookOptions
      });
      Reflect.defineMetadata(constants_1.DecoratorKeys.HooksPre, preHooks, target);
      break;
  }
}
exports.pre = hooks.pre;
exports.post = hooks.post;
// Export it PascalCased
exports.Pre = hooks.pre;
exports.Post = hooks.post;
