import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
	name: 'truncateHtml',
	pure: true,
	standalone: true,
})
export class TruncateHtmlPipe implements PipeTransform {
	sanitizer = inject(DomSanitizer);

	transform(html: string, limit: number = 200): SafeHtml {
		const tempElement = document.createElement('div');
		tempElement.innerHTML = html;

		// Get the text content and truncate it
		const textContent = tempElement.textContent ?? '';
		const dots = textContent.length > limit ? '...' : '';
		const truncatedText = textContent.substring(0, limit) + dots;

		const sanitizedString = this.sanitizer.bypassSecurityTrustHtml(truncatedText);
		return sanitizedString;
	}
}
