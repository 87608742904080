import { FieldFilterInput, SearchBarField } from '@furnas-technology/common-library/filters';
import { FT_FilterComponent } from './filter.functions';

export type ComponentFilterDefinition<T> = {
	component: FT_FilterComponent | undefined;
	filterName: string;
	initialFilters: FieldFilterInput[];
	searchBarFields: SearchBarField<T>[];
};

export const InitComponentFilterDefinition: ComponentFilterDefinition<unknown> = {
	component: undefined,
	filterName: '',
	initialFilters: [],
	searchBarFields: [],
};
