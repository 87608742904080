import { Inject, Injectable, computed, signal } from '@angular/core';
import { APP_ENVIRONMENT, EnvironmentConfig, ImageFit, ImagePosition } from '@ft/lib/core-lib';

declare global {
	interface Window {
		gtag: (...args: unknown[]) => void;
	}
}

export type ImageOptions = {
	width: number;
	height: number;
	fit: ImageFit;
	background: string;
	position: ImagePosition;
};

const defaultImageOptions: ImageOptions = {
	width: 48,
	height: 48,
	fit: 'contain',
	background: 'transparent',
	position: 'center',
};

@Injectable({
	providedIn: 'root',
})
export class EnvironmentService {
	authUrl = signal<string>(this.env.authUrl);
	baseUrl = signal<string>(this.env.baseUrl);
	backgroundsUrl = signal<string>(this.env.backgroundsUrl ?? '');
	loginCallback = signal<string>(this.env.loginCallback);
	logoutCallback = signal<string>(this.env.logoutCallback);

	stripePK = signal<string>(this.env.stripePK ?? '');
	stripePT = signal<string>(this.env.stripePT ?? '');
	stripePortal = signal<string>(this.env.stripePortal ?? '');

	baseImageUrl = computed(() => {
		return `${this.env.baseUrl}image`;
	});

	logoSquare = this.env.logoSquare;
	logoLandscape = this.env.logoLandscape;
	logoPortrait = this.env.logoPortrait;
	icon = this.env.icon;

	constructor(@Inject(APP_ENVIRONMENT) public env: EnvironmentConfig) {
		console.debug(`${this.constructor.name} - constructor - env=`, env);
	}

	environment() {
		return this.env;
	}

	triggerGoogleConversion() {
		if (typeof window.gtag === 'function' && this.env.production && this.env.googleConversionTag) {
			window.gtag('event', 'conversion', { send_to: this.env.googleConversionTag });
		}
	}

	logoSquareSize(size: number = 48): string {
		return `${this.env.logoSquare}?width=${size}&height=${size}&fit=contain&position=center`;
	}

	logoLandscapeSize(size: number = 48): string {
		return `${this.env.logoLandscape}?width=${size}&height=${size}&fit=contain&position=center`;
	}

	logoPortraitSize(size: number = 48): string {
		return `${this.env.logoPortrait}?width=${size}&height=${size}&fit=contain&position=center`;
	}

	logoWidthHeight(width: number, height: number, fit: ImageFit = 'contain', position: ImagePosition = 'center') {
		if (!width || !height) return '';
		const pctDiff = (Math.abs(width - height) / width) * 100;
		let logoUrl = this.env.logoSquare;
		if (pctDiff > 10 && width > height) {
			logoUrl = this.env.logoLandscape;
		} else if (pctDiff > 10 && width < height) {
			logoUrl = this.env.logoPortrait;
		}

		return `${logoUrl}?width=${width}&height=${height}&fit=${fit}&position=${position}`;
	}

	logoIconSize(size: number = 48): string {
		return `${this.env.icon}?width=${size}&height=${size}&fit=contain`;
	}

	imageOrLogo(imageUrl: string, options?: Partial<ImageOptions>): string {
		const opt = !options ? defaultImageOptions : Object.assign({}, defaultImageOptions, options);

		if (!imageUrl) return this.logoWidthHeight(opt.width, opt.height, opt.fit);
		return /amazonaws\.com\/image/.test(imageUrl)
			? `${imageUrl}?width=${opt.width}&height=${opt.height}&fit=${opt.fit}&position=${opt.position}&background=${opt.background}`
			: imageUrl;
	}
} // end service class
