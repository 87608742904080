<div
  class="ft-magic-image-component"
  [attr.data-image-type]="imageType()"
  [ngClass]="[
    imageType(),
    displayedImageSrc() ? 'has-source' : 'no-source',
    isHovered() ? 'is-hovered' : 'not-hovered',
    showClickElem() ? 'is-clickable' : 'not-clickable',
  ]"
>
  <div
    class="magic-image-drop-area"
    [ngClass]="[
      disabled() ? 'disabled' : '',
      isHovered() ? 'is-hovered' : '',
      isDragover ? 'is-dragover' : '',
      showHoverIcon() ? 'show-hover-icon' : '',
    ]"
    id="drop-area"
    [ngStyle]="{
      width: imgWidth() || 'auto',
      'max-height': imgHeight() || '100%',
      cursor: showClickElem() ? 'pointer' : 'default',
    }"
    appDragDrop="false"
    (click)="onClick($event)"
    (dragover)="onDragOver($event)"
    (drop)="onDrop($event)"
    (dragleave)="onDragLeave($event)"
    (mouseover)="isHovered.set(true)"
    (mouseenter)="isHovered.set(true)"
    (mouseleave)="isHovered.set(false)"
    (mouseout)="isHovered.set(false)"
  >
    <!-- (change)="uploadFile($event)" -->
    <input
      hidden
      type="file"
      #fileInput
      name="file"
      [attr.multiple]="multiple() ? '' : null"
      [disabled]="disabled()"
      [attr.accept]="acceptType() ? acceptType() : null"
    />
    <div class="displayed-image">
      @if (imageType() === 'pdf') {
        @if (displayedImageSrc()) {
          <ft-pdf-viewer
            class="pdf"
            [height]="imgHeight()"
            [width]="imgWidth()"
            [pdfSrc]="displayedImageSrc()"
          ></ft-pdf-viewer>
        } @else {
          <fa-icon [icon]="faFilePdf"></fa-icon>
        }
      } @else if (imageType() === 'image') {
        @if (displayedImageSrc()) {
          <img
            [src]="displayedImageSrcSafe()"
            (error)="imageError($event)"
            [ngClass]="{ profile: false }"
            [ngStyle]="imageStyle()"
            alt="upload image"
            appSpinner
          />
        } @else {
          <fa-icon [icon]="faImage"></fa-icon>
        }
      }
    </div>
  </div>
</div>
