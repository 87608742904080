"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.globalOptions = exports.constructors = exports.models = void 0;
const constants_1 = require("./constants");
/** Models Map */
exports.models = new Map();
/** Constructors Map */
exports.constructors = new Map();
/** Global Options */
exports.globalOptions = {
  options: {
    allowMixed: constants_1.Severity.WARN
  }
};
