export const APP_DATE_FORMATS = {
	parse: {
		dateInput: 'DD MMM YYYY',
	},
	display: {
		dateInput: 'DD MMM YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
		shortDate: 'dd/MM/YYYY',
		mediumDate: 'dd MMM YYYY',
		longDate: 'dd MMM YYYY',
	},
};

export type SortDirection = 'arrow-up' | 'arrow-down';

export interface FilterArrayValue {
	name: string;
	selected: boolean;
	filter: string;
}

export interface FilterTextValue {
	name: string;
	value: string;
	filterField: string | string[];
}
