import {
	type AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	ElementRef,
	LOCALE_ID,
	type OnInit,
	type TemplateRef,
	ViewEncapsulation,
	computed,
	effect,
	inject,
	input,
	output,
	signal,
	viewChild,
} from '@angular/core';

import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSort, MatSortModule, type Sort } from '@angular/material/sort';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { issueCustomTooltipDefaults } from '@ft/lib/models';
import { FT_getPropertyValue, FT_hasProperty } from '@furnas-technology/common-library/functions';
import { BehaviorSubject, take, tap } from 'rxjs';

import { MatTable, MatTableModule } from '@angular/material/table';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatCardModule } from '@angular/material/card';
import { DynamicSizeVirtualScrollDirective } from '@ft/lib/virtual-scroll';
import { DisplayActionsCellComponent } from '../display-actions/display-actions.component';
import { DisplayCellComponent } from '../display-cell/display-cell.component';
import { DisplayImageCellComponent } from '../display-image/display-image.component';
import { EditCellComponent } from '../edit-cell/edit-cell.component';
import { FT_TableService } from '../ft-table.service';
import { ChangedTableColumnValue, ColumnFilter, TableButtonPressed, TableColumn } from '../table-column.model';
import { DataRow, TableColumnType } from '../table-generic.model';

const DEFAULT_ITEM_SIZE = 52;
const DEFAULT_HEADER_SIZE = 56;

type ValidFilterType = 'string' | 'number' | 'boolean';

const isValidFilterType = (value: unknown): value is ValidFilterType => {
	return !!(typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean');
};

type ColumnTemplateInterface = {
	type: TableColumnType;
	ref: TemplateRef<unknown>;
};

@Component({
	selector: 'ft-display-cards',
	templateUrl: './display-cards.component.html',
	styleUrls: ['./display-cards.component.scss'],
	encapsulation: ViewEncapsulation.None,
	imports: [
		DisplayActionsCellComponent,
		DisplayCellComponent,
		DisplayImageCellComponent,
		DynamicSizeVirtualScrollDirective,
		EditCellComponent,
		FontAwesomeModule,
		FormsModule,
		MatButtonModule,
		MatCardModule,
		MatSortModule,
		MatTableModule,
		NgClass,
		NgTemplateOutlet,
		ReactiveFormsModule,
		ScrollingModule,
	],
	providers: [
		{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: issueCustomTooltipDefaults },
		{ provide: LOCALE_ID, useValue: navigator.language || 'en-AU' },
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayCardsComponent<T extends object> implements OnInit, AfterViewInit {
	protected destroyRef = inject(DestroyRef);
	protected tableService = inject(FT_TableService<T>);

	tableColumns = input.required<TableColumn<T>[]>();
	data = input.required<DataRow<T>[]>();
	dataRowsChanged = input<number>(0);
	defaultCardHeight = input.required<number>();

	buttonPressed = output<TableButtonPressed<T>>();

	viewport = viewChild(CdkVirtualScrollViewport, { read: CdkVirtualScrollViewport });

	sort = viewChild(MatSort);
	tableRef = viewChild('mattable', { read: MatTable });
	tableSection = viewChild('tableSection', { read: ElementRef<HTMLElement> });

	displayedColumnNames = signal<string[]>([]);

	itemHeights = computed<number[]>(() => {
		if (!this.data().length) return [];
		const heightsArray = Array.from(Array(this.data().length).keys()).map((i) => DEFAULT_ITEM_SIZE);
		return heightsArray;
	});

	itemSize: number = DEFAULT_ITEM_SIZE;
	headerHeight: number = DEFAULT_HEADER_SIZE;

	prevSort: Sort | undefined = undefined;

	faImage = faImage;

	scrolledIndex$ = new BehaviorSubject(0);
	scrollTop$ = new BehaviorSubject<number>(0);

	updatedFilters = signal<number>(0);
	columnFilters = signal<ColumnFilter<T>[]>([]);

	/**
	 * Determine title columns
	 */
	titleColumns = computed(() => {
		// title columns specified
		if (this.tableColumns().findIndex((col) => !!col.isCardTitle) > -1) {
			return this.tableColumns().filter((col) => !!col.isCardTitle);
		}

		// potential title columns
		const columns = this.tableColumns().filter((col) => {
			if (!col.canBeCardTitle) return false;
			return !!(['text'] as TableColumnType[]).includes(col.columnType);
		});
		return columns.length ? [columns[0]] : [];
	});

	/**
	 * Determine subtitle columns
	 */
	subtitleColumns = computed(() => {
		// subtitle columns specified
		if (this.tableColumns().findIndex((col) => !!col.isCardSubtitle) > -1) {
			return this.tableColumns().filter((col) => !!col.isCardSubtitle);
		}

		// subtitle columns derived
		const titleColumns = this.titleColumns();
		const columns = this.tableColumns().filter((col) => {
			if (!col.canBeCardSubtitle) return false;
			if (titleColumns && titleColumns.findIndex((x) => x.name === col.name) > -1) return false;
			return !!(['text'] as TableColumnType[]).includes(col.columnType);
		});
		return columns.length > 1 ? [columns[1]] : [];
	});

	/**
	 * Displayable columns
	 */
	displayableColumns = computed(() => {
		return this.tableColumns().filter(
			(col) => col.name !== '_id' && col.columnType !== 'actions' && col.canBeCardContent,
		);
	});

	/**
	 * Determine content columns
	 */

	contentColumns = computed(() => {
		const titleColumns = this.titleColumns();
		const subtitleColumns = this.subtitleColumns();
		const columns = this.displayableColumns().filter((col) => {
			if (col.name === '_id' || col.columnType === 'actions') return false;
			if (!!col.excludeFromCard || col.cardTitle || col.cardSubtitle) return false;
			if (titleColumns && titleColumns.findIndex((x) => x.name === col.name) > -1) return false;
			if (subtitleColumns && subtitleColumns.findIndex((x) => x.name === col.name) > -1) return false;
			return true;
		});
		return columns;
	});

	actionColumns = computed(() => {
		const columns = this.tableColumns().filter(
			(col) => col.name !== '_id' && col.columnType === 'actions' && !col.excludeFromCard,
		);
		return columns;
	});

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);

		/**
		 * Monitor for changes to datarows
		 */
		effect(() => {
			if (this.data() || this.data().length) {
				this.updateViewport();
			}
		});

		/**
		 * Monitor for changes to layout and screen size
		 */
		effect(() => {
			if (this.tableService.screenWidthHeight()) {
				this.updateViewport();
			}
		});
	}

	ngOnInit() {
		this.viewport()?.scrollToIndex(0);
	}

	ngAfterViewInit(): void {
		/**
		 * Force remeasure after content renders
		 */
		setTimeout(() => {
			if (this.viewport) {
				this.viewport()?.checkViewportSize();
			}
		});

		// const viewportSize = this.viewport()?.getViewportSize();
		// console.debug(`${this.constructor.name} - ngAfterViewInit - getViewportSize=${viewportSize}`);
		// setTimeout(() => {
		// 	if (!viewportSize) {
		// 		this.viewport()?.checkViewportSize();
		// 		window.dispatchEvent(new Event('resize'));
		// 	}
		// }, 0);
	} // end ngAfterViewInit

	trackByFn(index: number, item: DataRow<T>): string {
		return item._id ?? index.toFixed();
	}

	private updateLayout(adjust = 0): void {
		if (this.tableSection() && this.tableSection instanceof ElementRef) {
			const width = this.tableSection()?.nativeElement.offsetWidth ?? 0 + adjust;
			const height = this.tableSection()?.nativeElement.offsetHeight ?? 0 + adjust;
		}
	}

	getRowHeight(viewport: CdkVirtualScrollViewport, query: string): number {
		if (!viewport) return DEFAULT_ITEM_SIZE;

		let itemSize = DEFAULT_ITEM_SIZE;
		const nativeElement = viewport.elementRef.nativeElement;

		// process first row
		const firstRow = nativeElement.querySelector(query) as HTMLTableRowElement;
		if (firstRow) {
			itemSize = firstRow.offsetHeight ?? firstRow.clientHeight ?? DEFAULT_ITEM_SIZE;
		}

		// process all rows
		let totalHeight = 0;
		let totalRows = 0;
		const allRows = nativeElement.querySelectorAll(query);

		// biome-ignore lint/complexity/noForEach: <explanation>
		allRows.forEach((row) => {
			const rx = row as HTMLTableRowElement;
			const rh = rx.offsetHeight ?? rx.clientHeight ?? DEFAULT_ITEM_SIZE;
			totalHeight += rh;
			totalRows += 1;
		});

		const avgItemSize = Math.round(totalRows ? totalHeight / totalRows : 0);
		if (avgItemSize) itemSize = avgItemSize;
		return itemSize;
	}

	tableContentChanged(event: unknown) {
		const nativeElement = this.viewport()?.elementRef.nativeElement;
		this.updateLayout();
	}

	tableTrack(index: number, row: unknown) {
		if (FT_hasProperty(row, '_id')) {
			const value = FT_getPropertyValue(row, '_id');
			if (value || typeof value === 'number') return value;
		}
		return index;
	}

	editRow(dataRow: DataRow<T> | undefined) {
		if (dataRow?.['_id']) {
			dataRow['isEdit'] = true;
		} else if (dataRow) {
			dataRow['isEdit'] = false;
		}
	}

	addRow() {
		const newRow = {
			id: '',
		};
		// this.dataSource.data = [newRow, ...this.dataSource.data];
	}

	removeRow(id: string) {
		// this.buttonPressed.emit({ type: 'delete', _id: id });
	}

	selectAll(event: MouseEvent) {}

	updateViewport() {
		this.viewport()?.checkViewportSize();
		const viewportSize = this.viewport()?.getViewportSize();
		console.debug(`${this.constructor.name} - updateViewport - getViewportSize=${viewportSize}`);
	}

	onScrollEnd(event: Event) {
		this.viewport()
			?.elementScrolled()
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				take(1),
				tap((x) => {
					// nothing to see here
				}),
			)
			.subscribe();
	}

	onScroll(event: Event) {
		const target = event.target as HTMLElement;
		const scrollTop = target?.scrollTop ?? 0;
	}

	onScrolledIndexChange(scrolledIndex: number) {
		this.scrolledIndex$.next(scrolledIndex);
	}

	tooltipValue(column: TableColumn<T>, element: DataRow<T>) {
		const value = column.tooltipValue(element);
		return value;
	}

	onActionClicked(evt: TableButtonPressed<T>) {
		console.debug(`${this.constructor.name} - onActionClicked - evt=`, evt);
		if (evt) this.buttonPressed.emit(evt);
	}

	onChangedColumn(changedColumn: ChangedTableColumnValue<T>) {
		// if (!changedColumn.rowId) return;
		// this.changedColumn.emit(changedColumn);
	}
}
