import { Injectable, inject } from '@angular/core';
import { DataService, type GetFile } from '@ft/lib/data-lib';
import { Base64File, EmptyObject, FT_LogError, IsValidBase64Image } from '@furnas-technology/common-library/functions';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { Observable, catchError, finalize, from, map, of, switchMap } from 'rxjs';

export const MaxPixels = 1200;
export const ImageRatio = 100;
export const ImageQuality = 100;

interface IFile {
	lastModified: '';
	lastModifiedDate: EmptyObject;
	name: '';
	size: '';
	type: '';
	webkitRelativePath: '';
}

const InitBase64File: Base64File = {
	name: '',
	type: '',
	size: 0,
	b64File: '',
};

@Injectable({ providedIn: 'root' })
export class ImageUploadService {
	dataService = inject(DataService);
	imageCompress = inject(NgxImageCompressService);

	constructor() {}

	getImage(file: File): Observable<File | undefined> {
		if (!file) return of(undefined);
		if (!file.type.match(/image/)) return of(undefined);

		const reader: FileReader = new FileReader();
		reader.readAsDataURL(file);

		return of(undefined);
	} // end getImage

	getImageFromUrl(url: string): Observable<unknown> {
		const body = { url: url };
		return this.dataService.post<unknown>({ path: '/image', data: body });
	}

	getFileData(file: File) {
		// build file reader
		const reader: FileReader = new FileReader();

		// monitor progress
		reader.onprogress = (evt): void => {
			this.updateProgress(evt);
		};

		// monitor error
		reader.onerror = (evt): void => {
			this.errorHandler(evt);
		};

		reader.onload = (evt): void => {
			this.loadHandler(evt);
		};

		reader.readAsDataURL(file);
	} // end getFileData

	loadHandler(evt: ProgressEvent<FileReader>) {
		const fBase64 = evt.target?.result;
		return fBase64;
	}

	// evt.loaded and evt.total are ProgressEvent properties
	updateProgress(evt: ProgressEvent) {
		if (evt.lengthComputable && evt.total) {
			const loaded = evt.loaded / evt.total;
			if (loaded < 1) {
				// Increase the prog bar length
				// style.width = (loaded * 200) + "px";
			}
		}
	} // end updateProgress

	errorHandler(evt: ProgressEvent<FileReader>) {
		console.error(`❌ ${this.constructor.name} - event=`, evt);
		if (evt.target?.error?.name === 'NotReadableError') {
			// The file could not be read
		}
	} // end errorHandler

	/**
	 * Upload file as base64
	 */
	convertFileToBase64(file: File | undefined, notify = false, imageMaxPixels = 0): Observable<Base64File> {
		const base64File = { ...InitBase64File, name: file?.name ?? '', type: file?.type ?? '', size: file?.size ?? 0 };

		if (!file) return of(base64File);

		const reader = new FileReader();

		const fileObservable$ = new Observable<string>((observer) => {
			reader.onload = (event: ProgressEvent<FileReader>) => {
				observer.next(event?.target?.result as string);
				observer.complete();
			};

			reader.onerror = (err: unknown) => {
				FT_LogError(err, this.constructor.name, `convertFileToBase64`);
				observer.next('');
				observer.complete();
			};

			reader.onprogress = (event: ProgressEvent<FileReader>) => {
				if (event.lengthComputable) {
					const progress: number = Math.round((event.loaded / event.total) * 100);
				}
			};

			reader.readAsDataURL(file);
		});

		// return file as base64 and compressed if requested
		return fileObservable$.pipe(
			switchMap((base64String) => {
				if (typeof base64String === 'string') {
					// compress if requested
					if (file.type.match(/image/i) && imageMaxPixels) {
						const imageString = base64String.replace(/^data:image\/[a-z]+;base64,/, '');

						const compressedFile = this.compressFile(imageString, imageMaxPixels).pipe(
							map((b64String) => {
								base64File.b64File = b64String;
								return base64File;
							}),
						);

						return compressedFile;
					} else {
						base64File.b64File = base64String.toString();

						return of(base64File);
					}
				} else {
					return of(base64File);
				}
			}),
			catchError((err: unknown) => {
				FT_LogError(err, this.constructor.name, `convertFileToBase64`);
				return of(base64File);
			}), // Handle errors
		);
	} // end convertFileToBase64

	/**
	 * compress images stored as Base64
	 */
	compressFile(image: string, maxDimension: number = MaxPixels): Observable<string> {
		try {
			// ensure image exists
			if (!image || !IsValidBase64Image(image)) return of(image);

			console.debug(`${this.constructor.name} - compressFile - maxDimension=${maxDimension}, image=`, image);

			const bytesBeforeCompress = this.imageCompress.byteCount(image);
			const compressedImagePromise = this.imageCompress.compressFile(
				image,
				DOC_ORIENTATION.Default,
				ImageRatio,
				ImageQuality,
				maxDimension,
				maxDimension,
			);

			return from(compressedImagePromise).pipe(
				map((data: string) => {
					if (typeof data === 'string') {
						const bytesAfterCompress = this.imageCompress.byteCount(data);
						return bytesAfterCompress < bytesBeforeCompress ? data : image;
						// return data;
					} else {
						return '';
					}
				}),
				catchError((err: unknown) => {
					FT_LogError(err, this.constructor.name, `compressFile`);
					return of('');
				}),
				finalize(() => {
					console.debug(`${this.constructor.name} - compressFile - finalize - maxDimension=${maxDimension} `);
				}),
			);
		} catch (err: unknown) {
			FT_LogError(err, this.constructor.name, `compressFile`);
			return of(image);
		}
	} // end compressFile

	downloadPdf(pdfUrl: string): Observable<GetFile | undefined> {
		if (!pdfUrl) return of(undefined);

		const readFile$ = this.dataService.getFile(pdfUrl, false);

		return readFile$.pipe(
			map((res: GetFile | undefined) => {
				return res;
			}),
			catchError((err: unknown) => {
				FT_LogError(err, this.constructor.name, `downloadPdf`);
				return of(undefined);
			}),
		);
	} // end downloadPdf

	convertArrayBufferToBase64(buffer: ArrayBuffer): string {
		try {
			const binary = String.fromCharCode(...new Uint8Array(buffer));
			const base64String = btoa(binary);
			return base64String;
		} catch (err: unknown) {
			FT_LogError(err, this.constructor.name, `convertArrayBufferToBase64`);
			return '';
		}
	} // end convertArrayBufferToBase64

	convertBlobToBase64(blob: Blob): Observable<string> {
		const result$ = new Observable((subscriber) => {
			const reader = new FileReader();

			reader.readAsDataURL(blob);

			reader.onloadend = () => {
				if (reader.result) {
					const base64String: string = reader.result as string;
					subscriber.next(base64String.split(',')[1]); // Remove data URI prefix
					subscriber.complete();
				} else {
					subscriber.error(new Error('Error reading blob'));
				}
			};

			reader.onerror = (error) => {
				subscriber.error(error);
			};
		}).pipe(
			map((dataURI: unknown) => {
				return String(dataURI).split(',')[1];
			}), // Extract base64 data
			catchError((err: unknown) => {
				FT_LogError(err, `ConvertBlobToBase64`);
				return of('');
			}), // Handle errors
		);

		return result$;
	} // end convertBlobToBase64
}
