"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ModelOptions = exports.modelOptions = void 0;
const constants_1 = require("./internal/constants");
const utils_1 = require("./internal/utils");
/**
 * Define Options for the Class
 * @param options The Options to set
 * @example Example:
 * ```ts
 * @modelOptions({ schemaOptions: { timestamps: true } })
 * class ClassName {}
 *
 * // The default Class "TimeStamps" can be used for type information and options already set
 * ```
 */
function modelOptions(options) {
  return target => {
    (0, utils_1.assignGlobalModelOptions)(target);
    (0, utils_1.assignMetadata)(constants_1.DecoratorKeys.ModelOptions, options, target);
  };
}
exports.modelOptions = modelOptions;
exports.ModelOptions = modelOptions;
