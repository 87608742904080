import { HttpContextToken, HttpErrorResponse } from '@angular/common/http';
import { ApolloError } from '@apollo/client/core';

import { timer } from 'rxjs';

export const ApiCreateProp = 'createResult';
export const ApiMutateProp = 'mutateResult';

export const RETRY_LIMIT = 3;
export const RETRY_WAIT_MILLISECONDS = 1200;

export const BYPASS_LOG = new HttpContextToken(() => false);
export const IS_PUBLIC_API: HttpContextToken<boolean> = new HttpContextToken<boolean>(() => false);
export const IS_API_KEY = new HttpContextToken<boolean>(() => false);
export const IS_BASIC_AUTH = new HttpContextToken<boolean>(() => false);

export const UPDATEDAT_ASC = 'UPDATEDAT_ASC';
export const UPDATEDAT_DESC = 'UPDATEDAT_DESC';
export const _ID_ASC = '_ID_ASC';
export const _ID_DESC = '_ID_DESC';

export type DeleteResult = {
	status: 'success' | 'not found' | 'failed';
	documentId: string;
	documentDesc: string;
	message: string;
};

export const FT_RetryConfig = {
	count: RETRY_LIMIT,
	delay: (err: unknown) => {
		if (err instanceof HttpErrorResponse) {
			// throw errors
			if (err.status >= 400 && err.status <= 499) {
				console.debug(`FT_RetryConfig - HttpErrorResponse=${err.status}-${err.statusText} - Will not retry`);
				throw err;
			} else {
				console.debug(`FT_RetryConfig - HttpErrorResponse=${err.status}-${err.statusText} - will retry url=${err.url}`);
			}
		} else if (err instanceof ApolloError) {
			console.debug(`FT_RetryConfig - ApolloError=${err.message} - not retrying`);
			throw err;
		} else {
			console.debug(`FT_RetryConfig - UNKNOWN ERROR type not retrying, err=`, err);
			throw err;
		}
		console.debug(`FT_RetryConfig - ***retrying error in ${RETRY_WAIT_MILLISECONDS}ms`);
		return timer(RETRY_WAIT_MILLISECONDS);
	},
};

export const CognitoUrls = {
	validate: (authUrl: string, accessToken: string) => `${authUrl}validate?&user_info=1`,
	token: (authUrl: string, redirect: string, code: string, attrs: boolean) =>
		`${authUrl}token?redirect_uri=${redirect}&code=${code}&user=${attrs}`,
	refresh: (authUrl: string, attrs: boolean) => `${authUrl}refresh${attrs ? `?user=true` : ''}`,
	initapp: (authUrl: string, loginCallback: string, logoutCallback: string) =>
		`${authUrl}initApp?loginCallback=${loginCallback}&logoutCallback=${logoutCallback}`,
	userinfo: (authUrl: string) => `${authUrl}userinfo`,
	logout: (authUrl: string, logoutUrl: string, currentUrl: string) =>
		`${authUrl}logout?redirect_uri=${encodeURIComponent(currentUrl + logoutUrl)}`,
};
