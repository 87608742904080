import { DateTime } from 'luxon';
import { FT_DefaultLanguage } from '../models/index.js';
import { FT_LogError } from './logging-functions.js';
export const DefaultLanguage = FT_DefaultLanguage;
export const InvalidDate = '*Invalid Date*';
export const nowUTCISO = () => {
  return DateTime.utc().toISO();
};
export const yearRegExp = /\d{4}/g;
export const dateRegExp = /\s|\s+|\-|\/|\,|\.|\:|\;/g;
export const yearOnlyExp = /^\d{4}$/;
export const yearMonthOnlyExp = /^\d{4}-\d{2}$/;
export const isoDateRegExp = /^\d{4}-\d{2}-\d{2}/;
export const FT_MAT_DATE_FORMATS = {
  parse: {
    dateInput: 'D'
  },
  display: {
    dateInput: 'D',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};
export const DateRanges = ['Previous Years', 'Earlier This Year', 'Currently In Progress', 'Next 3 Months', 'Later This Year', 'Next Year'];
export const FT_generateDateFromStringInLocalDateFormat = dateString => {
  if (!dateString) return null;
  const utcTime = new Date(dateString);
  return new Date(utcTime.toString());
};
export const FT_minutesToMiliseconds = minutes => {
  if (!minutes) return 0;
  return minutes * 60 * 1000;
};
export const FT_isValidISODate = dateString => {
  // Implement your ISO 8601 validation logic here, using regular expressions or libraries
  // This example uses a simple check for basic format (YYYY-MM-DD)
  return /^\d{4}-\d{2}-\d{2}$/.test(dateString);
};
/**
 * retruns true if the value could be a month
 */
export const couldBeMonth = iValue => {
  const value = String(iValue).trim().toLowerCase();
  if (value.length < 3) return 0;
  const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
  for (const month of months) {
    if (value.includes(month)) {
      return months.findIndex(item => item === month) + 1;
    }
  }
  return 0;
};
export const isFT_DisplayDateFormat = value => {
  if (!value) return false;
  return !!(value === 'medium' || value === 'iso' || value === 'short' || value === 'long' || value === 'timestamp');
};
export const epochToIso = epoch => {
  if (!epoch) return '';
  return new Date(epoch * 1000).toISOString();
};
/**
 * returns a year if it exists in a string
 */
export const FT_getYear = value => {
  if (!value) return 0;
  const matches = value.match(yearRegExp);
  if (matches) {
    const year = matches[0] ?? 0;
    if (Number.isNaN(Number(year))) {
      return 0;
    } else {
      return Number(year);
    }
  } else {
    return 0;
  }
};
export const FT_ConvertDateToUTC = inputDate => {
  try {
    const formats = ['yyyy MM dd', 'MMM dd yyyy', 'dd MMM yyyy'];
    const iString = typeof inputDate === 'string' ? inputDate.replace(/\s|\s+|\-|\.|\//g, ' ') : '';
    let dateTime;
    for (const format of formats) {
      dateTime = DateTime.fromFormat(iString, format);
      if (dateTime.isValid) {
        break;
      }
    }
    // return if invalid date
    if (!dateTime || !dateTime.isValid) {
      return {
        date: DateTime.invalid(`[${inputDate}] is not a valid`),
        utcDateString: ''
      };
    }
    const parts = dateTime.toString().split('+');
    // biome-ignore lint/style/useTemplate: <explanation>
    const utcDateTimeString = parts[0] + '+00:00';
    const utcDateString = utcDateTimeString.slice(0, 10);
    const utcDate = DateTime.fromISO(`${utcDateString}T00:00:00`, {
      zone: 'UTC'
    });
    return {
      date: utcDate,
      utcDateString: utcDateString
    };
  } catch (err) {
    const errMessage = FT_LogError(err, `FT_ConvertDateToUTC`);
    return {
      date: DateTime.invalid(errMessage),
      utcDateString: ''
    };
  }
};
export const FT_getISOStringUTC = isoString => {
  const input = isoString || new Date().toISOString();
  const dateTime = DateTime.fromISO(input, {
    zone: 'UTC'
  });
  if (dateTime.isValid) {
    return dateTime.toISO({
      includeOffset: false
    });
  } else {
    return '';
  }
};
export const CorrectPartialDate = inputValue => {
  if (inputValue === null || inputValue === undefined) return '';
  if (typeof inputValue === 'string' && inputValue.length === 0) return '';
  if (typeof inputValue === 'string') {
    const ivt = inputValue.trim();
    // has text
    if (inputValue.trim().match(/[abcdefghijklmnopqrstuv]/i)) {
      // year only
    } else if (ivt.match(/^\d{4}$/i)) {
      return ivt;
    } else if (ivt.match(/^\d{4}\-\d{2}$/i)) {
      return ivt;
      // not a text value
    }
  }
  const {
    utcDateString
  } = FT_ConvertDateToUTC(inputValue);
  return utcDateString;
};
// takes a string that is a date and converts to an iso date string
export const CorrectDate = inputValue => {
  if (inputValue === null || inputValue === undefined) return '';
  if (typeof inputValue === 'string' && inputValue.length === 0) return '';
  const {
    utcDateString
  } = FT_ConvertDateToUTC(inputValue);
  return utcDateString;
};
