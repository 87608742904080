import { ComponentType } from '@angular/cdk/portal';
import { Inject, Injectable, InjectionToken, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActiveFilterService } from '@ft/lib/active-filter-lib';
import {} from '@ft/lib/core-lib';
import { DeleteDocumentRequest, EditDocumentRequest } from '@ft/lib/crud-helper-lib';
import { FT_DocumentService } from '@ft/lib/document-lib';
import { MethodNotImplemented, SignalStoreType } from '@ft/lib/signal-store-lib';
import { NotifyService } from '@ft/lib/snackbar-lib';
import {} from 'rxjs';

const SIGNAL_STORENAME = new InjectionToken<string>('SIGNAL_STORENAME');
const VIEW_COMPONENT = new InjectionToken<ComponentType<unknown>>('VIEW_COMPONENT');

export type GenericStoreServiceInput<T> = {
	signalStoreName: string;
	signalStore: SignalStoreType<T>;
	viewComponent: ComponentType<unknown> | undefined;
	editComponent: ComponentType<unknown> | undefined;
	resultProperty: string | undefined;
	nameProperty: keyof T | undefined;
};

export const STORE_SERVICE_INPUT = new InjectionToken<GenericStoreServiceInput<unknown>>('STORE_SERVICE_INPUT');

@Injectable({
	providedIn: 'root',
})
export class GenericStoreService<T> {
	protected afs = inject(ActiveFilterService);
	protected dialog = inject(MatDialog);
	protected notifyService = inject(NotifyService);
	private signalStore = inject(this.storeServiceInput.signalStore);
	private documentService = inject(FT_DocumentService);

	private signalStoreName = this.storeServiceInput.signalStoreName ?? '';
	private viewComponent = this.storeServiceInput.viewComponent;
	private editComponent = this.storeServiceInput.editComponent;
	// private resultProperty = this.storeServiceInput.resultProperty ?? '';
	private nameProperty = this.storeServiceInput.nameProperty ?? '';

	/**
	 * Surface store values
	 */
	isLoading = this.signalStore.isLoading;
	isLoaded = this.signalStore.isLoaded;

	lastEntityUpdated = this.signalStore.lastEntityUpdated;

	totals = this.signalStore.totals;
	documentsLoaded = this.signalStore.documentsLoaded;
	documents = this.signalStore.documents;
	filteredDocumentsNotOmitted = this.signalStore.filteredDocumentsNotOmitted;
	filteredDocumentsBySearchBarOnly = this.signalStore.filteredDocumentsBySearchBarOnly;

	constructor(@Inject(STORE_SERVICE_INPUT) private storeServiceInput: GenericStoreServiceInput<T>) {
		if (!this.storeServiceInput) {
			console.error(`❌ ${this.constructor.name} - constructor - storeServiceInput is undefined`);
			return;
		}
		console.debug(`${this.constructor.name} - constructor - signalStoreName=${this.storeServiceInput.signalStoreName}`);
	}

	methodNotImplemented(method: string, reason: string): void {
		console.error(`❌ ${this.constructor.name} - MethodNotImplemented - method=${method}, reason=${reason}`);
	}

	/**
	 * view document dialog
	 */
	viewDocument(id: string, closeOthers: boolean = true): void {
		if (!this.viewComponent) {
			this.methodNotImplemented('viewDocument', 'viewComponent is undefined');
			return;
		}
		this.documentService.viewDocument<T>(this.viewComponent, id, closeOthers);
	}

	/**
	 * CRUDs
	 */
	addDocument(request: EditDocumentRequest): void {
		const requestComponent = request.editComponentOverride ?? this.editComponent;
		if (!requestComponent) {
			this.methodNotImplemented('addDocument', 'editComponent (for addDocument) is undefined');
			return;
		}

		// const createRowFunction = (rowData: Partial<T>) => this.signalStore.createRow(rowData);
		const createRowFunction = this.signalStore.createRow;
		this.documentService.addDocument<T>(requestComponent, request, createRowFunction);
	}

	editDocument(request: EditDocumentRequest): void {
		const requestComponent = request.editComponentOverride ?? this.editComponent;
		if (!requestComponent) {
			MethodNotImplemented('editDocument', 'editComponent is undefined');
			return;
		}

		const createRowFunction = this.signalStore.createRow;
		const mutateRowFunction = this.signalStore.mutateRow;
		this.documentService.editDocument<T>(requestComponent, request, createRowFunction, mutateRowFunction);
	}

	deleteDocument(request: DeleteDocumentRequest): void {
		const nameProperty = this.nameProperty as keyof T;
		this.documentService.deleteDocument<T>(
			request,
			nameProperty,
			this.signalStore.entities(),
			this.signalStore.deleteRow,
		);
	}
} // end class
