import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	Inject,
	OnInit,
	computed,
	effect,
	inject,
	signal,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCameraRetro, faSpinner, faXmark } from '@fortawesome/free-solid-svg-icons';

import { MatButtonModule } from '@angular/material/button';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgClass } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AuthStore } from '@ft/lib/auth-lib';
import { AppSpinner } from '@ft/lib/directives';
import { NotifyService } from '@ft/lib/snackbar-lib';
import { CloseIconButtonComponent } from '@furnas-technology/angular-library/components';
import {
	Base64File,
	FT_LogError,
	First20Chars,
	IsValidUrl,
	UploadAcceptTypes,
	UploadType,
} from '@furnas-technology/common-library/functions';
import { DOC_ORIENTATION, DataUrl, NgxImageCompressService } from 'ngx-image-compress';
import { Observable, catchError, finalize, from, map, of, switchMap, take, tap } from 'rxjs';
import { ImageUploadService, MaxPixels } from '../image-upload.service';
import { FT_PdfViewerComponent } from '../pdf-viewer/pdf-viewer.component';

const MAX_MEGABYTE = 1;

export type UploadResult = {
	clear?: boolean;
	imageSrc?: string;
	name?: string;
	fileType?: string;
};

export interface FT_UploadDialogData {
	title: string;
	message: string;
	position?: unknown;
	imageSrc?: string;
	uploadType: UploadType;
	maxFileSize?: number;
}

type UploadResponse = {
	image: DataUrl;
	orientation: DOC_ORIENTATION;
	fileName: string;
};

@Component({
	selector: 'ft-upload-dialog',
	templateUrl: './upload-dialog.component.html',
	styleUrls: ['./upload-dialog.component.scss'],
	imports: [
		AppSpinner,
		NgClass,
		CloseIconButtonComponent,
		FontAwesomeModule,
		FormsModule,
		MatIconModule,
		MatButtonModule,
		MatDialogModule,
		MatInputModule,
		DragDropModule,
		MatFormFieldModule,
		MatFormField,
		MatInputModule,
		ReactiveFormsModule,
		FT_PdfViewerComponent,
	],
	providers: [ImageUploadService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FT_UploadDialog implements OnInit {
	destroyRef = inject(DestroyRef);
	dialogRef = inject(MatDialogRef<FT_UploadDialog>);
	fb = inject(FormBuilder);
	imageUpload = inject(ImageUploadService);
	notifyService = inject(NotifyService);
	imageCompress = inject(NgxImageCompressService);

	authStore = inject(AuthStore);

	faCameraRetro = faCameraRetro;
	faXmark = faXmark;
	faSpinner = faSpinner;

	form = new FormGroup({});

	reference: string = '';
	iconClasses = '';
	headerTitle = signal<string>('');
	message = '';
	baseImageUrl = '';
	accept = '';
	uploadType = signal<UploadType>('');
	errorMessage: string = '';

	maxFileSize = signal<number>(MAX_MEGABYTE);

	displayedImageSrc = signal('');
	imageSrcFirst20 = First20Chars(this.displayedImageSrc());

	selectedFile = signal<File | undefined>(undefined);
	filename$ = computed(() => (this.selectedFile() ? this.selectedFile()?.name : ''));
	filetype$ = computed(() => (this.selectedFile() ? this.selectedFile()?.type : ''));

	isExternalUrl = computed<boolean>(() => {
		if (!this.displayedImageSrc()) return false;
		return !(this.displayedImageSrc().startsWith('data') || this.displayedImageSrc().startsWith(this.baseImageUrl));
	});

	isDataOrUrl = computed<boolean>(() => {
		if (!this.displayedImageSrc()) return false;
		return !!(this.displayedImageSrc().startsWith('data') || this.displayedImageSrc().startsWith(this.baseImageUrl));
	});

	constructor(@Inject(MAT_DIALOG_DATA) public uploadDialog: FT_UploadDialogData) {
		console.debug(`${this.constructor.name} - constructor`);
		effect(() => {
			this.baseImageUrl = this.authStore.selectApiAppConfig()?.imageUrl ?? '';
		});
	}

	ngOnInit() {
		if (this.uploadDialog?.title) this.headerTitle.set(this.uploadDialog.title);
		if (this.uploadDialog?.message) this.message = this.uploadDialog.message;
		if (this.uploadDialog?.maxFileSize) this.maxFileSize.set(this.uploadDialog.maxFileSize);

		this.displayedImageSrc.set(this.uploadDialog?.imageSrc ?? '');

		// save image source
		this.reference = this.uploadDialog?.imageSrc ?? '';

		// set accept
		this.uploadType.set(this.uploadDialog.uploadType);
		this.accept = UploadAcceptTypes(this.uploadType()).join(',') || 'image/*';
	}

	onSubmit() {
		// determine if this is a valid image
		const valid = this.isExternalUrl() ? true : this.validateUrl(this.displayedImageSrc());
		if (valid) {
			this.dialogRef.close({
				clear: !this.displayedImageSrc(),
				imageSrc: this.displayedImageSrc() ?? '',
				name: this.filename$(),
				fileType: this.filetype$(),
			});
		}
	}

	confirm() {
		// this.dialogRef.close(FT_ConfirmResult.Confirm);
	}

	close() {
		this.dialogRef.close();
	}

	onClose() {
		this.dialogRef.close();
	}

	validateUrl(url: string): boolean {
		if (!this.accept) return true;
		const parts = this.accept.split(',');

		const imageUrl = url.split('?')[0];

		for (const fileType of parts) {
			const typeParts = fileType.split('/');

			if (fileType.startsWith('.')) {
				// nothing to see here
			}
		}

		return true;
	}

	/**
	 * Upload file and process - converting to base64 and compressing if too large
	 */
	uploadFile() {
		const fileInput = document.createElement('input');
		fileInput.type = 'file';
		fileInput.accept = this.accept;
		fileInput.click();
		fileInput.onchange = (event: Event) => {
			const files = (event?.target as HTMLInputElement)?.files;
			if (!files) {
				this.selectedFile.set(undefined);
				return;
			}

			const selectedFile: File = files[0];

			if (selectedFile && this.validateUpload(selectedFile.type ?? '')) {
				try {
					this.errorMessage = '';
					this.selectedFile.set(selectedFile);
					this.processUploadedFile(selectedFile);
				} catch (err: unknown) {
					FT_LogError(err, this.constructor.name, `uploadFile`);
				} finally {
					// nothing to see here
				}
			} else {
				this.errorMessage = `File type ${selectedFile?.type ?? '*blank'} does not match valid types of ${this.accept}`;
			}
		};
	}

	processUploadedFile(file: File): void {
		const process$ = this.convertToBase64(file).pipe(
			switchMap((uploadResponse: UploadResponse | null) => {
				console.debug(`${this.constructor.name} - processUploadedFile - uploadResponse=${!!uploadResponse}`);
				if (!uploadResponse) return of(null);
				return this.compressImage(uploadResponse);
			}),
		);

		process$
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				take(1),
				finalize(() => {
					console.debug(`${this.constructor.name} - processUploadedFile - FINALIZE`);
				}),
			)
			.subscribe((uploadResponse: UploadResponse | null) => {
				console.debug(`${this.constructor.name} - processUploadedFile - uploadResponse=`, uploadResponse);
				if (uploadResponse) {
					this.displayedImageSrc.set(uploadResponse.image);
				} else {
					this.displayedImageSrc.set('');
				}
			});
	}

	validateUpload(fileType: string): boolean {
		console.debug(`${this.constructor.name} - fileType=${fileType}, accept=${this.accept}`);

		if (!fileType) return false;
		if (!this.accept) return true;

		const fileParts = fileType.split('/');
		const filePfx = fileParts[0];
		const fileSfx = fileParts.length > 1 ? fileParts[1] : '';

		// fileType=image/png, accept=image/*
		const acceptParts = this.accept.split(',');
		for (const accept of acceptParts) {
			console.debug(`${this.constructor.name} - validateUpload - fileparts fileType=${fileType} to ${accept}`);

			const acceptParts = accept.split('/');
			const acceptPfx = acceptParts[0];
			const acceptSfx = acceptParts.length > 1 ? acceptParts[1] : '';

			if (acceptSfx && acceptSfx !== '*') {
				if (acceptSfx.toLowerCase() === fileSfx.toLowerCase() && filePfx.toLowerCase() === acceptPfx.toLowerCase()) {
					return true;
				}
			} else {
				if (filePfx.toLowerCase() === acceptPfx.toLowerCase()) {
					return true;
				}
			}
		}

		return false;
	}

	/**
	 * Convert uploaded file to base64
	 */
	convertToBase64(file: File): Observable<UploadResponse | null> {
		console.debug(`${this.constructor.name} - convertToBase64 - file=`, file);

		return this.imageUpload.convertFileToBase64(file, false, MaxPixels).pipe(
			takeUntilDestroyed(this.destroyRef),
			map((value: Base64File) => {
				console.debug(
					`${this.constructor.name} - convertToBase64 - value=${!!value}, first60=${First20Chars(value.b64File, 60)}...`,
				);

				let imageString = '';
				if (value) {
					imageString = value.b64File.startsWith('data:')
						? value.b64File
						: `data:${value.type};base64,${value.b64File}`;
				}

				return { image: imageString, orientation: DOC_ORIENTATION.Default, fileName: value.name };
			}),
			catchError((err: unknown) => {
				FT_LogError(err, this.constructor.name, `convertToBase64`);
				return of(null);
			}),
			finalize(() => {
				console.debug(`${this.constructor.name} - convertToBase64 - finalize - file=`, file);
			}),
		);
	}

	/**
	 * Convert uploaded file to base64
	 */
	compressImage(uploadResponse: UploadResponse): Observable<UploadResponse> {
		const mbLength = Math.round((uploadResponse.image.length / (1024 * 1024)) * 100) / 100;
		console.debug(
			`${this.constructor.name} - compressImage - uploadResponse.file=${uploadResponse.fileName}, maxMB=${this.maxFileSize()}, lengthMB=${mbLength}`,
		);

		return from(this.imageCompress.getImageWithMaxSizeAndMetas(uploadResponse, this.maxFileSize())).pipe(
			tap((result) => {
				const newImageLength = Math.round((result.image.length / (1024 * 1024)) * 100) / 100;
				console.debug(
					`${this.constructor.name} - compressImage - result.file=${result.fileName}, maxMB=${this.maxFileSize()}, lengthMB=${mbLength}, compressedMB=${newImageLength}`,
				);
			}),
		);
	}

	onClear() {
		this.errorMessage = '';
		this.displayedImageSrc.set('');
	}

	onChange(event: Event) {
		const imageSrc = (event.target as HTMLInputElement).value ?? '';
		console.debug(`${this.constructor.name} - onChange - imageSrc=${imageSrc}`);

		if (IsValidUrl(imageSrc)) {
			if (this.uploadType() === 'pdf') {
				const downloadedFile$ = this.imageUpload.downloadPdf(imageSrc);
				downloadedFile$
					.pipe(
						take(1),
						tap((file) => {
							if (file) {
								this.displayedImageSrc.set(file.base64File);
							}
						}),
					)
					.subscribe();
			} else {
				this.displayedImageSrc.set(imageSrc);
			}
		} else {
			this.displayedImageSrc.set('');
			if (imageSrc) {
				this.notifyService.warn(`Please enter a valid URL for a ${this.uploadType()}\n${imageSrc} is not valid`);
			}
		}
	}

	uploadImageLoaded(event: Event) {
		const target = event.target as HTMLImageElement;
		console.debug(`${this.constructor.name} - uploadImageLoaded - target=`, target);
		// if (target.complete) {
		// 	this.displayedImageSrc.set(target.src);
		// }
	}

	getBase64String(imageString: string): string {
		const parts = imageString.split(';');
		if (!parts.length) return imageString;
		if (parts.length < 2) return imageString;
		if (!parts[1].startsWith('base64,')) return imageString;
		const base64part = parts[1].slice(7);
		return base64part;
	}
}
