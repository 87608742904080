import { type TemplateRef } from '@angular/core';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

import {
	EmptyObject,
	FT_LogError,
	FT_NestedKeyOf,
	FT_NestedValue,
	FT_SortValue,
	FT_getErrorMessage,
	FT_getNestedValue,
	FT_isEmpty,
} from '@furnas-technology/common-library/functions';
import { type Observable, Subscription, isObservable } from 'rxjs';
import {
	FilterEmpty,
	FilterTrue,
	RequiresFilterValue,
	getWidthString,
	isDefined,
	isDisplayableValue,
	mapStyleString,
} from './table-functions';
import {
	AlignCenterColumnTypes,
	AlignEndColumnTypes,
	AlignStartColumnTypes,
	ColumnSearchValue,
	CouldBeObservableDisplayableValue,
	DataRow,
	DefaultTypeHeights,
	DefaultTypeWidths,
	DefaultWidths,
	DisplayableValue,
	FILTER_COMPARISON,
	HeightValue,
	NestedKeyOf,
	NumericSearchFunctions,
	RawCellValue,
	SearchFunctions,
	StyleObject,
	TableAlign,
	TableButton,
	TableColumnType,
	TableRowId,
	WidthValue,
} from './table-generic.model';

// Options for the select filter for columns and selection fields
export type ColumnFilterOption = {
	value: DisplayableValue;
	option?: string;
};

export const isColumnFilterOption = (obj: unknown): obj is ColumnFilterOption => {
	return typeof obj === 'object' && obj !== null && 'value' in obj;
};

export type ChangedTableColumnValue<T extends object> = {
	rowId: TableRowId;
	column: TableColumn<T>;
	value: DisplayableValue | DisplayableValue[];
};

export interface TableButtonPressed<T extends object> {
	tableAction: TableButton;
	column: TableColumn<T>;
	_id: string | string;
	dataRow?: DataRow<T>;
	event?: MouseEvent;
}

export type TableRowAndColumn<T extends object> = {
	rowId: TableRowId;
	column: TableColumn<T>;
};

export type ColumnFilter<T extends object> = {
	column: TableColumn<T>;
	comparison: FILTER_COMPARISON | '';
	value: ColumnSearchValue | string[];
};

export type FT_Join<K, P> = K extends string | number ? (P extends string | number ? `${K}.${P}` : never) : never;
export type FT_NestedPaths<T, Prev = never> = {
	[K in keyof T]: T[K] extends object ? (Prev extends K ? never : K | FT_Join<K, FT_NestedPaths<T[K], K>>) : K;
}[keyof T];

export class TableColumn<T extends object> {
	/**
	 * core column properties
	 */
	name!: string;
	columnType: TableColumnType = 'text';
	databaseProp: keyof T | NestedKeyOf<T> | undefined;
	isDatabaseProp: boolean = false;
	hidden?: boolean;
	elementId: string = '';
	editable?: boolean;
	required?: boolean;
	hideShow?: boolean;

	inputValue?: T[keyof T];
	customTemplate?: string;
	templateRef?: TemplateRef<unknown>;

	/**
	 * Label and placeholder
	 */
	label?: string;
	title?: string;
	placeHolder?: string;

	/**
	 * Sticky specifications
	 */

	sticky?: boolean = false;
	stickyEnd?: boolean = false;
	footer?: string;

	/**
	 * styles and formatting
	 */
	format?: 'date-ago' | 'date-iso' | (string & {});
	_style?: EmptyObject;
	headerStyles?: StyleObject;
	_headerStyle?: EmptyObject;
	cellStyles?: StyleObject;
	wrapText?: boolean;
	wrapHeaderText?: boolean;
	preWrap?: 'normal' | 'nowrap' | 'pre' | 'pre-line' | 'pre-wrap' | 'break-spaces';
	preWrapHeader?: boolean;
	align?: TableAlign;
	headerAlign?: TableAlign;

	/**
	 * Icons
	 */
	leadingIcon?: IconProp;
	trailingIcon?: IconProp;

	/**
	 * button definition
	 */
	tableButtons?: TableButton[];

	/**
	 * Column filter options - filtering coluns
	 */

	columnFilterSelect?: boolean = false;
	columnFilterOptions?: ColumnFilterOption[]; // Options for the select filter
	columnFilterMultiple: boolean = false; // Allow multiple selections

	/**
	 * Field selection options
	 */
	selectOptions?: unknown[];
	selectMultiple: boolean = false;
	selectNone: boolean = false;

	/**
	 * Tooltip specifications
	 */
	tooltipColumn?: keyof T | FT_NestedPaths<T> | string | ((record: T) => string) | undefined;
	tooltipColumnIsNested: boolean = false;
	tooltipText?: string;

	/**
	 * Flex and size specifications
	 */
	flex?: number;
	width?: WidthValue;
	maxWidth?: WidthValue;
	minWidth?: WidthValue;
	height?: HeightValue;
	maxHeight?: HeightValue;
	minHeight?: HeightValue;

	sortable?: boolean;
	resizable?: boolean;
	autoHeaderHeight?: boolean;
	sortIfArray?: boolean;
	arraySeparator: string = ',';

	/**
	 * Value specifications
	 */
	valueFn?: (record: DataRow<T>) => Observable<DisplayableValue> | DisplayableValue;
	functionValue: DisplayableValue | undefined;
	subscription?: Subscription[];
	valueConstant?: DisplayableValue;

	/**
	 * Sort specifications
	 */
	sortFieldProperty?: keyof T | FT_NestedPaths<T> | undefined;
	sortFieldPropertyIsNested: boolean = false;

	fontSize?:
		| string
		| 'larger'
		| 'smaller'
		| 'small'
		| 'x-small'
		| 'xx-small'
		| 'medium'
		| 'large'
		| 'x-large'
		| 'xx-large'
		| 'xxx-large';

	/* <relative-size> values */

	/**
	 * Card options
	 */
	cardTitle?: boolean;
	cardSubtitle?: boolean;
	excludeFromCard?: boolean;
	cardDefaultValue?: string;

	constructor(init: Partial<TableColumn<T>>) {
		Object.assign(this, init);
		this.subscription = undefined;
		this.scrubColumn();
	}

	scrubColumn(): void {
		this.label = this.label ?? this.title ?? this.name ?? '';
		this.hidden = this.name ? !!this.hidden : true;

		// determine if database or virtual
		if (this.isDatabaseColumn) {
			this.isDatabaseProp = true;
			if (!this.databaseProp) this.databaseProp = this.name as NestedKeyOf<T> | keyof T;
		} else {
			this.databaseProp = undefined;
			this.isDatabaseProp = false;
		}

		// determine tooltip column
		this.tooltipColumnIsNested = !!(
			this.tooltipColumn &&
			typeof this.tooltipColumn !== 'function' &&
			String(this.tooltipColumn).includes('.')
		);
		this.sortFieldPropertyIsNested = !!(this.sortFieldProperty && String(this.sortFieldProperty).includes('.'));

		this.editable = this.name ? !!this.editable : false;

		this.sticky = !!this.sticky;
		this.stickyEnd = !!this.stickyEnd;

		this.wrapText = this.wrapText == null ? true : !!this.wrapText;
		this.wrapHeaderText = this.wrapHeaderText == null ? true : !!this.wrapHeaderText;

		/**
		 * Prewrap header if set or contains line breaks
		 */
		if (typeof this.preWrapHeader !== 'boolean' || this.preWrapHeader === undefined) {
			if (
				typeof this.label === 'string' &&
				this.label.length > 0 &&
				(this.label.includes('\r') || this.label.includes('\n'))
			) {
				this.preWrapHeader = true;
			} else {
				this.preWrapHeader = false;
			}
		}

		/**
		 * Default elementId
		 */
		this.elementId = this.elementId || this.name;

		/**
		 * Table buttons array
		 */
		if (this.tableButtons) {
			if (!Array.isArray(this.tableButtons)) {
				this.tableButtons = [this.tableButtons];
			}
		} else {
			this.tableButtons = [];
		}

		/**
		 * Default alignment
		 */
		if (!this.align) {
			if (['text'].includes(this.columnType)) {
				this.align = 'start';
			} else if (['number', 'currency', 'decimal'].includes(this.columnType)) {
				this.align = 'end';
			} else if (['date', 'partialDate'].includes(this.columnType)) {
				this.align = 'center';
			} else if (['boolean'].includes(this.columnType)) {
				this.align = 'center';
			} else if (['image'].includes(this.columnType)) {
				this.align = 'center';
			} else if (['actions'].includes(this.columnType)) {
				this.align = 'center';
			}
		}

		if (!this.headerAlign) this.headerAlign = this.align;

		/**
		 * Filters
		 */
		if (!!this.columnFilterSelect && !this.columnFilterOptions) {
			throw Error(
				`${this.constructor.name}: TableColumn with filteSelect true requires the 'filterValue' property to be set`,
			);
		}
	} // end scrubColumn

	get isNestedProp(): boolean {
		return this.databaseProp && typeof this.databaseProp === 'string' ? this.databaseProp.includes('.') : false;
	}

	get hasTooltip(): boolean {
		return !!(this.tooltipColumn || this.tooltipText);
	}

	get mainClass(): string {
		return `${this.columnType.toLowerCase()}-cell`;
	}

	get templateRefName(): string {
		return `${this.columnType.toLowerCase()}TemplateRef`;
	}

	get commonCellStyle(): StyleObject {
		const styles: StyleObject = {};

		if (this.wrapText === false && !this.preWrap) {
			styles['white-space'] = `nowrap`;
			styles['text-wrap'] = `nowrap`;
		}

		if (this.preWrap) {
			styles['white-space'] = this.preWrap;
		}

		if (!this.sticky && !this.stickyEnd) {
			styles['left'] = '0px';
		}

		// image set maxImageHeight
		if (this.columnType === 'image') {
			const imageWidth = this.width ? this.width : '60px';
			const imageWidthPx = typeof imageWidth === 'number' ? `${imageWidth}px` : imageWidth;

			styles['max-height'] = '100%';
			styles['width'] = imageWidthPx;
			styles['max-width'] = imageWidthPx;
			styles['margin'] = 'auto';
		} else {
			const widthStyles = this.widthStyles(
				this.width,
				this.maxWidth,
				this.minWidth,
				DefaultTypeWidths[this.columnType],
			);
			Object.assign(styles, widthStyles);
		}

		return styles;
	}

	get cellStyle(): StyleObject {
		const styles: StyleObject = this.commonCellStyle;

		if (this.preWrap) {
			styles['white-space'] = this.preWrap;
		} else if (!this.wrapText) {
			styles['white-space'] = `nowrap`;
		}

		if (this.wrapText === false && !this.preWrap) {
			styles['white-space'] = `nowrap`;
			styles['text-wrap'] = `nowrap`;
		}

		if (this.fontSize) {
			styles['font-size'] = this.fontSize;
		}

		if (this.cellStyles) {
			Object.assign(styles, this.cellStyles);
		}

		return styles;
	}

	get isActionColumn(): boolean {
		return !!(this.columnType === 'actions');
	}

	get isDatabaseColumn(): boolean {
		return !((!this.name && !this.databaseProp) || this.isActionColumn || this.valueFn || this.valueConstant);
	}

	/**
	 * Card fields and options
	 */

	get isCardTitle(): boolean {
		if (!!this.excludeFromCard) return false;
		return !!this.cardTitle;
	}

	get isCardSubtitle(): boolean {
		if (!!this.excludeFromCard || this.isCardTitle) return false;
		return !!this.cardSubtitle;
	}

	get canBeCardTitle(): boolean {
		if (!!this.excludeFromCard) return false;
		if (this.name === '_id' || this.columnType === 'actions') return false;
		if (this.isCardTitle) return true;
		return true;
	}

	get canBeCardSubtitle(): boolean {
		if (!!this.excludeFromCard || this.isCardTitle) return false;
		if (this.name === '_id' || this.columnType === 'actions') return false;
		if (this.isCardSubtitle) return true;
		return true;
	}

	get canBeCardContent(): boolean {
		if (!!this.excludeFromCard) return false;
		if (this.isCardTitle || this.isCardSubtitle) return false;
		return true;
	}

	get imageStyle(): StyleObject {
		const styles: StyleObject = {};
		const imageHeight = this.maxHeight ? this.maxHeight : DefaultTypeHeights['image'];
		styles['max-height'] = typeof imageHeight === 'number' ? `${imageHeight}px` : imageHeight;
		styles['width'] = '100%';
		styles['height'] = '100%';
		styles['object-fit'] = 'contain';
		return styles;
	}

	get headerStyle(): StyleObject {
		const styles: StyleObject = this.commonCellStyle;

		if (this.preWrapHeader) {
			styles['white-space'] = 'pre-line';
		} else if (!this.wrapHeaderText) {
			styles['white-space'] = `nowrap`;
		}

		if (this.headerStyles) {
			Object.assign(styles, this.headerStyles);
		}

		return styles;
	}

	get headerStyleString(): string {
		return mapStyleString(this.headerStyle);
	}

	get isObservable(): boolean {
		return isObservable(this.displayableValue);
	}

	widthStyles(
		width: WidthValue,
		maxWidth: WidthValue,
		minWidth: WidthValue,
		defaultWidths: DefaultWidths,
	): StyleObject {
		const res: StyleObject = {};

		// if nothing defined, use default values
		if (!isDefined(width) && !isDefined(maxWidth) && !isDefined(minWidth)) {
			if (isDefined(defaultWidths.width)) res['width'] = getWidthString(defaultWidths.width);
			if (isDefined(defaultWidths.minWidth)) res['min-width'] = getWidthString(defaultWidths.minWidth);
			if (isDefined(defaultWidths.maxWidth)) res['max-width'] = getWidthString(defaultWidths.maxWidth);
			return res;
		}

		// load passed in values
		if (isDefined(width)) res['width'] = getWidthString(width);
		if (isDefined(minWidth)) res['min-width'] = getWidthString(minWidth);
		if (isDefined(maxWidth)) res['max-width'] = getWidthString(maxWidth);
		return res;
	}

	get cellClass(): string {
		const classList: string[] = [];
		classList.push(`ft-${this.columnType}`);
		classList.push(`ft-cell-class`);

		if (!!this.stickyEnd) {
			classList.push('sticky-end');
		} else {
			classList.push('not-sticky-end');
		}

		if (this.align) {
			classList.push(`align-${this.align}`);
		} else if (AlignCenterColumnTypes.includes(this.columnType)) {
			classList.push(`align-center`);
		} else if (AlignStartColumnTypes.includes(this.columnType)) {
			classList.push(`align-start`);
		} else if (AlignEndColumnTypes.includes(this.columnType)) {
			classList.push(`align-end`);
		}

		const classString = classList.join(' ');
		return classString;
	}

	get cellDefaultWidth(): number {
		if (this.width && !Number.isNaN(Number(this.width))) {
			return Number(this.width);
		}
		if (this.minWidth && !Number.isNaN(Number(this.minWidth))) {
			return Number(this.minWidth);
		}
		if (this.maxWidth && !Number.isNaN(Number(this.maxWidth))) {
			return Number(this.maxWidth);
		}
		return 0;
	}

	get cellStyleString(): string {
		return mapStyleString(this.cellStyle);
	}

	get headerClass(): string {
		const classList: string[] = [];
		classList.push(`ft-${this.columnType}`);
		classList.push(`ft-header-class`);

		if (!!this.sticky) classList.push('sticky-column');
		if (!!this.stickyEnd) classList.push('sticky-end');

		if (this.headerAlign) {
			classList.push(`align-${this.headerAlign}`);
		} else if (this.align) {
			classList.push(`align-${this.align}`);
		} else if (AlignCenterColumnTypes.includes(this.columnType)) {
			classList.push(`align-center`);
		} else if (AlignStartColumnTypes.includes(this.columnType)) {
			classList.push(`align-start`);
		} else if (AlignEndColumnTypes.includes(this.columnType)) {
			classList.push(`align-end`);
		}

		const classString = classList.join(' ');
		return classString;
	}

	get isSortable(): boolean {
		return this.columnType !== 'actions' && this.columnType !== 'boolean';
	}

	get isFilterable(): boolean {
		return this.columnType !== 'actions';
	}

	get isSelectFilter(): boolean {
		return !!this.columnFilterSelect;
	}

	get hasFunction(): boolean {
		return !!this.valueFn;
	}

	displayableValue(record: DataRow<T>): CouldBeObservableDisplayableValue {
		try {
			if (this.isDatabaseColumn) {
				return this.cellPropValue(record) as DisplayableValue;
			} else if (this.valueFn) {
				return this.valueFn(record);
			} else if (this.valueConstant) {
				return this.valueConstant;
			} else {
				return '*undefined*';
			}
		} catch (err: unknown) {
			FT_LogError(err, this.constructor.name, `cellValueFn`);
			return '*error*';
		}
	}

	rawValue(record: T): RawCellValue<T> {
		const value = this.getFieldValueFromRecord(record, this.databaseProp as FT_NestedKeyOf<T>, this.isNestedProp);
		return value;
	}

	cellPropValue(record: T): DisplayableValue {
		try {
			if (!this.databaseProp) return '';

			const value = this.rawValue(record);
			if (isDisplayableValue(value)) return value;
			if (value === null || value === undefined) return '';
			return this._adjustValue(value);
		} catch (err: unknown) {
			return `cellPropValue: ${FT_getErrorMessage(err)}`;
		}
	}

	private _adjustValue(value: RawCellValue<T>): DisplayableValue {
		try {
			// process as an array
			if (Array.isArray(value)) {
				const joinChar = this.arraySeparator ? this.arraySeparator : ', ';
				if (this.sortIfArray) {
					const sortValue: Array<FT_SortValue> = [...value];
					return sortValue
						.filter((x) => String(x ?? '') !== '')
						.sort((a, b) => (String(a ?? '') > String(b ?? '') ? 1 : -1))
						.join(joinChar);
				} else {
					return (value as Array<FT_SortValue>).join(joinChar);
				}
			}

			// process as an object
			if (typeof value === 'object' && value !== null) {
				return JSON.stringify(value);
			}

			// return as a string
			return String(value);
		} catch (err: unknown) {
			return `_adjustValue: ${FT_getErrorMessage(err)}`;
		}
	}

	tooltipValue(record: T): string {
		if (!this.hasTooltip) return '';
		if (!this.tooltipColumn) return '';

		if (typeof this.tooltipColumn === 'function') {
			return this.tooltipColumn(record);
		}

		const value = this.getFieldValueFromRecord(
			record,
			this.tooltipColumn as FT_NestedKeyOf<T>,
			this.tooltipColumnIsNested,
		);

		if (FT_isEmpty(value)) return '';
		if (Array.isArray(value)) return value.map((x) => String(x)).join(', ');
		return String(value);
	}

	sortFieldPropertyValue(record: T): string {
		if (!this.sortFieldProperty) return '';

		const value = this.getFieldValueFromRecord(
			record,
			this.sortFieldProperty as FT_NestedKeyOf<T>,
			this.sortFieldPropertyIsNested,
		);

		if (FT_isEmpty(value)) return '';
		if (Array.isArray(value)) return value.map((x) => String(x)).join(', ');
		return String(value);
	}

	cellStringValue(record: T): string {
		const value = this.cellPropValue(record);
		return value !== null && value !== undefined ? String(value) : '';
	}

	cellDisplayValue(record: T): string {
		const value = FT_getNestedValue(record, this.name as FT_NestedKeyOf<T>);
		return String(value);
	}

	cellFilter(record: DataRow<T>, filter: ColumnFilter<T>): { omitted: boolean; selected: boolean } {
		// ignore blank or undefined filterValue
		const defaultSelected = { omitted: false, selected: true };
		if (!filter.comparison) return defaultSelected;
		if (RequiresFilterValue(filter.comparison) && (filter.value == null || filter.value === '')) return defaultSelected;

		// get cellValue
		const cellValue = this.displayableValue(record);

		// initialize result
		const result = { omitted: false, selected: false };

		if (RequiresFilterValue(filter.comparison)) {
			result.selected = ValueSearchController(filter.comparison, cellValue, filter);
		} else if (filter.comparison === 'empty') {
			result.selected = FilterEmpty(cellValue);
		} else if (filter.comparison === 'notEmpty') {
			result.selected = !FilterEmpty(cellValue);
		} else if (filter.comparison === 'true') {
			result.selected = FilterTrue(cellValue);
		} else if (filter.comparison === 'false') {
			result.selected = !FilterTrue(cellValue);
		} else {
			throw new Error(`${this.constructor.name} - cellFilter - failed to account for operations=${filter.comparison}`);
		}

		return result;
	}

	curRecord(record: DataRow<T>): DataRow<T> {
		return record;
	}

	getFieldValueFromRecord(
		record: T,
		key: FT_NestedKeyOf<T> | keyof T,
		isNested: boolean,
	): T[keyof T] | FT_NestedValue<T, FT_NestedKeyOf<T>> {
		const value = isNested ? FT_getNestedValue(record, key as FT_NestedKeyOf<T>) : record[key as keyof T];
		return value;
	}
} // end class

const ValueSearchController = <T extends object>(
	searchType: FILTER_COMPARISON,
	fieldValue: CouldBeObservableDisplayableValue,
	filter: ColumnFilter<T>,
): boolean => {
	// return if no filtervalue
	if (FT_isEmpty(filter.value)) return true;

	/**
	 * process filtervalues
	 */
	if (Array.isArray(filter.value)) {
		if (!filter.value.length) return true;
		for (let i = 0; i < filter.value.length; i++) {
			if (ValueSearch(searchType, fieldValue, filter.value[i])) return true;
		}
		return false;
	} else if (filter.value !== undefined) {
		return ValueSearch(searchType, fieldValue, filter.value);
	} else {
		return true;
	}
};

const ValueSearch = (
	searchType: FILTER_COMPARISON,
	fieldValue: CouldBeObservableDisplayableValue,
	filterValue: ColumnSearchValue,
): boolean => {
	if (!searchType) return true;
	const filterValueType = typeof filterValue;
	const fieldValueType = typeof fieldValue;

	console.debug(
		`ValueSearch - searchType=${searchType}, fieldValue=${fieldValue}, filterValue=${filterValue}, typeof filterValue=${filterValueType}, typeof fieldValue=${fieldValueType}`,
	);

	if (filterValueType === 'string') {
		const sr = SearchFunctions[searchType] ? SearchFunctions[searchType](fieldValue, filterValue) : false;
		return sr;
	} else if (filterValueType === 'number') {
		return NumericSearchFunctions[searchType] ? NumericSearchFunctions[searchType](fieldValue, filterValue) : false;
	} else if (filterValueType === 'boolean') {
		return filterValue === !!fieldValue;
	} else {
		return SearchFunctions[searchType] ? SearchFunctions[searchType](fieldValue, filterValue) : false;
	}
};
