import {
	FT_DisplayDateFormat,
	FT_displayDate,
	FT_isStringOrNumber,
	FT_objectKeys,
	isFT_DisplayDateFormat,
} from '@furnas-technology/common-library/functions';

import { DateAgoPipe } from '@furnas-technology/angular-library/pipes';

import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import {
	ColumnSearchValue,
	CouldBeObservableDisplayableValue,
	DisplayableValue,
	FILTER_COMPARISON,
	FILTER_OPTIONS,
	FilterOperations,
	StyleObject,
	TableBooleanDisplay,
	TableColumnType,
	TableFilters,
	WidthValue,
} from './table-generic.model';

export const TableBooleanDisplayValues: TableBooleanDisplay[] = [
	{ value: true, label: 'Yes' },
	{ value: false, label: 'No' },
];

export const isDisplayableValue = (value: unknown): value is DisplayableValue => {
	return !!(
		typeof value === 'string' ||
		typeof value === 'number' ||
		typeof value === 'boolean' ||
		value instanceof Date
	);
};

export const RequiresFilterValue = (comparisonValue: FILTER_COMPARISON | '' | null | undefined): boolean => {
	if (!comparisonValue) return true;
	return FILTER_OPTIONS.find((option) => option.comparison === comparisonValue)?.requiresValue ?? false;
};

export const IsActiveFilter = (
	comparisonValue: FILTER_COMPARISON | '' | null | undefined,
	filterValue: ColumnSearchValue | null | undefined | string[],
): boolean => {
	const requiresValue = RequiresFilterValue(comparisonValue);
	const hasValue = !!(filterValue !== '' && filterValue !== null && filterValue !== undefined);
	const result = !!(!requiresValue || hasValue);
	return result;
};

export const HasCellFilter = (columnType: TableColumnType, filterName: TableFilters): boolean => {
	if (!columnType) return false;
	const filterOperation = FilterOperations[filterName];
	if (!filterOperation) return false;

	// exclude all
	if (filterOperation.excludeForType === '*all') return false;

	// includes
	if (filterOperation.includeForType !== '*all' && filterOperation.includeForType.length > 0) {
		return filterOperation.includeForType.findIndex((item) => item === columnType) > -1;
	}

	// excludes
	if (Array.isArray(filterOperation.excludeForType) && filterOperation.excludeForType.length > 0) {
		if (filterOperation.excludeForType.findIndex((item) => item === columnType) > -1) {
			return false;
		}
	}

	// select by default
	return true;
};

export const FT_formatCurrency = (value: DisplayableValue, locale: string): string => {
	if (!FT_isStringOrNumber(value)) return '';

	const pipe = new CurrencyPipe(locale);
	return pipe.transform(value) ?? '';
};

export const FT_formatDateAgo = (value: DisplayableValue): string => {
	if (!FT_isStringOrNumber(value)) return '';

	const pipe = new DateAgoPipe();
	return pipe.transform(String(value));
};

export const FT_formatDecimal = (value: DisplayableValue, format: string, locale: string): string => {
	if (!FT_isStringOrNumber(value)) return '0';
	const pipe = new DecimalPipe(locale);
	// {{3.6 | number: '1.0-0'}}
	const displayFormat = format || '1.0-0';
	return pipe.transform(value, displayFormat) ?? '';
};

export const FT_formatBoolean = (value: DisplayableValue): string => {
	return !!value ? 'Yes' : 'No';
};

export const FT_formatDate = (
	value: DisplayableValue,
	format: FT_DisplayDateFormat | (string & {}),
	locale: string,
): string => {
	try {
		if (!FT_isStringOrNumber(value)) return String(value);

		if (isFT_DisplayDateFormat(format)) {
			return FT_displayDate(value, format, locale);
		} else {
			const pipe = new DatePipe(locale);
			const displayFormat = format || 'dd-MMM-yy';
			const formattedDate = pipe.transform(value, displayFormat) ?? '';
			return formattedDate;
		}
	} catch (err: unknown) {
		return 'Error';
	}
};

export const FT_formatPartialDate = (value: DisplayableValue, format: FT_DisplayDateFormat = 'medium'): string => {
	try {
		if (!FT_isStringOrNumber(value)) return String(value);

		const asDate = FT_displayDate(value, format);
		return asDate || String(value);
	} catch (err: unknown) {
		return 'Error';
	}
};

export const isDefined = (value: WidthValue): boolean => {
	if (value === undefined || value === '' || value === null) return false;
	return true;
};

export const getWidthString = (value: WidthValue): string => {
	if (value === undefined) return 'unset';

	const suffix = Number.isNaN(Number(value)) ? '' : 'px';
	return `${value}${suffix}`;
};

export const mapStyleString = (style: StyleObject): string => {
	if (!style) return '';
	const keys = FT_objectKeys(style);
	return keys.map((key: keyof StyleObject) => `${key}: ${style[key]}`).join() || '*EMPTY';
};

export const FilterEmpty = (fieldValue: CouldBeObservableDisplayableValue): boolean => {
	if (fieldValue == null) {
		return true;
	} else if (typeof fieldValue === 'string') {
		return fieldValue.toLowerCase().trim() === '';
	} else if (typeof fieldValue === 'number') {
		return fieldValue === 0;
	} else if (Array.isArray(fieldValue)) {
		return fieldValue.length === 0;
	} else {
		return !fieldValue;
	}
};

export const FilterTrue = (fieldValue: CouldBeObservableDisplayableValue): boolean => {
	return !!fieldValue;
};
