"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapValueToSeverity = exports.parseENV = exports.setGlobalOptions = void 0;
const constants_1 = require("./internal/constants");
const data_1 = require("./internal/data");
const errors_1 = require("./internal/errors");
const utils_1 = require("./internal/utils");
const logSettings_1 = require("./logSettings");
/**
 * Set Typegoose's global Options
 */
function setGlobalOptions(options) {
  (0, utils_1.assertion)(!(0, utils_1.isNullOrUndefined)(options) && typeof options === 'object', () => new errors_1.ExpectedTypeError('options', 'object', options));
  logSettings_1.logger.info('"setGlobalOptions" got called with', options);
  for (const key of Object.keys(options)) {
    data_1.globalOptions[key] = Object.assign({}, data_1.globalOptions[key], options[key]);
  }
  logSettings_1.logger.info('new Global Options:', options);
}
exports.setGlobalOptions = setGlobalOptions;
/**
 * Parse Typegoose Environment Variables and apply them
 */
function parseENV() {
  logSettings_1.logger.info('"parseENV" got called');
  // do nothing if no process variable existing
  if (typeof process === 'undefined' || (0, utils_1.isNullOrUndefined)(process?.env)) {
    return;
  }
  const options = {
    globalOptions: {},
    options: {
      allowMixed: process.env.TG_ALLOW_MIXED && process.env.TG_ALLOW_MIXED in constants_1.Severity ? mapValueToSeverity(process.env.TG_ALLOW_MIXED) : data_1.globalOptions.options?.allowMixed
    }
  };
  setGlobalOptions(options);
}
exports.parseENV = parseENV;
/**
 * Maps strings to the number of "Severity"
 * -> This function is specifically build for "Severity"-Enum
 * @throws {Error} if not in range of the "Severity"-Enum
 * @example
 * ```ts
 * mapValueToSeverity("WARN") === 1
 * mapValueToSeverity("1") === 1
 * // now internal use
 * mapValueToSeverity(1) === 1
 * ```
 * @param value The Value to translate
 * @internal
 */
function mapValueToSeverity(value) {
  (0, utils_1.assertion)(value in constants_1.Severity, () => new Error(`"value" is not in range of "Severity"! (got: ${value})`));
  if (typeof value === 'number') {
    return value;
  }
  return mapValueToSeverity(constants_1.Severity[value]);
}
exports.mapValueToSeverity = mapValueToSeverity;
