import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export const FT_getNestedValueV2 = <T>(obj: T, key: string): unknown => {
	const keys = key.split('.');
	let current: unknown = obj;

	// Limit to 5 levels of depth
	if (keys.length > 5) return undefined;

	for (let i = 0; i < keys.length && i < 5; i++) {
		if (current && typeof current === 'object' && !Array.isArray(current) && keys[i] in current) {
			current = (current as Record<string, unknown>)[keys[i]];
		} else {
			return undefined; // Return undefined if the key doesn't exist
		}
	}
	return current;
};

export const isIconDefinition = (obj: unknown): obj is IconDefinition => {
	if (typeof obj === 'object' && obj !== null) {
		return !!('prefix' in obj && 'iconName' in obj);
	}

	return false;
};
