import { DateTime } from 'luxon';
import { DefaultLanguage, FT_isValidISODate, InvalidDate, couldBeMonth, dateRegExp, isoDateRegExp, yearMonthOnlyExp, yearOnlyExp, yearRegExp } from './date-utility-functions.js';
import { FT_LogError } from './logging-functions.js';
import { removeStartingCrap, removeTrailingCrap } from './string-functions.js';
export const currentDateTime = DateTime.now();
export const currentIsoDate = DateTime.now().toISODate();
export const fourWeeksFromNow = DateTime.now().plus({
  weeks: 4
}).toISODate();
export const threeMonthsFromNow = DateTime.now().plus({
  months: 3
}).toISODate();
export const currentYear = DateTime.now().year;
export const nextYear = DateTime.now().plus({
  years: 1
}).year;
/**
 * Return a date object from a string
 */
export const FT_getDate = iValue => {
  const messages = [];
  try {
    // scrub value
    if (!iValue) return {
      isoDateString: '',
      dateStringType: 'empty',
      dateTime: undefined
    };
    let value = String(iValue).trim();
    value = removeTrailingCrap(value);
    value = removeStartingCrap(value);
    if (!value) return {
      isoDateString: '',
      dateStringType: 'empty',
      dateTime: undefined
    };
    // if iso date format already, use iso portion only
    if (value.length > 10 && value.match(isoDateRegExp)) value = value.substring(0, 10);
    // determine if value is already isoDate
    if (typeof value === 'string') {
      const formats = ['yyyy-MM-dd', 'd MMM yyyy', 'd MMMM yyyy', 'yyyy-MM-d', 'yy-M-d', 'MM/yyyy', 'MMM/yyyy'];
      let isoDateCheck = undefined;
      for (const fmt of formats) {
        isoDateCheck = DateTime.fromFormat(value.substring(0, 10), fmt);
        if (isoDateCheck?.isValid) {
          break;
        }
      }
      if (isoDateCheck?.isValid) {
        return {
          isoDateString: isoDateCheck.toFormat('yyyy-MM-dd'),
          dateStringType: 'iso-date',
          dateTime: isoDateCheck
        };
      }
    } // end typeof value = string
    // skip if no year exists
    const matches = value.match(yearRegExp);
    if (!matches) return {
      isoDateString: '',
      dateStringType: 'invalid',
      dateTime: undefined
    };
    // check if we have a year only
    if (value.match(yearOnlyExp)) {
      return {
        isoDateString: value,
        dateStringType: 'year-only',
        dateTime: undefined
      };
    } else if (value.match(yearMonthOnlyExp)) {
      return {
        isoDateString: value,
        dateStringType: 'year-month',
        dateTime: undefined
      };
    }
    // split entered value into pieces
    const parts = value.split(dateRegExp).map(x => x.replace(dateRegExp, '').trim()).filter(x => x);
    messages.push(`value=${value} : (${parts.length}) parts=${parts.join(':')}`);
    // one part of less, invalid
    if (parts.length <= 1) return {
      isoDateString: '',
      dateStringType: 'invalid',
      dateTime: undefined
    };
    // find year part, skip if not found
    const yearIndex = parts.findIndex(item => item.trim().match(yearOnlyExp));
    if (yearIndex === -1) return {
      isoDateString: '',
      dateStringType: 'invalid',
      dateTime: undefined
    };
    // get month and day parts
    const yearPart = parts[yearIndex].trim();
    messages.push(`yearIndex=${yearIndex}, yearPart=${yearPart}`);
    parts.splice(yearIndex, 1);
    // check for text month
    let monthNumber = 0;
    let monthIndex = -1;
    // check by month as name
    monthIndex = parts.findIndex(item => couldBeMonth(String(item)) > 0);
    if (monthIndex > -1) {
      monthNumber = couldBeMonth(parts[monthIndex]);
    } else {
      monthIndex = parts.findIndex(item => !Number.isNaN(Number(item)) && Number(item) >= 1 && Number(item) <= 12);
      if (monthIndex > -1) {
        monthNumber = Number(parts[monthIndex]);
      }
    }
    const monthPart = Number.isNaN(monthNumber) ? '' : monthNumber.toString().padStart(2, '0');
    messages.push(`monthIndex=${monthIndex}, monthPart=${monthPart}`);
    if (monthIndex > -1) parts.splice(monthIndex, 1);
    // if only year and month, return
    if (parts.length === 0) {
      if (monthNumber > 0) {
        const yearMonthString = `${yearPart}-${monthPart}`;
        return {
          isoDateString: yearMonthString,
          dateStringType: 'year-month',
          dateTime: undefined
        };
      } else {
        return {
          isoDateString: '',
          dateStringType: 'invalid',
          dateTime: undefined
        };
      }
    }
    // if we have day, month and year
    const dayIndex = parts.findIndex(item => item.match(/\d{1,2}/));
    if (dayIndex > -1) {
      const dayNumber = Number(parts[dayIndex]);
      if (dayNumber >= 1 && dayNumber <= 31) {
        const dayPart = dayNumber.toString().padStart(2, '0');
        messages.push(`dayIndex=${dayIndex}, dayPart=${dayPart}`);
        const iString = `${yearPart}-${monthPart}-${dayPart}`;
        const iResult = DateTime.fromFormat(iString, 'yyyy-MM-dd');
        if (iResult.isValid) {
          const isoDateString = iResult.toFormat(`yyyy-MM-dd`);
          return {
            isoDateString: isoDateString,
            dateStringType: 'iso-date',
            dateTime: iResult
          };
        }
      }
    }
    // error if we reached this point
    return {
      isoDateString: '',
      dateStringType: 'invalid',
      dateTime: undefined
    };
  } catch (err) {
    console.error(`❌ FT_getDate - INVALID DATE - ${messages.join('\r\n')}`);
    return {
      isoDateString: '',
      dateStringType: 'invalid',
      dateTime: undefined
    };
  }
};
/**
 * compute date display
 */
export const FT_displayDate = (dateString, format = 'short', localeParm = DefaultLanguage) => {
  try {
    // if nothing or non-string, return blank
    if (!dateString) return '';
    let inputString = '';
    if (typeof dateString === 'string') {
      inputString = dateString;
    } else if (typeof dateString === 'number') {
      const iDate = new Date(inputString);
      inputString = iDate ? iDate.toISOString().substring(0, 10) : '';
    } else if (dateString instanceof Date) {
      inputString = dateString.toISOString().substring(0, 10);
    }
    // get date
    const {
      isoDateString,
      dateStringType,
      dateTime
    } = FT_getDate(inputString.trim());
    if (dateStringType === 'invalid') return InvalidDate;
    if (dateStringType === 'empty') return '';
    if (dateStringType === 'year-only') return isoDateString;
    if (dateStringType === 'year-month') {
      return DateTime.fromISO(`${isoDateString}-15`).toFormat('MMM yyyy');
    }
    if (!(dateTime instanceof DateTime)) return '';
    const locale = localeParm || DefaultLanguage;
    switch (format) {
      case 'short':
        return dateTime.setLocale(locale).toLocaleString(DateTime.DATE_SHORT);
      case 'iso':
        return dateTime.setLocale(locale).toISODate() ?? '';
      case 'medium':
        return dateTime.setLocale(locale).toLocaleString(DateTime.DATE_MED);
      case 'long':
        return dateTime.setLocale(locale).toLocaleString(DateTime.DATE_HUGE);
      case 'timestamp':
        return dateTime.setLocale(locale).toJSDate().toISOString();
      default:
        return dateTime.setLocale(locale).toLocaleString(DateTime.DATE_MED);
    }
  } catch (err) {
    return FT_LogError(err, `FT_displayDate`, `dateString=${dateString}, format=${format}`);
  }
};
/**
 * Returns an ISO date string if it exists in a string
 */
export const FT_getIsoDate = iValue => {
  const {
    isoDateString
  } = FT_getDate(iValue);
  return isoDateString;
};
export const ScrubDate = dateString => {
  // if no value, return blank
  if (!dateString) return '';
  // if already iso, return
  if (FT_isValidISODate(dateString)) return dateString;
  // extract ISO date string
  const {
    isoDateString,
    dateStringType
  } = FT_getDate(dateString);
  return dateStringType === 'iso-date' ? isoDateString : '';
};
export const FormatDateRange = (startDate, endDate, options, locale = DefaultLanguage) => {
  const formatOptions = options ? options : {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };
  return `${startDate.toLocaleDateString(locale, formatOptions)} - ${endDate.toLocaleDateString(locale, formatOptions)}`;
};
/**
 * returns a displayable date string from and to date string
 */
export const FT_FromToDate = (fromDateInput, toDateInput, format) => {
  // if neither from nor to, then return blank
  if (!fromDateInput && !toDateInput) return '';
  try {
    let result = '';
    const fromDate = fromDateInput && typeof fromDateInput === 'string' ? fromDateInput.slice(0, 10) : fromDateInput ?? '';
    const toDate = toDateInput && typeof toDateInput === 'string' ? toDateInput.slice(0, 10) : toDateInput ?? '';
    // if from and to are the same, return the single date
    if (fromDate && toDate && fromDate === toDate) return FT_displayDate(fromDate, format);
    // get date times for further calculations
    const fromDateTime = fromDate ? DateTime.fromISO(fromDate.slice(0, 10)) : undefined;
    const toDateTime = toDate ? DateTime.fromISO(toDate.slice(0, 10)) : undefined;
    // if both dates are valid, format accordingly as "from - to"
    if (fromDateTime?.isValid && toDateTime?.isValid) {
      if (format === 'iso') {
        result = `${fromDateTime.toISODate()} - ${fromDateTime.toISODate()}`;
      } else if (format === 'short') {
        result = `${fromDateTime.toFormat('DATE_SHORT')} - ${toDateTime.toFormat('DATE_SHORT')}`;
      } else {
        const monthFormat = format === 'long' ? 'MMMM' : 'MMM';
        const dateFormat = format === 'long' ? 'd MMMM yyyy' : 'd MMM yyyy';
        if (fromDateTime.year === toDateTime.year) {
          if (fromDateTime.month === toDateTime.month) {
            result = `${fromDateTime.day} - ${toDateTime.toFormat(dateFormat)}`;
          } else {
            result = `${fromDateTime.day} ${fromDateTime.toFormat(monthFormat)} - ${toDateTime.toFormat(dateFormat)}`;
          }
        } else {
          result = `${fromDateTime.toFormat(dateFormat)} - ${toDateTime.toFormat(dateFormat)}`;
        }
      }
    } else if (fromDateTime?.isValid) {
      // if only from date is valid, return from date
      result = FT_displayDate(fromDate, format);
    } else if (toDateTime?.isValid) {
      // if only to date is valid, return to date
      result = FT_displayDate(toDate, format);
    } else {
      result = '';
    }
    return result;
  } catch (err) {
    FT_LogError(err, `FT_FromToDate`, `fromDate=${fromDateInput}, toDate=${toDateInput}, format=${format}`);
    return '';
  }
};
export const GetFromToDatesDisplay = (fromToDates, returnAs) => {
  if (!fromToDates || !fromToDates.length) return returnAs === 'single' ? '' : [];
  const x = fromToDates.map(ftd => {
    const dateString = FT_FromToDate(ftd.fromDate ?? '', ftd.toDate ?? '', 'medium');
    const notes = ftd.notes ? String(ftd.notes).trim() : '';
    return [dateString, notes].filter(x => x).join(' : ');
  }).filter(x => x);
  if (returnAs === 'single') {
    return x.join('\n');
  } else {
    return x;
  }
};
/**
 * Get applicable date ranges based on from/to dates
 */
export const getDateRanges = (fromDate, toDate) => {
  const dateRanges = [];
  if (!fromDate && !toDate) return dateRanges;
  /**
   * Get Iso date strings -- if blank, use the other date
   */
  const fromIsoString = FT_getIsoDate(fromDate || toDate || '');
  const toIsoString = FT_getIsoDate(toDate || fromDate || '');
  if (!fromIsoString && !toIsoString) return dateRanges;
  /**
   * Get date objects - if only one is valid, use for both
   */
  let fromDateTime = DateTime.fromISO(fromIsoString);
  let toDateTime = DateTime.fromISO(toIsoString);
  if (!fromDateTime.isValid && toDateTime.isValid) fromDateTime = toDateTime;
  if (!toDateTime.isValid && fromDateTime.isValid) toDateTime = fromDateTime;
  if (!fromDateTime.isValid && !toDateTime.isValid) return dateRanges;
  const fromIsoDate = fromDateTime.toISODate() ?? '';
  const toIsoDate = toDateTime.toISODate() ?? '';
  const fromYear = fromDateTime.year;
  const toYear = toDateTime.year;
  /**
   * Prior events
   */
  if (currentDateTime > toDateTime) {
    if (currentDateTime.year === toDateTime.year) {
      dateRanges.push('Earlier This Year');
    } else {
      dateRanges.push('Previous Years');
    }
    return dateRanges;
  }
  /**
   * Currently in progres
   */
  if (fromIsoDate <= currentIsoDate && toIsoDate >= currentIsoDate) {
    dateRanges.push('Currently In Progress');
    return dateRanges;
  }
  /**
   * Starts later this year
   */
  if (fromIsoDate > threeMonthsFromNow && fromYear === currentYear) {
    dateRanges.push('Later This Year');
    return dateRanges;
  }
  /**
   * Starts in next three months (can also be next year)
   */
  if (fromIsoDate > currentIsoDate && fromIsoDate <= threeMonthsFromNow) {
    dateRanges.push('Next 3 Months');
  }
  /**
   * Starts next year
   */
  if (fromYear === nextYear) {
    dateRanges.push('Next Year');
    return dateRanges;
  }
  return dateRanges;
};
