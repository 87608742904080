import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { type Observable, catchError, map, of, take } from 'rxjs';

import { GetNotificationValue, SelectedId } from '@ft/lib/core-lib';

import type { ComponentType } from '@angular/cdk/overlay';
import { toSignal } from '@angular/core/rxjs-interop';
import { FT_LogError } from '@furnas-technology/common-library/functions';
import { FT_ConfirmDialogData, FT_ConfirmResult } from './confirm-dialog/confirm-dialog.component';
import { DeleteDialogInput, FT_DialogData, FT_DialogUpdateResult, FT_MatDialogConfig } from './dialog.model';
import { PromptService } from './prompt.service';

const DEFAULT_DIALOG_CONFIG: MatDialogConfig<unknown> = { panelClass: 'rounded-tiny', maxHeight: '85vh' };
// , maxHeight: '85vh'

@Injectable({
	providedIn: 'root',
})
export class CommonDialogService {
	private dialog = inject(MatDialog);
	private prompt = inject(PromptService);

	constructor() {}

	/**
	 * Remove active focus from the document
	 */
	public removeActiveFocus() {
		try {
			if (document.activeElement instanceof HTMLElement) {
				document.activeElement.blur();
			}
		} catch (err: unknown) {
			console.error(`❌ ${this.constructor.name} - removeActiveFocus - error=`, err);
		}
	}

	private closeOpenDialogs() {
		const openDialogs = this.dialog.openDialogs;
		for (const openDialog of openDialogs) {
			console.debug(`${this.constructor.name} - closeOpenDialogs - openDialog.id=${openDialog.id}`);
			openDialog.close();
		}
	}

	/**
	 * Open a dynamic dialog
	 */
	openDynamicDialog<T>(
		dialogComponent: ComponentType<unknown>,
		config?: MatDialogConfig<unknown> | undefined,
	): MatDialogRef<unknown, unknown> {
		this.removeActiveFocus();
		const dialogConfig = { ...DEFAULT_DIALOG_CONFIG, ...(config ?? {}) } as MatDialogConfig<T>;
		const dialogRef = this.dialog.open<unknown, unknown, unknown>(dialogComponent, dialogConfig);
		return dialogRef;
	}

	/**
	 * Open a dialog with a component
	 */
	openDialog<TComponent = unknown, TData = unknown, TResult = unknown>(
		component: ComponentType<TComponent>,
		config: FT_MatDialogConfig<TData> = {},
	): MatDialogRef<TComponent, TResult> | undefined {
		try {
			this.removeActiveFocus();
			if (config.data?.closeOthers) {
				this.closeOpenDialogs();
			}

			const dialogConfig = { ...DEFAULT_DIALOG_CONFIG, ...(config ?? {}) } as MatDialogConfig<TData>;
			const dialgRef = this.dialog.open<TComponent, TData, TResult>(component, dialogConfig);
			return dialgRef;
		} catch (err: unknown) {
			FT_LogError(err, this.constructor.name, `openDialog`);
			return undefined;
		}
	}

	/**
	 * CRUD dialogs
	 */
	viewDialog<TData>(
		id: string,
		displayComponent: ComponentType<unknown>,
		closeOthers: boolean = true,
		matConfig?: MatDialogConfig<TData> | undefined,
	): MatDialogRef<unknown, FT_DialogUpdateResult<TData>> | undefined {
		console.debug(`${this.constructor.name} - viewDialog`);
		try {
			this.removeActiveFocus();
			if (closeOthers) {
				this.closeOpenDialogs();
			}

			const configData: FT_DialogData<TData> = Object.assign({
				modeType: 'Info',
				selectedId: { id: id, timestamp: new Date().getTime() },
			});

			const dialogConfig = {
				...DEFAULT_DIALOG_CONFIG,
				...(matConfig ?? {}),
				data: configData,
			} as MatDialogConfig<TData>;

			const dialgRef = this.dialog.open<unknown, TData, FT_DialogUpdateResult<TData>>(displayComponent, dialogConfig);

			return dialgRef;
		} catch (err: unknown) {
			FT_LogError(err, this.constructor.name, `viewDialog`);
			return undefined;
		}
	}

	/**
	 * Edit record dialog
	 */
	editDialog<TData>(
		editComponent: ComponentType<unknown>,
		config: FT_DialogData<TData> = {},
		closeOthers: boolean = true,
		matConfig?: MatDialogConfig<TData> | undefined,
	): MatDialogRef<unknown, FT_DialogUpdateResult<TData>> | undefined {
		console.debug(`${this.constructor.name} - editDialog - editComponent=${editComponent.name}, config=`, config);
		try {
			this.removeActiveFocus();
			if (closeOthers) {
				this.closeOpenDialogs();
			}

			const modeType = config.modeType ?? 'Edit';
			const selectedId: SelectedId = !!config.selectedId
				? config.selectedId
				: { id: (config['id'] ?? '') as string, timestamp: new Date().getTime() };

			const configData: FT_DialogData<TData> = Object.assign({}, config, {
				modeType: modeType,
				selectedId: selectedId,
			});

			const dialogConfig = {
				...DEFAULT_DIALOG_CONFIG,
				...(matConfig ?? {}),
				data: configData,
			} as MatDialogConfig<TData>;

			const dialgRef = this.dialog.open<unknown, TData, FT_DialogUpdateResult<TData>>(editComponent, dialogConfig);

			return dialgRef;
		} catch (err: unknown) {
			FT_LogError(err, this.constructor.name, `editDialog`);
			return undefined;
		}
	}

	/**
	 * Delete record
	 */
	deleteDialog<T>(request: DeleteDialogInput<T>): boolean {
		console.debug(`deleteRecord=${request.id}`);
		if (!request.id) return false;

		const record = request.selectByIdFunction(request.id);
		if (!record) return false;

		const descValue = GetNotificationValue(record, request.promptProperty, request.promptValue);

		const deleteResult = this.deletePrompt(descValue).pipe(
			take(1),
			map((result) => {
				if (result === FT_ConfirmResult.Confirm) {
					request.deleteFunction(request.id);
					return true;
				}
				return false;
			}),
		);

		const deleteResult$ = toSignal(deleteResult);
		return deleteResult$() ?? false;
	}

	/**
	 * Delete record
	 */

	deletePrompt(descValue: string): Observable<FT_ConfirmResult> {
		const dialogConfig: MatDialogConfig<FT_ConfirmDialogData> = {
			...DEFAULT_DIALOG_CONFIG,
			data: {
				title: 'Delete Confirmation',
				message: `Are you sure you want to delete ${descValue ? `"<i>${descValue}</i>"` : 'the selected document'}?`,
			},
		};

		return this.prompt.confirmationPrompt(dialogConfig).pipe(
			catchError((err: unknown) => {
				return of(FT_ConfirmResult.Cancel);
			}),
		);
	}
} // end class
