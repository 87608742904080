"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Index = exports.index = void 0;
const constants_1 = require("./internal/constants");
const utils_1 = require("./internal/utils");
const logSettings_1 = require("./logSettings");
/**
 * Defines a index for this Class which will then be added to the Schema.
 * @param fields Which fields to index (if multiple fields are set, it will be a compound index)
 * @param options Options to pass to MongoDB driver's createIndex() function
 * @example Example:
 * ```ts
 * @index({ article: 1, user: 1 }, { unique: true })
 * class ClassName {}
 * ```
 */
function index(fields, options) {
  return target => {
    logSettings_1.logger.info('Adding "%o" Indexes to %s', {
      fields,
      options
    }, (0, utils_1.getName)(target));
    const indices = Array.from(Reflect.getOwnMetadata(constants_1.DecoratorKeys.Index, target) ?? []);
    indices.push({
      fields,
      options
    });
    Reflect.defineMetadata(constants_1.DecoratorKeys.Index, indices, target);
  };
}
exports.index = index;
exports.Index = index;
