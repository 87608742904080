import { HttpBackend, HttpClient, HttpContext, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

import { APP_ID, DestroyRef, Inject, Injectable, inject } from '@angular/core';
import { Observable, catchError, delay, map, of, retry, switchMap } from 'rxjs';

export type ServiceType = 'public' | '';

import { FT_RetryConfig, IS_PUBLIC_API, RETRY_LIMIT, RETRY_WAIT_MILLISECONDS } from '@ft/lib/core-lib';
import { FT_LogError, UploadType } from '@furnas-technology/common-library/functions';

import { AppIdValues, DefaultHeaders, FT_HeaderValue } from '@ft/lib/core-lib';
import { EnvironmentService } from '@ft/lib/environment-lib';

export type PostInput = {
	path: string;
	data?: Record<string, unknown> | null;
	headers?: HttpHeaders;
	params?: HttpParams;
};

export interface GetFile {
	base64File: string;
	fileType: UploadType;
}

export type ImageFormat = 'string' | 'blob';

export enum HttpHeaderName {
	ContentDisposition = 'content-disposition',
}

export enum HttpReponseType {
	Blob = 'blob',
	Text = 'text',
	Json = 'json',
	ArrayBuffer = 'arraybuffer',
}

export enum HttpObserveType {
	Response = 'response',
	Events = 'events',
	Bvents = 'body',
}

const mergeObjects = <T>(defObj: T, obj: T): T => {
	return { ...defObj, ...obj };
};

@Injectable({
	providedIn: 'root',
})
export class DataService {
	environment = inject(EnvironmentService);
	httpClient = inject(HttpClient);
	destroyRef = inject(DestroyRef);
	httpBackendModule = inject(HttpBackend);
	httpBackend: HttpClient;

	accessControlAllowOrigin = this.environment.baseUrl;

	defaultPostInput: PostInput = {
		path: '',
		data: null,
		headers: this.createHttpHeaders(),
		params: new HttpParams(),
	};

	constructor(@Inject(APP_ID) private appId: AppIdValues) {
		this.httpBackend = new HttpClient(this.httpBackendModule);
	}

	createHttpHeaders(additionalHeaders?: FT_HeaderValue): HttpHeaders {
		const headers = new HttpHeaders({ ...DefaultHeaders });
		if (additionalHeaders) {
			for (const hdr of Object.entries(additionalHeaders)) {
				const key = hdr[0];
				const value = hdr[1] as string | string[];
				headers.set(key, value);
			}
		}

		return headers;
	}

	get(
		path: string = '',
		headers: HttpHeaders = this.createHttpHeaders(),
		params: HttpParams = new HttpParams(),
	): Observable<unknown> {
		const url = path;
		return this.httpClient.get(url, { headers: headers, params: params });
	} // end get

	getPublic<T>(url: string): Observable<T | undefined> {
		const context = { context: new HttpContext().set(IS_PUBLIC_API, true) };
		const headers = new HttpHeaders({ 'X-Api-Appid': this.appId });

		// return backend call
		return this.httpBackend.get<T>(url, { headers: headers, ...context }).pipe(
			retry(FT_RetryConfig),
			catchError((err: unknown) => {
				FT_LogError(err, this.constructor.name, `getPublic`);
				return of(undefined);
			}),
		);
	} // end getPublic

	downloadFile(path: string): Observable<unknown> {
		const url = path ?? '';
		const headers = new HttpHeaders({ 'X-Api-Appid': this.appId });
		headers.append('Accept', '*/*');

		const httpOptions = {
			responseType: 'blob' as 'json',
			headers: new HttpHeaders({
				Accept: '*/*',
			}),
		};

		return this.httpClient.get(url, httpOptions).pipe();
	}

	getImage(
		url: string,
		imageFormat: ImageFormat = 'string',
		isPublic: boolean = false,
		retry = RETRY_LIMIT,
		attempt = 0,
	): Observable<string | Blob> {
		const headers = new HttpHeaders({
			Accept: '*/*',
			'Access-Control-Allow-Origin': `${this.accessControlAllowOrigin}`,
		});

		// delaytime
		const delayTime = attempt * RETRY_WAIT_MILLISECONDS;

		// set http for public/private
		// 'Access-Control-Allow-Headers': '*',

		const http = isPublic ? this.httpBackend : this.httpClient;
		return http
			.get(url, {
				responseType: 'blob',
				headers: {
					Accept: '*/*',
					'Access-Control-Allow-Origin': `${this.accessControlAllowOrigin}`,
				},
			})
			.pipe(
				delay(delayTime),
				switchMap((blob: Blob) => {
					const imageType = blob.type;

					const reader = new FileReader();

					return new Observable<string>((observer) => {
						reader.onloadend = () => {
							if (typeof reader.result === 'string') {
								const base64String = reader.result.split(',')[1]; // Extract base64 string
								const dataImage = `data:${imageType};base64,${base64String}`; // set image src
								observer.next(dataImage);
								observer.complete();
							} else {
								observer.error('Error converting blob to base64');
							}
						};
						reader.readAsDataURL(blob);
					});
				}),
				catchError((err: unknown) => {
					FT_LogError(err, this.constructor.name, 'getImage');
					if (!retry || attempt >= retry) {
						return of('');
					} else {
						return this.getImage(url, imageFormat, isPublic, retry, attempt + 1);
					}
				}),
			);
	}

	post<Result>(input: PostInput): Observable<Result> {
		const request = mergeObjects(this.defaultPostInput, input);
		return this.httpClient.post<Result>(request.path, request.data, {
			params: request.params,
			headers: request.headers,
		});
	}

	postPublic<Result>(input: PostInput): Observable<Result> {
		const context = { context: new HttpContext().set(IS_PUBLIC_API, true) };
		const request = mergeObjects(this.defaultPostInput, input);
		return this.httpBackend.post<Result>(request.path, request.data, {
			params: request.params,
			headers: { 'X-Api-Appid': this.appId },
			...context,
		});
	}

	put(
		path: string = '',
		data: unknown = null,
		headers: HttpHeaders = this.createHttpHeaders(),
		params: HttpParams = new HttpParams(),
	): Observable<unknown> {
		const url = path;
		return this.httpClient.put(url, data, { params: params, headers: headers });
	}

	delete(
		path: string = '',
		data: object | null = null,
		headers: HttpHeaders = this.createHttpHeaders(),
		params: HttpParams = new HttpParams(),
	): Observable<unknown> {
		const url = path;
		return this.httpClient.request('delete', url, {
			headers: headers,
			params: params,
			body: data,
		});
	}

	warmup() {
		return of(true);
		//
		// return this.get('/warmup', undefined, undefined, 'restWithAuthentication').pipe(
		//   switchMap(x => {
		//     if (x == WarmupStatus.Failure) {
		//       return throwError(() => new Error(x));
		//     }
		//     return of(x);
		//   })
		// );
	}

	download(
		path: string = '',
		data: object | null = null,
		headers: HttpHeaders = this.createHttpHeaders(),
		params: HttpParams = new HttpParams(),
	): Observable<HttpResponse<Blob>> {
		const url = path;
		return this.httpClient.post(url, data, {
			headers: headers,
			params: params,
			responseType: HttpReponseType.Blob,
			observe: HttpObserveType.Response,
		});
	}

	getFile(url: string, isPublic: boolean = false, retry = RETRY_LIMIT, attempt = 0): Observable<GetFile | undefined> {
		console.debug(`${this.constructor.name} - getFile - url=${url}`);
		const fileUrl = `${this.environment.baseUrl}getFile?url=${url}`;

		const http = isPublic ? this.httpBackend : this.httpClient;
		return http.get(fileUrl, {}).pipe(
			map((value: unknown) => {
				console.debug(`${this.constructor.name} - getFile - isGetFile=${IsGetFile(value)} - value=`, value);
				if (IsGetFile(value)) {
					return value;
				} else {
					return undefined;
				}
			}),
			catchError((err: unknown) => {
				FT_LogError(err, this.constructor.name, 'getFile');
				if (!retry || attempt >= retry) {
					return of(undefined);
				} else {
					return this.getFile(url, isPublic, retry, attempt + 1);
				}
			}),
		);
	}
} // end service class

const IsGetFile = (value: unknown): value is GetFile => {
	if (value && Object.hasOwn(value, 'base64File') && Object.hasOwn(value, 'fileType')) {
		return true;
	} else {
		return false;
	}
};
