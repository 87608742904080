import { ComponentType } from '@angular/cdk/portal';
import { Constructor } from '@angular/cdk/table';
import { SelectedId } from '@ft/lib/core-lib';
import { CreateRowRequest, GetPagedDataRequest, MutateRowRequest } from '@ft/lib/crud-helper-lib';
import { GraphqlDeleteRequest } from '@ft/lib/graphql-lib';
import { FT_IndexedDBConfig } from '@ft/lib/indexeddb-lib';
import { TotalsRecord } from '@ft/lib/models';
import { UPDATE_STATUS } from '@furnas-technology/angular-library';
import { SearchBarField } from '@furnas-technology/common-library/filters';
import { withMethods } from '@ngrx/signals';
import { withEntities } from '@ngrx/signals/entities';

export type Has_Id<T extends object = object> = {
	_id: string | number;
} & T;

export const isHas_Id = <T>(obj: T): obj is Has_Id & T => {
	return !!(obj != null && typeof obj === 'object' && '_id' in obj);
};

export type HasUpdatedAt<T extends object = object> = {
	updatedAt: string;
};

export type FT_EntityStore<T> = ReturnType<typeof withEntities<T>> & ReturnType<typeof withMethods>;

export type HasRetrievedAt<T extends object = object> = {
	retrievedAt: string;
};

export type HasTimestamps<T extends object = object> = {
	updatedAt: string;
	retrievedAt: string;
};

// Define specific method types
export type FT_StoreMethods<T> = {
	[K: string]: (store: FT_EntityStore<T>) => (...args: []) => undefined | T | T[];
};

export type FT_PropertyKey<T> = keyof T;
export type FT_PropertyValue<T> = T[keyof T];

export type LoadOn = 'auth' | 'subscribe' | 'authNotSubscribed' | 'all';

export type FT_SignalStorename = string;

export type FT_SignalStoreRequest<T> = {
	signalStoreName: FT_SignalStorename;
	getPagedDataRequest: GetPagedDataRequest<T> | undefined;
	createRowRequest: CreateRowRequest<T> | undefined;
	mutateRowRequest: MutateRowRequest<T> | undefined;

	apiDelete?: (_id: string) => GraphqlDeleteRequest;
	editResultProperty: string | undefined;
	editComponent: ComponentType<unknown> | undefined | null;

	nameProperty: keyof T | undefined;
	sortProperty: keyof T | undefined;
	searchBarFields?: SearchBarField<T>[] | undefined;
	initialState?: FT_SignalStoreState<T>;
	sortFunction?: (a: T, b: T) => number;
	customMethodsFn?: (store: FT_EntityStore<T>) => FT_StoreMethods<T>;

	loadOn?: LoadOn;
	classConstructor?: Constructor<T>;

	indexedDBConfig?: FT_IndexedDBConfig;
};

export type FT_SignalStoreState<T> = {
	updateStatus: UPDATE_STATUS;
	loadStatus: UPDATE_STATUS;
	indexLoaded: boolean;
	error: string | null;
	selectedId: SelectedId;
	totals: TotalsRecord;
	lastEntityUpdated: number;
	editComponent: ComponentType<unknown> | null;
	overrideSearchString: string;
};
