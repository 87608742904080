import { Constructor } from '@angular/cdk/table';
import { computed, inject } from '@angular/core';
import { ActiveFilterService } from '@ft/lib/active-filter-lib';
import { FieldFilter, FilteredRecord, SearchBarField } from '@furnas-technology/common-library/filters';
import { signalStoreFeature, type, withComputed, withMethods, withState } from '@ngrx/signals';
import { EntityId, EntityState } from '@ngrx/signals/entities';
import { DateTime } from 'luxon';
import { FT_PropertyKey, FT_SignalStoreState, HasTimestamps, HasUpdatedAt, Has_Id } from './signal-store.models';

export const MethodNotImplemented = (method: string, reason: string) => {
	console.error(`❌ SignalStore - MethodNotImplemented - method=${method}, reason=${reason}`);
};

export const isHasUpdatedAt = <T>(obj: T): obj is HasUpdatedAt & T => {
	return !!(obj != null && typeof obj === 'object' && 'updatedAt' in obj);
};

export const isHasRetrievedAt = <T>(obj: T): obj is HasUpdatedAt & T => {
	return !!(obj != null && typeof obj === 'object' && 'retrievedAt' in obj);
};

export const isHasTimestamps = <T>(obj: T): obj is HasUpdatedAt & T => {
	return !!(obj != null && typeof obj === 'object' && 'retrievedAt' in obj && 'updatedAt' in obj);
};

export const HydrateRecords = <T>(records: T[], classConstructor: Constructor<T> | undefined): T[] => {
	if (classConstructor) {
		const hydratedRecords = records.map((record) => new classConstructor(record));
		return hydratedRecords;
	} else {
		return records;
	}
};

export const HydrateRecord = <T>(record: T, classConstructor: Constructor<T> | undefined): T => {
	if (classConstructor) {
		const hydratedRecord = new classConstructor(record);
		return hydratedRecord;
	} else {
		return record;
	}
};

export const SortByProperty = <T>(documents: T[], sortProperty: keyof T): T[] => {
	const resultSort = documents.toSorted((a, b) => {
		const aValue = a[sortProperty] ?? '';
		const bValue = b[sortProperty] ?? '';
		if (typeof aValue === 'string' && typeof bValue === 'string') {
			return aValue.localeCompare(bValue);
		} else if (typeof aValue === 'number' && typeof bValue === 'number') {
			return !!(aValue > bValue) ? 1 : -1;
		} else {
			return String(aValue).localeCompare(String(bValue));
		}
	});
	return resultSort;
};

export const SelectMaxTimestamps = <T>(records: T[]): { maxUpdatedAt: string; maxRetrievedAt: string } => {
	try {
		if (!records.length) return { maxUpdatedAt: '', maxRetrievedAt: '' };

		// ensure records have updatedAt and retrievedAt
		const firstRecord = records[0];
		if (!isHasUpdatedAt(firstRecord) || !isHasRetrievedAt(firstRecord)) {
			return { maxUpdatedAt: '', maxRetrievedAt: '' };
		}
		const timestampRecords: HasTimestamps[] = records as HasTimestamps[];

		const extremes = timestampRecords.reduce(
			(acc, obj) => {
				if (!acc.maxRetrievedAt || obj.updatedAt > acc.maxRetrievedAt) acc.maxRetrievedAt = obj.retrievedAt;
				if (!acc.maxUpdatedAt || obj.updatedAt > acc.maxUpdatedAt) acc.maxUpdatedAt = obj.updatedAt;
				return acc;
			},
			{ maxUpdatedAt: '', maxRetrievedAt: '' },
		);

		return { maxUpdatedAt: extremes.maxUpdatedAt, maxRetrievedAt: extremes.maxRetrievedAt };
	} catch (err: unknown) {
		console.error(`❌ SelectMaxTimestamps - error:`, err);
		return { maxUpdatedAt: '', maxRetrievedAt: '' };
	}
};

export const CalcLastUpdatedValue = <T>(records: T[], reloadDays = 0): string => {
	if (!records.length || records.length < 5) return '';

	try {
		const { maxUpdatedAt, maxRetrievedAt } = SelectMaxTimestamps(records);

		if (!maxUpdatedAt) return '';
		if (!maxRetrievedAt) return maxUpdatedAt;

		const currentDate = DateTime.now();
		const compareDate = DateTime.fromISO(maxRetrievedAt);
		const diff = currentDate.diff(compareDate, 'days');
		const daysDiff = Math.floor(diff.days);
		console.debug(
			`CalcLastUpdatedValue - maxRetrievedAt=${maxRetrievedAt}, maxUpdatedAt=${maxUpdatedAt}, daysDiff=${daysDiff}`,
		);

		return (reloadDays && daysDiff > reloadDays) || !maxUpdatedAt ? '' : maxUpdatedAt;
	} catch (err: unknown) {
		console.error(`❌ CalcLastUpdatedValue - date difference calc error:`, err);
		return '';
	}
};

export type BaseState<T> = FT_SignalStoreState<T> & {
	entityState: EntityState<T>;
	entities: T[];
};

export function withStandardComputed<T>() {
	return signalStoreFeature(
		{
			state: type<BaseState<EntityId>>(),
		},
		withComputed((store) => ({
			totalEntities: computed(() => store.entityState().ids.length),
		})),
	);
}

export type SelectedEntityState = { selectedEntityId: EntityId | null };

export function withSelectedEntity<T>(entities: T[]) {
	return signalStoreFeature(
		{ state: type<EntityState<EntityId>>(), entities: entities },
		withState<SelectedEntityState>({ selectedEntityId: null }),

		withComputed(({ entityMap, selectedEntityId }) => ({
			selectedEntity: computed(() => {
				const selectedId = selectedEntityId();
				return selectedId ? entityMap()[selectedId] : null;
			}),
		})),

		withMethods((store) => ({
			documentIdsAndField(
				fieldname: FT_PropertyKey<T>,
			): { _id: string | number; fieldname: keyof T; value: T[keyof T] }[] {
				const records = entities;
				const mappedRecords = records.map((record) => ({
					_id: (record as Has_Id)._id,
					fieldname: fieldname,
					value: record[fieldname],
				}));
				return mappedRecords;
			},
		})),
	);
}

export const FilteredDocuments = <T>(
	entities: T[],
	searchBarFields: SearchBarField<T>[],
	activeFieldFilters: FieldFilter<unknown>[],
	lastUpdated: number,
): FilteredRecord<T>[] => {
	const _afService = inject(ActiveFilterService);

	const filteredDocuments = _afService.filterData<T>({
		records: entities,
		searchBarFields: searchBarFields,
		fieldFilters: activeFieldFilters,
		lastChange: lastUpdated,
	});
	return filteredDocuments;
};
