export type TotalsRecord = {
	retrievedAt: string;
	lastUpdatedAt: string;
	lastDeletedAt: string;
	count: number;
	deletedCount: number;
};

export const InitTotalsRecord: TotalsRecord = {
	retrievedAt: '',
	lastUpdatedAt: '',
	lastDeletedAt: '',
	count: 0,
	deletedCount: 0,
};

export type TotalsData = {
	[x: string]: TotalsRecord;
};
