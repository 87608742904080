import { ComponentType } from '@angular/cdk/portal';
import { Injectable, inject } from '@angular/core';
import { isCreatableRow, isMutableRow } from '@ft/lib/core-lib';
import { CommonDialogService, FT_DialogData } from '@ft/lib/dialogs-lib';
import { CoreModeType } from '@furnas-technology/angular-library';
import { finalize } from 'rxjs';
import { EditDocumentRequest } from './crud-helper.service';

export type EditDocumentInput<T> = {
	request: EditDocumentRequest;
	editComponent: ComponentType<unknown>;
	mutateRowFunction: (rowData: T) => void;
	createRowFunction: (rowData: T) => void;
	callerName: string;
	signalStoreName: string;
};

@Injectable({
	providedIn: 'root',
})
export class CrudDocumentService {
	protected dialogService = inject(CommonDialogService);

	/**
	 * edit document
	 */
	editDocument<T>(inp: EditDocumentInput<T>): void {
		const request: EditDocumentRequest = inp.request;

		console.debug(`${inp.callerName} - ${inp.signalStoreName} - editDocument`);

		const requestComponent = request.editComponentOverride ?? inp.editComponent;
		if (!requestComponent) {
			console.error(
				`❌ ${inp.callerName} - ${inp.signalStoreName} - editDocument - MethodNotImplemented - editComponent is undefined`,
			);
			return;
		}

		const reqModeType = request.modeType || 'Edit';

		const dialogRequest: FT_DialogData<T> = {
			fromId: request.fromId ?? '',
			selectedId: { id: request.id ?? '', timestamp: new Date().getTime() },
			modeType: reqModeType,
		};

		const dialgRef = this.dialogService.editDialog<T>(requestComponent, dialogRequest, request.closeOthers ?? true);
		if (!dialgRef) return;
		dialgRef
			.afterClosed()
			.pipe(
				finalize(() => console.debug(`${this.constructor.name} - ${inp.signalStoreName}  - FINALIZE - editDocument`)),
			)
			.subscribe((result) => {
				console.debug(
					`${this.constructor.name} - ${inp.signalStoreName}  - SUBSCRIBE - editDocument - result=`,
					result,
				);
				if (!result?.success) return;
				if (!result.data) {
					console.error(
						`❌ ${inp.callerName} - ${inp.signalStoreName}  - editDocument - success=${result.success}, result.data is undefined`,
					);
					return;
				}

				const modeType = result.modeType || reqModeType;

				const rowData = result.data as T;
				if (CoreModeType(modeType) === 'Edit' && !!inp.mutateRowFunction && isMutableRow<T>(rowData)) {
					inp.mutateRowFunction(rowData);
				} else if (CoreModeType(modeType) === 'Add' && !!inp.createRowFunction && isCreatableRow<T>(rowData)) {
					inp.createRowFunction(rowData);
				}
			});
	}
}
