"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CacheDisabledError = exports.DuplicateOptionsError = exports.NoDiscriminatorFunctionError = exports.PathNotInSchemaError = exports.InvalidOptionsConstructorError = exports.InvalidEnumTypeError = exports.ExpectedTypeError = exports.ResolveTypegooseNameError = exports.OptionDoesNotSupportOptionError = exports.StringLengthExpectedError = exports.FunctionCalledMoreThanSupportedError = exports.NotValidModelError = exports.RefOptionIsUndefinedError = exports.SelfContainingClassError = exports.CannotBeSymbolError = exports.InvalidPropTypeError = exports.AssertionFallbackError = exports.NoValidClassError = exports.NotAllVPOPElementsError = exports.NotStringTypeError = exports.NotNumberTypeError = exports.InvalidTypeError = void 0;
const utils_1 = require("./utils");
// Note: don't forget to use "toStringNoFail" on values that are "unknown" or "any"
class InvalidTypeError extends Error {
  constructor(targetName, key, Type) {
    super(`"${targetName}.${key}"'s Type is invalid! Type is: "${(0, utils_1.toStringNoFail)(Type)}" [E009]`);
  }
}
exports.InvalidTypeError = InvalidTypeError;
class NotNumberTypeError extends Error {
  constructor(targetName, key, enumKey, enumValue) {
    super(`Typeof "${targetName}.${key}" is "Number", value is undefined/null or does not have a reverse mapping! [E011]\n` + `  Encountered with property: "${enumKey}.${typeof enumValue}"`);
  }
}
exports.NotNumberTypeError = NotNumberTypeError;
class NotStringTypeError extends Error {
  constructor(targetName, key, enumKey, enumValue) {
    super(`Typeof "${targetName}.${key}" is "String", used enum is not only Strings! [E010]\n` + `  Encountered with property in Enum: "${enumKey}.${typeof enumValue}"`);
  }
}
exports.NotStringTypeError = NotStringTypeError;
/** Not All Virtual Populate Elements Error */
class NotAllVPOPElementsError extends Error {
  constructor(name, key) {
    super(`"${name}.${key}" has not all needed Virtual Populate Options! Needed are: ${utils_1.allVirtualoptions.join(', ')} [E006]`);
  }
}
exports.NotAllVPOPElementsError = NotAllVPOPElementsError;
class NoValidClassError extends TypeError {
  constructor(value) {
    super('Value is not a function or does not have a constructor! [E028]\n' + `Value: "${(0, utils_1.toStringNoFail)(value)}"`);
  }
}
exports.NoValidClassError = NoValidClassError;
class AssertionFallbackError extends Error {
  constructor() {
    super('Assert failed - no custom error [E019]');
  }
}
exports.AssertionFallbackError = AssertionFallbackError;
/** Error for when an unknown PropType is passed to an switch, gets thrown in the default case */
class InvalidPropTypeError extends Error {
  constructor(proptype, name, key, where) {
    super(`"${(0, utils_1.toStringNoFail)(proptype)}"(${where}) is invalid for "${name}.${key}" [E013]`);
  }
}
exports.InvalidPropTypeError = InvalidPropTypeError;
class CannotBeSymbolError extends Error {
  constructor(name, key) {
    super(`A property key in Typegoose cannot be an symbol! ("${name}.${(0, utils_1.toStringNoFail)(key)}") [E024]`);
  }
}
exports.CannotBeSymbolError = CannotBeSymbolError;
class SelfContainingClassError extends TypeError {
  constructor(name, key) {
    super('It seems like the type used is the same as the target class, which is not supported ' + `("${name}.${key}") [E004]`);
  }
}
exports.SelfContainingClassError = SelfContainingClassError;
class RefOptionIsUndefinedError extends Error {
  constructor(name, key) {
    super(`Prop-Option "ref"'s value is "null" or "undefined" for "${name}.${key}" [E005]`);
  }
}
exports.RefOptionIsUndefinedError = RefOptionIsUndefinedError;
class NotValidModelError extends TypeError {
  constructor(model, where) {
    super(`Expected "${where}" to be a valid mongoose.Model! (got: "${(0, utils_1.toStringNoFail)(model)}") [E025]`);
  }
}
exports.NotValidModelError = NotValidModelError;
class FunctionCalledMoreThanSupportedError extends Error {
  constructor(functionName, supported, extra) {
    super(`Function "${functionName}" only supports to be called "${supported}" times with the same parameters [E003]\n${extra}`);
  }
}
exports.FunctionCalledMoreThanSupportedError = FunctionCalledMoreThanSupportedError;
class StringLengthExpectedError extends TypeError {
  constructor(length, got, where, valueName) {
    // create the "got:" message, when string say it was a string, but not the length
    // if not string, then say it is not a string plus the value
    const gotMessage = typeof got === 'string' ? `(String: "${got.length}")` : `(not-String: "${(0, utils_1.toStringNoFail)(got)}")`;
    super(`Expected "${valueName}" to have at least length of "${length}" (got: ${gotMessage}, where: "${where}") [E026]`);
  }
}
exports.StringLengthExpectedError = StringLengthExpectedError;
class OptionDoesNotSupportOptionError extends TypeError {
  constructor(currentOption, problemOption, expected, provided) {
    super(`The Option "${currentOption}" does not support Option "${problemOption}" other than "${expected}" (provided was: "${provided}") [E027]`);
  }
}
exports.OptionDoesNotSupportOptionError = OptionDoesNotSupportOptionError;
class ResolveTypegooseNameError extends ReferenceError {
  constructor(input) {
    super("Input was not a string AND didn't have a .typegooseName function AND didn't have a .typegooseName string [E014]\n" + `Value: "${(0, utils_1.toStringNoFail)(input)}"`);
  }
}
exports.ResolveTypegooseNameError = ResolveTypegooseNameError;
class ExpectedTypeError extends TypeError {
  constructor(optionName, expected, got) {
    super(`Expected Argument "${optionName}" to have type "${expected}", got: "${(0, utils_1.toStringNoFail)(got)}" [E029]`);
  }
}
exports.ExpectedTypeError = ExpectedTypeError;
class InvalidEnumTypeError extends TypeError {
  constructor(name, key, value) {
    super(`Invalid Type used for options "enum" at "${name}.${key}"! [E012]\n` + `Type: "${(0, utils_1.toStringNoFail)(value)}"\n` + 'https://typegoose.github.io/typegoose/docs/guides/error-warning-details#invalid-type-for-enum-e012');
  }
}
exports.InvalidEnumTypeError = InvalidEnumTypeError;
class InvalidOptionsConstructorError extends TypeError {
  constructor(name, key, type) {
    super(`Type has a invalid "OptionsConstructor" on "${name}.${key}"! [E016]\n` + `Type: "${(0, utils_1.toStringNoFail)(type)}"`);
  }
}
exports.InvalidOptionsConstructorError = InvalidOptionsConstructorError;
class PathNotInSchemaError extends Error {
  constructor(name, key) {
    super(`Path "${key}" on "${name}" does not exist in the Schema! [E030]`);
  }
}
exports.PathNotInSchemaError = PathNotInSchemaError;
class NoDiscriminatorFunctionError extends Error {
  constructor(name, key) {
    super(`Path "${name}.${key}" does not have a function called "discriminator"! (Nested Discriminator cannot be applied) [E031]`);
  }
}
exports.NoDiscriminatorFunctionError = NoDiscriminatorFunctionError;
class DuplicateOptionsError extends TypeError {
  constructor(duplicateAt) {
    super(`Duplicate Option definition at [${duplicateAt.join(',')}] [E032]`);
  }
}
exports.DuplicateOptionsError = DuplicateOptionsError;
class CacheDisabledError extends TypeError {
  constructor(where) {
    super(`Tried using cache, but was disabled at "${where}" [E033]`);
  }
}
exports.CacheDisabledError = CacheDisabledError;
