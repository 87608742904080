import { animate, style, transition, trigger } from '@angular/animations';

import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, input, signal, viewChild } from '@angular/core';

export type CarouselImage = {
	url: string;
	title?: string; // Optional title for the image
};

const NoImageOpen = -1;

@Component({
	selector: 'ft-image-carousel',
	templateUrl: './image-carousel.component.html',
	styleUrls: ['./image-carousel.component.scss'],
	imports: [],
	animations: [
		trigger('textAnimation', [
			transition(':enter', [animate('0.7s', style({ transform: 'rotate(180deg)' }))]),
			transition(':leave', [animate('0.7s', style({ opacity: 0 }))]),
			transition('* => *', [
				animate('0.3s', style({ transform: 'rotate(180deg)' })),
				// animate('0.3s', style({ transform: 'skew(90deg)' })),
				// animate('0.5s', style({ transform: 'skew(0deg)' })),
			]),
		]),
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCarouselComponent implements AfterViewInit {
	readonly images = input<CarouselImage[]>([]);

	selectedImage = signal<CarouselImage | null>(null);
	isOpen = signal<number>(NoImageOpen);

	textToAnimate = viewChild<ElementRef>('textToAnimate');
	textAnimate = '';

	NoImageOpen = NoImageOpen;

	constructor() {
		// console.debug(`${this.constructor.name} - constructor`);
	}

	ngOnInit() {
		console.debug(`${this.constructor.name} - ngOnInit - images=`, this.images());
	}

	ngAfterViewInit(): void {
		// console.debug(`***AnimiateTextComponent - afterviewinit - textToAnimate=`, this.textToAnimate);
	}

	onThumbnailClick(image: CarouselImage, index: number) {
		if (!image?.url || index === this.isOpen()) {
			this.selectedImage.set(null);
			this.isOpen.set(NoImageOpen);
		} else {
			this.selectedImage.set(image);
			this.isOpen.set(index);
		}
	}

	imageClick(index: number) {
		this.selectedImage.set(null);
		this.isOpen.set(NoImageOpen);
	}
}
