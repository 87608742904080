import { computed, inject } from '@angular/core';
import { IS_PRODUCTION } from '@ft/lib/core-lib';
import { AuthStatus, InitUserinfo, SessionValues, Userinfo } from '@ft/lib/models';
import { patchState, signalStore, watchState, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { CognitoJwtVerifierSingleUserPool } from 'aws-jwt-verify/cognito-verifier';

const inProgressPattern = /progress/i;
const signalStoreName = 'AuthStore';

type CognitoJwtVerifier = CognitoJwtVerifierSingleUserPool<{
	userPoolId: string;
	tokenUse: 'access';
	clientId: string;
}>;

type AnyJwtVerifier = CognitoJwtVerifierSingleUserPool<{
	userPoolId: string;
	tokenUse: null;
	clientId: string;
}>;

export type FT_CognitoVerifier = {
	verifierClientAndPool: string;
	cognitoJwtVerifier: CognitoJwtVerifier;
	anyJwtVerifier: AnyJwtVerifier;
};

// auth.store.ts
type AuthState = {
	_isAuthenticated: boolean;
	_isSubscribed: boolean;
	_isAdmin: boolean;
	authStatus: AuthStatus;
	authenticatedCallbacks: (() => void)[];
	subscribedCallbacks: (() => void)[];
	_userInfo: Userinfo;
	accessToken: string;
	idToken: string;

	sessionValues: SessionValues | null;
	cognitoToken: string;
	cognitoAppId: string;
	appId: string;
	apiKey: string;

	cognitoVerifier: FT_CognitoVerifier | null;
};

export const AuthStore = signalStore(
	{ protectedState: true, providedIn: 'root' },
	withState<AuthState>({
		_isAuthenticated: false,
		_isSubscribed: false,
		_isAdmin: false,
		authStatus: 'none',
		authenticatedCallbacks: [],
		subscribedCallbacks: [],
		_userInfo: new Userinfo(InitUserinfo),
		accessToken: '',
		idToken: '',

		sessionValues: null,
		cognitoToken: '',
		cognitoAppId: '',
		appId: '',
		apiKey: '',

		cognitoVerifier: null,
	}),
	withMethods((store) => ({
		executeCallbacks(type: string, callbacks: (() => void)[]) {
			for (const callback of callbacks) {
				console.debug(`👉 AuthStore - executeCallbacks [${type}] - executing callback`);
				callback();
			}
		},
	})),

	withMethods((store) => ({
		setAdmin(admin: boolean) {
			const _isAdmin = !!admin;
			patchState(store, { _isAdmin });
		},
		setAuthStatus(authStatus: AuthStatus) {
			patchState(store, { authStatus });
		},
		clearUserinfo() {
			patchState(store, { _userInfo: new Userinfo(InitUserinfo) });
		},

		setAccessToken(accessToken: string) {
			patchState(store, { accessToken });
		},

		setIdToken(idToken: string) {
			patchState(store, { idToken });
		},

		setAccessTokenAndIdToken(accessToken: string, idToken: string) {
			patchState(store, { accessToken, idToken });
		},

		setSessionValues(sessionValues: SessionValues) {
			console.debug(`👉 AuthStore - setSessionValues - sessionValues=`, sessionValues);
			patchState(store, { sessionValues });
			patchState(store, {
				cognitoToken: sessionValues.config.token,
				cognitoAppId: sessionValues.config.appId,
				appId: sessionValues.config.appId,
				apiKey: sessionValues.config.token,
			});
		},

		setCognitoVerifier(cognitoVerifier: FT_CognitoVerifier) {
			patchState(store, { cognitoVerifier });
		},
	})),
	withMethods((store) => ({
		/**
		 * callbacks for Subscribed
		 */
		setSubscribed(isSubscribed: boolean) {
			console.debug(
				`${signalStoreName} - setSubscribed - isSubscribed=${isSubscribed} - callbacks=${
					store.subscribedCallbacks().length
				}`,
			);
			const _isSubscribed = !!isSubscribed;
			patchState(store, { _isSubscribed });
			/**
			 * Execute pending callbacks when subscribed
			 */
			if (_isSubscribed && store.subscribedCallbacks().length > 0) {
				store.executeCallbacks('subscribed', store.subscribedCallbacks());
				// Clear the queue
				patchState(store, { subscribedCallbacks: [] });
			}
		},

		addSubscribedCallback(callback: () => void) {
			console.debug(
				`${signalStoreName} - addSubscribedCallback - callbacks=${
					store.subscribedCallbacks().length
				}, isSubscribed=${store._isSubscribed()}, isAdmin=${store._isAdmin()}`,
			);
			if (store._isSubscribed()) {
				store.executeCallbacks('already subscribed', [callback]);
			} else if (store._isAdmin()) {
				console.debug(`${signalStoreName} - addSubscribedCallback - not already subscribed, but isAdmin`);
				store.executeCallbacks('is admin', [callback]);
			} else {
				console.debug(`${signalStoreName} - addSubscribedCallback - not already subscribed`);
				patchState(store, (state) => ({
					subscribedCallbacks: [...state.subscribedCallbacks, callback],
				}));
			}
		},
		/**
		 * Callbacks for authenticated
		 */
		setAuthenticated(isAuthenticated: boolean) {
			const _isAuthenticated = !!isAuthenticated;
			console.debug(
				`${signalStoreName} - setAuthenticated - isAuthenticated=${isAuthenticated}, isAdmin=${store._isAdmin()}, - authenticatedCallbacks=${
					store.authenticatedCallbacks().length
				}`,
			);

			/**
			 * Update store
			 */
			patchState(store, { _isAuthenticated });
			if (_isAuthenticated) {
				store.setAuthStatus('success');
			} else {
				store.setAuthStatus('none');
				this.setSubscribed(false);
				store.clearUserinfo();
			}

			/**
			 * Execute pending callbacks when authenticated
			 */
			if (_isAuthenticated && store.authenticatedCallbacks().length > 0) {
				console.debug(`${signalStoreName} - executeCallbacks - authenticated=${_isAuthenticated}`);
				store.executeCallbacks('authenticated', store.authenticatedCallbacks());
				// Clear the queue
				patchState(store, { authenticatedCallbacks: [] });
			}
		},

		addAuthenticatedCallback(callback: () => void) {
			console.debug(
				`${signalStoreName} - addAuthenticatedCallback - callbacks=${store.authenticatedCallbacks().length}`,
			);

			if (store._isAuthenticated()) {
				store.executeCallbacks('already authenticated', [callback]);
			} else {
				console.debug(`${signalStoreName} - addAuthenticatedCallback - not already authenticated`);

				patchState(store, (state) => ({
					authenticatedCallbacks: [...state.authenticatedCallbacks, callback],
				}));
			}
		},

		setUserinfo(_userInfo: Userinfo) {
			patchState(store, { _userInfo });

			const groups = _userInfo.groups ?? [];
			const isSubscribed = groups.findIndex((item) => item.match(/^subscription_active$|^subscription_trial$/i)) > -1;

			if (isSubscribed !== store._isSubscribed()) {
				this.setSubscribed(isSubscribed);
			}
			if (_userInfo.isAdmin !== store._isAdmin()) {
				store.setAdmin(_userInfo.isAdmin);
			}

			/**
			 * Execute pending callbacks if admin and subscribed callbacks
			 */
			console.debug(
				`${signalStoreName} - setUserinfo - isAdmin=${store._isAdmin()}, - subscribedCallbacks=${
					store.subscribedCallbacks().length
				}`,
			);

			if (_userInfo.isAdmin && store.subscribedCallbacks().length > 0) {
				store.executeCallbacks('subscribed', store.subscribedCallbacks());
				patchState(store, { subscribedCallbacks: [] });
			}
		},
	})),
	withComputed((store) => ({
		isAuthenticated: computed(() => store._isAuthenticated()),
		isSubscribed: computed(() => store._isSubscribed()),
		isAdmin: computed(() => store._isAdmin()),
		isSubscribedOrAdmin: computed(() => !!(store._isSubscribed() || store._isAdmin())),
		authInProgress: computed(() => {
			const authStatus = store.authStatus() ?? '';
			return inProgressPattern.test(authStatus);
		}),

		userinfo: computed(() => store._userInfo()),

		email: computed(() => store._userInfo()?.email ?? ''),
		fullname: computed(() => store._userInfo()?.fullname ?? ''),
		username: computed(() => store._userInfo()?.username ?? ''),
		groups: computed(() => store._userInfo()?.groups ?? []),
		restrictFields: computed(() => store._userInfo()?.restrictFields ?? false),

		loggedInOutClass: computed(() => (store._isAuthenticated() ? 'logged-in' : 'logged-out')),
	})),
	withComputed((store) => ({
		isUser: computed(() => !!store._userInfo()?.isUser),
		authOrInprogress: computed(() => {
			return !!(store._isAuthenticated() || store.authInProgress());
		}),
	})),

	withComputed((store) => ({
		selectAccessToken: computed(() => store.accessToken()),
		selectIdToken: computed(() => store.idToken()),
	})),

	withComputed((store) => ({
		selectCognitoToken: computed(() => store.cognitoToken()),
		selectCognitoAppId: computed(() => store.cognitoAppId()),
		selectAppId: computed(() => store.appId()),
		selectApiKey: computed(() => store.apiKey()),

		selectSessionValues: computed(() => store.sessionValues()),
		selectAppSettings: computed(() => store.sessionValues()?.settings),
		selectApiAppConfig: computed(() => store.sessionValues()?.config),

		selectConfigCognito: computed(() => store.sessionValues()?.config.cognito),
		selectVerifierClientAndPool: computed(() => store.cognitoVerifier()?.verifierClientAndPool ?? ''),
		selectCognitoJwtVerifier: computed(() => store.cognitoVerifier()?.cognitoJwtVerifier ?? null),
		selectAnyJwtVerifier: computed(() => store.cognitoVerifier()?.anyJwtVerifier ?? null),
		selectCognitoVerifier: computed(() => store.cognitoVerifier() ?? null),
	})),

	withHooks({
		onInit(store) {
			const isProduction = inject(IS_PRODUCTION);
			const isDev = !isProduction;

			watchState(store, (state) => {
				if (isDev) {
					console.log(
						`🚥 AuthStore - [watchState] authenticated=${store._isAuthenticated()}, authenticatedCallbacks=${
							store.authenticatedCallbacks().length
						}, subscribed=${store._isSubscribed()}, subscribedCallbacks=${store.subscribedCallbacks().length}, state=`,
						state,
					);
				}
			});
		},
	}),
);
