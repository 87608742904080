<stripe-pricing-table
  #pricingtable
  class="ft-stripe-pricing-table"
  pricing-table-id="{{ stripePT() }}"
  publishable-key="{{ stripePK() }}"
  client-reference-id="{{ userinfo().username }}"
  customer-email="{{ userinfo().email }}"
>
</stripe-pricing-table>

@if (!userinfo().id) {
  <div>You must be signed in to subscribe</div>
}
