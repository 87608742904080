import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class KeyboardService {
	escapeKeyPressed = new EventEmitter<void>();

	constructor() {}

	setupEscapeListener(): void {
		document.addEventListener('keydown', (event) => {
			if (event.key === 'Escape') {
				this.escapeKeyPressed.emit();
				event.preventDefault();
				event.stopPropagation();
			}
		});

		document.addEventListener('keyup', (event) => {
			if (event.key === 'Escape') {
				this.escapeKeyPressed.emit();
				event.preventDefault();
				event.stopPropagation();
			}
		});
	}
}
