import type { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core';

import { BasicFilters, BooleanFilters, StringFilters } from '@furnas-technology/common-library/filters';
import { FT_NestedKeyOf, FT_NestedValue } from '@furnas-technology/common-library/functions';
import { type Observable } from 'rxjs';

export type CellValueType<T> = T[keyof T];

export type DisplayableValue = string | number | boolean | Date;
export type CouldBeObservableDisplayableValue = DisplayableValue | Observable<DisplayableValue>;

export type RawCellValue<T> = T[keyof T] | FT_NestedValue<T, FT_NestedKeyOf<T>>;

export type NestedKeyOf<ObjectType extends object> = {
	[Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
		? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
		: `${Key}`;
}[keyof ObjectType & (string | number)];

export type StyleObject = {
	[key: string]: string | number;
};

export type DataRow<T extends object> = T & {
	_id?: string;
	isEdit?: boolean;
} & {
	[K in keyof T]: T[K] extends object ? string | number | boolean : T[K];
};

export type Position = 'start' | 'mid' | 'end';
export type FilterButtonType = 'apply' | 'clear' | 'reset' | 'cancel';

export type TableColumnType =
	| 'text'
	| 'number'
	| 'image'
	| 'currency'
	| 'decimal'
	| 'boolean'
	| 'date'
	| 'actions'
	| 'partialDate';

export type SortableColumnType = Exclude<TableColumnType, 'actions'>;

export const AlignCenterColumnTypes: TableColumnType[] = ['image', 'boolean', 'actions', 'date', 'number'];
export const AlignStartColumnTypes: TableColumnType[] = ['text', 'partialDate'];
export const AlignEndColumnTypes: TableColumnType[] = ['currency', 'decimal'];

export type WidthValue = string | number | undefined;
export type HeightValue = string | number | undefined;

export type DefaultWidths = {
	width?: WidthValue;
	minWidth?: WidthValue;
	maxWidth?: WidthValue;
};

export type TableColumnWidth = {
	[key in TableColumnType]: DefaultWidths;
};

export const DefaultTypeWidths: TableColumnWidth = {
	image: { width: 80 },
	// text: { width: `clamp(90px, 20vw, 400px)` },
	text: { width: 'fit-content' },
	// number: { width: 80 },
	number: { width: 'fit-content' },
	// currency: { width: 80 },
	currency: { width: 'fit-content' },
	// decimal: { width: 80 },
	decimal: { width: 'fit-content' },
	// boolean: { width: 50 },
	boolean: { width: 'fit-content' },
	// date: { width: 120 },
	date: { width: 'fit-content' },
	// partialDate: { width: 100 },
	partialDate: { width: 'fit-content' },
	// actions: { width: 120 },
	actions: { minWidth: 0 },
};

export const DefaultTypeHeights: Record<string, number | string> = {
	image: '3rem', // 60,
};

export const DB_COLUMN_TYPES = ['text', 'number', 'image', 'currency', 'decimal', 'boolean', 'date'];

export type TableRowId = string | string | number;

export type TableButton = {
	buttonType: ButtonType;
	buttonName: string;
	buttonLabel?: string;
	buttonIcon: IconDefinition;
	elementId?: string;
	menuButtons?: TableButton[];
	hoverColor?: string;
};

export type ButtonType = 'button' | 'menu';
export type TableAlign = 'center' | 'start' | 'end';

export type MenuButton = {
	buttonType: ButtonType;
	buttonName: string;
	buttonIcon: IconProp;
};

export type ColumnSearchValue = string | number | boolean | Date | string[] | null;

export type FilterOption = {
	label:
		| 'Equal'
		| 'Not Equal'
		| 'Contains'
		| 'Does Not Contain'
		| 'Starts With'
		| 'Ends With'
		| 'Greater Than'
		| 'Less Than'
		| 'Greater Than Or Equal To'
		| 'Less Than Or Equal To'
		| 'Month'
		| 'Empty'
		| 'Not Empty'
		| 'Yes'
		| 'No';
	comparison:
		| 'eq'
		| 'neq'
		| 'contains'
		| 'notContains'
		| 'startsWith'
		| 'endsWith'
		| 'gt'
		| 'lt'
		| 'gte'
		| 'lte'
		| 'month'
		| 'empty'
		| 'notEmpty'
		| 'true'
		| 'false';
	applicableTypes: TableColumnType[];
	requiresValue: boolean;
};

export const FILTER_OPTIONS: FilterOption[] = [
	{ label: 'Equal', comparison: 'eq', applicableTypes: ['text', 'number', 'currency', 'date'], requiresValue: true },
	{
		label: 'Not Equal',
		comparison: 'neq',
		applicableTypes: ['text', 'number', 'currency', 'date'],
		requiresValue: true,
	},
	{
		label: 'Contains',
		comparison: 'contains',
		applicableTypes: ['text', 'number', 'currency', 'partialDate', 'date'],
		requiresValue: true,
	},
	{
		label: 'Does Not Contain',
		comparison: 'notContains',
		applicableTypes: ['text', 'number', 'currency', 'partialDate', 'date'],
		requiresValue: true,
	},
	{
		label: 'Starts With',
		comparison: 'startsWith',
		applicableTypes: ['text', 'partialDate', 'date'],
		requiresValue: true,
	},
	{ label: 'Ends With', comparison: 'endsWith', applicableTypes: ['text', 'partialDate', 'date'], requiresValue: true },
	{
		label: 'Greater Than',
		comparison: 'gt',
		applicableTypes: ['number', 'currency', 'date', 'partialDate'],
		requiresValue: true,
	},
	{
		label: 'Less Than',
		comparison: 'lt',
		applicableTypes: ['number', 'currency', 'date', 'partialDate'],
		requiresValue: true,
	},
	{
		label: 'Greater Than Or Equal To',
		comparison: 'gte',
		applicableTypes: ['number', 'currency', 'date', 'partialDate'],
		requiresValue: true,
	},
	{
		label: 'Less Than Or Equal To',
		comparison: 'lte',
		applicableTypes: ['number', 'currency', 'date', 'partialDate'],
		requiresValue: true,
	},
	{ label: 'Month', comparison: 'month', applicableTypes: ['date'], requiresValue: true },

	{
		label: 'Empty',
		comparison: 'empty',
		applicableTypes: ['text', 'number', 'currency', 'date', 'partialDate'],
		requiresValue: false,
	},
	{
		label: 'Not Empty',
		comparison: 'notEmpty',
		applicableTypes: ['text', 'number', 'currency', 'date', 'partialDate'],
		requiresValue: false,
	},
	{ label: 'Yes', comparison: 'true', applicableTypes: ['boolean'], requiresValue: false },
	{ label: 'No', comparison: 'false', applicableTypes: ['boolean'], requiresValue: false },
] as const;

export type FILTER_LABEL = (typeof FILTER_OPTIONS)[number]['label'];
export type FILTER_COMPARISON = (typeof FILTER_OPTIONS)[number]['comparison'];

type SearchFunction = (fieldValue: CouldBeObservableDisplayableValue, filterValue: ColumnSearchValue) => boolean;
type SearchFunctionMap = {
	[key in FILTER_COMPARISON]: SearchFunction;
};

export const SearchFunctions: Partial<SearchFunctionMap> = {
	contains: (fieldValue: CouldBeObservableDisplayableValue, filterValue: ColumnSearchValue) =>
		String(fieldValue).toLowerCase().trim().includes(String(filterValue).toLowerCase().trim()),
	notContains: (fieldValue: CouldBeObservableDisplayableValue, filterValue: ColumnSearchValue) =>
		!String(fieldValue).toLowerCase().trim().includes(String(filterValue).toLowerCase().trim()),
	eq: (fieldValue: CouldBeObservableDisplayableValue, filterValue: ColumnSearchValue) =>
		String(fieldValue).toLowerCase().trim() === String(filterValue).toLowerCase().trim(),
	neq: (fieldValue: CouldBeObservableDisplayableValue, filterValue: ColumnSearchValue) =>
		String(fieldValue).toLowerCase().trim() !== String(filterValue).toLowerCase().trim(),
	startsWith: (fieldValue: CouldBeObservableDisplayableValue, filterValue: ColumnSearchValue) =>
		String(fieldValue).toLowerCase().trim().startsWith(String(filterValue).toLowerCase().trim()),
	endsWith: (fieldValue: CouldBeObservableDisplayableValue, filterValue: ColumnSearchValue) =>
		String(fieldValue).toLowerCase().trim().endsWith(String(filterValue).toLowerCase().trim()),
	gt: (fieldValue: CouldBeObservableDisplayableValue, filterValue: ColumnSearchValue) =>
		String(fieldValue).toLowerCase().trim() > String(filterValue).toLowerCase().trim(),
	lt: (fieldValue: CouldBeObservableDisplayableValue, filterValue: ColumnSearchValue) =>
		String(fieldValue).toLowerCase().trim() < String(filterValue).toLowerCase().trim(),
	gte: (fieldValue: CouldBeObservableDisplayableValue, filterValue: ColumnSearchValue) =>
		String(fieldValue).toLowerCase().trim() >= String(filterValue).toLowerCase().trim(),
	lte: (fieldValue: CouldBeObservableDisplayableValue, filterValue: ColumnSearchValue) =>
		String(fieldValue).toLowerCase().trim() <= String(filterValue).toLowerCase().trim(),
	month: (fieldValue: CouldBeObservableDisplayableValue, filterValue: ColumnSearchValue) =>
		fieldValue instanceof Date && fieldValue.getMonth() === Number(filterValue),
};

export const NumericSearchFunctions: Partial<SearchFunctionMap> = {
	eq: (fieldValue: CouldBeObservableDisplayableValue, filterValue: ColumnSearchValue) =>
		Number(fieldValue) === Number(filterValue),
	neq: (fieldValue: CouldBeObservableDisplayableValue, filterValue: ColumnSearchValue) =>
		Number(fieldValue) !== Number(filterValue),
	gt: (fieldValue: CouldBeObservableDisplayableValue, filterValue: ColumnSearchValue) =>
		Number(fieldValue) > Number(filterValue),
	lt: (fieldValue: CouldBeObservableDisplayableValue, filterValue: ColumnSearchValue) =>
		Number(fieldValue) < Number(filterValue),
	gte: (fieldValue: CouldBeObservableDisplayableValue, filterValue: ColumnSearchValue) =>
		Number(fieldValue) >= Number(filterValue),
	lte: (fieldValue: CouldBeObservableDisplayableValue, filterValue: ColumnSearchValue) =>
		Number(fieldValue) <= Number(filterValue),
};

export type TableFilters = BasicFilters | StringFilters | BooleanFilters;

export type FilterOperation = {
	name: TableFilters;
	text: string;
	symbol: string;
	needsFilter: boolean;
	includeForType: TableColumnType[] | '*all';
	excludeForType: TableColumnType[] | '*all';
	value?: string | number | boolean | undefined;
};

export const DefaultFilterOperation: FilterOperation = {
	name: '',
	text: '',
	symbol: '',
	needsFilter: false,
	includeForType: [],
	excludeForType: '*all',
	value: undefined,
};

export const FilterOperations: { [key in TableFilters]: FilterOperation } = {
	'': {
		name: '',
		text: '', // Adjust text for empty filter
		symbol: '',
		needsFilter: false,
		includeForType: [],
		excludeForType: '*all',
	},
	equals: {
		name: 'equals',
		text: 'Equals',
		symbol: '=',
		needsFilter: true,
		includeForType: [],
		excludeForType: ['actions', 'image', 'boolean'],
	},
	contains: {
		name: 'contains',
		text: 'Contains',
		symbol: '≈',
		needsFilter: true,
		includeForType: [],
		excludeForType: ['actions', 'image', 'boolean', 'number'],
	},
	doesNotContain: {
		name: 'doesNotContain',
		text: 'Does Not Contain',
		symbol: '!≈',
		needsFilter: true,
		includeForType: [],
		excludeForType: ['actions', 'image', 'boolean', 'number'],
	},
	notEqual: {
		name: 'notEqual',
		text: 'Not Equal',
		symbol: 'Not =',
		needsFilter: true,
		includeForType: [],
		excludeForType: ['actions', 'image', 'boolean'],
	},
	empty: {
		name: 'empty',
		text: 'Is Empty',
		symbol: 'Empty',
		needsFilter: false,
		includeForType: [],
		excludeForType: ['actions', 'image', 'boolean', 'number'],
	},
	notEmpty: {
		name: 'notEmpty',
		text: 'Is Not Empty',
		symbol: 'Not Empty',
		needsFilter: false,
		includeForType: [],
		excludeForType: ['actions', 'image', 'boolean', 'number'],
	},
	yes: {
		name: 'yes',
		text: 'Yes',
		symbol: 'Y',
		needsFilter: false,
		includeForType: ['boolean'],
		excludeForType: [],
		value: true,
	},
	no: {
		name: 'no',
		text: 'No',
		symbol: 'N',
		needsFilter: false,
		includeForType: ['boolean'],
		excludeForType: [],
		value: false,
	},
} as const;

export type TableBooleanDisplay = {
	value: boolean;
	label: 'Yes' | 'No';
};

export const DefaultFilterName: { [key in TableColumnType]: TableFilters | undefined } = {
	number: 'equals',
	boolean: undefined,
	text: 'contains',
	image: undefined,
	currency: 'equals',
	decimal: 'equals',
	date: 'contains',
	actions: undefined,
	partialDate: 'equals',
};
