import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

import { isFT_DisplayDateFormat } from '@furnas-technology/common-library/functions';
import { TableColumn } from '../table-column.model';
import {
	FT_formatBoolean,
	FT_formatCurrency,
	FT_formatDate,
	FT_formatDateAgo,
	FT_formatDecimal,
	FT_formatPartialDate,
} from '../table-functions';
import { DisplayableValue } from '../table-generic.model';

@Pipe({ name: 'tableFieldDisplay', pure: true, standalone: true })
export class TableFieldDisplayPipe implements PipeTransform {
	constructor(@Inject(LOCALE_ID) private locale: string) {}

	transform<T extends object>(value: DisplayableValue | null, tableColumn: TableColumn<T>): DisplayableValue {
		if (value === null || value === undefined) {
			return '';
		} else if (tableColumn.columnType === 'text' || (tableColumn.columnType === 'number' && !tableColumn.format)) {
			return value;
		} else if (tableColumn.columnType === 'boolean') {
			return FT_formatBoolean(value);
		} else if (tableColumn.columnType === 'decimal' || (tableColumn.columnType === 'number' && !!tableColumn.format)) {
			return FT_formatDecimal(value, tableColumn.format ?? '', this.locale);
		} else if (tableColumn.columnType === 'currency') {
			return FT_formatCurrency(value, this.locale);
		} else if (tableColumn.columnType === 'partialDate') {
			return FT_formatPartialDate(value, isFT_DisplayDateFormat(tableColumn.format) ? tableColumn.format : 'medium');
		} else if (tableColumn.columnType === 'date') {
			if (tableColumn.format === 'date-ago') {
				return FT_formatDateAgo(value);
			} else if (tableColumn.format === 'date-iso') {
				return FT_formatDate(value, 'iso', this.locale);
			} else if (tableColumn.format === 'timestamp') {
				return FT_formatDate(value, 'timestamp', this.locale);
			} else {
				return FT_formatDate(
					value,
					isFT_DisplayDateFormat(tableColumn.format) ? tableColumn.format : 'medium',
					this.locale,
				);
			}
		}

		// if nothing else, return value as string
		return String(value);
	}
}
