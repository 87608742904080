import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	LOCALE_ID,
	OnInit,
	computed,
	inject,
	input,
	output,
	signal,
} from '@angular/core';

import { DatePipe, NgClass, NgStyle } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faClose } from '@fortawesome/free-solid-svg-icons';

import { MatButtonModule } from '@angular/material/button';
import { type DataRow, DisplayableValue, StyleObject, TableColumnType, type TableRowId } from '../table-generic.model';

import { TextFieldModule } from '@angular/cdk/text-field';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { MagicImageComponent } from '@ft/lib/media-lib';
import { issueCustomTooltipDefaults } from '@ft/lib/models';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { DateAgoPipe } from '@furnas-technology/angular-library/pipes';
import { Observable, Subscription, isObservable } from 'rxjs';
import { ConditionalAsyncComponent } from '../conditional-async/conditional-async.component';
import { ChangedTableColumnValue, TableColumn } from '../table-column.model';

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
type ElementType = any;

@Component({
	selector: 'ft-edit-cell',
	templateUrl: './edit-cell.component.html',
	styleUrls: ['./edit-cell.component.scss'],
	imports: [
		ConditionalAsyncComponent,
		DatePipe,
		DateAgoPipe,
		NgClass,
		NgStyle,
		FontAwesomeModule,
		FormsModule,
		MagicImageComponent,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule,
		MatSelectModule,
		MatTooltipModule,
		ReactiveFormsModule,
		TextFieldModule,
	],
	providers: [
		{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: issueCustomTooltipDefaults },
		{ provide: LOCALE_ID, useValue: navigator.language || 'en-AU' },
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditCellComponent<T extends object> implements OnInit {
	protected destroyRef = inject(DestroyRef);
	protected layout = inject(LayoutInfoService);

	column = input.required<TableColumn<T>>();
	element = input.required<ElementType>();
	rowId = input<TableRowId>();
	displayableValue = input.required<DisplayableValue | Observable<DisplayableValue>>();

	tooltipValue = input<string>();

	changedColumn = output<ChangedTableColumnValue<T>>();

	faImage = faImage;
	faClose = faClose;
	isHovered = false;

	valueIsObservable = computed<boolean>(() => isObservable(this.displayableValue()));

	placeHolder = computed<string>(() => {
		console.debug(
			`${this.constructor.name} - placeHolder=${this.column().placeHolder}, typeof=${typeof this.column().placeHolder}`,
		);
		console.debug(`${this.constructor.name} - label=${this.column().label}, typeof=${typeof this.column().label}`);
		if (!!this.column().placeHolder) return this.column().placeHolder ?? '';
		if (!!this.column().label) return `Enter ${this.column().label}...`;
		return '';
	});

	observableValue = computed(() => {
		return this.displayableValue() as Observable<DisplayableValue>;
	});

	nonObservableValue = computed<DisplayableValue>(() => {
		return this.displayableValue() as DisplayableValue;
	});

	elementId = computed(() => this.column().elementId);
	elementValue = computed(() => {
		const elementId = this.column().elementId as keyof T;
		return this.element()[elementId];
	});

	cellStringValue = computed(() => this.column().cellStringValue(this.element()));

	selectedValues = signal<string[]>([]);

	private inputValue = signal<ChangedTableColumnValue<T> | undefined>(undefined);
	private inputSubscription: Subscription | undefined;

	columnName = computed<string>(() => this.column().name);
	rawValue = computed(() => this.column().rawValue(this.element()));

	columnType = computed<TableColumnType>(() => {
		return this.column().columnType;
	});

	inputColumnType = computed<'input' | 'date' | 'partialDate' | 'select' | 'image' | 'none'>(() => {
		const columnType = this.column().columnType;
		const selectOptions = this.column().selectOptions;

		if (selectOptions?.length) {
			return 'select';
		} else if ((['text', 'number', 'boolean', 'currency', 'decimal'] as TableColumnType[]).includes(columnType)) {
			return 'input';
		} else if (columnType === 'date') {
			return 'date';
		} else if (columnType === 'partialDate') {
			return 'partialDate';
		} else if (columnType === 'image') {
			return 'image';
		} else {
			return 'none';
		}
	});

	editStyle = computed<StyleObject>(() => {
		const styles: StyleObject = {};
		const fontSize = this.column()?.fontSize;
		if (!!fontSize) styles['font-size'] = fontSize;
		return styles;
	});

	constructor() {}

	ngOnInit(): void {
		// create select values if necessary
		if (this.column().selectOptions?.length) {
			const rawValue = Array.isArray(this.rawValue()) ? this.rawValue() : [];
			if (Array.isArray(rawValue)) {
				this.selectedValues.set(rawValue);
			} else {
				this.selectedValues.set([]);
			}
		}

		this.destroyRef.onDestroy(() => {
			this.inputSubscription?.unsubscribe();
		});

		console.debug(
			`${this.constructor.name} - ngOnInit - ${this.column().name} - column().mainClass=${typeof this.column().mainClass}`,
			this.column().mainClass,
		);
		console.debug(
			`${this.constructor.name} - ngOnInit - ${this.column().name} - column().cellClass=${typeof this.column().cellClass}`,
			this.column().cellClass,
		);
	}

	editRow(dataRow: DataRow<T> | undefined) {
		if (dataRow?.['_id']) {
			dataRow['isEdit'] = true;
		} else if (dataRow) {
			dataRow['isEdit'] = false;
		}
	}

	addRow() {
		const newRow = {
			id: '',
		};
	}

	onTextInput(column: TableColumn<T>, evt: Event) {
		const value = (evt.target as HTMLInputElement).value;
		column.inputValue = value as T[keyof T];
		this.inputValue.set({ column: column, value: value, rowId: this.rowId() ?? '' });
	}

	onSelectInput(column: TableColumn<T>, evt: MatSelectChange) {
		column.inputValue = Array.isArray(evt.value) ? (evt.value.join(',') as T[keyof T]) : (evt.value ?? '');
		this.inputValue.set({ column: column, value: evt.value, rowId: this.rowId() ?? '' });
	}

	clearSelections(column: TableColumn<T>, evt: Event) {
		evt.stopPropagation();
		evt.preventDefault();

		this.selectedValues.set([]);

		column.inputValue = '' as T[keyof T];
		this.inputValue.set({ column: column, value: [], rowId: this.rowId() ?? '' });
	}

	finishEditing(element: ElementType): void {
		console.debug(`${this.constructor.name} - finishEditing - element=`, element());
		console.debug(
			`${this.constructor.name} - finishEditing - displayableValue=${this.displayableValue()}, column.inputValue=${this.column().inputValue}`,
		);

		console.debug(`${this.constructor.name} - finishEditing - inputValue=`, this.inputValue());

		const inputValue = this.inputValue();

		if (!!inputValue && this.displayableValue() !== inputValue.value) {
			this.changedColumn.emit(inputValue);
		}
		// this.inputValue.next({ column: this.column(), value: this.displayableValue(), rowId: this.rowId() ?? '' });
	}

	handleChangedImage(image: string): void {
		console.debug(`${this.constructor.name} - handleChangedLogo - logo.length=${image ? image.length : 0}`);
	}
}
