import { VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { ChangeDetectorRef, Directive, ElementRef, effect, forwardRef, inject, input } from '@angular/core';
import { DynamicSizeVirtualScrollStrategy } from './virtual-scroll-strategy';

type ItemHeight = number[];
const DEFAULT_ITEM_HEIGHT = 50;

function factory(dir: DynamicSizeVirtualScrollDirective) {
	return dir.scrollStrategy;
}

@Directive({
	selector: 'cdk-virtual-scroll-viewport[dynamicSize]',
	standalone: true,
	providers: [
		{
			provide: VIRTUAL_SCROLL_STRATEGY,
			useFactory: factory,
			deps: [forwardRef(() => DynamicSizeVirtualScrollDirective)],
		},
	],
})
export class DynamicSizeVirtualScrollDirective {
	private elRef = inject(ElementRef);
	private cdr = inject(ChangeDetectorRef);

	defaultItemHeight = input<number>(DEFAULT_ITEM_HEIGHT);
	querySelector = input<string>('.cdk-virtual-item');

	scrollStrategy: DynamicSizeVirtualScrollStrategy = new DynamicSizeVirtualScrollStrategy(
		this.defaultItemHeight(),
		this.querySelector(),
	);

	constructor() {
		/**
		 * Process changes to itemHeights
		 */
		effect(() => {
			const defaultItemHeight = this.defaultItemHeight();
			console.debug(`${this.constructor.name} - EFFECT - defaultItemHeight=${defaultItemHeight}`);
			this.scrollStrategy.updateItemHeights(defaultItemHeight);
			this.cdr.detectChanges();
		});
	}
}

// import { CdkVirtualScrollViewport, VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
// import { DestroyRef, Directive, ElementRef, OnInit, effect, forwardRef, inject, input } from '@angular/core';
// import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
// import { DynamicSizeVirtualScrollStrategy } from './virtual-scroll-strategy';

// const EstimatedItemHeight = 50; // Fallback estimate

// @Directive({
// 	selector: 'ft-virtual-scroll-viewport[dynamicSize]',
// 	standalone: true,
// 	providers: [
// 		{
// 			provide: VIRTUAL_SCROLL_STRATEGY,
// 			useFactory: (directive: DynamicSizeVirtualScrollDirective) => directive.scrollStrategy,
// 			deps: [forwardRef(() => DynamicSizeVirtualScrollDirective)],
// 		},
// 	],
// })
// export class DynamicSizeVirtualScrollDirective implements OnInit {
// 	private viewport = inject(CdkVirtualScrollViewport, { optional: true });
// 	private elementRef = inject(ElementRef);
// 	private destroyRef = inject(DestroyRef);

// 	// Use signal input
// 	estimatedItemHeight = input<number>(EstimatedItemHeight);

// 	// The scroll strategy instance
// 	scrollStrategy = new DynamicSizeVirtualScrollStrategy(this.estimatedItemHeight());

// 	constructor() {
// 		/**
// 		 * Create an effect to update the strategy when the viewport is attached
// 		 */
// 		effect(() => {
// 			const height = this.estimatedItemHeight();
// 			this.scrollStrategy = new DynamicSizeVirtualScrollStrategy(height);

// 			/**
// 			 * Trigger a recalculation if the viewport is already attached
// 			 */
// 			if (this.viewport) {
// 				this.viewport.checkViewportSize();
// 			}
// 		});
// 	}

// 	ngOnInit() {
// 		console.debug(`${this.constructor.name} - ngOnInit - this.estimatedItemHeight=${this.estimatedItemHeight()}`);
// 		/**
// 		 * Handle content rendered events to measure items
// 		 */
// 		if (this.viewport) {
// 			this.viewport.renderedRangeStream.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
// 				setTimeout(() => this.scrollStrategy.onContentRendered());
// 			});
// 		}
// 	}
// }
