import { Injectable, effect, inject, linkedSignal, signal } from '@angular/core';
import { Params } from '@angular/router';
import {
	AllFilters,
	FieldFilter,
	FieldFilterInput,
	FilterByFields,
	FilteredRecord,
	SearchBarField,
	SearchStringToFieldFilters,
} from '@furnas-technology/common-library/filters';
import { ActiveFilterStore, UpdateFilterForFieldnamesInput } from '../store/active-filter.store';
import { RegisteredComponentFilter } from './active-filter.model';
import { getMatchingSearchBarFields } from './filter.functions';

const sanitiseString = (searchString: string | undefined) => {
	return String(searchString ?? '').trim();
};

type FilterDataRequest<T> = {
	records: T[];
	searchBarFields: SearchBarField<T>[];
	fieldFilters: FieldFilter<T>[];
	lastChange: number;
};

type FilterDataBySearchBarRequest<T> = {
	records: T[];
	searchBarFields: SearchBarField<T>[];
	fieldFilters: FieldFilter<T>[];
	lastChange: number;
	searchStringOverride: string;
};

type FilterRecordsRequest<T> = {
	records: T[];
	searchBarFields: SearchBarField<T>[];
	fieldFilters: FieldFilter<T>[];
	filterType: 'all' | 'searchbar' | 'field';
	searchStringOverride: string | undefined;
};

@Injectable({
	providedIn: 'root',
})
export class ActiveFilterService {
	private activeFilterStore = inject(ActiveFilterStore);

	private previousQueryParamsString = signal<string>('');

	getRegisteredFilterByName(filterName: string): RegisteredComponentFilter<unknown> | undefined {
		return this.activeFilterStore.selectRegisteredFilters().find((item) => item.filterName === filterName);
	}

	selectActiveFieldFilters = linkedSignal(() => this.activeFilterStore.selectActiveFieldFilters());
	selectShowSearchBar = linkedSignal(() => this.activeFilterStore.selectShowSearchBar());
	selectActiveSearchString = linkedSignal(() => this.activeFilterStore.selectActiveSearchString());
	selectActiveComponentFilter = linkedSignal(() => this.activeFilterStore.selectActiveComponentFilter());
	visibleFilterCount = linkedSignal(() => this.activeFilterStore.visibleFilterCount());
	selectLastUpdated = linkedSignal(() => this.activeFilterStore.selectLastUpdated());
	selectActiveSearchBarInFilterComponent = linkedSignal(() =>
		this.activeFilterStore.selectActiveSearchBarInFilterComponent(),
	);
	selectActiveSearchBarFields = linkedSignal(() => this.activeFilterStore.selectActiveSearchBarFields());
	selectRegisteredFilters = linkedSignal(() => this.activeFilterStore.selectRegisteredFilters());

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);

		effect(() => {
			const activeFieldFilters = this.activeFilterStore.selectActiveFieldFilters();
			console.debug(`${this.constructor.name} - effect - activeFieldFilters=`, activeFieldFilters);
		});
	}

	updateFilterForFieldnames(input: UpdateFilterForFieldnamesInput): void {
		this.activeFilterStore.updateFilterForFieldnames(input);
	}

	setActiveSearchString(searchString: string): void {
		this.activeFilterStore.setActiveSearchString(searchString);
	}

	updateActiveFieldFilters(updatedFieldFilters: FieldFilter<unknown>[] = []): void {
		this.activeFilterStore.updateActiveFieldFilters(updatedFieldFilters);
	}

	clearActiveFilter(): void {
		this.activeFilterStore.clearActiveFilter();
	}

	filterData<T>(request: FilterDataRequest<T>): FilteredRecord<T>[] {
		const filteredRecords = this.filterRecords({
			records: request.records,
			searchBarFields: request.searchBarFields,
			fieldFilters: request.fieldFilters,
			filterType: 'all',
			searchStringOverride: undefined,
		});

		return filteredRecords;
	}

	filterDataBySearchBar<T>(request: FilterDataBySearchBarRequest<T>): FilteredRecord<T>[] {
		const filteredRecords = this.filterRecords({
			records: request.records,
			searchBarFields: request.searchBarFields,
			fieldFilters: request.fieldFilters,
			filterType: 'searchbar',
			searchStringOverride: request.searchStringOverride,
		});
		return filteredRecords;
	}

	/**
	 * filter records
	 */
	private filterRecords<T>(request: FilterRecordsRequest<T>): FilteredRecord<T>[] {
		const records = request.records;
		const searchBarFields = request.searchBarFields;
		const fieldFilters = request.fieldFilters;
		const filterType = request.filterType;
		const searchStringOverride = request.searchStringOverride;

		/**
		 * perform filter
		 */
		if (!records.length) return [];
		/**
		 * Create list of matching fields for searchBarFilters
		 */
		const searchString =
			searchStringOverride === undefined
				? sanitiseString(this.activeFilterStore.selectActiveSearchString())
				: searchStringOverride;

		const searchBarFilters = SearchStringToFieldFilters<T>(searchString);
		for (const searchBarFilter of searchBarFilters) {
			const matchingSearchBarFields = getMatchingSearchBarFields<T>(searchBarFields, searchBarFilter.filterDataType);
			searchBarFilter.fieldnames = matchingSearchBarFields;
		}

		/**
		 * log searchBarFilters and fieldFilters
		 */
		console.debug(`${this.constructor.name} - filterRecords - searchBarFilters=`, searchBarFilters);
		console.debug(`${this.constructor.name} - filterRecords - fieldFilters=`, fieldFilters);

		/**
		 * Map and filter records - loop thru each record
		 */
		const filteredRecords = records.map((rec: T) => {
			// create filteredRecord
			const filteredRecord: FilteredRecord<T> = {
				selected: null,
				omitted: null,
				record: rec,
			};

			/**
			 * perform searchbar filters
			 */
			if (
				['all', 'searchbar'].includes(filterType) &&
				searchBarFilters.length &&
				this.activeFilterStore.selectShowSearchBar()
			) {
				FilterByFields(filteredRecord, searchBarFilters);
			}

			/**
			 * Peform field filters
			 */
			if (['all', 'field'].includes(filterType) && fieldFilters.length) {
				FilterByFields(filteredRecord, fieldFilters);
			}

			return filteredRecord;
		});

		let selectedCount = 0;
		let omittedCount = 0;
		for (const x of filteredRecords) {
			if (x.selected) selectedCount += 1;
			if (x.omitted) omittedCount += 1;
		}

		return filteredRecords;
	}

	/**
	 * Exclude specific field from filter
	 */
	excludeFieldsFilter<T>(
		records: T[],
		excludeFields: string[] = [],
		returnType: 'selected' | 'notOmitted' = 'notOmitted',
	): T[] {
		/**
		 * perform filter when records or active filters changes
		 */
		if (!records.length) return [];

		/**
		 * Map and filter records - loop thru each record
		 */
		const filteredRecords: T[] = [];

		for (const rec of records) {
			// create filteredRecord
			const filteredRecord: FilteredRecord<T> = {
				selected: null,
				omitted: null,
				record: rec,
			};

			/**
			 * Create fieldFilters from activeFilterSetting
			 */
			const fieldFilters = this.activeFilterStore.selectActiveFieldFilters()?.filter((x) => {
				return !x.fieldnames.some((elem) => excludeFields.includes(String(elem)));
			});

			// perform field filters
			if (fieldFilters?.length) {
				FilterByFields(filteredRecord, fieldFilters);
			}

			if (
				(returnType === 'notOmitted' && !filteredRecord.omitted) ||
				(returnType === 'selected' && filteredRecord.selected)
			) {
				filteredRecords.push(filteredRecord.record);
			}
		}

		return filteredRecords;
	}

	/**
	 * updates array of fieldFilters
	 */
	updateFieldFilters = <T>(
		updatedFieldFilter: FieldFilter<T>,
		fieldFilters: FieldFilter<T>[] | undefined,
		activeFilter: RegisteredComponentFilter<unknown> | undefined = undefined,
	): FieldFilter<T> | undefined => {
		try {
			// validation
			console.debug(
				`${this.constructor.name} - updateFieldFilters - field=${updatedFieldFilter.fieldnames}, updatedFieldFilter, fieldFilters=`,
				updatedFieldFilter,
				fieldFilters,
			);
			if (!updatedFieldFilter.fieldnames?.length) return undefined;

			// default activeComponentFilter
			const activeComponentFilter = activeFilter ?? this.activeFilterStore.selectActiveComponentFilter();

			// use store fieldFilters or passed in fieldFilters
			const curFieldFilters = fieldFilters ? fieldFilters : [...(activeComponentFilter?.fieldFilters ?? [])];

			// load current field filter
			const existingFilter = curFieldFilters.find(
				(item) => item.fieldnames.length && item.fieldnames[0] === updatedFieldFilter.fieldnames[0],
			);
			const updatedFilterData = existingFilter ? { ...existingFilter, ...updatedFieldFilter } : updatedFieldFilter;
			const upsertedFieldFilter = new FieldFilter(updatedFilterData);

			console.debug(`👉 ${this.constructor.name} - updateFieldFilters - upsertedFieldFilter=`, upsertedFieldFilter);

			return upsertedFieldFilter;
		} catch (err: unknown) {
			console.error(`❌ ${this.constructor.name} - updateFieldFilters=${updatedFieldFilter?.fieldnames}, err=`, err);
			return undefined;
		}
	};

	updateBooleanFilter(
		fieldname: string,
		value: boolean,
		trueFilterOperation: AllFilters,
		falseFilterOperation: AllFilters,
	) {
		console.debug(
			`👉 ${this.constructor.name} - watch - updateBooleanFilter - fieldname=${fieldname}, value=${value}, trueFilterOperation=${trueFilterOperation}, falseFilterOperation=${falseFilterOperation}`,
		);

		const filterOperation = value ? trueFilterOperation : falseFilterOperation;
		this.activeFilterStore.updateFilterForFieldnames({
			fieldnames: fieldname,
			operation: filterOperation,
			filterValues: [],
		});
	}

	updateYesField(fieldname: string, value: boolean) {
		console.debug(`👉 ${this.constructor.name} - watch - updateYesField - fieldname=${fieldname}, value=${value}`);

		const filterOperation = value ? 'yes' : '';
		this.activeFilterStore.updateFilterForFieldnames({
			fieldnames: fieldname,
			operation: filterOperation,
			filterValues: [],
		});
	}

	updateNoField(fieldname: string, value: boolean) {
		console.debug(`👉 ${this.constructor.name} - watch - updateNoField - fieldname=${fieldname}, value=${value}`);

		const filterOperation = value ? 'no' : '';
		this.activeFilterStore.updateFilterForFieldnames({
			fieldnames: fieldname,
			operation: filterOperation,
			filterValues: [],
		});
	}

	updateNotEmptyField(fieldname: string, value: boolean) {
		console.debug(`👉 ${this.constructor.name} - watch - updateNotEmptyField - fieldname=${fieldname}, value=${value}`);

		const filterOperation = value ? 'notEmpty' : '';
		this.activeFilterStore.updateFilterForFieldnames({
			fieldnames: fieldname,
			operation: filterOperation,
			filterValues: [],
		});
	}

	updateNotEmptyOrTrueField(fieldname: string, value: boolean) {
		console.debug(
			`👉 ${this.constructor.name} - watch - updateNotEmptyOrTrueField - fieldname=${fieldname}, value=${value}`,
		);

		const filterOperation = value ? 'notEmpty' : '';
		this.activeFilterStore.updateFilterForFieldnames({
			fieldnames: fieldname,
			operation: filterOperation,
			filterValues: [],
		});
	}

	/**
	 * registers field filters - defines  filterName, component, searchString, searchBarFields, initialFilters, etc.
	 */
	registerComponentFilter<T>(
		filter: RegisteredComponentFilter<unknown>,
		makeActive: boolean = false,
		replaceFilter: boolean = false,
	) {
		if (!filter.filterName) return;

		console.debug(
			`👉 ${this.constructor.name} - registerComponentFilter - makeActive=${makeActive}, replaceFilter=${replaceFilter}, filter=`,
			filter,
		);

		try {
			/**
			 * Determine if this is an existing registered filter
			 */
			const existingFilter = this.getRegisteredFilterByName(filter.filterName);
			const fieldFilters =
				!existingFilter || replaceFilter
					? this.loadInitialFilters(filter.fieldFilters ?? [], filter.initialFilters ?? [])
					: filter.fieldFilters;
			const componentFilter = { ...filter, fieldFilters: fieldFilters };
			/**
			 * register filter with active filter store
			 */
			this.activeFilterStore.registerComponentFilter(componentFilter, makeActive, replaceFilter);
		} catch (err: unknown) {
			console.error(`❌ ${this.constructor.name} - registerComponentFilter=${filter.filterName}, err=`, err);
		}
	}

	/**
	 * Load initial filters
	 */
	loadInitialFilters<T>(existingFieldFilters: FieldFilter<T>[], initialFilters: FieldFilterInput[]): FieldFilter<T>[] {
		if (!initialFilters?.length) return existingFieldFilters;

		/**
		 * create initial filters
		 */
		const fieldFilters = [...existingFieldFilters] as FieldFilter<T>[];

		for (const iFilter of initialFilters) {
			const newFilter: FieldFilter<T> = new FieldFilter({
				fieldnames: iFilter.fieldnames,
				operation: iFilter.operation,
				filterValues: iFilter.filterValues,
				filterDataType: iFilter.filterDataType,
				defaultOperation: iFilter.defaultOperation,
				defaultFilterValues: iFilter.defaultFilterValues,
			});

			console.debug(`${this.constructor.name} - loadInitialFilters - newFilter=`, newFilter);

			const existingIndex = fieldFilters.findIndex(
				(item) => item.fieldnames.length && item.fieldnames[0] === newFilter.fieldnames[0],
			);
			if (existingIndex > -1) {
				fieldFilters[existingIndex] = newFilter;
			} else {
				fieldFilters.push(newFilter);
			}
		}

		return fieldFilters;
	}

	/**
	 * Set filters from query params
	 */
	setFiltersFromQueryParams(queryParams: Params) {
		console.debug(`${this.constructor.name} - setFiltersFromQueryParams BEG - queryParams=`, queryParams);
		console.debug(
			`${this.constructor.name} - setFiltersFromQueryParams - previousParams=`,
			this.previousQueryParamsString(),
		);
		const queryParamsString = JSON.stringify(queryParams);
		if (queryParamsString === this.previousQueryParamsString()) return;

		this.previousQueryParamsString.set(queryParamsString);

		for (const [key, value] of Object.entries(queryParams)) {
			if (key?.length) {
				const typeOfValue = typeof value;
				const valueLength = value?.length ?? 0;

				console.debug(
					`${this.constructor.name} - setFiltersFromQueryParams - type=${typeof key}, key=${key}, type=${typeof value}, length=${valueLength}, value=${value}`,
				);

				const filterValues = valueLength === 0 ? undefined : Array.isArray(value) ? value : [value];

				this.activeFilterStore.updateFilterForFieldnames({
					fieldnames: key,
					operation: 'equals',
					filterValues: filterValues,
				});

				// this.cdr.detectChanges(); // update view
			}
		}
	}

	/**
	 * Clear active non default filters
	 */
	clearActiveFilterFields() {
		const activeFilters = this.selectActiveFieldFilters();
		console.debug(`${this.constructor.name} - clearActiveFilterFields - activeFilters=`, activeFilters);
	}
}
