import { APP_ID, computed, inject } from '@angular/core';
import { patchState, signalStore, watchState, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';

import { IS_PRODUCTION } from '@ft/lib/core-lib';
import { APP_NAVBAR_SETTINGS, FT_RouteData, NavbarSettings } from '@ft/lib/models-routing';
import { LayoutInfoService, MediaBreakpoint, WindowOrientation, WindowSize } from '@ft/lib/screen-lib';
import { LocalStorageService } from '@furnas-technology/angular-library/services-storage';

export type GotoRouteType = 'login' | 'logout' | 'home' | (string & {});
const lsTheme = 'theme';
const lsWindowSize = 'windowSize';

const signalStoreName = 'GlobalStore';

// global.store.ts
type GlobalStoreState = {
	appId: string;
	loginUrl: string;
	logoutUrl: string;
	theme: string;

	language: string;

	goToRoute: GotoRouteType;

	routeData: FT_RouteData<unknown> | null;
	navbarSettings: NavbarSettings | null;
	windowSize: WindowSize;
	windowOrientation: WindowOrientation;
	mediaBreakpoint: MediaBreakpoint;
	userAgent: string;
	initStatus: string;
	maxTouchPoints: number;

	componentRect: DOMRectReadOnly | null;

	initStart: number;
	initEnd: number;
};

const initialState: GlobalStoreState = {
	initStatus: '',
	appId: '',
	loginUrl: '',
	logoutUrl: '',
	theme: 'default',
	language: navigator.language ?? '',
	goToRoute: '',
	routeData: null,
	navbarSettings: null,
	windowSize: 'Large',
	windowOrientation: 'landscape',
	mediaBreakpoint: 'WebLandscape',
	userAgent: navigator.userAgent ?? '',
	maxTouchPoints: navigator.maxTouchPoints ?? 0,
	componentRect: null,
	initStart: 0,
	initEnd: 0,
};

export const GlobalStore = signalStore(
	{ protectedState: true, providedIn: 'root' },
	withState<GlobalStoreState>(initialState),

	withMethods((store, ls = inject(LocalStorageService)) => ({
		setTheme(theme: string) {
			patchState(store, { theme: theme });
			ls.setLocalStorage(lsTheme, theme);
		},
		setWindowSize(windowSize: WindowSize) {
			patchState(store, { windowSize: windowSize });
			ls.setLocalStorage(lsWindowSize, windowSize);
		},
	})),

	withMethods((store) => ({
		setInitStatus(status: string) {
			const initStart = store.initStart() ? store.initStart() : new Date().getTime();
			patchState(store, { initStatus: status, initStart: initStart, initEnd: new Date().getTime() });
		},
		setLoginUrl(url: string) {
			patchState(store, { loginUrl: url });
		},
		setLogoutUrl(url: string) {
			patchState(store, { logoutUrl: url });
		},
		setLanguage(language: string) {
			patchState(store, { language: language });
		},
		setGoToRoute(route: GotoRouteType) {
			console.debug(`${this.constructor.name} - setGoToRoute - route=${route}`);
			patchState(store, { goToRoute: route });
		},
		setRouteData(routeData: FT_RouteData<unknown>) {
			patchState(store, { routeData: routeData });
		},
		setNavbarSettings(navbarSettings: NavbarSettings) {
			patchState(store, { navbarSettings: navbarSettings });
		},
		setWindowOrientation(windowOrientation: WindowOrientation) {
			patchState(store, { windowOrientation: windowOrientation });
		},
		setMediaBreakpoint(mediaBreakpoint: MediaBreakpoint) {
			patchState(store, { mediaBreakpoint: mediaBreakpoint });
		},
		setUserAgent(userAgent: string) {
			patchState(store, { userAgent: userAgent });
		},
		setComponentRect(componentRect: DOMRectReadOnly) {
			patchState(store, { componentRect: componentRect });
		},
	})),
	withMethods((store) => ({})),
	withComputed((store) => ({
		selectInitStatus: computed(() => store.initStatus()),
		selectGoToRoute: computed(() => store.goToRoute()),
		selectTheme: computed(() => store.theme()),
		selectLanguage: computed(() => store.language()),
		selectRouteData: computed(() => store.routeData()),
		selectNavbarSettings: computed(() => store.navbarSettings()),
		selectWindowSize: computed(() => store.windowSize()),
		selectWindowOrientation: computed(() => store.windowOrientation()),
		selectMediaBreakpoint: computed(() => store.mediaBreakpoint()),
		selectUserAgent: computed(() => store.userAgent()),
		selectMaxTouchPoints: computed(() => store.maxTouchPoints()),

		selectIsTouch: computed(() => !!(store.userAgent().match(/mobi/i) || store.maxTouchPoints())),
		selectIsMobile: computed(() => !!store.userAgent().match(/mobi/i)),
		selectIsTablet: computed(() => !!store.userAgent().match(/tablet/i)),
		selectIsDesktop: computed(() => !!store.userAgent().match(/desktop/i)),

		selectComponentRect: computed(() => store.componentRect()),
		selectInitTime: computed(() => (store.initEnd() - store.initStart()) * 0.001),
	})),
	withComputed((store, layout = inject(LayoutInfoService)) => ({
		navbarHeight: computed(() => {
			if (layout.isGT_small()) {
				return '72px';
			} else {
				return '42px';
			}
		}),
	})),

	withComputed((store) => ({})),

	withComputed((store) => ({
		isTouch: computed(() => !!(store.userAgent().match(/mobi/i) || store.maxTouchPoints())),
	})),

	withHooks({
		onInit(store) {
			const isProduction = inject(IS_PRODUCTION);
			const isDev = !isProduction;
			const appId = inject(APP_ID);
			const navbarSettings = inject(APP_NAVBAR_SETTINGS);
			const ls = inject(LocalStorageService);

			if (isDev) {
				console.debug(`🌎 ${signalStoreName} - onInit`);
			}

			watchState(store, (state) => {
				if (isDev) {
					console.log(`${signalStoreName} - [watchState] - state=`, state);
				}
			});

			// update store values
			patchState(store, { appId: appId.toString(), navbarSettings: navbarSettings });

			const theme = ls.getLocalStorage(lsTheme);
			patchState(store, { theme: theme || 'default' });
		},
	}),
);
