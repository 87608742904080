import { NgStyle } from '@angular/common';
import { Component, computed, inject, output } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DialogButtonComponent } from '@ft/lib/button-lib';
import { LayoutInfoService } from '@ft/lib/screen-lib';
import { SubscriptionService } from '@ft/lib/stripe-lib';

@Component({
	selector: 'ft-please-subscribe',
	templateUrl: './please-subscribe.component.html',
	styleUrls: ['./please-subscribe.component.scss'],
	providers: [{ provide: MAT_DATE_LOCALE, useValue: navigator.language ?? 'en-AU' }],
	imports: [NgStyle, DialogButtonComponent],
})
export class PleaseSubscribeComponent {
	private layout = inject(LayoutInfoService);
	protected subscriptionService = inject(SubscriptionService);

	close = output<boolean>();

	compStyles = computed(() => {
		if (this.layout.isGT_large()) {
			return {
				padding: `10vh 5vw`,
			};
		} else if (this.layout.isGT_small()) {
			return {
				padding: `6vh 3vw`,
			};
		} else {
			return {
				padding: `1vh 1vw`,
			};
		}
	});

	constructor() {
		console.debug(`${this.constructor.name} - constructor`);
	}

	closePopup(): void {
		this.close.emit(true);
	}
} // end class
