import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	LOCALE_ID,
	computed,
	inject,
	input,
	output,
} from '@angular/core';

import { NgStyle } from '@angular/common';
// import { ReactiveFormsModule } from '@angular/forms';

import { issueCustomTooltipDefaults } from '@ft/lib/models';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { MatButtonModule } from '@angular/material/button';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';

import { AppSpinner } from '@ft/lib/directives';
import {} from '@furnas-technology/angular-library/pipes';
import { Observable } from 'rxjs';
import { TableButtonPressed, TableColumn } from '../table-column.model';
import { DataRow, DisplayableValue } from '../table-generic.model';

@Component({
	selector: 'ft-display-image-cell',
	templateUrl: './display-image.component.html',
	styleUrls: ['./display-image.component.scss'],
	imports: [AppSpinner, NgStyle, FontAwesomeModule, MatButtonModule, MatTooltipModule],
	providers: [
		{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: issueCustomTooltipDefaults },
		{ provide: LOCALE_ID, useValue: navigator.language || 'en-AU' },
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayImageCellComponent<T extends object> {
	destroyRef = inject(DestroyRef);

	column = input.required<TableColumn<T>>();
	element = input.required<DataRow<T>>();
	rowId = input<string>();
	displayableValue = input.required<DisplayableValue | Observable<DisplayableValue>>();
	tooltipValue = input<string>();
	dataRowsChanged = input<number>(0);

	buttonPressed = output<TableButtonPressed<T>>();

	faImage = faImage;
	faSpinner = faSpinner;
	isHovered = false;

	columnType = computed(() => this.column().columnType);

	constructor() {}
}
