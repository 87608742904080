import { FT_copyNonNulls } from '@furnas-technology/common-library/functions';

export type AutoCompletableString = string & {};
export type StringWithAutocompleteOptions<TOptions extends string> = (string & {}) | TOptions;
type ColorExample = StringWithAutocompleteOptions<'blue' | 'green'>;

export const Session = 'session_info';

type SessionAction = 'restore' | 'new' | '';

export type AuthStatus =
	| 'refresh in progress'
	| 'failed'
	| 'success'
	| 'login in progress'
	| 'none'
	| 'logout in progress';

const BearerTokenType = 'Bearer';
const BasicTokenType = 'Basic';

export type TokenType = typeof BearerTokenType | typeof BasicTokenType;

export const AuthUserAttributeKeys = [
	'address',
	'city',
	'country',
	'isAdmin',
	'level',
	'postcode',
	'email',
	'email_verified',
	'family_name',
	'gender',
	'given_name',
	'locale',
	'name',
	'nickname',
	'phone_number',
	'phone_number_verified',
	'picture',
	'preferred_username',
	'profile',
	'updated_at',
];

export type AuthResult = {
	access_token: string;
	id_token: string;
	token_type: TokenType | '';
	expires_in: number;
	userinfo: Userinfo;
	username: string;
	message?: string;
	success?: boolean;
}; // end AuthResult

export type SessionTokens = {
	access_token: string;
	id_token: string;
	token_type: TokenType | '';
	expires_in: number;
};

export const InitSessionTokens: SessionTokens = {
	access_token: '',
	id_token: '',
	token_type: '',
	expires_in: 0,
};

export type AccessTokenInfo = Pick<SessionTokens, 'access_token' | 'expires_in' | 'token_type'>;

export const isSessionTokens = (value: unknown): value is SessionTokens => {
	if (typeof value === 'object' && value != null) {
		const valueKeys = Object.keys(value);
		if (
			valueKeys.includes('access_token') &&
			valueKeys.includes('id_token') &&
			valueKeys.includes('token_type') &&
			valueKeys.includes('expires_in')
		) {
			return true;
		}
	}
	return false;
};

export const DOMAIN_KEY = 'Domains';

export function curTime(): number {
	return new Date().getTime();
}

export function expiresAt(): number {
	return curTime() + 360 * 1000;
}

export class Userinfo {
	id: string = '';
	username: string = '';
	displayName: string = '';
	groups: string[] = [];

	email: string = '';
	emailVerified: boolean = false;

	isAdmin: boolean = false;
	isUser: boolean = false;

	phoneNumber: string = '';
	phoneNumberVerified: boolean = false;

	familyName: string = '';
	givenName: string = '';
	fullname: string = '';

	clientId: string = '';

	providerNames: string = '';

	picture: string = '';

	updatedAt: string = '';
	retrievedAt: string = '';

	stripecustomer: string = '';
	stripeemail: string = '';
	subenddate: string = '';
	subscriptionid: string = '';
	substartdate: string = '';
	substatus: string = '';

	constructor(init?: Partial<Userinfo>) {
		if (init) {
			FT_copyNonNulls(this, init);
		}
	}

	get isSubscribed(): boolean {
		return this.groups.findIndex((item) => item.match(/^subscription_active$|^subscription_trial$/i)) > -1;
	}

	get isSubscribedOrAdmin(): boolean {
		return !!(this.isSubscribed || this.isAdmin);
	}

	get activeSubscription(): boolean {
		return this.groups.findIndex((item) => item.match(/^subscription_active$/i)) > -1;
	}

	get trialSubscription(): boolean {
		return this.groups.findIndex((item) => item.match(/^subscription_trial$/i)) > -1;
	}

	get restrictFields(): boolean {
		return !!(!this.isAdmin && !this.isSubscribed);
	}
} // end Userinfo

export const InitUserinfo = new Userinfo();
