<div class="ft-dialog-component paste-text-rows-component">
  <div mat-dialog-title>
    <!-- card header  -->
    <div class="title">
      <fa-icon [icon]="faPaste"></fa-icon>
      <h4 cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{ data.title }}</h4>
    </div>

    <div class="title-options">
      <ft-close-button (closeClicked)="close()"></ft-close-button>
    </div>
  </div>

  <mat-dialog-content>
    <form [formGroup]="form" class="card-dialog__form" (ngSubmit)="onSubmit()">
      <div class="card-dialog__form-content hide-scrollbars">
        <div class="paste-text-dialog-container">
          <mat-form-field class="paste-text-dialog-form">
            @if (data.label) {
              <mat-label>{{ data.label }}</mat-label>
            }
            <textarea
              matInput
              cdkTextareaAutosize
              textareaAutosize
              [placeholder]="data.placeHolder"
              wrap="hard"
              [formControl]="rows"
              class="paste-text-dialog-textarea hide-scrollbars"
              rows="3"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <!-- card actions  -->
  <mat-dialog-actions class="ft-dialog-actions">
    <button
      mat-button
      type="submit"
      class="btn btn-primary submit-button clickable"
      [disabled]="!rows.valid || !rows.dirty"
      aria-label="Save"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
