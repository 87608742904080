import { FT_getDate } from '../functions/date-functions.js';
import { FT_LogError } from '../functions/logging-functions.js';
import { FieldFilter } from './field-filter.model.js';
import { AllFilterValues, AllNumbersPattern, ComparisonFilters, PossibleYearPattern } from './filter-types.model.js';
import { FT_getFieldValue } from '../functions/object-field-functions.js';
/**
 * Creates filtervalue permutations
 */
export const CreateFilterValuePermutations = (values, filterDataType) => {
  const filterValuePermutations = [];
  for (const value of values) {
    if (typeof value === 'string' && value === '' || value == null) continue; // skip empty values
    if (value === null || value === undefined) continue;
    // regexp same for all types
    const rx = new RegExp(String(value), 'i'); // get regex pattern
    if (filterDataType === 'number' || filterDataType === 'year') {
      const numberValue = Number(value);
      if (Number.isNaN(numberValue)) continue;
      filterValuePermutations.push({
        value: numberValue,
        valueLc: numberValue,
        valueRx: rx
      });
      continue;
    }
    if (filterDataType === 'boolean') {
      const booleanValue = !!(value && String(value).toLowerCase() === 'true');
      filterValuePermutations.push({
        value: booleanValue,
        valueLc: booleanValue,
        valueRx: rx
      });
      continue;
    }
    const stringValue = String(value);
    const lc = stringValue.toLowerCase(); // convert strings to lowercase
    filterValuePermutations.push({
      value: stringValue,
      valueLc: lc,
      valueRx: rx
    });
  }
  return filterValuePermutations;
};
/**
 * Filter our field value to the comparison value
 */
export const FT_FilteredValues = (term, populationValues) => {
  if (term === null || term === undefined) return populationValues ?? [];
  if (String(term).trim().length === 0) return populationValues ?? [];
  return FT_FilterByTerm(String(term).trim(), populationValues);
};
/**
 *
 * @param term
 * @param itemPopulation
 * @returns
 */
export const FT_FilterByTerm = (term, itemPopulation) => {
  if (!itemPopulation || !Array.isArray(itemPopulation)) {
    console.warn(`FT_FilterByTerm - term=${term}, itemPopulation not passed/not an array, itemPopulation=`, itemPopulation);
    return [];
  }
  const filteredItems = term.trim().length > 0 ? itemPopulation.filter(x => String(x).toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1) : itemPopulation;
  return filteredItems;
};
/**
 * convert a search string Token to a FieldFilter
 */
export const TokenToFieldFilter = (searchString, forceSearchType = '') => {
  let searchType = 'string';
  let searchValue = searchString === null || searchString === undefined ? '' : String(searchString).trim();
  // flag if searchValue begins with comparison
  let comparisonFilter = undefined;
  if (String(searchValue).startsWith('>=')) {
    comparisonFilter = ComparisonFilters.find(item => item.numDesc === '>=');
  } else if (String(searchValue).startsWith('<=')) {
    comparisonFilter = ComparisonFilters.find(item => item.numDesc === '<=');
  } else {
    comparisonFilter = ComparisonFilters.find(item => String(searchValue).startsWith(item.numDesc));
  }
  if (comparisonFilter) {
    if (comparisonFilter.filterName === 'greater than') {}
    // filterType = comparisonFilter.filterName;
    searchValue = searchValue.replace(comparisonFilter.numDesc, '');
  }
  // '' |  'string' | 'boolean' | 'stringIfNumber' | 'stringIfYear' | 'numberIfYear';
  if (forceSearchType === 'string') {
    // nothing to see here, default is all strings
  } else if (forceSearchType === 'boolean') {
    searchType = 'boolean';
    searchValue = Boolean(searchValue) ? 1 : 0;
  } else if (AllNumbersPattern.test(searchValue)) {
    // all numbers, could be year or number
    if (Number(searchValue) >= 1600 && Number(searchValue) <= 2099) {
      if (forceSearchType === 'numberIfYear') {
        searchType = 'number';
        searchValue = Number(searchValue);
      } else if (forceSearchType !== 'stringIfYear') {
        searchType = 'year';
        searchValue = Number(searchValue);
      }
    } else {
      if (forceSearchType !== 'stringIfNumber') {
        searchType = 'number';
        searchValue = Number(searchValue);
      }
    }
  } else if (PossibleYearPattern.test(searchValue)) {
    // year exists - could be date or year-month
    const dateResult = FT_getDate(searchValue);
    if (dateResult.dateStringType === 'iso-date') {
      searchType = 'date';
      searchValue = dateResult.isoDateString;
    } else if (dateResult.dateStringType === 'year-month') {
      searchType = 'year-month';
      searchValue = dateResult.isoDateString;
    }
  }
  // create fieldfilter from search token
  const operation = ['boolean', 'date', 'number', 'year'].includes(searchType) ? 'equals' : 'contains';
  const fieldFilter = new FieldFilter({
    fieldnames: 'searchbar',
    operation: operation,
    filterValues: [searchValue],
    filterDataType: searchType
  });
  return fieldFilter;
};
export const SearchStringToFieldFilters = (searchString, forceSearchType = '') => {
  try {
    let tokens = [];
    const parts = ConvertStringToTokens(searchString);
    tokens = parts.filter(x => x != null && String(x).trim().length > 0).map(part => {
      const fieldFilter = TokenToFieldFilter(String(part).trim(), forceSearchType);
      return fieldFilter;
    }); // end parts.filter
    return tokens;
  } catch (err) {
    FT_LogError(err, `SearchStringToFieldFilters`, `searchString=${searchString}`);
    return [];
  }
}; // end SearchStringToFieldFilters
/**
 * Convert search string to tokens
 */
export const ConvertStringToTokens = searchString => {
  try {
    const tokens = [];
    let currentTerm = '';
    let withinQuotes = false;
    // const lastIndex = searchString.length - 1;
    let previousTokenIsComparison = false;
    for (let i = 0; i < searchString.length; i++) {
      const char = searchString[i];
      const isQuote = char === `"`;
      // const isComparison = ['>','<','=','!=',].includes(char) && i < lastIndex;
      if (isQuote) {
        withinQuotes = !withinQuotes;
      } else if (previousTokenIsComparison && char === ' ') {
        // const skipBlank = true;
      } else if ([' ', ','].includes(char) && !withinQuotes) {
        if (currentTerm.length > 0) {
          if (previousTokenIsComparison) {
            addToComparisonToken(tokens, currentTerm);
            previousTokenIsComparison = false;
          } else {
            tokens.push(currentTerm);
            previousTokenIsComparison = ComparisonFilters.findIndex(item => item.numDesc === currentTerm) > -1;
          }
        }
        currentTerm = '';
      } else {
        currentTerm += char;
      }
    }
    if (currentTerm !== '') {
      if (previousTokenIsComparison) {
        addToComparisonToken(tokens, currentTerm);
      } else {
        tokens.push(currentTerm);
      }
    }
    return tokens;
  } catch (err) {
    FT_LogError(err, `ConvertStringToTokens`, `tokeniseS${searchString}`);
    return [];
  }
};
const addToComparisonToken = (tokens, currentTerm) => {
  const lastTI = tokens.length - 1;
  tokens[lastTI] = `${tokens[lastTI]}${currentTerm}`;
};
/**
 * FieldFilter typeguard
 */
export const isFieldFilter = obj => {
  if (typeof obj === 'object' && obj !== null && 'fieldnames' in obj && 'operation' in obj) {
    const possiblyFieldFiilter = obj;
    if (Array.isArray(possiblyFieldFiilter.fieldnames) && possiblyFieldFiilter.fieldnames.length && AllFilterValues.includes(possiblyFieldFiilter.operation)) {
      return true;
    }
  }
  return false;
};
export const isFilterValue = value => {
  if (Array.isArray(value)) {
    for (const x of value) {
      if (!(typeof x === 'string' || typeof x === 'number' || typeof x === 'boolean')) {
        return false;
      }
    }
    return true;
  }
  return !!(typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean');
};
export const GetFieldFilter = (fieldname, fieldFilters) => {
  const fieldFilter = fieldFilters.find(item => item.fieldnames.findIndex(x => x === fieldname) > -1);
  if (!fieldFilter) {
    return new FieldFilter({
      fieldnames: fieldname,
      operation: '',
      filterValues: []
    });
  }
  return fieldFilter;
};
/**
 * Field Filters
 */
export const FilterByFields = (rec, fieldFilters, debug = false) => {
  // skip if record already omitted
  if (rec.omitted) return;
  /**
   * Loop through field filters
   */
  for (const fieldFilter of fieldFilters) {
    let someSelected = false;
    for (const fieldname of fieldFilter.fieldnames) {
      const fieldValue = FT_getFieldValue(rec.record, fieldname);
      const fieldSelected = fieldFilter.filterFieldValue(fieldValue);
      if (debug) {
        console.debug(`FilterByFields - fieldname=${String(fieldname)}, fieldValue=${fieldValue}, fieldSelected=${fieldSelected}`);
      }
      if (fieldSelected === true) {
        someSelected = true;
        break;
      }
    } // end loop fieldFilters
    if (someSelected === false) {
      rec.omitted = true;
      break;
    }
  } // end loop fieldFilters
};
