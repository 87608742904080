import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

import { AsyncPipe } from '@angular/common';

import { TableFieldDisplayPipe } from '../table-pipes/table-field-display.pipe';
import { isObservable, Observable } from 'rxjs';
import { TableColumn } from '../table-column.model';
import { DisplayableValue } from '../table-generic.model';

@Component({
	selector: 'ft-conditional-async',
	templateUrl: './conditional-async.component.html',
	styleUrls: ['./conditional-async.component.scss'],
	imports: [AsyncPipe, TableFieldDisplayPipe],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConditionalAsyncComponent<T extends object> {
	column = input.required<TableColumn<T>>();
	displayableValue = input.required<DisplayableValue | Observable<DisplayableValue>>();

	valueIsObservable = computed<boolean>(() => isObservable(this.displayableValue()));

	observableValue = computed(() => {
		return this.displayableValue() as Observable<DisplayableValue>;
	});

	nonObservableValue = computed<DisplayableValue>(() => {
		return this.displayableValue() as DisplayableValue;
	});
}
