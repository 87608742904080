import {
	type ApolloClientOptions,
	ApolloLink,
	DefaultContext,
	InMemoryCache,
	type Operation,
	from,
} from '@apollo/client/core';
// apollo.provider.ts
import { APOLLO_FLAGS, APOLLO_OPTIONS, Apollo } from 'apollo-angular';

import { EnvironmentProviders, inject, isDevMode, makeEnvironmentProviders } from '@angular/core';
import type { NormalizedCacheObject } from '@apollo/client/cache/inmemory/types';
import { setContext } from '@apollo/client/link/context';
import { HttpLink } from 'apollo-angular/http';

import { HttpErrorResponse } from '@angular/common/http';
import { UriFunction } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { AuthService, AuthStore } from '@ft/lib/auth-lib';
import { GRAPHQL_DEFAULT_URI, GRAPHQL_PUBLIC_URI } from '@ft/lib/core-lib';
import { FT_getErrorMessage } from '@furnas-technology/common-library/functions';

type UriType = string | UriFunction | undefined;

const defaultCache = new InMemoryCache();

const disableCache = new InMemoryCache({
	addTypename: false, // Disable automatic typename generation
	dataIdFromObject: () => undefined, // Disable object caching
});

const BasicContext = setContext((_, { headers }) => {
	const auth = inject(AuthService);
	const { ApiKey, ApiKeyValue, ApiAppId, ApiAppIdValue } = auth.getApiHeaderValues();

	return {
		headers: {
			...headers,
			Accept: 'charset=utf-8',
			// Accept: '*/*',
			// authorization: `Bearer randomtoken`,
			'Content-Type': 'application/json',
			[ApiKey]: ApiKeyValue(),
			[ApiAppId]: ApiAppIdValue(),
		},
	};
});

const authLink = setContext(() => {
	const authStore = inject(AuthStore);

	const token = authStore.selectAccessToken();

	if (token === null) {
		return {};
	} else {
		return {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		};
	}
});

/**
 * default Apollo handler
 */
export function createDefaultApollo(httpLink: HttpLink): ApolloClientOptions<NormalizedCacheObject> {
	const uri = inject(GRAPHQL_DEFAULT_URI);
	return createApolloClient(httpLink, uri, 'Default Apollo Client');
} // end createDefaultApollo

export function createPublicApollo(httpLink: HttpLink): ApolloClientOptions<NormalizedCacheObject> {
	const uri = inject(GRAPHQL_PUBLIC_URI);
	return createApolloClient(httpLink, uri, 'Public Apollo Client');
} // end createDefaultApollo

/**
 * Provide Apollo Injection Tokens
 */
export const provideApollo = (): EnvironmentProviders =>
	makeEnvironmentProviders([
		{
			provide: APOLLO_FLAGS,
			useValue: {
				useInitialLoading: true,
			},
		},
		{
			provide: APOLLO_OPTIONS,
			useFactory: createDefaultApollo,
			deps: [HttpLink],
		},
		Apollo,
	]);

/**
 * on error handler
 */
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
	console.debug(`errorLink - graphqlError - graphQLErrors=${graphQLErrors?.length}`);
	console.debug(`errorLink - graphqlError - networkError=`, networkError);
	console.debug(`errorLink - graphqlError - operation=`, operation);

	const oldHeaders = operation.getContext()['headers'];
	console.debug(`errorLink - graphqlError - oldHeaders=`, oldHeaders);

	if (graphQLErrors) {
		for (const err of graphQLErrors) {
			console.debug(`errorLink - graphqlError - code=${err.extensions?.['code']}`);
		}
	}

	if (networkError) {
		// handle network error
		if (networkError.message?.match(/Unknown Error/i)) {
			console.error(`❌ [GraphQL Network error]: ${networkError.message}`);
		} else if (networkError instanceof HttpErrorResponse) {
			if (networkError.status === 401 || networkError.status === 403) {
				const context: DefaultContext = operation.getContext();
				const oldHeaders = operation.getContext()['headers'];
			}
		}
	}
});

const retryLinkWithAuth = (auth: AuthService, accessToken: string): RetryLink => {
	const apiXHeaders = auth.getApiHeaderValues();

	const link = new RetryLink({
		delay: {
			initial: 300,
			max: 3000,
			jitter: true,
		},
		attempts: {
			max: 2,
			retryIf: (error: unknown, operation: Operation) => {
				const message = FT_getErrorMessage(error);
				const opVariables: string[] = [];
				if (operation.variables) {
					for (const opVar of Object.entries(operation.variables)) {
						opVariables.push(`${opVar[0]}: ${opVar[1]}`);
					}
				}

				console.debug(
					`GraphQL - RetryLink - kind=${operation.query.kind}, error=${message}, variables=${opVariables.join(', ')}`,
				);

				const oldHeaders = operation.getContext()['headers'] ?? {};
				console.debug(`GraphQL - RetryLink - cur accessToken=${accessToken?.slice(-15)}`);

				return Boolean(error);
			},
		},
	});
	return link;
};

function createDefaultApolloWithoutAuthInterceptor(httpLink: HttpLink): ApolloClientOptions<NormalizedCacheObject> {
	const cache = new InMemoryCache();

	// create http link based on our API URL Token
	const http = httpLink.create({
		uri: inject(GRAPHQL_DEFAULT_URI),
	});

	return {
		connectToDevTools: isDevMode(),
		assumeImmutableResults: true,
		cache,
		link: from([BasicContext, authLink, http]),
	};
}

const createApolloClient = (
	httpLink: HttpLink,
	uri: UriType,
	debugName: string = 'FT Apollo Client',
): ApolloClientOptions<NormalizedCacheObject> => {
	const auth = inject(AuthService);
	const authStore = inject(AuthStore);

	const cache = defaultCache;
	const http = httpLink.create({ uri: uri });
	const apiXHeaders = auth.getApiHeaderValues();
	const retryLink = retryLinkWithAuth(auth, authStore.selectAccessToken());
	const link = ApolloLink.from([http]);

	const apolloClient: ApolloClientOptions<NormalizedCacheObject> = {
		devtools: {
			enabled: isDevMode(),
			name: debugName,
		},
		connectToDevTools: isDevMode(),
		assumeImmutableResults: true,
		cache: cache,
		link: link,
		defaultOptions: {
			watchQuery: {
				errorPolicy: 'none',
			},
		},
	};

	return apolloClient;
};
