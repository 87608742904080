"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.processProp = void 0;
const tslib_1 = require("tslib");
const logSettings_1 = require("../logSettings");
const typegoose_1 = require("../typegoose");
const constants_1 = require("./constants");
const errors_1 = require("./errors");
const utils = tslib_1.__importStar(require("./utils"));
/**
 * Function that is the actual processing of the prop's (used for caching)
 * @param input All the options needed for prop's
 */
function processProp(input) {
  const {
    key,
    target
  } = input;
  const name = utils.getName(target);
  const rawOptions = Object.assign({}, input.options);
  let Type = Reflect.getMetadata(constants_1.DecoratorKeys.Type, target, key);
  let propKind = input.propType ?? detectPropType(Type);
  logSettings_1.logger.debug('Starting to process "%s.%s"', name, key);
  utils.assertion(typeof key === 'string', () => new errors_1.CannotBeSymbolError(name, key));
  // optionDeprecation(rawOptions);
  {
    // soft errors & "type"-alias mapping
    switch (propKind) {
      case constants_1.PropType.NONE:
        break;
      case constants_1.PropType.MAP:
      case constants_1.PropType.ARRAY:
        // set the "Type" to undefined if "ref" or "refPath" are defined, as an fallback in case "type" is also not defined
        if (('ref' in rawOptions || 'refPath' in rawOptions) && !('type' in rawOptions)) {
          Type = undefined;
        }
        break;
    }
  }
  if (!utils.isNullOrUndefined(rawOptions.type)) {
    logSettings_1.logger.info('Prop Option "type" is set to ', rawOptions.type);
    const gotType = utils.getType(rawOptions.type);
    Type = gotType.type;
    if (gotType.dim > 0) {
      rawOptions.dim = gotType.dim;
      // Infer "type: [TYPE]" as a array, only if the PropType is not manually set or already inferred as something else
      // This is useful if reflection fails or when working without "emitDecoratorMetadata"
      if (utils.isNullOrUndefined(input.propType) && propKind == constants_1.PropType.NONE) {
        logSettings_1.logger.debug('Detected "type" being set to a array, using PropType.ARRAY');
        propKind = constants_1.PropType.ARRAY;
      }
    }
    delete rawOptions.type;
  }
  // prevent "infinite" buildSchema loop / Maximum Stack size exceeded
  if (Type === target.constructor) {
    throw new errors_1.SelfContainingClassError(name, key);
  }
  // map to correct buffer type, otherwise it would result in "Mixed"
  if (Type === typegoose_1.mongoose.Types.Buffer) {
    Type = typegoose_1.mongoose.Schema.Types.Buffer;
  }
  // confirm that "PropType" is an ARRAY and if that the Type is still an *ARRAY, set them to Mixed
  // for issues like https://github.com/typegoose/typegoose/issues/300
  if (propKind === constants_1.PropType.ARRAY && detectPropType(Type) === constants_1.PropType.ARRAY) {
    logSettings_1.logger.debug('Type is still *ARRAY, defaulting to Mixed');
    Type = typegoose_1.mongoose.Schema.Types.Mixed;
  }
  // confirm that "PropType" is an MAP and if that the Type is still an *MAP, set them to Mixed
  if (propKind === constants_1.PropType.MAP && detectPropType(Type) === constants_1.PropType.MAP) {
    logSettings_1.logger.debug('Type is still *Map, defaulting to Mixed');
    Type = typegoose_1.mongoose.Schema.Types.Mixed;
  }
  if (utils.isNotDefined(Type)) {
    (0, typegoose_1.buildSchema)(Type);
  }
  const modelOptionsOfType = Reflect.getMetadata(constants_1.DecoratorKeys.ModelOptions, Type ?? {}) ?? {};
  // throw a error when both "discriminators" as a prop-option and as a model-option are defined
  if ('discriminators' in rawOptions && !utils.isNullOrUndefined(modelOptionsOfType?.options?.discriminators)) {
    throw new errors_1.DuplicateOptionsError(['discriminators(prop-option)', 'discriminators(model-option)']);
  }
  if ('discriminators' in rawOptions || !utils.isNullOrUndefined(modelOptionsOfType?.options?.discriminators)) {
    const discriminatorsToUse = rawOptions?.discriminators ?? modelOptionsOfType?.options?.discriminators;
    logSettings_1.logger.debug('Found option "discriminators" in "%s.%s"', name, key);
    const gotType = utils.getType(discriminatorsToUse, true);
    utils.assertion(gotType.dim === 1, () => new errors_1.OptionDoesNotSupportOptionError('discriminators', 'dim', '1', `dim: ${gotType.dim}`));
    const discriminators = gotType.type.map((val, index) => {
      if (utils.isConstructor(val)) {
        return {
          type: val
        };
      }
      if (typeof val === 'object') {
        if (!('type' in val)) {
          throw new Error(`"${name}.${key}" discriminator index "${index}" is an object, but does not contain the "type" property!`);
        }
        return val;
      }
      throw new Error(`"${name}.${key}" discriminators index "${index}" is not an object or an constructor!`);
    });
    const disMap = new Map(Reflect.getMetadata(constants_1.DecoratorKeys.NestedDiscriminators, target.constructor) ?? []);
    disMap.set(key, discriminators);
    Reflect.defineMetadata(constants_1.DecoratorKeys.NestedDiscriminators, disMap, target.constructor);
    delete rawOptions.discriminators;
  }
  // allow setting the type asynchronously
  if ('ref' in rawOptions) {
    const gotType = utils.getType(rawOptions.ref);
    utils.assertion(gotType.dim === 0, () => new errors_1.OptionDoesNotSupportOptionError('ref', 'dim', '0', `dim: ${gotType.dim}`));
    rawOptions.ref = gotType.type;
    utils.assertion(!utils.isNullOrUndefined(rawOptions.ref), () => new errors_1.RefOptionIsUndefinedError(name, key));
    rawOptions.ref = typeof rawOptions.ref === 'string' ? rawOptions.ref : utils.isConstructor(rawOptions.ref) ? utils.getName(rawOptions.ref) : rawOptions.ref;
  }
  if (utils.isWithVirtualPOP(rawOptions)) {
    if (!utils.includesAllVirtualPOP(rawOptions)) {
      throw new errors_1.NotAllVPOPElementsError(name, key);
    }
    const virtuals = new Map(Reflect.getMetadata(constants_1.DecoratorKeys.VirtualPopulate, target.constructor) ?? []);
    virtuals.set(key, rawOptions);
    Reflect.defineMetadata(constants_1.DecoratorKeys.VirtualPopulate, virtuals, target.constructor);
    return;
  }
  if ('justOne' in rawOptions) {
    logSettings_1.logger.warn(`Option "justOne" is defined in "${name}.${key}" but no Virtual-Populate-Options!\n` + 'Look here for more: https://typegoose.github.io/typegoose/docs/api/virtuals#virtual-populate');
  }
  const schemaProp = utils.getCachedSchema(input.cl);
  // do this early, because the other options (enum, ref, refPath, discriminators) should not matter for this one
  if (Type instanceof typegoose_1.Passthrough) {
    logSettings_1.logger.debug('Type is "instanceof Passthrough" ("%s.%s", %s, direct: %s)', name, key, propKind, Type.direct);
    // this is because the check above narrows down the type, which somehow is not compatible
    const newType = Type.raw;
    if (Type.direct) {
      schemaProp[key] = newType;
      return;
    }
    switch (propKind) {
      case constants_1.PropType.ARRAY:
        schemaProp[key] = utils.mapArrayOptions(rawOptions, newType, target, key);
        return;
      case constants_1.PropType.MAP:
        const mapped = utils.mapOptions(rawOptions, newType, target, key);
        schemaProp[key] = {
          ...mapped.outer,
          type: Map,
          of: {
            type: newType,
            ...mapped.inner
          }
        };
        return;
      case constants_1.PropType.NONE:
        schemaProp[key] = {
          ...rawOptions,
          type: newType
        };
        return;
      default:
        throw new errors_1.InvalidPropTypeError(propKind, name, key, 'PropType(Passthrough)');
    }
  }
  // use "Type" if it is an suitable ref-type, otherwise default back to "ObjectId"
  const refType = utils.isAnRefType(Type) ? Type : typegoose_1.mongoose.Schema.Types.ObjectId;
  if ('ref' in rawOptions) {
    const ref = rawOptions.ref;
    delete rawOptions.ref;
    switch (propKind) {
      case constants_1.PropType.ARRAY:
        schemaProp[key] = utils.mapArrayOptions(rawOptions, refType, target, key, undefined, {
          ref
        });
        break;
      case constants_1.PropType.NONE:
        schemaProp[key] = {
          type: refType,
          ref,
          ...rawOptions
        };
        break;
      case constants_1.PropType.MAP:
        const mapped = utils.mapOptions(rawOptions, refType, target, key);
        schemaProp[key] = {
          ...mapped.outer,
          type: Map,
          of: {
            type: refType,
            ref,
            ...mapped.inner
          }
        };
        break;
      default:
        throw new errors_1.InvalidPropTypeError(propKind, name, key, 'PropType(ref)');
    }
    return;
  }
  if ('refPath' in rawOptions) {
    const refPath = rawOptions.refPath;
    delete rawOptions.refPath;
    utils.assertion(typeof refPath === 'string' && refPath.length > 0, () => new errors_1.StringLengthExpectedError(1, refPath, `${name}.${key}`, 'refPath'));
    switch (propKind) {
      case constants_1.PropType.ARRAY:
        schemaProp[key] = utils.mapArrayOptions(rawOptions, refType, target, key, undefined, {
          refPath
        });
        break;
      case constants_1.PropType.NONE:
        schemaProp[key] = {
          type: refType,
          refPath,
          ...rawOptions
        };
        break;
      default:
        throw new errors_1.InvalidPropTypeError(propKind, name, key, 'PropType(refPath)');
    }
    return;
  }
  // check if Type is actually a real working Type
  if (utils.isNullOrUndefined(Type) || typeof Type !== 'function') {
    throw new errors_1.InvalidTypeError(name, key, Type);
  }
  if (!utils.isNullOrUndefined(rawOptions.enum)) {
    let useType = rawOptions.enum;
    let inValues = false;
    if (rawOptions.enum?.constructor === Object && 'values' in rawOptions.enum) {
      useType = rawOptions.enum.values;
      inValues = true;
    }
    // disabling lint line, because eslint seemingly cant handle a changing value and a unchanging value in the same destruction
    // eslint-disable-next-line prefer-const
    let {
      dim: enumDim,
      type: enumType
    } = utils.getType(useType, true);
    utils.assertion(enumDim === 1 || enumDim === 0, () => new errors_1.OptionDoesNotSupportOptionError('enum', 'dim', '0 or 1', `dim: ${enumDim}`));
    // check if the option is already a array (mongoose enum), if not convert it
    if (!Array.isArray(enumType)) {
      if (Type === String || Type === typegoose_1.mongoose.Schema.Types.String) {
        enumType = Object.entries(enumType) // get all key-value pairs of the enum
        // no reverse-filtering because if it is full of strings, there is no reverse mapping
        .map(([enumKey, enumValue]) => {
          // convert key-value pairs to an mongoose-usable enum
          // safeguard, this should never happen because TypeScript only sets "design:type" to "String"
          // if the enum is full of strings
          if (typeof enumValue !== 'string') {
            throw new errors_1.NotStringTypeError(name, key, enumKey, typeof enumValue);
          }
          return enumValue;
        });
      } else if (Type === Number || Type === typegoose_1.mongoose.Schema.Types.Number) {
        enumType = Object.entries(enumType) // get all key-value pairs of the enum
        // filter out the "reverse (value -> name) mappings"
        // https://www.typescriptlang.org/docs/handbook/enums.html#reverse-mappings
        .filter(([enumKey, enumValue], _i, arr) => {
          // safeguard, this should never happen because typescript only sets "design:type" to "Number"
          // if the enum is full of numbers
          if (utils.isNullOrUndefined(enumValue) || arr.findIndex(([k]) => k === enumValue.toString()) <= -1) {
            // if there is no reverse mapping, throw an error
            throw new errors_1.NotNumberTypeError(name, key, enumKey, typeof enumValue);
          }
          return typeof enumValue === 'number';
        }).map(([enumKey, enumValue]) => {
          // convert key-value pairs to an mongoose-useable enum
          if (typeof enumValue !== 'number') {
            throw new errors_1.NotNumberTypeError(name, key, enumKey, typeof enumValue);
          }
          return enumValue;
        });
      } else {
        // this will happen if the enum type is not "String" or "Number"
        // most likely this error happened because the code got transpiled with babel or "tsc --transpile-only"
        throw new errors_1.InvalidEnumTypeError(name, key, Type);
      }
    }
    // re-assign the option with the updated type
    if (inValues) {
      rawOptions.enum.values = enumType;
    } else {
      rawOptions.enum = enumType;
    }
  }
  if (!utils.isNullOrUndefined(rawOptions.addNullToEnum)) {
    rawOptions.enum = Array.isArray(rawOptions.enum) ? rawOptions.enum : [];
    rawOptions.enum.push(null);
    delete rawOptions.addNullToEnum;
  }
  {
    let included = utils.isWithStringValidate(rawOptions);
    if (!utils.isString(Type)) {
      // warn if String-Validate options are included, but is not string
      utils.warnNotCorrectTypeOptions(name, key, 'String', 'String-Validate', included);
    }
    included = utils.isWithStringTransform(rawOptions);
    if (!utils.isString(Type)) {
      // warn if String-Transform options are included, but is not string
      utils.warnNotCorrectTypeOptions(name, key, 'String', 'String-Transform', included);
    }
    included = utils.isWithNumberValidate(rawOptions);
    if (!utils.isNumber(Type)) {
      // warn if Number-Validate options are included, but is not number
      utils.warnNotCorrectTypeOptions(name, key, 'Number', 'Number-Validate', included);
    }
    included = utils.isWithEnumValidate(rawOptions);
    if (!utils.isString(Type) && !utils.isNumber(Type)) {
      // warn if "enum" is included, but is not Number or String
      utils.warnNotCorrectTypeOptions(name, key, 'String | Number', 'extra', included);
    }
  }
  /** Is this Type (/Class) in the schemas Map? */
  const hasCachedSchema = !utils.isNullOrUndefined(Reflect.getMetadata(constants_1.DecoratorKeys.CachedSchema, Type));
  if (utils.isPrimitive(Type)) {
    if (utils.isObject(Type, true)) {
      utils.warnMixed(target, key);
    }
    switch (propKind) {
      case constants_1.PropType.ARRAY:
        schemaProp[key] = utils.mapArrayOptions(rawOptions, Type, target, key);
        return;
      case constants_1.PropType.MAP:
        let mapped;
        let finalType;
        // Map the correct options for the end type
        if (utils.isTypeMeantToBeArray(rawOptions)) {
          mapped = utils.mapOptions(rawOptions, typegoose_1.mongoose.Schema.Types.Array, target, key);
          // "rawOptions" is not used here, because that would duplicate some options to where the should not be
          finalType = utils.mapArrayOptions({
            ...mapped.inner,
            dim: rawOptions.dim
          }, Type, target, key);
        } else {
          mapped = utils.mapOptions(rawOptions, Type, target, key);
          finalType = {
            ...mapped.inner,
            type: Type
          };
        }
        schemaProp[key] = {
          ...mapped.outer,
          type: Map,
          of: {
            ...finalType
          }
        };
        return;
      case constants_1.PropType.NONE:
        schemaProp[key] = {
          ...rawOptions,
          type: Type
        };
        return;
      default:
        throw new errors_1.InvalidPropTypeError(propKind, name, key, 'PropType(primitive)');
    }
  }
  // If the 'Type' is not a 'Primitive Type' and no subschema was found treat the type as 'Object'
  // so that mongoose can store it as nested document
  if (utils.isObject(Type) && !hasCachedSchema) {
    utils.warnMixed(target, key);
    logSettings_1.logger.warn('if someone can see this message, please open an new issue at https://github.com/typegoose/typegoose/issues with reproduction code for tests');
    schemaProp[key] = {
      ...rawOptions,
      type: typegoose_1.mongoose.Schema.Types.Mixed
    };
    return;
  }
  const virtualSchema = (0, typegoose_1.buildSchema)(Type);
  switch (propKind) {
    case constants_1.PropType.ARRAY:
      schemaProp[key] = utils.mapArrayOptions(rawOptions, virtualSchema, target, key, Type);
      return;
    case constants_1.PropType.MAP:
      // special handling if the lower type should be an array
      if ('dim' in rawOptions) {
        logSettings_1.logger.debug('Map SubDocument Array for "%s.%s"', name, key);
        const {
          type,
          ...outer
        } = utils.mapArrayOptions(rawOptions, virtualSchema, target, key, Type);
        schemaProp[key] = {
          ...outer,
          type: Map,
          of: type
        };
        return;
      }
      const mapped = utils.mapOptions(rawOptions, virtualSchema, target, key, Type);
      schemaProp[key] = {
        ...mapped.outer,
        type: Map,
        of: {
          type: virtualSchema,
          ...mapped.inner
        }
      };
      return;
    case constants_1.PropType.NONE:
      schemaProp[key] = {
        ...rawOptions,
        type: virtualSchema
      };
      return;
    default:
      throw new errors_1.InvalidPropTypeError(propKind, name, key, 'PropType(subSchema)');
  }
}
exports.processProp = processProp;
// The following function ("optionDeprecation") is disabled until used again
/**
 * Check for deprecated options, and if needed process them
 * @param options
 */
// function optionDeprecation(options: any) {}
/**
 * Detect "PropType" based on "Type"
 * @param Type The Type used for detection
 */
function detectPropType(Type) {
  logSettings_1.logger.debug('Detecting PropType');
  if (Type === Array || Type === typegoose_1.mongoose.Types.Array || Type === typegoose_1.mongoose.Schema.Types.Array || Type === typegoose_1.mongoose.Types.DocumentArray || Type === typegoose_1.mongoose.Schema.Types.DocumentArray) {
    return constants_1.PropType.ARRAY;
  }
  if (Type === Map || Type === typegoose_1.mongoose.Types.Map || Type === typegoose_1.mongoose.Schema.Types.Map) {
    return constants_1.PropType.MAP;
  }
  return constants_1.PropType.NONE;
}
