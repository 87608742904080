export type AppImageKey =
	| `backgroundImage${string}`
	| `defaultLogo${string}`
	| `squareLogo${string}`
	| `landscapeLogo${string}`
	| `logo${string}`
	| `background${string}`
	| `icon${string}`
	| `${string}Logo`;

export type AppImageUrl = string;
export type AppImageSrc = string;

export type AppImage = {
	imageName: AppImageKey;
	imageUrl: AppImageUrl;
};

export class AppImages {
	[key: AppImageKey]: AppImage;
}
