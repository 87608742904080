<div
  class="display-cell-component"
  color="primary"
  [matTooltip]="tooltipValue()"
  [matTooltipDisabled]="!column().hasTooltip || !tooltipValue()"
  (mouseenter)="isHovered = true"
  (mouseleave)="isHovered = false"
>
  <div
    [ngClass]="[column().mainClass, column().cellClass]"
    [ngStyle]="cellStyles()"
    [attr.data-element]="column().elementId"
  >
    <ft-conditional-async [displayableValue]="displayableValue()" [column]="column()"></ft-conditional-async>
  </div>
</div>
