// shared-values.service.ts
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Injectable } from '@angular/core';
import { ItemHeights } from './virtual-scroll.model';

const DEFAULT_ITEM_SIZE = 52;
const DEFAULT_HEADER_SIZE = 56;

@Injectable({ providedIn: 'root' })
export class VirtualScrollService {
	constructor() {}

	measureRenderedItems(
		viewport: CdkVirtualScrollViewport | null = null,
		itemHeights: ItemHeights = [],
		defaultItemHeight: number = 52,
		querySelector: string = '.cdk-virtual-item',
	): void {
		if (!viewport) return;

		const renderedRange = viewport.getRenderedRange(); // range of items rendered
		const renderedItems = viewport.elementRef.nativeElement.querySelectorAll(querySelector); // rendered items
		const renderedItemsLength = renderedItems?.length ?? 0;
		const renderedRangeLength = renderedRange.end - renderedRange.start;
		if (
			renderedRangeLength !== renderedItemsLength &&
			renderedRange.start > 0 &&
			renderedRange.end > 0 &&
			renderedItemsLength > 0
		) {
			console.error(
				`❌ ${this.constructor.name} - measureRenderedItems - renderedRangeLength=${renderedRangeLength}, renderedItemsLength=${renderedItemsLength} - are multiple divisions assigned class cdk-virtual-item?	`,
			);
			return;
		}

		/**
		 * Get actual height of the rendered items
		 */
		let renderedHeight = 0;
		for (let i = renderedRange.start; i < renderedRange.end && i >= 0; i++) {
			const renderedItemsIndex = i - renderedRange.start;

			const height =
				renderedItemsIndex >= 0 && renderedItemsIndex < renderedItemsLength
					? renderedItems[renderedItemsIndex].getBoundingClientRect().height
					: defaultItemHeight;

			if (itemHeights[i] !== height) {
				itemHeights[i] = height;
			}

			renderedHeight += itemHeights[i];
		}
	}
}
