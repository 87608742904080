<button
  mat-flat-button
  [disabled]="!!disabled()"
  [ngClass]="layout.cssClasses()"
  [matTooltip]="tooltip()"
  [color]="color()"
  [ngClass]="buttonClasses()"
  (click)="handleClick()"
>
  <div class="button-content" [ngClass]="contentClasses()">
    @if (textPosition() === 'above' || textPosition() === 'before') {
      {{ buttonText() }}
    }

    @if (!!iconDefinition()) {
      <fa-icon class="button-icon" [icon]="iconDefinition()!"></fa-icon>
    }

    @if (textPosition() === 'below' || textPosition() === 'after') {
      {{ buttonText() }}
    }
  </div>
</button>
