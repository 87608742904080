<!-- select field -->
<div class="table-select-cell" [ngClass]="layout.cssClasses()">
  <ng-select
    #selectElem
    class="select-options"
    [name]="fieldPath()"
    [id]="fieldPath()"
    [items]="availableOption$ | async"
    [placeholder]="pluralText()"
    bindLabel=""
    [addTag]="false"
    [multiple]="true"
    [hideSelected]="true"
    [trackByFn]="trackByFnString"
    [minTermLength]="1"
    [virtualScroll]="true"
    [loading]="false"
    loadingText="Loading values, please wait"
    typeToSearchText="Please enter 1 or more characters to view available values"
    notFoundText="No matching values"
    [typeahead]="fieldInput$"
    [(ngModel)]="selectedOptions"
    [ngModelOptions]="{ standalone: true }"
    (change)="onInputChange(fieldPath())"
    (open)="onOpen(fieldPath())"
    (search)="onSearch($event, fieldPath())"
    (clear)="onClear()"
    [attr.data-fieldpath]="fieldPath()"
  >
  </ng-select>
</div>
