import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { DestroyRef, Injectable, Signal, WritableSignal, computed, effect, inject, signal } from '@angular/core';

import { DeviceDetectorService, type DeviceInfo, DeviceType } from 'ngx-device-detector';

import { OverlayContainer } from '@angular/cdk/overlay';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LocalStorageService } from '@furnas-technology/angular-library/services-storage';
import { FT_LogError } from '@furnas-technology/common-library/functions';
import { Subscription, catchError, debounceTime, of, tap } from 'rxjs';
// import { type MaterialCssVariablesConfig, MaterialCssVarsService } from 'angular-material-css-vars';

// Check if the user has dark mode turned on
export const SYSTEM_DARK = window.matchMedia('(prefers-color-scheme: dark)').matches;
export const DEFAULT_UI_MODE: UiMode = SYSTEM_DARK ? 'darkMode' : 'lightMode';
export type UiMode = 'lightMode' | 'darkMode';

// local storage constants
const HIDE_SIDEBAR = 'hide_sidebar';
const CSS_CLASSES_ITEM = 'cssClasses';
const UIMODE_ITEM = 'uiMode';
export const RESIZE_DEBOUNCE = 300;

export type FT_BreakpointOutput = {
	breakpoints: { [key: string]: boolean };
	screenWidth: number;
	screenHeight: number;
	windowWidth: number;
	windowHeight: number;
};

export const OrientationConstants = {
	landscape: '(orientation: landscape)',
	portrait: '(orientation: portrait)',
};

export type MediaBreakpoint =
	| 'HandsetPortrait'
	| 'TabletPortrait'
	| 'WebPortrait'
	| 'HandsetLandscape'
	| 'TabletLandscape'
	| 'WebLandscape';

export type LayoutInfo = {
	windowSize: WindowSize;
	windowOrientation: WindowOrientation;
	mediaBreakpoint: MediaBreakpoint;
	isTouch: boolean;
	deviceType: DeviceType;
};

type DeviceDetectorInfo = DeviceInfo & { isMobile: boolean; isTablet: boolean; isDesktop: boolean };

type WidthAndHeight = {
	width: number;
	height: number;
	windowSize: WindowSize;
};

type BreakpointMatches = {
	[key: string]: boolean;
};

const isUiMode = (value: string): value is UiMode => {
	return !!(value && typeof value === 'string' && (value === 'lightMode' || value === 'darkMode'));
};

export type WindowClass =
	| 'is-tiny'
	| 'is-touch'
	| 'is-xsmall'
	| 'is-small'
	| 'is-medium'
	| 'is-large'
	| 'is-xlarge'
	| 'is-huge'
	| 'lt-small'
	| 'lt-medium'
	| 'lt-large'
	| 'lt-xlarge'
	| 'gt-xsmall'
	| 'gt-small'
	| 'gt-medium'
	| 'gt-large'
	| 'gt-xlarge'
	| 'xsmall-typography'
	| 'small-typography'
	| 'medium-typography'
	| 'large-typography'
	| 'xlarge-typography'
	| 'portrait'
	| 'landscape'
	| 'HandsetPortrait'
	| 'TabletPortrait'
	| 'WebPortrait'
	| 'HandsetLandscape'
	| 'TabletLandscape'
	| 'WebLandscape';

export type SizeCategory = 'Mobile' | 'Tablet' | 'Desktop';
export type WindowSize = 'XSmall' | 'Small' | 'Medium' | 'Large' | 'XLarge';
export type WindowOrientation = 'portrait' | 'landscape';

const _Tiny: string = 'Tiny';
const _Huge: string = 'Huge';

const _XSmall: WindowSize = 'XSmall';
const _Small: WindowSize = 'Small';
const _Medium: WindowSize = 'Medium';
const _Large: WindowSize = 'Large';
const _XLarge: WindowSize = 'XLarge';

const MINIMUM_SIZES: WindowSize[] = ['XSmall', _Small, _Medium];

const XSmall: WindowSize[] = [_XSmall];
const XSmall_Small: WindowSize[] = [_XSmall, _Small];
const XSmall_Small_Medium: WindowSize[] = [_Medium, _XSmall, _Small];
const XSmall_Small_Medium_Large: WindowSize[] = [_Medium, _XSmall, _Small, _Large];
const XSmall_Small_Medium_Large_XLarge: WindowSize[] = [_Medium, _XSmall, _Small, _Large, _XLarge];

const Small_Medium: WindowSize[] = [_Medium, _Small];
const Small_Medium_Large: WindowSize[] = [_Medium, _Small, _Large];
const Small_Medium_Large_XLarge: WindowSize[] = [_Medium, _Small, _Large, _XLarge];

const Medium_Large: WindowSize[] = [_Medium, _Large];
const Medium_Large_XLarge: WindowSize[] = [_Medium, _Large, _XLarge];

const Large_XLarge: WindowSize[] = [_Large, _XLarge];
const XLarge: WindowSize[] = [_XLarge];

const AngularBreakpoints = {
	XSmall: Breakpoints.XSmall,
	Small: Breakpoints.Small,
	Medium: Breakpoints.Medium,
	Large: Breakpoints.Large,
	XLarge: Breakpoints.XLarge,
	Handset: Breakpoints.Handset,
	Tablet: Breakpoints.Tablet,
	Web: Breakpoints.Web,
	HandsetPortrait: Breakpoints.HandsetPortrait,
	HandsetLandscape: Breakpoints.HandsetLandscape,
	TabletPortrait: Breakpoints.TabletPortrait,
	TabletLandscape: Breakpoints.TabletLandscape,
	WebPortrait: Breakpoints.WebPortrait,
	WebLandscape: Breakpoints.WebLandscape,
};

const CustomBreakpoints = {
	Tiny: `(max-width: 349.98px)`,
	XSmall: '(max-width: 599.98px)',
	Small: '(min-width: 600px) and (max-width: 759.98px)',
	Medium: '(min-width: 760px) and (max-width: 1279.98px)',
	Huge: `(min-width: 3000px)`,
};

const MergedBreakpoints = { ...AngularBreakpoints, ...OrientationConstants, ...CustomBreakpoints };

@Injectable({
	providedIn: 'root',
})
export class LayoutInfoService {
	destroyRef = inject(DestroyRef);
	localStorage = inject(LocalStorageService);
	deviceService = inject(DeviceDetectorService);
	breakpointObserver = inject(BreakpointObserver);
	overlay = inject(OverlayContainer);

	/**
	 * Window
	 */
	private window: Window | null = null;

	private breakpoints = signal<{ [key: string]: boolean }>({});

	private breakpointQuery = this.breakpointObserver.observe(Object.values(MergedBreakpoints)).pipe(
		takeUntilDestroyed(this.destroyRef),
		debounceTime(RESIZE_DEBOUNCE),
		tap((breakpointState: BreakpointState) => {
			this.checkAndUpdateState(breakpointState);
		}),
		catchError((err: unknown) => {
			FT_LogError(err, this.constructor.name);
			return of(null);
		}),
	);

	private breakpoints$: Subscription | undefined = undefined;

	/**
	 * Breakpoints
	 */
	screenWidth = signal<number>(0);
	screenHeight = signal<number>(0);
	windowWidth = signal<number>(0);
	windowHeight = signal<number>(0);
	deviceInfo = signal<DeviceDetectorInfo>(this.getDeviceDetectorInfo());

	readonly screenWidthHeight = computed<string>(
		() => `${this.screenWidth().toFixed()}x${this.screenHeight().toFixed()}`,
	);

	/**
	 * computed signals
	 */
	readonly isMobile = computed(() => this.deviceInfo().isMobile);
	readonly isTablet = computed(() => this.deviceInfo().isTablet);
	readonly isDesktop = computed(() => this.deviceInfo().isDesktop);

	readonly windowSize = computed<WindowSize>(() => {
		if (this.breakpoints()[_XSmall]) return _XSmall;
		if (this.breakpoints()[_Small]) return _Small;
		if (this.breakpoints()[_Medium]) return _Medium;
		if (this.breakpoints()[_Large]) return _Large;
		if (this.breakpoints()[_XLarge]) return _XLarge;
		return _XLarge;
	});

	readonly windowOrientation = computed<WindowOrientation>(() => {
		return this.breakpoints()['portrait'] ? 'portrait' : 'landscape';
	});

	readonly touch = computed<boolean>(() => {
		return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
	});

	readonly deviceType = computed<DeviceType>(() => {
		return this.deviceInfo().deviceType as DeviceType;
	});

	readonly mediaBreakpoint = computed<MediaBreakpoint>(() => {
		if (this.breakpoints()['HandsetPortrait']) return 'HandsetPortrait';
		if (this.breakpoints()['TabletPortrait']) return 'TabletPortrait';
		if (this.breakpoints()['WebPortrait']) return 'WebPortrait';
		if (this.breakpoints()['HandsetLandscape']) return 'HandsetLandscape';
		if (this.breakpoints()['TabletLandscape']) return 'TabletLandscape';
		if (this.breakpoints()['WebLandscape']) return 'WebLandscape';
		return 'WebLandscape';
	});

	readonly layoutInfo = computed<LayoutInfo>(() => {
		return {
			windowSize: this.windowSize(),
			windowOrientation: this.windowOrientation(),
			mediaBreakpoint: this.mediaBreakpoint(),
			isTouch: this.touch(),
			deviceType: this.deviceType(),
		};
	});

	/**
	 * Orientation signals
	 */
	readonly hideSidebar = signal<boolean>(!!(this.localStorage.getLocalStorage(HIDE_SIDEBAR) === 'true'));

	readonly isLandscape = computed(() => this.windowOrientation() === 'landscape');
	readonly isPortrait = computed(() => this.windowOrientation() === 'portrait');
	readonly isSquareish = computed(() => {
		if (this.screenHeight() > 0 && this.screenWidth() > 0) {
			const max = Math.max(this.screenHeight(), this.screenWidth());
			const min = Math.min(this.screenHeight(), this.screenWidth());
			return max / min <= 1.0999;
		}
		return false;
	});

	/**
	 * Computed screen sizes
	 */
	readonly isTiny = computed(() => !!this.breakpoints()[_Tiny]);
	readonly isGT_tiny = computed(() => !this.isTiny());
	readonly isTinyScreen = this.isTiny;

	readonly isXSmall = computed(() => !!this.breakpoints()[_XSmall]);
	readonly isGT_xsmall = computed(() => !this.breakpoints()[_XSmall]);
	readonly xSmallScreen = this.isXSmall;
	readonly gtXSmallScreen = this.isGT_xsmall;

	readonly isSmall = computed(() => !!this.breakpoints()[_Small]);
	readonly isLT_small = computed(() => XSmall.includes(this.windowSize()));
	readonly isGT_small = computed(() => !XSmall_Small.includes(this.windowSize()));
	readonly smallScreen = this.isSmall;
	readonly ltSmallScreen = this.isLT_small;
	readonly gtSmallScreen = this.isGT_small;

	readonly isMedium = computed(() => !!this.breakpoints()[_Medium]);
	readonly isLT_medium = computed(() => XSmall_Small.includes(this.windowSize()));
	readonly isGT_medium = computed(() => Large_XLarge.includes(this.windowSize()));
	readonly mediumScreen = this.isMedium;
	readonly ltMediumScreen = this.isLT_medium;
	readonly gtMediumScreen = this.isGT_medium;

	readonly isLarge = computed(() => !!this.breakpoints()[_Large]);
	readonly isLT_large = computed(() => XSmall_Small_Medium.includes(this.windowSize()));
	readonly isGT_large = computed(() => XLarge.includes(this.windowSize()));
	readonly largeScreen = this.isLarge;
	readonly ltLargeScreen = this.isLT_large;
	readonly gtLargeScreen = this.isGT_large;

	readonly isXLarge = computed(() => !!this.breakpoints()[_XLarge]);
	readonly isLT_xlarge = computed(() => !this.breakpoints()[_XLarge]);
	readonly xLargeScreen = this.isXLarge;
	readonly ltXLargeScreen = this.isLT_xlarge;

	readonly isHuge = computed(() => !!this.breakpoints()[_Huge]);
	readonly isLT_huge = computed(() => !this.breakpoints()[_Huge]);
	readonly isHugeScreen = this.isHuge;

	/**
	 * Theme values
	 */
	readonly currentTheme = signal<string>('');
	public themeClasses: string = '';

	readonly cssClasses = computed<string>(() => {
		return this.setClasses().join(' ');
	});

	readonly windowClasses = computed<WindowClass[]>(() => {
		return this.setClasses();
	});

	readonly uiMode = signal<UiMode>(DEFAULT_UI_MODE);

	readonly uiModeClass = computed(() => {
		return this.uiMode() === 'darkMode' ? 'dark' : 'light';
	});
	readonly notModeClass = computed(() => {
		return this.uiMode() !== 'darkMode' ? 'dark' : 'light';
	});

	/**
	 * Computed signals
	 */

	readonly themeclasses = computed(() => {
		const result = this.mergeClasses([this.currentTheme(), this.uiModeClass(), this.cssClasses()]);
		return result;
	});

	readonly sizeCategory: Signal<SizeCategory> = computed(() => {
		if (this.windowSize() === _XSmall) return 'Mobile';
		if (this.windowSize() === _Small || this.windowSize() === _Medium) return 'Tablet';
		return 'Desktop';
	});

	readonly resized: Signal<WidthAndHeight> = computed(() => ({
		width: this.screenWidth(),
		height: this.screenHeight(),
		windowSize: this.windowSize(),
	}));

	readonly deviceDesc = computed(() => {
		return [this.deviceInfo().os, `${this.deviceInfo().browser}/${this.deviceInfo().browser_version}`].join('; ');
	});

	readonly isTouch: WritableSignal<boolean> = signal<boolean>(
		!!(navigator.userAgent.match(/mobi/i) || navigator.maxTouchPoints),
	);

	readonly sidbarIsHidden = computed(() => {
		return !!(this.isTouch() || MINIMUM_SIZES.includes(this.windowSize()) || this.hideSidebar() === true);
	});

	constructor() {
		console.debug(`${this.constructor.name} - CONSTRUCTOR`);
		/**
		 * initialize uiMode
		 */
		if (this.localStorage.getLocalStorage(UIMODE_ITEM)) {
			const uiMode = this.localStorage.getLocalStorage(UIMODE_ITEM);
			if (isUiMode(uiMode)) this.uiMode.set(uiMode);
		}

		/**
		 * save hide_sidebar setting
		 */
		effect(() => {
			this.localStorage.setLocalStorage(HIDE_SIDEBAR, String(this.hideSidebar()));
		});

		/**
		 * Process change to uiMode
		 */
		effect(() => {
			console.debug(`${this.constructor.name} - EFFECT - uiMode=${this.uiModeClass()}`);

			/**
			 * save uiModeClass for use next time user logs in
			 */
			this.localStorage.setLocalStorage(UIMODE_ITEM, String(this.uiMode()));

			const addClass = this.uiModeClass();
			const removeClass = this.notModeClass();

			/**
			 * Update overlay container for Angular
			 */
			const overlayContainer = this.overlay?.getContainerElement();
			if (overlayContainer) {
				overlayContainer.classList.add(addClass);
				overlayContainer.classList.remove(removeClass);
			}

			const body = document.body;
			body.classList.add(addClass);
			body.classList.remove(removeClass);
		});
	}

	monitorBreakpoints() {
		if (!this.breakpoints$) this.breakpoints$ = this.breakpointQuery.subscribe();
	}

	breakpointChange(response: FT_BreakpointOutput) {
		console.debug(`${this.constructor.name} - breakpointChange - response=`, response);
		this.breakpoints.set(response.breakpoints);
		this.screenWidth.set(response.screenWidth);
		this.screenHeight.set(response.screenHeight);
		this.windowWidth.set(response.windowWidth);
		this.windowHeight.set(response.windowHeight);

		/**
		 * Update device info on breakpoint change
		 */
		this.deviceInfo.set(this.getDeviceDetectorInfo());
	}

	private getDeviceDetectorInfo(): DeviceDetectorInfo {
		this.deviceService.device;
		const deviceInfo = this.deviceService.getDeviceInfo();
		const isMobile = this.deviceService.isMobile();
		const isTablet = this.deviceService.isTablet();
		const isDesktop = this.deviceService.isDesktop();
		console.debug(
			`${this.constructor.name} - getDeviceDetectorInfo - isDesktop=${isDesktop}, isMobile=${isMobile}, isTable=${isTablet}, deviceInfo=`,
			deviceInfo,
		);
		return { ...deviceInfo, isMobile: isMobile, isTablet: isTablet, isDesktop: isDesktop };
	}

	private checkAndUpdateState(breakpointState: BreakpointState) {
		/**
		 * Set breakpoints that are matched
		 */
		const breakpoints: { [key: string]: boolean } = {};
		for (const [key, query] of Object.entries(MergedBreakpoints)) {
			const isMatched = this.breakpointObserver.isMatched(query);
			breakpoints[key] = !!breakpointState.breakpoints[query];
		}

		// Create response with all dimensions
		const response: FT_BreakpointOutput = {
			breakpoints: breakpoints,
			screenWidth: window?.screen.width ?? 0,
			screenHeight: window?.screen.height ?? 0,
			windowWidth: window?.innerWidth ?? 0,
			windowHeight: window?.innerHeight ?? 0,
		};
		/**
		 * update values
		 */
		this.breakpointChange(response);
	}

	toggleHideSidebar(): boolean {
		this.hideSidebar.update((value) => !value);
		return this.hideSidebar();
	}

	setDarkMode(darkMode: boolean) {
		const uiMode: UiMode = darkMode ? 'darkMode' : 'lightMode';
		if (uiMode !== this.uiMode()) {
			this.uiMode.set(uiMode);
		}
	}

	toggleDarkMode() {
		this.uiMode.update((value) => (value === 'darkMode' ? 'lightMode' : 'darkMode'));
	}

	mergeClasses(classStrings: string[]): string {
		if (!classStrings || classStrings.length === 0) return '';

		const classes = classStrings.join(' ').replace(/\s+/g, ' ').trim();
		return classes;
	}

	setCurrentTheme(currentTheme: string): void {
		this.currentTheme.set(currentTheme);
	}

	// set application classes based on screen size
	setClasses(): WindowClass[] {
		// touch class
		const classes: WindowClass[] = [];
		if (this.isTouch()) {
			classes.push('is-touch');
		}

		// size class
		if (this.windowSize() === _XSmall) {
			classes.push('is-xsmall');
			classes.push('xsmall-typography');
			classes.push('lt-xlarge', 'lt-large', 'lt-medium', 'lt-small');
		} else if (this.windowSize() === _Small) {
			classes.push('is-small');
			classes.push('small-typography');
			classes.push('gt-xsmall');
			classes.push('lt-xlarge', 'lt-large', 'lt-medium');
		} else if (this.windowSize() === _Medium) {
			classes.push('is-medium');
			classes.push('medium-typography');
			classes.push('gt-xsmall', 'gt-small');
			classes.push('lt-xlarge', 'lt-large');
		} else if (this.windowSize() === _Large) {
			classes.push('is-large');
			classes.push('gt-xsmall', 'gt-small', 'gt-medium');
			classes.push('lt-xlarge');
		} else if (this.windowSize() === _XLarge) {
			classes.push('is-xlarge');
			classes.push('gt-xsmall', 'gt-small', 'gt-medium', 'gt-large');
		}

		// device orientation
		if (this.windowOrientation()) {
			classes.push(this.windowOrientation());
		}

		if (this.mediaBreakpoint()) classes.push(this.mediaBreakpoint());
		if (this.isTiny()) classes.push('is-tiny');
		if (this.isHuge()) classes.push('is-huge');

		// const cssClasses = classes.join(' ');
		return classes;
	}
}
